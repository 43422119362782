import React, { useEffect, useState, useContext } from "react"
import API from "../utils/API"
import { auth } from "../utils/FirebaseConfig"

export const AuthContext = React.createContext()
export const useAuth = () => useContext(AuthContext)

export function AuthProvider({ children }) {
  const [userInfo, setUserInfo] = useState()
  const [loggedUserInfo, setLoggedUserInfo] = useState()
  const [emailVerifed, setEmailVerifed] = useState(false)
  const [loading, setLoading] = useState(true)

  function signUp(email, password,name) {
    return auth.createUserWithEmailAndPassword(email, password).then((userCredential)=>{
      // send verification mail.
    userCredential.user.sendEmailVerification();
    userCredential.user.updateProfile({
      displayName: name,
      
    });
  })
  .catch(alert);
  }

  function isEmailVerifed() {
    return emailVerifed;
  }
  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password).then(authUser => {

      if(authUser.user.emailVerified){
        console.log('email  verified') //This will return true or false
       /* API.getUserById(authUser.user.email).then(userData => {
          setLoggedUserInfo(userData.data);
        });*/
        setEmailVerifed(true);
      }else{
        console.log('email not verified')
        setEmailVerifed(false);
      }
      }).catch(function(error) {
      });
  }
  function logout() {
    localStorage.setItem('companyFilter','');
    localStorage.setItem('branchFilter','')
    localStorage.setItem('companyFilterProduct','');
    localStorage.setItem('branchFilterProduct','')
    localStorage.setItem('companyFilterCampaign','');
    localStorage.setItem('branchFilterCampaign','')
    localStorage.setItem('companyFilterTransactions','');
    localStorage.setItem('branchFilterTransactions','')
    localStorage.setItem('companyFilterBills','');
    localStorage.setItem('branchFilterBills','')
    localStorage.setItem('companyFilterUsers','')
    localStorage.setItem('companyFilterConReport','');
    localStorage.setItem('branchFilterConReport','')
    localStorage.setItem('companyFilterSyncCatalog','');
    localStorage.setItem('branchFilterSyncCatalog','')
    return auth.signOut()
  }
  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
     // console.log("user>>>>>>>>>>>>>>>>>",user);
      if(user)
      {
      
      API.getUserById(user.email).then(userData => {
        if((user && user.emailVerified) || (userData.data!=undefined && userData.data!=null && userData.data!="" && userData.data.isShopify=="Y"))
        {
        setLoggedUserInfo(userData.data);
        setUserInfo(user)
      }
      else
      {
      setUserInfo(null);
     // setLoggedUserInfo(null);
      }
      });
       }
      else
      {
      setUserInfo(null);
     // setLoggedUserInfo(null);
      }

      setLoading(false)
      
    })
  
   /* const unsubscribe1 = auth.onCreate((user) => {
      setUserInfo(null)
      setLoading(false)
      
    })*/

    return unsubscribe
  }, [])

  const value = {
    userInfo,
    signUp,
    login,
    isEmailVerifed,
    logout,
    resetPassword,
    loggedUserInfo,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
