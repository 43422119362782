import React, { useEffect, useState,Fragment } from "react"
import { useAuth, useStore } from "../context"
import { Form, InputGroup } from "react-bootstrap"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import { NavLink } from "react-router-dom"
import API from "../utils/API"
import { useHistory } from "react-router-dom";
import { useForm, useStep } from "react-hooks-helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const defaultData = {
    
    tab:"",
    keyword:"",
    category:"",
    section:"",
    sectionName:"",
    suggestedBid:"",
    bid:"",
    
  };

function SelectSponsoredKeyword(props) {
  
    
    const [selectedCategoryId,setSelectedCategoryId] = useState();
    const [selectedCategory,setSelectedCategory] = useState();
    const [sectionList, setSectionList] = useState([]);
    const [form, setForm] = useForm(defaultData);
    const { categoryData, storeData, getMainCategories, getStoreByCompany } = useStore();
    const { tab,category,section,sectionName,suggestedBid,bid,keyword } = form;
    const [validated, setValidate] = useState(false);
    const { userInfo,loggedUserInfo } = useAuth();
    const [inputFields, setInputFields] = useState([]);
    const [resetField, setResetField] = useState();
    const [selectedSection, setSelectedSection] = useState("");
    const history = useHistory();
    useEffect(() => {
        if (loggedUserInfo != undefined) {
        getMainCategories();
        }
        if(props.selectedKeyword!=undefined&&props.selectedKeyword!=null)
        setInputFields(props.selectedKeyword);

        
      }, []);

     
      const handleSubmit = async (e) => {

        props.setIsOpen(false);
        props.onSelectSection(selectedCategoryId);
        //history.push(`/home/products/addProduct?catId=`+selectedCategoryId)
      };


      const handleCategoryChanage = async (e) => {
        console.log("event>>>>>>>>>",e.target.value);
        if(e.target.value!=undefined && e.target.value!='')
        {
         // setSelectedCategory(e.target.value);
          form.category=e.target.value;
          if(form.tab!=''&& form.tab!=undefined)
          {
            API.getSectionByCategory(e.target.value,form.tab).then((sectionResult) => {  
                         
              if(sectionResult.data!=undefined && sectionResult.data.length>0)
              {
                setSectionList(sectionResult.data);
            
              }
              else
              {
                setSectionList([]);
              }
                   })
                   .catch((error) => {
                     console.log(error);
                     alert(error);
                  });
          }
          //form.categoryName=e.nativeEvent.target[index].text;
        }
      };

      const handleSectionChanage = async (e) => {
        console.log("event>>>>>>>>>",e.target.value);
        if(e.target.value!=undefined && e.target.value!='')
        {
         // setSelectedCategory(e.target.value);
          form.section=e.target.value;
          var index = e.nativeEvent.target.selectedIndex;
        form.sectionName=e.nativeEvent.target[index].text;
        setSelectedSection(e.target.value);
        }
    };
      const addSection = async (e) => {

         if (form.keyword == '' || form.tab == keyword) {
            toast.error("Please enter keyword")
           
          }
          else if (form.category == '' || form.category == undefined) {
            toast.error("Please select category")
           
          }
          
          else if(form.keyword != '' && form.category != '')
          {
            handleAddFields(form.keyword,form.category);
            form.keyword="";
            form.category="";
            
            setResetField("");

          }

      };

      const saveSection = async (e) => {

        if(inputFields.length==0)
        {
            toast.error("Please add keyword")
        }
        else
        {
        props.setIsOpen1(false);
        props.onSelectKeyword(inputFields);
        }
        console.log("keywords values>>>>>>>>>>",inputFields);

       

     };

     const cancelSection = async (e) => {

        props.setIsOpen1(false);
        

     };


      const handleAddFields = (keyword,category) => {
        const values = [...inputFields];
        values.push({ keyword:keyword,category: category, suggestedBid: '0.5', bid: '0' });
        setInputFields(values);
      };
      
      const handleRemoveFields = index => {
        const values = [...inputFields];
        values.splice(index, 1);
        setInputFields(values);
      };
      
      const handleInputChange = (index, event) => {
        const values = [...inputFields];
        if (event.target.name === "keyword") {
          values[index].keyword = event.target.value;
        }else if(event.target.name === "suggestedBid")
        {
            values[index].suggestedBid = event.target.value;
        }
        
        else {
          values[index].bid = event.target.value;
        }
      
        setInputFields(values);
      };


  return (
    <Form noValidate validated={validated} action="#">
      <h4 class="mb-3"><strong>Configure Sponsored Keyword</strong></h4> 
<div className="row mb-4">
    <div className="col-md-6">
    <div className="bg-white p-4 shadow-sm rounded-lg">
    <InputGroup className="mb-3">
                 <Form.Group className="col-6">
            <Form.Label>Enter Keyword</Form.Label>
            <Form.Control
              name="keyword"
              type="text"
             value={keyword}
              onChange={setForm}
              //onChange={e =>  form.campaignName=e.target.value }
              required
            />
            <Form.Control.Feedback type="invalid" >Please enter keyword</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="col-6">
            <Form.Label>Select Category</Form.Label>
            <Form.Control
              as="select"
              className="mr-sm-2"
              id="inlineFormCustomSelect1"
              name="category"
              custom
              value={category}
              onChange={setForm}
              required
            >
              <option></option>
              <option value="Trending">Trending</option>
              {categoryData.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
              
            </Form.Control>
            <Form.Control.Feedback type="invalid" >Please select category</Form.Control.Feedback>
          </Form.Group>
                   </InputGroup>

                <div className="text-center">
                 <Button variant="contained" color="primary" onClick={(e) =>addSection(e)} className="btn btn-primary">Add Keyword</Button>
                 </div>
        </div>
        </div>
        <div className="col-md-6">
        <div >
        <h5>Added Keywords</h5>

        <InputGroup className="mb-3">
        {inputFields.map((inputField, index) => (
            <Fragment key={`${inputField}~${index}`}>
        <Form.Group className="col-4">
                   <Form.Label>{index==0?"Keyword":""}</Form.Label>
                   <Form.Control 
                   name="keyword"
                   value={inputField.keyword}
                   onChange={event => handleInputChange(index, event)}
                   readOnly={true}
                   type="text"
                   required
                   />
                    <Form.Control.Feedback type="invalid" >Please enter Suggested bid</Form.Control.Feedback>
                 </Form.Group>

                   <Form.Group className="col-3">
                   <Form.Label>{index==0?"Suggested Bid":""}</Form.Label>
                   <Form.Control 
                   name="suggestedBid"
                   value={inputField.suggestedBid}
                   onChange={event => handleInputChange(index, event)}
                   type="number"
                   required
                   />
                    <Form.Control.Feedback type="invalid" >Please enter Suggested bid</Form.Control.Feedback>
                 </Form.Group>
                 <Form.Group className="col-3">
                   <Form.Label>{index==0?"Enter Bid":""}</Form.Label>
                   <Form.Control 
                   name="bid"
                   value={inputField.bid}
                   onChange={event => handleInputChange(index, event)}
                   type="number"
                   required
                   />
                    <Form.Control.Feedback type="invalid" >Please enter Valid bid</Form.Control.Feedback>
          </Form.Group>
          <a href="#"  className="mt-4" onClick={() => handleRemoveFields(index)}>
          <svg fill="#000000" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="20px" height="20px"><path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"/></svg>
        </a>
            </Fragment>
          ))}
                 </InputGroup>
                 {inputFields.length>0?
                 <div className="text-center">
                 <Button variant="contained" color="primary" onClick={(e) =>saveSection(e)} className="btn btn-primary">Save Keywords</Button>
                 <Button variant="contained" color="primary" onClick={(e) =>cancelSection(e)} className="btn btn-primary ml-2">Cancel</Button>
                 </div>
                 :""}
            </div>
            </div> 
        </div>  
        <ToastContainer />
        </Form>
      )
}

export default SelectSponsoredKeyword


