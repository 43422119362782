import React, { useEffect,useState } from "react";
import { Form, Button, InputGroup, Alert } from "react-bootstrap";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { useAuth,useUser,useStore } from "../context";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Navigation } from "@material-ui/icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import {createUser} from "../utils/API";
import API from "../utils/API";
import { auth } from "../utils/FirebaseConfig"
import { useForm, useStep } from "react-hooks-helper";
import { uuid } from '../utils/uuid'

const defaultData = {
  businessName: "",
  companyLogo: "",
  countryBA: "",
  streetBA: "",  
  cityBA:"",
  provinceBA:"",
  postalCodeBA:"",
  AppartmentName:"",

};

const country = ["Canada"];

const FormContainer = styled.form`
  width: 400px;
  background-color: #fff;
  padding: 50px;
  margin-top: 150px;
  box-shadow: 0 0 20px silver;
  border-radius: 10px;
`;
const RegisterLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px 25px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px silver;
  padding: 25px;
  :hover {
    text-decoration: none;
  }
`;

const AddCompanyLogo = (props) => {
  const companyId=new URLSearchParams(props.location.search).get("id");
  console.log("updated companyId id>>>>>>>>>>>>>",companyId);
  const [cities,setCities]=useState([]);
  const { stateData, getState} =useStore();
  const [form, setForm] = useForm(defaultData);
  const [fileUrl,setFileUrl]=useState();
  const [mobile, setMobile] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [btnDisable, setBtnDisable] = useState("");
  const [selectedLogoFile, setSelectedLogoFile] = useState("");
  const [validated,setValidate]=useState(false);
  const [companyData,setCompanyData]=useState(false);
  const { resetPassword } = useAuth();
  const { roles, getRoles } = useUser();
  //const { userInfo } = useAuth();
  const { businessName, companyLogo, countryBA,streetBA,cityBA,provinceBA,postalCodeBA,AppartmentName } = form;
  const { signUp, userInfo,loggedUserInfo } = useAuth();
  console.log("loggedUserInfo>>>>>>>>>>>",loggedUserInfo);
  console.log("userInfo>>>>>>>>>>>",userInfo);
  useEffect(() => {
    getState("gust");
    getRoles(userInfo.email);
    if(companyId!=undefined && companyId!=null)
    {
      API.getCompanyId(companyId).then((result) => {  
       
        console.log("user data>>>>>>>>>>>>>>",result)
        if(result.data!=undefined)
        {
          setCompanyData(result.data)
          setFileUrl(result.data.logo);
         
          
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error);
     });

    }
   

  }, [companyId]);



  const handleSubmit = async (e) => {
    try {
      setValidate(true);
      console.log("otp>>>>>>>>>>>>>>>>>>",form.otp);
  
     if(selectedLogoFile!=undefined && selectedLogoFile!="")
    {
      try {
       
            if(selectedLogoFile!=undefined && selectedLogoFile!="")
            var imageExtension = selectedLogoFile.name.substring(selectedLogoFile.name.indexOf('.'),selectedLogoFile.name.length)
      
            var uploadImageName="";
            var uploadFolder="";
            if(companyData.name!=undefined)
            {
               uploadImageName=companyData.name.replace(/\s+/g, '')
              uploadFolder=companyData.name.replace(/\s+/g, '')
            }
              uploadImageName=uploadImageName+"_logo"
    
            var id=""
            if(companyId!=undefined && companyId!=null)
            {
              id=companyId;
            }
            else
            {
              id=uuid();
            }
    
            let companyBody = {
              id:id,
              name:companyData.name,
              regName:companyData.regName,
              corporationId:companyData.corporationId,
              website:companyData.website,
              managerName:companyData.managerName,
              managerMobile:companyData.managerMobile,
              managerEmail:companyData.managerEmail,
              isShopify:companyData.isShopify,
              logo:selectedLogoFile!=undefined && selectedLogoFile!=""?API.fullBucketURL+uploadFolder+"/"+uploadImageName+imageExtension:form.companyLogo,
              active:"Y",
              address1:companyData.address1,
              address2:companyData.address2,
              pincode:companyData.pincode,
              cityId:companyData.cityId,
              stateId:companyData.stateId,
              countryId:companyData.countryId,
              createdBy:companyId!=undefined && companyId!=null?companyData.createdBy:userInfo.email,
              updatedBy:userInfo.email,
             };
             if(companyId!=undefined && companyId!=null)
             {
              API.updateCompany(userInfo.email,companyBody);
             }
            
             if(selectedLogoFile!=undefined && selectedLogoFile!="")
           {
            uploadCompanyLogo(selectedLogoFile,uploadFolder);
           }
    
             setTimeout(() => { 
                
              history.push(`/home/company`)
            
            }, 3000);
          
   


      
       // await login(`${email}`, `${password}`)
        //history.replace("/home")
        //    history.replace("/home");
      } catch (err) {
        console.log(err);
      }
    }
    else
    {
      toast.error("Please select logo for update")
    }
    
    } catch (e) {
      console.log(e);
    }
   
  };

  const uploadCompanyLogo = async (logoFile,companyName) => {
    const formData = new FormData()
    formData.append('file', logoFile)
    console.log("logoFile>>>>>>>>>>>",logoFile);
    if (logoFile !== null) {
      //store.dispatch('setOverlay', { showing: true })
      var imageName = logoFile.name.substring(0, logoFile.name.indexOf('.'))
      API.uploadCompanyLogo(formData, companyName,"storeId")
        .then(response => {
          if (response.isAxiosError) {
           // store.dispatch('setOverlay', { showing: false })
            //store.dispatch('setSnackbar', { color: 'error', text: 'Something went wrong please try again' })
          } else {
            //store.dispatch('setOverlay', { showing: false })
            //store.dispatch('setSnackbar', { text: 'Company Logo Uploaded successfully' })
          }
        }).catch(response => {
         // store.dispatch('setOverlay', { showing: false })
          // error callback
        })
    }
    // API.setUploadLogo(formData)
  }

  const history = useHistory();
  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
        this.validateSignUp(e);
    }
}

const handleRoleChange = async (e) => {
  //console.log("event>>>>>>>>>",e.target.value);
  form.roleId=e.target.value;

};

const uploadLogo =  (e) => {
  form.companyLogo=e.target.files[0].name;
  //form.fileObject=Array.from(e.target.files)
  setSelectedLogoFile(e.target.files[0]);
  //setFileUrl(URL.createObjectURL(e.target.files[0]));
  setFileUrl(e.target.files[0].name);
  console.log("setSelectedLogoFile>>>>>>>>>>>>",selectedLogoFile);
  
    //setForm(form.companyLogo);
}

  return (
    <>
          <Form noValidate validated={validated} action="#" style={style.form}>
          <InputGroup>
      <Form.Group className="col-1">
          <Button onClick={(e) =>history.goBack()} variant="primary"   type="button">
            Back
          </Button>
        </Form.Group>
      </InputGroup>
      <center className="mb-5">
        <Form.Label as="legend">Update Company Logo</Form.Label>
      </center>
      
      <InputGroup className="mb-2">
     
      <Form.Group controlId="validationbusinessName" className="col-3">
      <Form.Label>Company Logo</Form.Label>
          <Form.File
        id="custom-file"
        label={companyLogo}
        accept="image/*"
        custom
        name="companyLogo"
        //onChange={setForm}
        onChange={uploadLogo}
      />
      <p>{fileUrl}</p>
      <strong className="mt-2">For Best view please upload logo with size 500 x 250</strong>
      </Form.Group >
          </InputGroup>
          
        
        
          <InputGroup className="mb-3">
          <Form.Group className="col-2">
          <Button
          onClick={(e) =>handleSubmit(e)}
            variant="primary"
            type="button"
            className="w-100"
          >
            Submit
          </Button>
          </Form.Group>
      </InputGroup>
          </Form>
       
      <ToastContainer />
    </>
  );
};

export default AddCompanyLogo;

const style = {
  backButton: {
    fontSize: "25px",
    marginBottom: "10px",
    color: "blue",
    cursor: "pointer",
  },
};
