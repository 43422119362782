import React from "react"
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom"
import BillOrdersView from "../domain/BillOrdersView"
import ConversionReport from "../domain/ConversionReport"
import ConversionReportAllStore from "../domain/ConversionReportAllStore"
import ManageTransactions from "../domain/ManageTransactions"

function ConsolidateConversionReport() {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/consolidateConversionReport`} component={ConversionReportAllStore} />
      <Route path={`${path}/viewBillOrder`} component={BillOrdersView} />
      <Redirect to={`${path}/consolidateConversionReport`} />
    </Switch>
  )
}

export default ConsolidateConversionReport
