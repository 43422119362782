import React, { useEffect, useState } from "react"
import { useStore, useAuth } from "../context"
import { Stores } from "."
import MUIDataTable from "mui-datatables"
import Button from "@material-ui/core/Button"
import AddRoundedIcon from "@material-ui/icons/AddRounded"
import { NavLink } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom";
//import Modal from "react-modal";
import {Modal} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import API from "../utils/API";
import Moment from 'react-moment';
import {Form} from 'react-bootstrap';

const useStyles = makeStyles(() => ({
  tab: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    padding: "0 10px",
    boxShadow: "0 0 15px silver",
  },
  button: {
    "&:hover": {
      color: "#fff",
    },
  },
}))




function CatalogProductDetails(props) {

  let catId=new URLSearchParams(props.location.search).get("id");
  const [productList, setProductList] = useState([]);
  const { userInfo,loggedUserInfo } = useAuth();
  const classes = useStyles()
  const [companyList, setCompanyList] = useState([]);
  const history = useHistory();
  const { companyData, storeData, getCompanyByUser, getStoreByCompany } =
    useStore();
  const options = {
    rowsPerPage: 10,
    print: false,
    selectableRowsOnClick: false,
    selectToolbarPlacement: "none",
    selectableRows:"none",
   /* customToolbar: () => (
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddRoundedIcon />}
       // onClick={toggleModal}
        component={NavLink}
        to={"/home/categories/AddCategory"}
      >
        Add Category
      </Button>
    ),*/
    
  }

  useEffect(() => {
    if(loggedUserInfo!=undefined)
   {
    API.getAllCompany(loggedUserInfo.email).then((companyResult) => {  
       
      if(companyResult.data!=undefined && companyResult.data.length>0)
      {
        setCompanyList(companyResult.data)
       
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });

        }
     API.getProductsByCataLog(catId).then((result) => {  
       
      if(result.data!=undefined && result.data.length>0)
      {
        setProductList(result.data)
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });
        
    
  }, [catId]);


  const columns = [
    {
      name: "ProductImage",
      label: " ",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
            <img height='100' width='100'  src={value}/>
          );
        }
      },
    },
    {
    name: "ProductName",
    label: "Product Name",
    options: {
      filter: true,
      sort: true,
      
    },
  },
  {
    name: "brand",
    label: "Brand",
    options: {
      filter: true,
      sort: false,
    },
  },
    {
      name: "ProductHeading",
      label: "Product heading",
      options: {
        filter: true,
        sort: true,
        
      },
    },
    {
      name: "branch",
      label: "Branch",
      options: {
        filter: true,
        sort: false,
        display: loggedUserInfo!=null && loggedUserInfo!=undefined && loggedUserInfo.isMaster!='Y' && loggedUserInfo.isAgentUser!='Y' && !loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")?true:false
      },
    },
    {
      name: "campaignName",
      label: "Campaign",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "startDate",
      label: "Start Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
            value!=undefined?
          <Moment tz="CST" format="YYYY/MM/DD hh:mm:ss A">
          {value}
          </Moment>
          :""
          );
         }      
      },
      
    },
    {
      name: "endDate",
      label: "End Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
            value!=undefined?
          <Moment tz="CST" format="YYYY/MM/DD hh:mm:ss A">
          {value}
          </Moment>
          :""
          );
         } 
      },
      
    },
   
    
  ]



  const rowData = productList.map((item) => ({
    ProductName: item.name,
    ProductHeading: item.heading,
    branch:item.storeName,
    campaignName:item.campaignName,
    brand:item.brand,
    createdBy:item.createdBy,
    startDate:item.validFrom,
    endDate:item.validTill,
    ProductImage:item.images[0]!=undefined && item.images[0]!="" && item.images[0]!=null?item.images[0]:"https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg",
    Action:item.id,
  }));

 




  return (

    <div>
    <div className={classes.tab}>
    <Button className="mb-2 mt-2" onClick={(e) =>history.push("/home/catalog/manage?details=true")} variant="contained" color="primary" type="button">
            Back
          </Button>
</div>
   
    <MUIDataTable
        className="mb-3"
        title="Sync Catalog Products"
        data={rowData}
        columns={columns}
        options={options}
      />
      </div>
  )
}

export default CatalogProductDetails
