import React, { useState,useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button, Alert } from "react-bootstrap";
import { motion } from "framer-motion";
import { Rating } from "@material-ui/lab";
import API from "../utils/API";
import { useAuth } from "../context";


export default function RatingView(props) {
  const { userInfo } = useAuth();
  const { handleClose, handleOpen, open,billId} = props;
 
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState("");
  const [ratingMedia, setRatingMedia] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [reviewData, setReviewData] = useState([]);
  const [ratingItem, setRatingItem] = useState([]);

  useEffect(() => {

    if( billId!=undefined && billId!=null &&  billId!='')
    {
    API.getRatingByBillId(billId).then((result) => {  
      if(result.data!=undefined)
      {
        setReviewData(result.data)
        setComment(result.data.comment)
        setRatingMedia(result.data.ratingMedia)
        console.log("rating>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",result.data.rating)
        setRating(result.data.rating)
        if(result.data.ratingType=="Offer")
        {
          API.getOfferById(userInfo.email,result.data.offerId).then((result) => {  
            if(result.data!=undefined)
            {
              setRatingItem(result.data);
            }
          })
          .catch((error) => {
            console.log(error);
            alert(error);
          });
        }

        if(result.data.ratingType=="Product")
        {
          API.getProductById(userInfo.email,result.data.productId).then((result) => {  
            if(result.data!=undefined)
            {
              setRatingItem(result.data);
            }
          })
          .catch((error) => {
            console.log(error);
            alert(error);
          });
        }
      }
      else
      setRating("")

          })
          .catch((error) => {
            console.log(error);
            alert(error);
          });
        }

  },[props]);

 

  const isVideo = (reviewUrl) => {
    const videos = ["mp4", "3gp", "ogg", "mov", "wmv", "flv", "avi", "mkv"];
    const url = new URL(reviewUrl);
    const extension = url.pathname.split(/\.(?=[^\.]+$)/)[1];
    console.log("extension>>>>>>>>>>>>>>>>>>>>>>>>>>>", extension);
    let flag = false;
    if (videos.includes(extension)) {
      flag = true;
    }
    console.log("isVideo>>>>>>>>>>>>>>>>>>>>>>>>>>>", flag);
    return flag;
  }


  return (
    <Dialog
      open={open}
      keepMounted
      maxWidth="lg"
      fullScreen={false}
      onClose={() => handleClose("login")}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      
        <Form  className="reviewViewFormNew">
          
        <Form.Group controlId="formBasicEmail">
            <Form.Label>{reviewData.ratingType} Rating</Form.Label>
            <Form.Control
              name="comment"
              as="textarea" rows={2}
              disabled={true}
              value={ratingItem.heading}
              
            />
          </Form.Group>
          
                  <Rating
          emptySymbol="fa fa-star-o fa-2x"
          fullSymbol="fa fa-star fa-2x"
         // fractions={2}
         value={rating}
          defaultValue={rating}
         
          disabled={true}
          className="ratingForm"
          
        />

          <Form.Group controlId="formBasicEmail">
            <Form.Label>Comment</Form.Label>
            <Form.Control
              name="comment"
              as="textarea" rows={3}
              disabled={true}
              value={comment}
              
            />
          </Form.Group>

          <Form.Label>Uploaded Media</Form.Label>
      {ratingMedia!=null && ratingMedia!=undefined&& ratingMedia.length>0?
      ratingMedia.map((item, index) => (
       <p> {isVideo(item)?
        <video width="360" height="215" controls autoPlay={false} muted>
              <source
                src={item}
                type={"video/" + item.split(/\.(?=[^\.]+$)/)[1]}
              />
            </video>
        :<img height={130} width={200} src={item}/>}</p>
        ))

      :""}

          <Button
           variant="primary"
           type="button"
           onClick={(e) =>handleClose("login")}
           className="w-30 ml-4 mt-4 mb-4"
         >
           Okay
         </Button>
          
        </Form>
       
      </motion.div>
    
    </Dialog>
  );
}
