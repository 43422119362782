
import { Form, InputGroup } from "react-bootstrap"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import React, { useEffect, useState } from "react";
import { useAuth, useUser, useStore } from "../context";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Navigation } from "@material-ui/icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import {createUser} from "../utils/API";
import API from "../utils/API";
import { useForm, useStep } from "react-hooks-helper";
import { useHistory } from "react-router-dom";
import { uuid } from '../utils/uuid'
import eventBus from '../utils/eventBus'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { tr } from "date-fns/locale";
import { toDate } from "date-fns";
import PaypalExpressBtn from "react-paypal-express-checkout";
import { PayPalButton } from "react-paypal-button-v2";
import { clientNew } from "../utils/paypalConfig";
import MUIDataTable from "mui-datatables"
import { maxCharge, minCharge, minItem } from "../utils/campaignConfig";
import Moment from 'react-moment';
import moment from 'moment';
import MuiAlert from "@material-ui/lab/Alert";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {PaymentElement} from '@stripe/react-stripe-js';
import CheckoutForm from "./CheckoutForm";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import SaveCardCheckoutForm from "./SaveCardCheckoutForm";


const useStylesTop = makeStyles((theme) => ({
  root: {
      '& > *': {
          margin: theme.spacing(1),
      },
  },
  extendedIcon: {
      marginRight: theme.spacing(1),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));




const defaultData = {
  promotionArea: "",
  cityList:[],
  stateList: [],
  promocode:"",
  fromDate:"",
  toDate:"",
  campaignName:"",
  campaignFor:"",
  budget:"",
  cpc:"",
  companyId:"",
  storeId:"",
  
};



const stripePromise = loadStripe('pk_test_51KNKwXAAZoI1RaarGpFnvqCiaE6w2xz52BZa2emBXU5ohEsI2kcYM70nPJzKtu002nWGZUBOGtJkJxKG0tKmZGil00LIoP11RU');

function PaymentMethod(props) {

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  //const areEqual = (prevProps, nextProps) => true;
 // const StartPromotionBatch = React.memo(props => {
  const appearance = {
    theme: 'stripe'
  }

  
 // elements = stripe.elements({options, appearance});
 const [clientSecret, setClientSecret] = useState("");
 const [stripPaymentoptions, setStripPaymentoptions] = useState("");
 const [cardList, setCardList] = useState([]);
 const [cardId, setCardId] = useState("");
 const [paymentIntent, setPaymentIntent] = useState("");
 const [returnURL, setReturnURL] = useState("");
 const [isPayLoading, setIsPayLoading] = useState(false);
  const campagainType=new URLSearchParams(props.location.search).get("type");
  const campagainId=new URLSearchParams(props.location.search).get("id");
  console.log("campagainType>>>>>>>>>>>>>>>>>>>",campagainType);
  console.log("campagainId>>>>>>>>>>>>>>>>>>>",campagainId);
  const [form, setForm] = useForm(defaultData);
  const [pendingCompanyOffer, setPendingCompanyOffer] = useState([]);
  const [validated, setValidate] = useState(false);
  const { signUp, userInfo, loggedUserInfo } = useAuth();
  const [companyList, setCompanyList] = useState([]);
  const { companyId } = form;
  //const {storeData,getStoreByCompany } =useStore();

  
  useEffect(() => {
    if (loggedUserInfo != undefined) {
      setReturnURL(window.location.href);
      API.getAllCompany(userInfo.email).then((companyResult) => {  
       
        if(companyResult.data!=undefined && companyResult.data.length>0)
        {
          setCompanyList(companyResult.data)
         
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            }); 
      API.createPaymentIntent(userInfo.email,"0.5" ).then((intentResult) => {  
        console.log("intentResult>>>>>>>>>>>>",intentResult);
       if(intentResult.data!=undefined)
       {
         var clientSecret=intentResult.data.clientSecret;
         setStripPaymentoptions({
           clientSecret,
           appearance,
         });
         setClientSecret(intentResult.data.clientSecret)
         setCardList(intentResult.data.card)
         setPaymentIntent(intentResult.data.paymentIntentId)
        
       }
            })
            .catch((error) => {
              console.log(error);
              alert(error);
           });
    }
  }, [loggedUserInfo]);

 
  const history = useHistory();


  const onPaymentSuccessUpdateData = (payment,type) => {
    // Congratulation, it came here means everything's fine!
        console.log("The payment was succeeded!", payment);
        
        var paymentType="";
        var paymentId="";
        var payerId="";
        var paymentStatus="";
        if(type=="paypal")
        {
        if(payment.intent!=undefined && payment.intent!=null && payment.intent=="CAPTURE")
        {
          paymentType="Card";
          paymentId=payment.id;
          payerId=payment.payer.payer_id;
          if(payment.status=="COMPLETED")
          {
            paymentStatus="Paid"
          }
          else
          {
            paymentStatus="Fail"
          }
        }
        else
        {
          paymentType="PayPal";
          paymentId=payment.paymentID;
          payerId=payment.payerID; 
          if(payment.paid)
          {
            paymentStatus="Paid"
          }
          else
          {
            paymentStatus="Fail"
          }
        }
      }
      if(type=="stripeExist")
      {
        paymentType="card";
        paymentId=payment["id"];
        payerId="";
        paymentStatus=payment["status"];
      }
      else
      {
        paymentType="card";
        paymentId=payment.id;
        payerId=payment.payment_method;
        paymentStatus=payment.status;
  
      }

      let ptBody = {
        id:uuid(),
        transactionType:"Card Verification",
        status:paymentStatus,
        //offerId:totalSelectedOffer,
        userId:userInfo.email,
        //companyId:totalSelectedOffer[0].companyId,
        //storeId:form.storeId,
        paymentId:paymentId,
        payerId:payerId,
        paymentType:paymentType,
       // campaignId:campaognResult.data.id,
        amount:0.5,
       };
      let invoiceBody = {
        id:uuid(),
        invoiceType:"Card Verification",
        status:paymentStatus,
        //offerId:totalSelectedOffer,
        userId:userInfo.email,
        //companyId:totalSelectedOffer[0].companyId,
        //storeId:form.storeId,
        paymentId:paymentId,
        payerId:payerId,
        paymentType:paymentType,
        paymentAmount:0.5,
        amount:0.5,
        discountAmount:0,
        taxAmount:0,
        campaignId:0,
        gst:0,
        hst:0,
        pst:0,
        qst:0,
       };
       API.createPaymentTransaction(userInfo.email,ptBody);

       API.createInvoice(userInfo.email,invoiceBody);
    }


const paymentSucessByNewCard = (paymentIntent,isCardSave) => {
console.log("paymentIntent>>>>>>>>>>>>>>>>>>>>>>>>>>>>",paymentIntent);
console.log("isCardSave>>>>>>>>>>>>>>>>>>>>>>>>>>>>",isCardSave);
switch (paymentIntent.status) {
  case "succeeded":
    if(isCardSave=="on")
    {
    API.addStripCardToCustomer(userInfo.email,paymentIntent.id);
    //window.location.reload(false);
    //setStripPaymentoptions("");
    API.createPaymentIntent(userInfo.email,"0.5" ).then((intentResult) => {  
      console.log("intentResult>>>>>>>>>>>>",intentResult);
     if(intentResult.data!=undefined)
     {
       var clientSecret=intentResult.data.clientSecret;
       setStripPaymentoptions({
         clientSecret,
         appearance,
       });
       setClientSecret(...intentResult.data.clientSecret)
       setCardList(intentResult.data.card)
       setPaymentIntent(intentResult.data.paymentIntentId)
      
     }
          })
          .catch((error) => {
            console.log(error);
            alert(error);
         });

      toast.success("Card added successfully.")   
    }
    onPaymentSuccessUpdateData(paymentIntent,"stripe");
    break;
  default:
    //onPaymentErrorUpdateData(paymentIntent,"stripe")
    break;
}

}


const deleteCustomerCard = async (cardId) => {
console.log("card going to delete>>>>>>>>>>>",cardId);
 const res= await API.deleteCustomerCard(userInfo.email,cardId);

 API.getCustomerCardList(userInfo.email).then((cardListResponse) => {  
  console.log("cardListResponse>>>>>>>>>>>>",cardListResponse);
 if(cardListResponse.data!=undefined)
 {
  toast.success("Card deleted successfully.")  
   setCardList(cardListResponse.data.card)
 }
      })
      .catch((error) => {
        console.log(error);
        alert(error);
     });
    
};

const getCompanyBranches = async (e) => {
  console.log("event>>>>>>>>>",e.target.value);
  if(e.target.value!=undefined)
  {
    form.companyId=e.target.value
   // setErrors({});
   // getStoreByCompany(e.target.value);
      }
      
};


  return (
    <>

      {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")) ? (
         <InputGroup className="mb-3 mt-4">
            <Form.Group className="col-4">
        <Form.Control
          as="select"
          className=" mr-3"
          name="company"
          value={companyId}
          onChange={getCompanyBranches}
          required
        >
          <option value="">Select Company</option>
          {companyList.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Form.Control>
        </Form.Group>

        <Form.Group className="col-4">
        <Form.Control
          as="select"
          className=" mr-3"
          name="company"
          value={companyId}
          onChange={getCompanyBranches}
          required
        >
          <option value="">Select User</option>
          {companyList.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Form.Control>
        </Form.Group>
       </InputGroup>
         ) : null}
    <Form noValidate validated={validated} action="#">
      <h4 class="mb-3"><strong>Payment Method</strong></h4> 
      <div className="row mb-4">
      
        <div className="col-md-6 mt-2">
          <div className="bg-white p-4 shadow-sm rounded-lg mt-4">
          {stripPaymentoptions!=undefined&&stripPaymentoptions!=""?
<Elements  key={paymentIntent} stripe={stripePromise} options={stripPaymentoptions}>
      <SaveCardCheckoutForm key={paymentIntent} returnURL={window.location.href} onSucessPayment={paymentSucessByNewCard} />
    </Elements>
    :""}          </div>
        </div>

        <div className="col-md-6">
            <div >
            
              <h5 className="mt-2">Your saved Credit/Debit cards</h5>
        
    <div className={classes.root}>
      
      
      
      <div>
    
    <div>
    <table class="table highlight bordered" id = "">
              <tr>
							  <th>Brand</th>
							  <th>last 4 digit</th>
							  <th>Expiry</th>							  
							</tr>	
              {cardList!= null&&cardList!=undefined&& cardList.length>0? cardList.map((listValue, index) => (
                <tbody>
					<tr>
							  <td className="align-items-center d-flex">
							  	{/*<input type="radio"  onChange={(e) =>setCardId(e.target.value)}  id="paymentMethodId" name="paymentMethodId" value={listValue.cardId} />*/}
      							<label for={index} className="mb-0 ml-2">{listValue.brand}</label>

              </td>
							  <td>**** **** **** {listValue.last4digit}</td>
							  <td> { listValue.expMonth } / { listValue.expYear }</td>
					  <td width="1%"><i  onClick={(e) =>deleteCustomerCard(listValue.cardId)} class="fa fa-trash" style={{color: "red",cursor: "pointer"}} aria-hidden="true"></i></td>
 						 </tr>
              
              
               </tbody>                																									
                        ))
                   :"No Save Card Found"}
                        </table>
  </div>

  </div>
      
    </div>



            

              
             
            </div>
          </div>
        </div>



     {/* <div className="row">
        <div className="col-md-6">
          <div className="amount-wrapper bg-white">
            <h5>Ad Library visibility
              
            </h5>
            <div className="reach">Your ad and information from your Marketplace profile may appear publicly in Ad Library search results. <a href="">Learn more</a></div>
          </div>
        </div>
  </div>*/}

       {/* <div className="row">
        <div className="col-md-6">
          <div className="amount-wrapper bg-white">
            <h5>Payment Method</h5>
            <div className="reach">You may receive more then one bill for this ad. Learn more <a href="">Learn more</a></div>
            <div className="card-deatils">
              <div className="card-side">
              <img src="https://static-00.iconduck.com/assets.00/visa-icon-512x322-wb39y8f5.png" alt="" className="mr-3" />
              <b>....4383</b>
              </div>
              <Button variant="contained" class="btn btn-secondary  ">Change</Button>
            </div>
          </div>
        </div>
      </div>*/}

    { /* <div className="promote-now amount-wrapper bg-white d-flex justify-content-between flex-wrap align-items-center">
        <div>By clicking Promote Now, you agree to Offer Dhamaka <a href="">Terms & Condition</a> | <a href="">Help Center</a></div>

        {selectedCampaignPlan==undefined || selectedCampaignPlan=='' || selectedCampaignPlan==null ?  (
      <Button variant="contained" color="primary" onClick={(e) =>handleSubmit(e)} className="btn btn-primary">Promote Now</Button>
      ):""}


      </div>*/}
      <ToastContainer />
    </Form>
    </>
  )
}



export default PaymentMethod

// const style = {
//   form: {
//     backgroundColor: "white",
//     padding: "25px",
//     boxShadow: "0 0 15px silver",
//   },
// }
