import React from "react"
import { Form, Button, Alert, InputGroup } from "react-bootstrap"
import styled from "styled-components"
import { NavLink } from "react-router-dom"
import { useAuth } from "../context"
import { useHistory } from "react-router-dom"

const FormContainer = styled.form`
  width: 500px;
  background-color: #fff;
  padding: 50px;
  margin-top: 75px;
  box-shadow: 0 0 20px silver;
  border-radius: 10px;
`


function Confirmation({ form, setForm, navigation }) {
  const { login,isEmailVerifed } = useAuth()
  const history = useHistory()
  const { isExistUser } = form;
console.log("isExistUser>>>>>>>>>>>>>>>>>in confimation",isExistUser);
  const userLogin = async (e) => {

    history.replace(`/login?from=onBoard`);
    //window.location.reload();

    // login(form.email, form.password).then(authUser => {
    //   setTimeout(() => {  history.push(`/home`); }, 3000);
      
    // })

  };

  return (
    <FormContainer className="mx-auto">
      <h3 className="text-center mb-3">Confirmation</h3>
     <h5>Welcome to ShopiAds!!!</h5>
    {!isExistUser? <h5>A Verification mail has been sent to your Email-Id.Please verify your email and proceed with Login</h5>
    : <h5>You has been onboard with existing user so reset password mail has been sent to your Email-Id.Please change your password and proceed with Login</h5>
}
      <div className="w-100 d-flex justify-content-around">
       <Button
          className="mt-3 w-50 mr-1"
         // component={NavLink}
          onClick={userLogin}
        >
         Go To login
  </Button>
      </div>
    </FormContainer>
  )
}

export default Confirmation
