import React, { useEffect, useState } from "react"
import { useAuth } from "../context"
import { Form, InputGroup } from "react-bootstrap"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import { NavLink } from "react-router-dom"
import API from "../utils/API"
import { useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables"
import Moment from 'react-moment';


function ViewOfferProduct(props) {
  
    const [mainCategoryList, setMainCategoryList] = useState([]);
    
    const { userInfo } = useAuth();
    const history = useHistory();
    useEffect(() => {
        API.getMainCategories(userInfo.email).then((result) => {  
           
          if(result.data!=undefined && result.data.length>0)
          {
              //alert('data=='+result.data);
            setMainCategoryList(result.data)
          }
               })
               .catch((error) => {
                 console.log(error);
                 alert(error);
              });
        
      }, []);

      
     
     

      const columns = [
        {
          name: "ProductImage",
          label: " ",
          options: {
            filter: false,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              //alert(value);
              return (
                <img height='100' width='100'  src={value}/>
              );
            }
          },
        },
        {
        name: "ProductName",
        label: "Product Name",
        options: {
          filter: true,
          sort: true,
          
        },
      },
      {
        name: "brand",
        label: "Brand",
        options: {
          filter: true,
          sort: false,
        },
      },
        {
          name: "ProductHeading",
          label: "Product Heading",
          options: {
            filter: true,
            sort: true,
            
          },
        },
        {
          name: "campaignName",
          label: "Campaign",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "startDate",
          label: "Start Date",
          options: {
            filter: true,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              //alert(value);
              return (
                value!=undefined&&value!=''?
              <Moment tz="CST" format="YYYY/MM/DD hh:mm:ss A">
              {value}
              </Moment>
              :""
              );
             }      
          },
          
        },
        {
          name: "endDate",
          label: "End Date",
          options: {
            filter: true,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              //alert(value);
              return (
                value!=undefined&&value!=''?
              <Moment tz="CST" format="YYYY/MM/DD hh:mm:ss A">
              {value}
              </Moment>
               :""
              );
             } 
          },
          
        },
       
        
      ]
      
      const options = {
        download: false,
        print: false,
        rowsPerPage: 10,
        selectableRowsOnClick: false,
        selectToolbarPlacement: "none",
        selectableRows:"none",
        selectableRowsOnClick: false,
        selectableRows:"none",
      }

      const activeProductData = props.offerProductData.map((item) => ({
        ProductName: item.name,
        ProductHeading: item.heading,
        campaignName:item.campaignName,
        brand:item.brand,
        createdBy:item.createdBy,
        startDate:item.validFrom,
        endDate:item.validTill,
        ProductImage:item.images[0]!=undefined && item.images[0]!="" && item.images[0]!=null?item.images[0]:"https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg",
        Action:item.id,
      }));


  return (
    <div className="">
        <div className="">
     <MUIDataTable
          title={"Offer Associate Products"}
          data={activeProductData}
          columns={columns}
          options={options}
        />
</div>
    </div>

      )
}

export default ViewOfferProduct


