import React, { useEffect, useState } from "react"
import { useStore, useAuth } from "../context"
import { Stores } from "."
import MUIDataTable from "mui-datatables"
import Button from "@material-ui/core/Button"
import AddRoundedIcon from "@material-ui/icons/AddRounded"
import { NavLink } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom";
//import Modal from "react-modal";
import {Modal} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import API from "../utils/API";
import Moment from 'react-moment';
import {Form} from 'react-bootstrap';
import moment from 'moment';
import {
  TableCell,
  TableFooter,
  TableRow,
  Box
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  tab: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    padding: "0 10px",
    boxShadow: "0 0 15px silver",
  },
  button: {
    "&:hover": {
      color: "#fff",
    },
  },
  footerCell: {
    backgroundColor: "grey",
    //backgroundColor: "#3f51b5",
    borderBottom: "none",
    color: "white",
    fontSize: 14,
    fontWeight: "bolder"
  }
}))




function ConversionReportAllStore() {

  const [reportList, setReportList] = useState([]);
  const { userInfo,loggedUserInfo } = useAuth();
  const classes = useStyles()
  const [companyList, setCompanyList] = useState([]);
  const date1 = new Date();
  const preDate = new Date();
  const date = new Date(preDate.setDate(preDate.getDate() - 30));
  const dateFrom = moment(date).format("yyyy-MM-DD");
  const dateTo = moment(date1).format("yyyy-MM-DD");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);


  const [fromDate, setFromDate] = useState(dateFrom);
  const [toDate, setToDate] = useState(dateTo);
  const history = useHistory();
  const { companyData, storeData, getCompanyByUser, getStoreByCompany } =
    useStore();
  const options = {
    rowsPerPage: 10,
    print: false,
    selectableRowsOnClick: false,
    selectToolbarPlacement: "none",
    selectableRows:"none",
    onChangeRowsPerPage(numberOfRows) {
      setRowsPerPage(numberOfRows);
    },
    onChangePage(page) {
      setPage(page);
    },
    customTableBodyFooterRender: (opts) => {
      const startIndex = page * rowsPerPage;
      const endIndex = (page + 1) * rowsPerPage;
      let sumTotal = opts.data
        .slice(startIndex, endIndex)
        .reduce((accu, item) => {
          return accu + item.data[2];
        }, 0);
      let sumTotalCommission = opts.data
        ?.slice(startIndex, endIndex)
        ?.reduce((accu, item) => {
          return accu + item.data[3];
        }, 0);
     
      return (
        <>
          {reportList.length > 0 && (
            <TableFooter className={classes.footerCell}>
              <TableRow>
                {opts.columns.map((col, index) => {
                  if (col.display === "true") {
                    if (col.name === "shop") {
                      return (
                        <TableCell key={index} className={classes.footerCell}>
                          Total
                        </TableCell>
                      );
                    } else if (col.name === "date") {
                      return (
                        <TableCell key={index} className={classes.footerCell}>
                          {""}
                        </TableCell>
                      );
                    } else if (col.name === "totalPrice") {
                      return (
                        <TableCell key={index} className={classes.footerCell}>
                          {sumTotal}
                        </TableCell>
                      );
                    } 
                    else if (col.name === "totalCommission") {
                      return (
                        <TableCell key={index} className={classes.footerCell}>
                          {sumTotalCommission}
                        </TableCell>
                      );
                    }
                    else if (col.name === "orderList") {
                      return (
                        <TableCell key={index} className={classes.footerCell}>
                          {""}
                        </TableCell>
                      );
                    }
                  }
                })}
              </TableRow>
            </TableFooter>
          )}
        </>
      );
    }
   /* customToolbar: () => (
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddRoundedIcon />}
       // onClick={toggleModal}
        component={NavLink}
        to={"/home/categories/AddCategory"}
      >
        Add Category
      </Button>
    ),*/
    
  }

  useEffect(() => {
    if(loggedUserInfo!=undefined)
   {
    API.getAllCompany(loggedUserInfo.email).then((companyResult) => {  
       
      if(companyResult.data!=undefined && companyResult.data.length>0)
      {
        setCompanyList(companyResult.data)
       
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });

          const date1 = new Date();
          const preDate = new Date();
          const date = new Date(preDate.setDate(preDate.getDate() - 30));
          const dateFrom = moment(date).format("yyyy-MM-DD");
          const dateTo = moment(date1).format("yyyy-MM-DD");

            API.getStoreShopifyBillReport("undefined","undefined",dateFrom,dateTo).then((result) => {  
             
              if(result.data!=undefined && result.data.length>0)
              {
                setReportList(result.data)
              }
              else
              {
                setReportList([])
              }
                   })
                   .catch((error) => {
                     console.log(error);
                     alert(error);
                  });
        
    }
  }, [loggedUserInfo]);



  const handleViewDeatils = async (e,billid) => {

    history.push(`/home/report/viewBillOrder?billId=`+billid)
  };


  const columns = [
    {
      name: "shop",
      label: "Shopify Shop",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "date",
      label: "Bill Date",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
          <Moment format="YYYY/MM/DD">
          {value}
          </Moment>
          );
         } 
      },
    },
    {
      name: "totalPrice",
      label: "Total Price",
      options: {
        filter: false,
        sort: true,
      },
    },  
    {
      name: "totalCommission",
      label: "Total Commission",
      options: {
        filter: false,
        sort: true, 
      },
    },
  
    {
      name: "orderList",
      label: "View Details",
      options: {
        filter: true,
        sort: false,
       // display: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              
           
            <div className="d-flex align-items-center">
            {/*<p className="mb-0 mr-3">City</p>*/}
            <Button  variant="outlined"><a href="javascript:void(0)"  onClick={(e) =>handleViewDeatils(e,value)}>View Details</a>
            </Button>
            </div>
                 
              </div>
             
          )
      }
      },
      
    },
    
   /* {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
            <Button
            variant="contained"
            color="primary"
           // startIcon={<AddRoundedIcon />}
            component={NavLink}
            to={"/home/categories/AddCategory?id="+value}
          >
            Update
          </Button>
          );
        }
      },
    },*/
    
  ];

  const rowData = reportList.map((item) => ({
    shop: item.shop,
    date: item.createdOn,
    totalPrice:parseFloat(item.totalPrice.toFixed(2)),
    totalCommission:parseFloat(item.totalCommission.toFixed(2)),
    orderList:item.id,
    //paymentType:item.paymentType,
    //status:item.status
  }));


  const getCompanyBranches = async (e) => {
    console.log("event>>>>>>>>>",e.target.value);
    if(e.target.value!=undefined && e.target.value!='')
    {
      localStorage.setItem('companyFilterConReport', e.target.value);
      getStoreByCompany(e.target.value);
        }
        
  };

  const handleBranchChanage = async (e) => {
    console.log("event>>>>>>>>>",e.target.value);
    if(e.target.value!=undefined && e.target.value!='')
    {
      localStorage.setItem('branchFilterConReport', e.target.value);
      API.getStoreShopifyBillReport("undefined",e.target.value,"undefined","undefined").then((result) => {  
             
        if(result.data!=undefined && result.data.length>0)
        {
          setReportList(result.data)
        }
        else
        {
          setReportList([])
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            });
    }
  };

  const handleFilter = async (e) => {

    var date1 = moment(fromDate)
      var date2 = moment(toDate)
     var nowDate= moment().format("yyyy-MM-DD");
     
  console.log("date1>>>>>>>>>>>>>>>>",date1);
  console.log("date2>>>>>>>>>>>>>>>>",date2);
     if(fromDate==undefined || fromDate==null || fromDate=='')
     {
       toast.error("Please enter from date")
     }
  
     else if(toDate==undefined || toDate==null || toDate=='')
     {
       toast.error("Please enter to date")
     }
  
     else if(date2<=date1)
     {
       toast.error("from date can not greater than or equal to date")
     }
  
     else if(toDate!='' && fromDate!='' &&  date2>date1 )
    {
      API.getStoreShopifyBillReport("undefined","undefined",fromDate,toDate).then((result) => {  
             
        if(result.data!=undefined && result.data.length>0)
        {
          setReportList(result.data)
        }
        else
        {
          setReportList([])
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            });
    }
    
  }

  return (

    <div>
    <div>
    {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")) ? (
      <div className="d-flex ml-auto py-2">
      <Form.Group>
                   <Form.Label className="">From Date</Form.Label>
                   <Form.Control 
                   name="fromDate"
                   className="form-control-sm"
                   value={fromDate}
                   onChange={(e) =>setFromDate(e.target.value)}
                   type="date" 
                   required
                   />
                    <Form.Control.Feedback type="invalid" >Please enter Valid from</Form.Control.Feedback>
                 </Form.Group>
      
                 <Form.Group className="px-3">
                   <Form.Label className="">To date</Form.Label>
                   <Form.Control  name="toDate"
                   value={toDate}
                   className="form-control-sm"
                   onChange={(e) =>setToDate(e.target.value)}
                   type="date"
                   required
                   />
                    <Form.Control.Feedback type="invalid" >Please enter valid till</Form.Control.Feedback>
                 </Form.Group>
                 <div style={{marginTop: "25px"}}>
                 <button 
                 onClick={(e) =>handleFilter(e)}
                  className="btn btn-primary btn-sm">Filter</button></div>
      </div>
       ) : null}
</div>
   
    <MUIDataTable
        className="mb-3"
        title="Consolidate Conversion Report"
        data={rowData}
        columns={columns}
        options={options}
      />
      </div>
  )
}

export default ConversionReportAllStore
