import React, { useEffect, useState,useRef } from "react"
import { useStore, useAuth } from "../context"
import { Stores } from "."
import MUIDataTable from "mui-datatables"
import Button from "@material-ui/core/Button"
import AddRoundedIcon from "@material-ui/icons/AddRounded"
import { NavLink } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom";
//import Modal from "react-modal";
import {Modal} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import API from "../utils/API";
import Moment from 'react-moment';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import ReactDOM from 'react-dom';
import { PDFViewer } from '@react-pdf/renderer';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import {Form} from 'react-bootstrap';

const useStyles = makeStyles(() => ({
  tab: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    padding: "0 10px",
    boxShadow: "0 0 15px silver",
  },
  button: {
    "&:hover": {
      color: "#fff",
    },
  },
}))

const columns = [
  {
    name: "invoiceId",
    label: "Invoice ID",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "date",
    label: "Date",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        //alert(value);
        return (
        <Moment format="YYYY/MM/DD">
        {value}
        </Moment>
        );
       } 
    },
  },
  {
    name: "paymentAmount",
    label: "Invoice Amount",
    options: {
      filter: false,
      sort: true,
    },
  },  
  
  
  {
    name: "action",
    label: "Action",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        //alert(value);
        return (
          <Button
          variant="contained"
          color="primary"
         // startIcon={<AddRoundedIcon />}
         //onClick={downloadPdf}
         
          component={NavLink}
          to={"/home/invoice/viewInvoice?id="+value}
        >
          View/DownLoad
         </Button>
        );
      }
    },
  },
  
];

function ManageInvoice() {

  const [invoiceList, setInvoiceList] = useState([]);
  const [isPdfSet, setIsPdfSet] = useState(false);
  const { userInfo,loggedUserInfo } = useAuth();
  const history = useHistory();
  const classes = useStyles()
  const [companyList, setCompanyList] = useState([]);
  const { companyData, storeData, getCompanyByUser, getStoreByCompany } =
    useStore();
  const options = {
    rowsPerPage: 10,
    print: false,
    selectableRowsOnClick: false,
    selectToolbarPlacement: "none",
    selectableRows:"none",
   /* customToolbar: () => (
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddRoundedIcon />}
       // onClick={toggleModal}
        component={NavLink}
        to={"/home/categories/AddCategory"}
      >
        Add Category
      </Button>
    ),*/
    
  }

  useEffect(() => {
    if(loggedUserInfo!=undefined)
   {
    API.getAllCompany(loggedUserInfo.email).then((companyResult) => {  
       
      if(companyResult.data!=undefined && companyResult.data.length>0)
      {
        setCompanyList(companyResult.data)
       
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });

     API.getCompanyInvoice(loggedUserInfo.companyId).then((result) => {  
       
      if(result.data!=undefined && result.data.length>0)
      {
        setInvoiceList(result.data)
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });
    }
  }, [loggedUserInfo]);

  

  const rowData = invoiceList.map((item) => ({
    invoiceId: item.invoiceId,
    date: item.createdOn,
    paymentAmount:item.paymentAmount,
    action:item.id,
  }));

 

  const getCompanyBranches = async (e) => {
    console.log("event>>>>>>>>>",e.target.value);
    if(e.target.value!=undefined && e.target.value!='')
    {
      getStoreByCompany(e.target.value);
        }
        
  };

  const handleBranchChanage = async (e) => {
    console.log("event>>>>>>>>>",e.target.value);
    if(e.target.value!=undefined && e.target.value!='')
    {
      API.getCompanyBranchInvoice(e.target.value).then((result) => {  
       
        if(result.data!=undefined && result.data.length>0)
        {
          setInvoiceList(result.data)
        }
        else
        {
          setInvoiceList([])
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            });
    }
  };


  return (
  
    <div>
    <div className={classes.tab}>
    {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")) ? (
      <div className="d-flex ml-auto py-2">
      <Form.Control
        as="select"
        className=" mr-3"
        name="company"
        //value={'hzf3qcnuhpwv5llw3n9lvndgpk2prrqxb'}
        onChange={getCompanyBranches}
        required
      >
        <option>Select Company</option>
        {companyList.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Control>
      
      <Form.Control
        as="select"
        className="mr-sm-0"
        name="companyBranch"          
       // value
        onChange={handleBranchChanage}
        required
      >
        <option>Select Branch</option>
        {storeData.map((item, index) => (
          <option key={item.id} value={item.id}>
            {item.code}
          </option>
        ))}
      </Form.Control>
      </div>
       ) : null}
</div>
   
    <MUIDataTable
        className="mb-3"
        title="Invoice"
        data={rowData}
        columns={columns}
        options={options}
      />
     
     </div>
      )
}

export default ManageInvoice
