import React, { useEffect,useState } from "react";
import { Form, Button, InputGroup, Alert } from "react-bootstrap";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { useAuth,useUser } from "../context";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Navigation } from "@material-ui/icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import {createUser} from "../utils/API";
import API from "../utils/API";
import { auth } from "../utils/FirebaseConfig"
import { useForm, useStep } from "react-hooks-helper";
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import { PayPalButton } from "react-paypal-button-v2";
import { clientNew } from "../utils/paypalConfig";
import { maxCharge, minCharge, minItem } from "../utils/campaignConfig";
import { uuid } from '../utils/uuid'
import moment from 'moment';

const defaultData = {
  mobileNo: "",
  firstName: "",
  password: "",
  email: "",  
  roleId:"",
  agent:"N",
  companyId:"",
};

const FormContainer = styled.form`
  width: 400px;
  background-color: #fff;
  padding: 50px;
  margin-top: 150px;
  box-shadow: 0 0 20px silver;
  border-radius: 10px;
`;
const RegisterLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px 25px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px silver;
  padding: 25px;
  :hover {
    text-decoration: none;
  }
`;


const MapProduct = (props) => {
  let offerId=new URLSearchParams(props.location.search).get("id");

  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState([])
  const [offerData, setOfferData] = React.useState([])
  const [offerStore, setOfferStore] = React.useState("")
  const animatedComponents = makeAnimated();
  const [form, setForm] = useForm(defaultData);
  const { signUp, userInfo,loggedUserInfo } = useAuth();
  const [existTotalItem, setExistTotalItem] = React.useState(0)
  const [paymentTotal, setPaymentTotal] = React.useState(0)
  const [paymentTotalWithTax, setPaymentTotalWithTax] = React.useState(0)
  const [totalTaxAmount, setTotalTaxAmount] = React.useState(0)
  const [diffDays, setDiffDays] = React.useState(0)
  const history = useHistory();
  const [stateCityCount, setstateCityCount] = React.useState(0)
  const [totalTaxPer, setTotalTaxPer] = useState(0);
  const [gstTax, setGstTax] = useState(0);
  const [hstTax, setHstTax] = useState(0);
  const [qstTax, setQstTax] = useState(0);
  const [pstTax, setPstTax] = useState(0);

  let stateTaxAmount=0
  let gst =0;
	let hst =0;
	let qst = 0;
	let pst = 0;

  useEffect(() => {

    API.getOfferById(userInfo.email,offerId).then((result) => {  
      if(result.data!=undefined)
          {
            setOfferData(result.data);
            if(result.data.products!=undefined && result.data.products!=null && result.data.products.length>0)
            setExistTotalItem(result.data.products.length+1)
            else
            setExistTotalItem(1)

            var to = moment(result.data.validTill)
            var nowDate= moment();
            const diffDays = to.diff(nowDate,'days');
             // console.log("diffDays>>>>>>>>>>>>>>>>>>>",diffDays);
            setDiffDays(diffDays);
            API.getStoreById(result.data.storeId).then((storeData) => {  
  
              if(storeData.data!=undefined)
              {
            setOfferStore(storeData.data);
            API.getStateById(storeData.data.stateId).then((stateResult) => {  

              if(stateResult.data!=undefined)
              {
                stateTaxAmount=stateTaxAmount+Number(stateResult.data.totalTax);
                gst=gst+Number(stateResult.data.gst);
                hst=hst+Number(stateResult.data.hst);
                pst=pst+Number(stateResult.data.pst);
                qst=qst=Number(stateResult.data.qst);
                console.log("setGstTax>2222222>>>>>>>>>>",gst)
                setTotalTaxPer(stateTaxAmount);
                setGstTax(gst);
                setHstTax(hst);
                setPstTax(pst);
                setQstTax(qst);
              }
                   })
                   .catch((error) => {
                     console.log(error);
                     alert(error);
                  });
              }
            })
            .catch((error) => {
              console.log(error);
              alert(error);
           });
    API.getCompanyBranchProduct(result.data.storeId,"all").then((productResult) => {  
      if(productResult.data!=undefined && productResult.data.length>0)
          {
            setProductList(productResult.data);
       
          }
               })
               .catch((error) => {
                 console.log(error);
                 alert(error);
              });
            }
            })
          
            .catch((error) => {
              console.log(error);
              alert(error);
           });

           var totalCity=0;
               API.getState(userInfo.email).then((stateData) => {  
       
                if(stateData.data!=undefined && stateData.data.length>0)
                {
                  stateData.data.map((item) => {
                  
                    API.getCitiesByState("gust",item.id).then((result) => {  
                      
                      if(result.data!=undefined && result.data.length>0)
                      {
                        totalCity=totalCity+result.data.length
                        setstateCityCount(totalCity);
                      }
                          })
                          .catch((error) => {
                            console.log(error);
                            alert(error);
                          });
          
                          
                    
                  });
                }
                })
                .catch((error) => {
                  console.log(error);
                  alert(error);
               });

  },[userInfo]);
  let productOption=[];
//let categoryOption=[];
console.log("form.productOption>>>>>>>>>>>>>>>>",form.maincategories)
productList.map((item) => {
  let tempObj ={"label":<div><img src={item.images!=undefined && item.images!="" && item.images!=null&& item.images.length>0?item.images[0]:"https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg"} height="60px" width="60px"/> &nbsp;{item.name} </div>,"value":item.id}
  productOption.push(tempObj)
 
});

const handleProductChange = (selectedOptions) => {
  form.product=[];   
  var totalItemCount=0;
  var planAmount=0;
  setSelectedProduct(selectedOptions)
  selectedOptions.map((item1) => {
    form.product.push(item1.value)

      
    stateTaxAmount=stateTaxAmount+Number(totalTaxPer);
    gst=gst+Number(gstTax);
    hst=hst+Number(hstTax);
    pst=pst+Number(pstTax);
    qst=qst=Number(qstTax);
    console.log("setGstTax>2222222>>>>>>>>>>",gst)
    setTotalTaxPer(stateTaxAmount);
    setGstTax(gst);
    setHstTax(hst);
    setPstTax(pst);
    setQstTax(qst);
          
             
      
   

  });
  totalItemCount=existTotalItem+form.product.length;
  if(totalItemCount<=minItem)
  {
    planAmount=diffDays*minCharge;
    
  }
  else
  {
    planAmount=diffDays*maxCharge;
  }
  if(offerData.campaignArea=="city")
  {

    setPaymentTotal(planAmount*offerData.cityList.length)
  }

  else if(offerData.campaignArea=="state")
  {
    setPaymentTotal(planAmount*offerData.stateList.length)
  }
  else
  {
    
    setPaymentTotal(planAmount*stateCityCount)
    
  }
  console.log("stateTaxAmount>>>>>>>>>>>>>>>>",stateTaxAmount)
  var taxAmount=(planAmount*Number(stateTaxAmount))/100;
  console.log("taxAmount>>>>>>>>>>>>>>>>",taxAmount)
  setPaymentTotalWithTax(planAmount+taxAmount);
  setTotalTaxAmount(taxAmount);
  //form.weeklyOff=selectedOptions.value;
  console.log("selectedOptions>>>>>>>>>>>>>>>>",form.product)
  
};


const onSuccess = (payment) => {
  // Congratulation, it came here means everything's fine!
      console.log("The payment was succeeded!", payment);
      var paymentType="";
      var paymentId="";
      var payerId="";
      var paymentStatus="";
      if(payment.intent!=undefined && payment.intent!=null && payment.intent=="CAPTURE")
      {
        paymentType="Card";
        paymentId=payment.id;
        payerId=payment.payer.payer_id;
        if(payment.status=="COMPLETED")
        {
          paymentStatus="Paid"
        }
        else
        {
          paymentStatus="Fail"
        }
      }
      else
      {
        paymentType="PayPal";
        paymentId=payment.paymentID;
        payerId=payment.payerID; 
        if(payment.paid)
        {
          paymentStatus="Paid"
        }
        else
        {
          paymentStatus="Fail"
        }
      }

      let offerBody = {
        id:offerId,
        products:form.product,
      };
      API.mapProductToActiveCamp(userInfo.email,offerBody).then((campaognResult) => {  

              let ptBody = {
                id:uuid(),
                transactionType:"Add Product In Active Offer Campaign",
                status:paymentStatus,
                productId:selectedProduct,
                userId:userInfo.email,
                companyId:offerData.companyId,
                storeId:offerData.storeId,
                paymentId:paymentId,
                payerId:payerId,
                paymentType:paymentType,
                campaignId:campaognResult.data.id,
                amount:paymentTotalWithTax,
               };
      
               let invoiceBody = {
                id:uuid(),
                invoiceType:"Add Product In Active Offer Campaign",
                status:paymentStatus,
                productId:selectedProduct,
                userId:userInfo.email,
                companyId:offerData.companyId,
                storeId:offerData.storeId,
                paymentId:paymentId,
                payerId:payerId,
                paymentType:paymentType,
                paymentAmount:paymentTotalWithTax,
                amount:paymentTotal,
                discountAmount:0,
                taxAmount:totalTaxAmount,
                campaignId:offerData.campaignId,
                gst:gstTax,
                hst:hstTax,
                pst:pstTax,
                qst:qstTax,
               };
               API.createPaymentTransaction(userInfo.email,ptBody);
        
               API.createInvoice(userInfo.email,invoiceBody);
               toast.success("yah!! product map with live offer successfully")
               setTimeout(() => { 
                history.push(`/home/offers/manage`)
               
               // toast.success("yah!! offer live successfully")
              
              }, 3000);
            })
            .catch((error) => {
              console.log(error);
              alert(error);
           });

    }

const onCancel = (data) => {
  // User pressed "cancel" or close Paypal's popup!
  console.log('The payment was cancelled!', data);
  toast.error("Payment cancelled")
 
  let ptBody = {
    id:uuid(),
    transactionType:"Add Product In Active Offer Campaign",
    status:"Cancelled",
    productId:selectedProduct,
    userId:userInfo.email,
    companyId:offerData.companyId,
    storeId:offerData.storeId,
    paymentId:data.paymentID!=undefined && data.paymentID!='' && data.paymentID!=null?data.paymentID:data.orderID,
    payerId:data.payerID,
    paymentType:"PayPal",
    amount:paymentTotalWithTax,
   };
   API.createPaymentTransaction(userInfo.email,ptBody);
  
  // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
}

const onError = (err) => {
  // The main Paypal's script cannot be loaded or somethings block the loading of that script!
  toast.error("Error,please try again")
  console.log("Error!", err);
  
  let ptBody = {
    id:uuid(),
    status:"Error",
    transactionType:"Add Product In Active Offer Campaign",
   productId:selectedProduct,
    userId:userInfo.email,
    companyId:offerData.companyId,
    storeId:offerData.storeId,
    paymentId:err.paymentID,
    payerId:err.payerID,
    paymentType:"PayPal",
    amount:paymentTotalWithTax,
   };
   API.createPaymentTransaction(userInfo.email,ptBody);
  
  // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
  // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
}


  return (
    <>
          <Form noValidate validated={true} action="#" style={style.form}>

          <Form.Group className="col-12 mb-3 mt-2">
        <Form.Label>Mapped Products to Offer ({offerData.heading})</Form.Label>
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          isMulti
          name="product"
          value={selectedProduct}
          options={productOption}
          onChange={handleProductChange}
      />
       
      </Form.Group>
     
      {selectedProduct!=undefined && selectedProduct!='' && selectedProduct!=null && paymentTotal>0 ?  (
        <div>
         <div className="amount-wrapper bg-white">
         <h5>Payment Summary</h5>
         <div className="total">Total Budge: ${paymentTotal}</div>
         </div>
  <div class="float-center mb-4">
{/* <PaypalExpressBtn  env={env} client={client} currency={currency} total={total} onError={onError} onSuccess={onSuccess} onCancel={onCancel} />*/}
<PayPalButton  options={clientNew}  shippingPreference={"NO_SHIPPING"} vault={false} amount={paymentTotalWithTax} onError={onError} onSuccess={onSuccess} onCancel={onCancel} />
</div></div>
):""}
                   </Form>
       
      <ToastContainer />
    </>
  );
};

export default MapProduct;

const style = {
  backButton: {
    fontSize: "25px",
    marginBottom: "10px",
    color: "blue",
    cursor: "pointer",
  },
};
