import React, { useEffect, useState } from "react"
import { useStore, useAuth } from "../context"
import { Stores } from "."
import MUIDataTable from "mui-datatables"
import Button from "@material-ui/core/Button"
import AddRoundedIcon from "@material-ui/icons/AddRounded"
import { NavLink } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom";
//import Modal from "react-modal";
import {Modal} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import API from "../utils/API";
import Moment from 'react-moment';
import {Form} from 'react-bootstrap';

const useStyles = makeStyles(() => ({
  tab: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    padding: "0 10px",
    boxShadow: "0 0 15px silver",
  },
  button: {
    "&:hover": {
      color: "#fff",
    },
  },
}))




function ConversionReport() {

  const [reportList, setReportList] = useState([]);
  const { userInfo,loggedUserInfo } = useAuth();
  const classes = useStyles()
  const [companyList, setCompanyList] = useState([]);
  const history = useHistory();
  const { companyData, storeData, getCompanyByUser, getStoreByCompany } =
    useStore();
  const options = {
    rowsPerPage: 10,
    print: false,
    selectableRowsOnClick: false,
    selectToolbarPlacement: "none",
    selectableRows:"none",
   /* customToolbar: () => (
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddRoundedIcon />}
       // onClick={toggleModal}
        component={NavLink}
        to={"/home/categories/AddCategory"}
      >
        Add Category
      </Button>
    ),*/
    
  }

  useEffect(() => {
    if(loggedUserInfo!=undefined)
   {
    API.getAllCompany(loggedUserInfo.email).then((companyResult) => {  
       
      if(companyResult.data!=undefined && companyResult.data.length>0)
      {
        setCompanyList(companyResult.data)
       
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });

          if(localStorage.getItem('branchFilterConReport')!=undefined && localStorage.getItem('branchFilterConReport')!=null && localStorage.getItem('branchFilterConReport')!='')
          {
            getStoreByCompany(localStorage.getItem('companyFilterConReport'));
            API.getStoreShopifyBillReport("undefined",localStorage.getItem('branchFilterConReport'),"undefined","undefined").then((result) => {  
             
              if(result.data!=undefined && result.data.length>0)
              {
                setReportList(result.data)
              }
              else
              {
                setReportList([])
              }
                   })
                   .catch((error) => {
                     console.log(error);
                     alert(error);
                  });
          }
          else{
            API.getStoreShopifyBillReport("undefined",loggedUserInfo?.storeId,"undefined","undefined").then((result) => {  
             
              if(result.data!=undefined && result.data.length>0)
              {
                setReportList(result.data)
              }
              else
              {
                setReportList([])
              }
                   })
                   .catch((error) => {
                     console.log(error);
                     alert(error);
                  });
        }
    }
  }, [loggedUserInfo]);



  const handleViewDeatils = async (e,billid) => {

    history.push(`/home/report/viewBillOrder?billId=`+billid)
  };


  const columns = [
    {
      name: "billId",
      label: "Bill ID",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "date",
      label: "Bill Date",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
          <Moment format="YYYY/MM/DD">
          {value}
          </Moment>
          );
         } 
      },
    },
    {
      name: "totalPrice",
      label: "Total Price",
      options: {
        filter: false,
        sort: true,
      },
    },  
    {
      name: "totalCommission",
      label: "Total Commission",
      options: {
        filter: false,
        sort: true, 
      },
    },
  
    {
      name: "orderList",
      label: "View Details",
      options: {
        filter: true,
        sort: false,
       // display: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              
           
            <div className="d-flex align-items-center">
            {/*<p className="mb-0 mr-3">City</p>*/}
            <Button  variant="outlined"><a href="javascript:void(0)"  onClick={(e) =>handleViewDeatils(e,value)}>View Details</a>
            </Button>
            </div>
                 
              </div>
             
          )
      }
      },
      
    },
    
   /* {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
            <Button
            variant="contained"
            color="primary"
           // startIcon={<AddRoundedIcon />}
            component={NavLink}
            to={"/home/categories/AddCategory?id="+value}
          >
            Update
          </Button>
          );
        }
      },
    },*/
    
  ];

  const rowData = reportList.map((item) => ({
    billId: item.id,
    date: item.createdOn,
    totalPrice:parseFloat(item.totalPrice.toFixed(2)),
    totalCommission:parseFloat(item.totalCommission.toFixed(2)),
    orderList:item.id,
    //paymentType:item.paymentType,
    //status:item.status
  }));


  const getCompanyBranches = async (e) => {
    console.log("event>>>>>>>>>",e.target.value);
    if(e.target.value!=undefined && e.target.value!='')
    {
      localStorage.setItem('companyFilterConReport', e.target.value);
      getStoreByCompany(e.target.value);
        }
        
  };

  const handleBranchChanage = async (e) => {
    console.log("event>>>>>>>>>",e.target.value);
    if(e.target.value!=undefined && e.target.value!='')
    {
      localStorage.setItem('branchFilterConReport', e.target.value);
      API.getStoreShopifyBillReport("undefined",e.target.value,"undefined","undefined").then((result) => {  
             
        if(result.data!=undefined && result.data.length>0)
        {
          setReportList(result.data)
        }
        else
        {
          setReportList([])
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            });
    }
  };


  return (

    <div>
    <div className={classes.tab}>
    {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")) ? (
      <div className="d-flex ml-auto py-2">
      <Form.Control
        as="select"
        className=" mr-3"
        name="company"
        value={localStorage.getItem('companyFilterConReport')}
        onChange={getCompanyBranches}
        required
      >
        <option>Select Company</option>
        {companyList.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Control>
      
      <Form.Control
        as="select"
        className="mr-sm-0"
        name="companyBranch"          
        value={localStorage.getItem('branchFilterConReport')}
        onChange={handleBranchChanage}
        required
      >
        <option>Select Branch</option>
        {storeData.map((item, index) => (
          <option key={item.id} value={item.id}>
            {item.code}
          </option>
        ))}
      </Form.Control>
      </div>
       ) : null}
</div>
   
    <MUIDataTable
        className="mb-3"
        title="Conversion Report"
        data={rowData}
        columns={columns}
        options={options}
      />
      </div>
  )
}

export default ConversionReport
