import React from "react"
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom"
import { AddOffer, ManageOffers,StartPromotion } from "../domain"
import CampaignList from "../domain/CampaignList"
import CampaignType from "../domain/CampaignType"
import ManageCampaign from "../domain/ManageCampaign"
import SponsoredCampaignList from "../domain/SponsoredCampaignList"
import SponsoredPromotion from "../domain/SponsoredPromotion"
import StartPromotionBatch from "../domain/StartPromotionBatch"

function Campaign() {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/manage`} component={ManageCampaign} />
      <Route path={`${path}/campaignType`} component={CampaignType} />
      <Route path={`${path}/promotionBatch`} component={StartPromotionBatch} />
      <Route path={`${path}/sponsoredPromotion`} component={SponsoredPromotion} />
      <Route path={`${path}/campaignList`} component={CampaignList} />
      <Route path={`${path}/sponsoredCampaignList`} component={SponsoredCampaignList} />
      <Redirect to={`${path}/manage`} />
    </Switch>
  )
}

export default Campaign
