import React from "react"
import PrivateRoute from "./utils/PrivateRoute"
import { Switch, Route,BrowserRouter } from "react-router-dom"
import { HomePage, LandingPage } from "./screens"
import { StoreDataProvider, UserDataProvider } from "./context"

function App() {
  return (
    <BrowserRouter basename={'/ADMIN-UI'}>
    <UserDataProvider>
      <StoreDataProvider>
        <Switch>
          <PrivateRoute path="/home" component={HomePage} />
          <Route path="/" component={LandingPage} />
        </Switch>
      </StoreDataProvider>
    </UserDataProvider>
    </BrowserRouter>
  )
}

export default App
