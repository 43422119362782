import React, { useEffect, useState } from "react"
import { Form, Row } from "react-bootstrap"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import { useUser,useAuth } from "../context"
import { uuid } from "../utils/uuid"
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from "react-router-dom";

function AddRole() {
  const history = useHistory();
  const { privilege, getPrivilege, addRole } = useUser()
  const { userInfo } = useAuth();
  const [checkedPrivilege, setCheckedPrivilege] = useState([])
  const [roleName, setRoleName] = useState("")

  useEffect(() => {
    getPrivilege(userInfo.email)
  }, [])

  const handleInputChange = (e) => {
    setRoleName(e.target.value)
  }

  const handleCheckBox = (e, item) => {
    const { checked } = e.target
    let privilege = {
      id: item.id,
      privilegeCode: item.privilegeCode,
      privilegeName: item.privilegeName,
    }
    if (checked) setCheckedPrivilege([...checkedPrivilege, privilege])
    else console.log("remove privilege from state")
  }

  const addNewRole = async () => {
   
    if(checkedPrivilege.length<=0)
    {
      toast.error("Please select atleast one privilige for role");
      return;
    }

    const body = {
      id:uuid(),
      roleName: roleName,
      isSystemRole: "N",
      privileges: checkedPrivilege,
    }

    const id = userInfo.email;

    try {
      const response = await addRole({ id, body })
      console.log(response)
      //if(response.data=="Success")
      //{
        toast.success("Role added Successfully ");
       // history.replace("/home/users");
       history.push(`/home/users`)
     // }
    } catch (error) {
      console.log(error)
    }
  }

  const cancelNewRole = async () => {
    history.push(`/home/users`)
  };

  return (
    <Form style={style.form}>
      <Form.Label as="legend" className="text-center mb-5">
        Add New Role
      </Form.Label>

      <Form.Group>
        <Form.Control
          type="text"
          placeholder="Enter Role Name"
          required
          value={roleName}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Row>
        {privilege?.length > 0 &&
          privilege.map((item, index) => (
            <Form.Group key={index} className="col-6 m-0">
              <FormControlLabel
                control={
                  <Checkbox
                    name="privilege"
                    color="primary"
                    value={item.privilegeCode}
                    onChange={(e) => handleCheckBox(e, item)}
                  />
                }
                label={item.privilegeName}
              />
            </Form.Group>
          ))}
      </Row>
      <Form.Text>Please select atleast one privilige for role</Form.Text>

      <Form.Group className="text-center mt-5">
        <Button
          variant="contained"
          color="default"
          type="button"
          onClick={cancelNewRole}
          className="mr-3"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={addNewRole}
        >
          Add
        </Button>
      </Form.Group>
      <ToastContainer />
    </Form>
  )
}

export default AddRole

const style = {
  form: {
    backgroundColor: "white",
    padding: "25px",
    boxShadow: "0 0 15px silver",
  },
}
