import React, { useEffect, useState } from "react";
import { useForm, useStep } from "react-hooks-helper";
import {
  StoreInfoForm,
  ChooseCategoryForm,
  PersonalInfoForm,
  AddressForm,
  MobileNo,
  SignUp,
} from "../domain";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {Modal} from 'react-bootstrap';
import StoreInfoFormModel from "../domain/StoreInfoFormModel";
import ChooseCategoryFormModel from "../domain/ChooseCategoryFormModel";
import AddressFormModel from "../domain/AddressFormModel";
import PersonalInfoFormModel from "../domain/PersonalInfoFormModel";
import API from "../utils/API";
import { useStore, useAuth } from "../context"
const defaultData = {
 
  storeId:"",
  branchName: "",
  storeName: "",
  businessName: "",
  websiteUrl: "",
  companyId:"",
  logo: "",
  companyLogo:"",
  mainCategory: [],

  countryBA: "",
  streetBA: "",
  cityBA: "",
  provinceBA: "",
  postalCodeBA: "",
  AppartmentName: "",
  isPhysicalStore: "",
  isPhysicalSameAsRegisteredAddress: "",
  secStreetBA: "",
  secCityBA: "",
  secProvinceBA: "",
  secPostalCodeBA: "",
  secAppartmentName: "",

  countryPA: "",
  streetPA: "",
  cityPA: "",
  provincePA: "",
  postalCodePA: "",

  ownerName: "",
  managerEmail: "",
  contactNumber: "",
  operatingHoursFrom: "",
  operatingHoursTo: "",
  weeklyOff: "",
  marketPlace:[],
  agreement: false,
  fromHrs:"",
  toHrs:"",
  timeNote:"",
};

const steps = [
  { id: "storeInfo" },
  { id: "chooseCategory" },
  { id: "address" },
  { id: "personalInfo" },
];

const BackToLogin = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px 25px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px silver;
  padding: 25px;
  :hover {
    text-decoration: none;
  }
`;

function AddStore(parentProps) {
  const [form, setForm] = useForm(defaultData);
 console.log("parentProps>>>>>>>>>>",parentProps);
 const [storeData1, setStoreData] = useState();
 const {storeData, getStoreByCompany } =
 useStore();
 const storeId=parentProps.storeId
if(storeId==null || storeId==undefined) 
{
 // reset();
form.companyId=parentProps.companyId;
form.businessName=parentProps.businessName
form.companyLogo=parentProps.selectedCompanyLogo
//form.reset();

}
//parentProps.setIsOpen(false);
//setIsOpen(false);
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });
  const setIsOpen=parentProps.setIsOpen;

  useEffect(() => {

    console.log("storeId>>>>>>>>>>",storeId);
    console.log("step.id>>>>>>>>>",step.id);
    getStoreByCompany(form.companyId);
    if(storeId==null || storeId==undefined) 
{
   //setTimeout(() => { 
    form.storeId=""
    if(storeData!=undefined && storeData!=null && storeData.length==0 )
    form.branchName = "Primary";
    else
    form.branchName = "";

    form.storeName=""
    //form.businessName=""
    //form.companyId=""
    form.websiteUrl=""
    form.ownerName=""
    form.managerEmail=""
    form.contactNumber=""
    form.isPhysicalSameAsRegisteredAddress=""
    form.isPhysicalStore=""
    form.secAppartmentName=""
    form.secStreetBA=""
    form.secPostalCodeBA=""
    form.secCityBA=""
    form.secProvinceBA=""
    form.mainCategory=[]
    form.marketPlace=[]
    form.weeklyOff= ""
    form.fromHrs=""
    form.toHrs=""
    form.timeNote=""
     // }, 4000);
}

    if(storeId!=undefined && storeId!=null)
    {
    API.getStoreById(storeId).then((result) => {  
       
      if(result.data!=undefined)
      {
        
        //console.log("parentProps222222222222222222222",parentProps);
       form.storeId=result.data.id
      form.branchName=result.data.code    
      form.storeName=result.data.storeName
      form.companyLogo=result.data.companyLogo
      form.businessName=result.data.companyBusinessName
      form.companyId=result.data.companyId
      form.websiteUrl=result.data.websiteUrl
      form.ownerName=result.data.managerName
      form.managerEmail=result.data.managerEmail
      form.contactNumber=result.data.managerMobile
      form.isPhysicalSameAsRegisteredAddress=result.data.isSameAddress
      form.isPhysicalStore=result.data.isphysical
      form.secAppartmentName=result.data.address1
      form.secStreetBA=result.data.address2
      form.secPostalCodeBA=result.data.pincode
      form.secCityBA=result.data.cityId
      form.secProvinceBA=result.data.stateId
      form.mainCategory=result.data.categories
      form.timeNote=result.data.timeNote
      form.weeklyOff=result.data.weeklyOff
      if(result.data.fromHrs!=undefined && result.data.fromHrs!=null && result.data.fromHrs.length>0)
      form.fromHrs=result.data.fromHrs[0]
      if(result.data.toHrs!=undefined && result.data.toHrs!=null && result.data.toHrs.length>0)
      form.toHrs=result.data.toHrs[0]

      form.marketPlace=result.data.marketPlace;
              


      console.log("marketPlace frist step>>>>>>>>>>",form.marketPlace);
      setStoreData(result.data);
  
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });
        }
        else
        {
       form.storeId=""
       if(storeData!=undefined && storeData!=null && storeData.length==0 )
       form.branchName = "Primary";
       else
       form.branchName = "";    
      form.storeName=""
      form.businessName=""
      form.companyId=""
      form.websiteUrl=""
      form.ownerName=""
      form.managerEmail=""
      form.contactNumber=""
      form.isPhysicalSameAsRegisteredAddress=""
      form.isPhysicalStore=""
      form.secAppartmentName=""
      form.secStreetBA=""
      form.secPostalCodeBA=""
      form.secCityBA=""
      form.secProvinceBA=""
      form.mainCategory=[]
      form.weeklyOff= ""
      form.fromHrs=""
      form.toHrs=""
      form.timeNote=""
      setStoreData([]);
        }
        
  },[]);

  const props = { form, setForm, navigation,setIsOpen };
  



  return (
    <div> 
        <StoreForms id={step.id} data={props} />
       
      </div>
    
  );
}

const StoreForms = ({ id, data }) => {
  //id="signUp";
  switch (id) {
    case "storeInfo":
      return<StoreInfoFormModel {...data} />
    case "chooseCategory":
      return <ChooseCategoryFormModel {...data} />;
    case "address":
      return <AddressFormModel {...data} />;
    case "personalInfo":
      return <PersonalInfoFormModel {...data}  />;
  }
};

export default AddStore;
