import React, { useEffect, useState } from "react"
import { useStore, useAuth } from "../context"
import { Form, InputGroup } from "react-bootstrap"
import { Stores } from "."
import MUIDataTable from "mui-datatables"
import Button from "@material-ui/core/Button"
import AddRoundedIcon from "@material-ui/icons/AddRounded"
import { NavLink } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom";
//import Modal from "react-modal";
import {Modal} from 'react-bootstrap';
import API from "../utils/API";
import Moment from 'react-moment';
import { findAllByDisplayValue } from "@testing-library/react"
import moment from 'moment';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import faker from 'faker';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip1 from "react-bootstrap/Tooltip";


const useStyles = makeStyles(() => ({
  tab: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    padding: "0 10px",
    boxShadow: "0 0 15px silver",
  },
  button: {
    "&:hover": {
      color: "#fff",
    },
  },
}))

const rowData=[];

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


let labels=[];


function CampaignAnalytics(props) {

  const campaignId=props.campaignId;
  const type=props.type;
  const level=props.level;
  const classes = useStyles()
  const [viewLevel, setViewLevel] = useState("");

  const [offerCampaign, setOfferCampaign] = useState([]);
  const [productCampaign, setProductCampaign] = useState([]);
  const [campaignData, setCampaignData] = useState();
  const [isFilter, setIsFilter] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [chartLevel, setChartLevel] = useState([]);
  const [chartData, setChartData] = useState({});
  const { userInfo,loggedUserInfo } = useAuth();

  
  
  const renderTooltip = props => (
    <Tooltip1 {...props}>You can see historically data for this product/offer by selecting date range</Tooltip1>
  );


function getDaysBetweenDates (startDate, endDate) {
  var now = startDate.clone(), dates = [];

  while (now.isSameOrBefore(endDate)) {
      dates.push(now.format('MM/DD/YYYY'));
      now.add(1, 'days');
  }
  return dates;
};
  


  
  useEffect(() => {
    if(campaignId!=undefined)
   {

    setOfferCampaign([])
    setProductCampaign([])
    setIsFilter(false);
    setFromDate("");
    setToDate("");
    console.log("props.level>>>>>>>>>>>>>>",props.level);
    setViewLevel(props.level);
          API.getCampaignById(campaignId).then((campaignResult) => {  
       
            if(campaignResult.data!=undefined )
            {

              setCampaignData(campaignResult.data)

            }
                 })
                 .catch((error) => {
                   console.log(error);
                   alert(error);
                });

                API.getCampaignOffer(campaignId,type,undefined,undefined).then((result) => {  
       
                  if(result.data!=undefined && result.data.length>0)
                  {
                    setOfferCampaign(result.data)
                    
                  }
                       })
                       .catch((error) => {
                         console.log(error);
                         alert(error);
                      });

                      API.getCampaignProduct(campaignId,type,undefined,undefined).then((result) => {  
       
                        if(result.data!=undefined && result.data.length>0)
                        {
                          setProductCampaign(result.data)
                        }
                             })
                             .catch((error) => {
                               console.log(error);
                               alert(error);
                            });
    }
  }, [campaignId,type,level,props.itemId]);


console.log("productCampaign>>>>>>>>>>>>>>",productCampaign);
console.log("offerCampaign>>>>>>>>>>>>>>",offerCampaign);


if(campaignData!=null)
{
  var startDate = moment(campaignData.validFrom);
   // var endDate = moment(campaignData.validTill);
   var endDate = moment();
 labels = getDaysBetweenDates(startDate,endDate);
 //setChartLevel(labels);
}
let allCampaignItem = [...offerCampaign, ...productCampaign];
/*let totalClick=0;
let totalImpression=0;
let totalBuyNow=0;
let totalSnips=0;
 
  if(props.level=="campaign")
  {
 allCampaignItem.map((item) => {
 const click='offerId' in item?item.productBeacon.clicks:item.offerBeacon.clicks;
  totalClick=totalClick+Number(click);
 const impressions='offerId' in item?item.productBeacon.impressions:item.offerBeacon.impressions;  
  totalImpression=totalImpression+Number(impressions);
  const buyNow='offerId' in item?item.productBeacon.buyNowClicks:item.offerBeacon.buyNowClicks;
  totalBuyNow=totalBuyNow+Number(buyNow)
  const snip='offerId' in item?item.productBeacon.snips:item.offerBeacon.snips;
  totalSnips=totalSnips+Number(snip)
  });
}
else
{
  allCampaignItem.map((item) => {
    if(item.id==props.itemId)
    {
    const click='offerId' in item?item.productBeacon.clicks:item.offerBeacon.clicks;
     totalClick=totalClick+Number(click);
    const impressions='offerId' in item?item.productBeacon.impressions:item.offerBeacon.impressions;  
     totalImpression=totalImpression+Number(impressions);
     const buyNow='offerId' in item?item.productBeacon.buyNowClicks:item.offerBeacon.buyNowClicks;
     totalBuyNow=totalBuyNow+Number(buyNow)
    }
     });
    
}*/

let clickData=[];
let impressionData=[];
let buyNowData=[];
let snipData=[];
let itemName="";
if(allCampaignItem!=undefined )
{
  if(props.level=="campaign")
  {
allCampaignItem.map((item) => {
  var productLength=item.productBeacon?.length;
  var offerLength=item.offerBeacon?.length;
  labels.map((lableItem) =>{
    var clickCount=0;
    var impressionCount=0;
    var buyNowCount=0;
    var snipCount=0;
    var count=1;
    var isFound=false;
    'offerId' in item?item.productBeacon.map((item) => {
      var createdOn = moment(item.createdOn).format('MM/DD/YYYY');
      var lableDate = moment(lableItem).format('MM/DD/YYYY');
      if(createdOn===lableDate)
      {
      clickCount=item.clicks+""
      impressionCount=item.impressions+""
      buyNowCount=item.buyNowClicks+""
      snipCount=item?.snips+""
      clickData.push(clickCount)
      impressionData.push(impressionCount)
      buyNowData.push(buyNowCount)
      snipData.push(snipCount)
      isFound=true;
      }
      else if(count==productLength && !isFound)
      {
      clickCount="0"
      impressionCount="0"
      buyNowCount="0"
      snipCount="0"
      clickData.push(clickCount)
      impressionData.push(impressionCount)
      buyNowData.push(buyNowCount)
      snipData.push(snipCount)
      }
     
      count++;
    })
    
    :item.offerBeacon.map((item) => {
      var createdOn = moment(item.createdOn).format('MM/DD/YYYY');
      var lableDate = moment(lableItem).format('MM/DD/YYYY');
      if(createdOn===lableDate)
      {
      clickCount=item.clicks+""
      impressionCount=item.impressions+""
      buyNowCount=item.buyNowClicks+""
      snipCount=item?.snips+""
      clickData.push(clickCount)
      impressionData.push(impressionCount)
      buyNowData.push(buyNowCount)
      snipData.push(snipCount)
      isFound=true;
      }
      else if(count==offerLength && !isFound)
      {
      clickCount="0"
      impressionCount="0"
      buyNowCount="0"
      snipCount="0"
      clickData.push(clickCount)
      impressionData.push(impressionCount)
      buyNowData.push(buyNowCount)
      snipData.push(snipCount)
      
      }
    count++;
    })
    
  })
})
  }
  else
  {
allCampaignItem.map((item) => {
  if(item.id==props.itemId)
    {
      itemName='offerId' in item?item.name:item.heading;
      var productLength=item.productBeacon?.length;
      var offerLength=item.offerBeacon?.length;
      console.log("item.productBeacon length>>>>>>>>>>>>>>>>",item.productBeacon)
  labels.map((lableItem) =>{
    var clickCount=0;
    var impressionCount=0;
    var buyNowCount=0;
    var snipCount=0;
    var count=1;
    var isFound=false;
    'offerId' in item?
    item.productBeacon.map((item) => {
      
      var createdOn = moment(item.createdOn).format('MM/DD/YYYY');
      var lableDate = moment(lableItem).format('MM/DD/YYYY');
      if(createdOn===lableDate)
      {
        
      clickCount=item.clicks+""
      impressionCount=item.impressions+""
      buyNowCount=item.buyNowClicks+""
      snipCount=item?.snips+""
      console.log("true>>>clickData>>>>>>>>>>>>>>>>",clickCount)
      clickData.push(clickCount)
      impressionData.push(impressionCount)
      buyNowData.push(buyNowCount)
      snipData.push(snipCount)
      isFound=true
      }
      else if(count==productLength && !isFound)
      {
      clickCount="0"
      impressionCount="0"
      buyNowCount="0"
      snipCount="0"
      clickData.push(clickCount)
      impressionData.push(impressionCount)
      buyNowData.push(buyNowCount)
      snipData.push(snipCount)
      }
      count++;
    })
    
    :item.offerBeacon.map((item) => {
      var createdOn = moment(item.createdOn).format('MM/DD/YYYY');
      var lableDate = moment(lableItem).format('MM/DD/YYYY');
      if(createdOn===lableDate)
      {

      clickCount=item.clicks+""
      impressionCount=item.impressions+""
      buyNowCount=item.buyNowClicks+""
      snipCount=item?.snips+""
      console.log("true>>>clickData>>>>>>>>>>>>>>>>",clickCount)
      clickData.push(clickCount)
      impressionData.push(impressionCount)
      buyNowData.push(buyNowCount)
      snipData.push(snipCount)
      isFound=true
      }
      else if(count==offerLength && !isFound)
      {
      clickCount="0"
      impressionCount="0"
      buyNowCount="0"
      snipCount="0"
      clickData.push(clickCount)
      impressionData.push(impressionCount)
      buyNowData.push(buyNowCount)
      snipData.push(snipCount)
      }
      count++;
    })
   
   
  })
}
})
  }
}

console.log("clickData>>>>>>>>>>>>>>>>",clickData)

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        //width:"20px",
        height:"800px"
      },
      title: {
        display: true,
        text: props.level=="campaign"?campaignData?.campaignName +' Campaign Analytics Chart':itemName+" Analytics Chart",
      },
    },
  };

 const  data = {
    labels,
    datasets: [
      {
        label: "Clicks",
        data:clickData,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: "Impressions",
        data: impressionData,
        borderColor: 'rgb(255, 199, 132)',
        backgroundColor: ['rgba(255, 199, 132, 0.5)'],
      },
      {
        label: "Buy Now",
        data: buyNowData,
        borderColor: 'rgb(0,128,0)',
        backgroundColor: ['rgb(0,128,0,0.5)'],
      },
      {
        label: "Snips",
        data: snipData,
        borderColor: 'rgb(48, 25, 52)',
        backgroundColor: ['rgb(48, 25, 52,0.5)'],
      },
     
    ],
  };

  let sponsoredRowData=[];
  if(type=="Sponsored" && campaignData!=undefined)
  {
    const allSponsoredCampaignItem = [...campaignData.sponsoredSection, ...campaignData.keywords];
     sponsoredRowData = allSponsoredCampaignItem.map((item) => ({
    
      section: 'section' in item?item.section:item.keyword,
      bid: item.bid,
      suggestedBid:item.suggestedBid,
      budget:item.budget,
      type:'section' in item?"Section":"Keyword",
     // cpc:item.cpc,
      impressions:item.impressions,
      clicks:item.clicks
    }));
 /* if(campaignData.sponsoredSection==undefined && campaignData.sponsoredSection!=null && campaignData.sponsoredSection.length>0)
  {

  }*/
}


const handleFilter = async (e) => {

  var date1 = moment(fromDate)
    var date2 = moment(toDate)
   var nowDate= moment().format("yyyy-MM-DD");
   
console.log("date1>>>>>>>>>>>>>>>>",date1);
console.log("date2>>>>>>>>>>>>>>>>",date2);
   if(fromDate==undefined || fromDate==null || fromDate=='')
   {
     toast.error("Please enter from date")
   }

   else if(toDate==undefined || toDate==null || toDate=='')
   {
     toast.error("Please enter to date")
   }

   else if(date2<=date1)
   {
     toast.error("from date can not greater than or equal to date")
   }

   else if(toDate!='' && fromDate!='' &&  date2>date1 )
  {
    const result=await API.getCampaignOffer(campaignId,type,fromDate,toDate)
       
      if(result.data!=undefined && result.data.length>0)
      {
        //setOfferCampaign(result.data)
        
      }
          
      const presult=await API.getCampaignProduct(campaignId,type,fromDate,toDate)
       
            if(presult.data!=undefined && presult.data.length>0)
            {
          //    setProductCampaign(presult.data)
            }

   const labels = getDaysBetweenDates(date1,date2);
   setIsFilter(true);
   setFiterChart(date1,date2,result.data,presult.data);
  
  }


}

const setFiterChart = async (startDate,endDate,offerData,productData) => {


   labels = getDaysBetweenDates(startDate,endDate);
   console.log("updated level>>>>>>>>>>>>>>",labels)
   console.log("offerCampaign level>>>>>>>>>>>>>>",offerData)
  // setChartLevel(labels);
   allCampaignItem = [...offerData, ...productData];
  let clickData=[];
  let impressionData=[];
  let buyNowData=[];
  let snipData=[];
  if(allCampaignItem!=undefined)
  {
    if(props.level=="campaign")
    {
  allCampaignItem.map((item) => {
    labels.map((lableItem) =>{
      var clickCount=0;
      var impressionCount=0;
      var buyNowCount=0;
      var snipCount=0;
      'offerId' in item?item.productBeacon.map((item) => {
        var createdOn = moment(item.createdOn).format('MM/DD/YYYY');
        var lableDate = moment(lableItem).format('MM/DD/YYYY');
        if(createdOn===lableDate)
        {
        clickCount=item.clicks+""
        impressionCount=item.impressions+""
        buyNowCount=item.buyNowClicks+""
        snipCount=item?.snips+""
        }
        else
        {
        clickCount="0"
        impressionCount="0"
        buyNowCount="0"
        snipCount="0"
        }
  
      })
      
      :item.offerBeacon.map((item) => {
        var createdOn = moment(item.createdOn).format('MM/DD/YYYY');
        var lableDate = moment(lableItem).format('MM/DD/YYYY');
        if(createdOn===lableDate)
        {
        clickCount=item.clicks+""
        impressionCount=item.impressions+""
        buyNowCount=item.buyNowClicks+""
        snipCount=item?.snips+""
        }
        else
        {
        clickCount="0"
        impressionCount="0"
        buyNowCount="0"
        snipCount="0"
        }
      })
      clickData.push(clickCount)
      impressionData.push(impressionCount)
      buyNowData.push(buyNowCount)
      snipData.push(snipCount)
    })
  })
    }
    else
    {
  allCampaignItem.map((item) => {

    var productLength=item.productBeacon?.length;
      var offerLength=item.offerBeacon?.length;

    if(item.id==props.itemId)
      {
    labels.map((lableItem) =>{
      var clickCount=0;
      var impressionCount=0;
      var buyNowCount=0;
      var snipCount=0;
      var count=1;
    var isFound=false;
      'offerId' in item?item.productBeacon.map((item) => {
        var createdOn = moment(item.createdOn).format('MM/DD/YYYY');
        var lableDate = moment(lableItem).format('MM/DD/YYYY');
        if(createdOn===lableDate)
        {
        clickCount=item.clicks+""
        impressionCount=item.impressions+""
        buyNowCount=item.buyNowClicks+""
        snipCount=item?.snips+""
        clickData.push(clickCount)
        impressionData.push(impressionCount)
        buyNowData.push(buyNowCount)
        snipData.push(snipCount)
        isFound=true;
        }
        else if(count==productLength && !isFound)
        {
        clickCount="0"
        impressionCount="0"
        buyNowCount="0"
        snipCount="0"
        clickData.push(clickCount)
        impressionData.push(impressionCount)
        buyNowData.push(buyNowCount)
        snipData.push(snipCount)
        }
        count++;
      })
      
      :item.offerBeacon.map((item) => {
        var createdOn = moment(item.createdOn).format('MM/DD/YYYY');
        var lableDate = moment(lableItem).format('MM/DD/YYYY');
        if(createdOn===lableDate)
        {
        clickCount=item.clicks+""
        impressionCount=item.impressions+""
        buyNowCount=item.buyNowClicks+""
        snipCount=item?.snips+""
        clickData.push(clickCount)
        impressionData.push(impressionCount)
        buyNowData.push(buyNowCount)
        snipData.push(snipCount)
        isFound=true;
        }
        else if(count==offerLength && !isFound)
        {
        clickCount="0"
        impressionCount="0"
        buyNowCount="0"
        snipCount="0"
        clickData.push(clickCount)
        impressionData.push(impressionCount)
        buyNowData.push(buyNowCount)
        snipData.push(snipCount)
        }
        count++;
      })
     
    })
  }
  })
    }
  }
  
  console.log("clickData>>>>>>>>>>>>>>>>",clickData)
  
  
    const data1 = {
      labels,
      datasets: [
        {
          label: "Clicks",
          data:clickData,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: "Impressions",
          data: impressionData,
          borderColor: 'rgb(255, 199, 132)',
          backgroundColor: ['rgba(255, 199, 132, 0.5)'],
        },
        {
          label: "Buy Now",
          data: buyNowData,
          borderColor: 'rgb(0,128,0)',
          backgroundColor: ['rgb(0,128,0,0.5)'],
        },
        {
          label: "Snips",
          data: snipData,
          borderColor: 'rgb(48, 25, 52)',
          backgroundColor: ['rgb(48, 25, 52,0.5)'],
        },
       
      ],
    };

    setChartData(data1);
  

}


 
  

  return (
    <div className="table-exclude-search pt-3">
      {viewLevel!="campaign"?
        <div className="date-filter">
          <OverlayTrigger placement="top" overlay={renderTooltip}>
        <p className="small font-weight-bold mb-1">What filter does? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
</svg></p>
      </OverlayTrigger>
                <InputGroup className="align-items-end flex-nowrap mb-3">
                   <Form.Group>
                   <Form.Label className="small">From Date</Form.Label>
                   <Form.Control 
                   name="fromDate"
                   className="form-control-sm"
                   value={fromDate}
                   onChange={(e) =>setFromDate(e.target.value)}
                   type="date" 
                   required
                   />
                    <Form.Control.Feedback type="invalid" >Please enter Valid from</Form.Control.Feedback>
                 </Form.Group>
         
                 <Form.Group className="px-3">
                   <Form.Label className="small">To date</Form.Label>
                   <Form.Control  name="toDate"
                   value={toDate}
                   className="form-control-sm"
                   onChange={(e) =>setToDate(e.target.value)}
                   type="date"
                   required
                   />
                    <Form.Control.Feedback type="invalid" >Please enter valid till</Form.Control.Feedback>
                 </Form.Group>
                 <button 
                 onClick={(e) =>handleFilter(e)}
                  className="btn btn-primary btn-sm">Filter</button>
                 </InputGroup>
        </div>
                 :""}
                 {!isFilter?
     <Line options={options} data={data} />
     :
     <Line options={options} data={chartData} />
     }
     <ToastContainer />
      </div>
  )
}

export default CampaignAnalytics
