import React from "react"

import category from '../../assets/Category.png'
import signuppng from '../../assets/SignUp.png'
import storeinfo from '../../assets/Store-Info1.png'
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


import NavigationIcon from '@material-ui/icons/Navigation';
import Fab from '@material-ui/core/Fab';
import Sidebar from "./Sidebar"
import SearchBar from "./SearchBar"
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'





const useStylesTop = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));



// const FormContainer = styled.form`
//   width: 400px;
//   background-color: #fff;
//   padding: 50px;
//   margin-top: 150px;
//   box-shadow: 0 0 20px silver;
//   border-radius: 10px;
// `
// const RegisterLink = styled(Link)`
//   position: absolute;
//   top: 0;
//   left: 0;
//   margin: 10px 25px;
//   background-color: #fff;
//   border-radius: 10px;
//   box-shadow: 0 0 20px silver;
//   padding: 25px;
//   :hover {
//     text-decoration: none;
//   }
// `


function SignUp() {

  const classes = useStyles();
  
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  const classesTop = useStylesTop();

  

  const history = useHistory();

  const handleSubmit = async (e) => {

    history.push(`/login`)
  }

  return (
    <>
      <SearchBar/>

      <div className={classes.root}>
        <AppBar position="static" color="#fff" position="sticky">
          <div className="container-fluid px-3 px-lg-5">
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" indicatorColor="primary">
              <Tab label="Functional">Functional</Tab>
              <Tab label="Technical"></Tab>
            </Tabs>
          </div>
        </AppBar>
        <TabPanel value={value} index={0}>
          <div className="container-fluid px-0 px-lg-5">
            <div className="row mt-3">
              <div className="col-md-3 ">
              <Sidebar/>

              </div>

              <div className="col-md-9">
                <div className="help-content">
                <section id="Signing-Up">
                    <h3>Signing Up</h3>
                    <p>The first step is to sign up into ShopiAds using a valid email id. User needs to enter following details when doing a signup</p>
                    <ul>
                      <li><strong>Mobile Number -</strong> User needs to enter a valid mobile number during the signup process. A valid mobile number allows the system to verify the genuinity of the data.</li>
                      <li><strong>First Name -</strong> Here, the user needs to enter the First Name.</li>
                      <li><strong>Last Name -</strong> Here, the user can enter Last Name.</li>
                      <li><strong>Email Address -</strong> User needs to enter a valid email id in order to verify the account and proceed with ShopiAds. User will receive a verification email to the email id entered as soon as signup is done.</li>
                      <li><strong>Set Password -</strong> User needs to set a password for the account. A strong password will help in keeping the account secure. </li>
                    </ul>
                    <Zoom>
                                            <picture>
                            <source media="(max-width: 800px)" srcSet={signuppng} />
                            <img
                              alt="that wanaka tree"
                              src={signuppng}
                              width="500"
                            />
                          </picture>

                          </Zoom>    
                    
                    <p>It is important that the user enters a valid email id to sign up as this email id will be used in the later part. Also, a valid email id will increase the genuinity of the ads and help in getting maximum reach for your ads.

                      After successful signup, the user is taken to the Store Info screen, where the user needs to enter the accurate information about the Store.

                      The first screen that will be displayed to the user is <strong>“Store Info”</strong></p>

                      <Zoom>
                                            <picture>
                            <source media="(max-width: 800px)" srcSet={storeinfo} />
                            <img
                              alt="that wanaka tree"
                              src={storeinfo}
                              width="500"
                            />
                          </picture>

                          </Zoom>    
                   


                    <p>In the Store Info screen, the user has to provide following information about the Store. </p>

                    <ul>
                      <li><strong>Company Business Name -</strong> Name of the company for which the store is created. </li>
                      <li><strong>Store Name -</strong> Name for the Store.</li>
                      <li><strong>Branch Name -</strong> Name of the branch where the store is located.</li>
                      <li><strong>Website URL -</strong> Here, the user needs to enter the Web Site URL of the company for which the store is getting created. </li>
                      <li><strong>Company Logo -</strong> The user can upload the logo of the company by clicking on the browse button and uploading the company logo.</li>
                    </ul>

                    <p>By pressing the Next button, the user is taken to the Category screen from where the user can set the selling category. Users are allowed to select only one category for the time being.

                      <strong>Category</strong> is the area of business where you are into. It is important to mention the exact nature of your business as it will help in getting into the correct market and find your targeted audience. </p>
                        
                        
                      <Zoom>
                                            <picture>
                            <source media="(max-width: 800px)" srcSet={category} />
                            <img
                              alt="that wanaka tree"
                              src={category}
                              width="500"
                            />
                          </picture>

                          </Zoom>  

                    <p>After the user selects a category and clicks on the Next button, the Business Address screen is displayed where the user can enter the business address of the store user is creating.</p>

                    <Zoom>
                                            <picture>
                            <source media="(max-width: 800px)" srcSet={require('../../assets/Business-Address.png').default} />
                            <img
                              alt="that wanaka tree"
                              src={require('../../assets/Business-Address.png').default}
                              width="500"
                            />
                          </picture>

                          </Zoom>  

                   

                    <p>Here, the user needs to make sure the address entered is correct as the user is not allowed to make any edit to it once this information is saved to the system. After filling all the business info details, when the user clicks on the Next button, a Personal Info screen is displayed.

                      In the Personal Info screen, the user needs to enter details like store owner name, store operating hours and weekly off details.
                    </p>

                    <Zoom>
                                            <picture>
                            <source media="(max-width: 800px)" srcSet={require('../../assets/Personal-Info.png').default} />
                            <img
                              alt="that wanaka tree"
                              src={require('../../assets/Personal-Info.png').default}
                              width="500"
                            />
                          </picture>

                          </Zoom> 

                   

                    <p>Once the user clicks on the Submit button after filling all the required information in the Personal Info screen, a confirmation popup message is displayed that welcomes the user to ShopiAds and by clicking on the login button, the user can log into the ShopiAds admin portal to setup his/her store. </p>

                    <Zoom>
                                            <picture>
                            <source media="(max-width: 800px)" srcSet={require('../../assets/confirmation.PNG').default} />
                            <img
                              alt="that wanaka tree"
                              src={require('../../assets/confirmation.PNG').default}
                              width="500"
                            />
                          </picture>

                          </Zoom> 

                   

                  </section>

                </div>

              </div>

            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          Technical
        </TabPanel>
      </div>

      <div className="backtoptop">
        <a href="#top">
          <Fab variant="extended">
            <NavigationIcon className={classesTop.extendedIcon} />
            Top
          </Fab>
        </a>
      </div>




    </>
  )
}

export default SignUp