import React, { useEffect, useState } from "react"
import { useAuth } from "../context"
import { Form, InputGroup } from "react-bootstrap"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"

import { NavLink } from "react-router-dom"
import API from "../utils/API"


function ManageCategories() {
  
    const [mainCategoryList, setMainCategoryList] = useState([]);
    const [subCategoryList1, setSubCategoryList1] = useState([]);
    const [subCategoryList2, setSubCategoryList2] = useState([]);
    const [subCategoryList3, setSubCategoryList3] = useState([]);
    const [mainLevel,setMainLevel] = useState();
    const [level1,setLevel1] = useState();
    const [level2,setLevel2] = useState();
    const [level3,setLevel3] = useState();
    const [activeId1,setActiveId1] = useState();
    const [activeId2,setActiveId2] = useState();
    const [activeId3,setActiveId3] = useState();
    const [activeId4,setActiveId4] = useState();
    const [parentIdlevel1,setParentIdLevel1] = useState();
    const [parentIdlevel2,setParentIdLevel2] = useState();
    const [parentIdlevel3,setParentIdLevel3] = useState();
    const [disableCreate,setDisableCreate] = useState(true);
    const [selectedCategoryId,setSelectedCategoryId] = useState();
    const { userInfo } = useAuth();
    useEffect(() => {
        API.getMainCategories(userInfo.email).then((result) => {  
           
          if(result.data!=undefined && result.data.length>0)
          {
              //alert('data=='+result.data);
            setMainCategoryList(result.data)
          }
               })
               .catch((error) => {
                 console.log(error);
                 alert(error);
              });
        
      }, []);

      const getSubCategory =   (e,item,level) => {
       // alert("categoryId"+categoryId);
        
        API.getSubCategoriesList(userInfo.email,item.id).then((result) => {  
            
            if(result.data!=undefined && result.data.length>0)
            {
                // alert('data=='+result.data);
                if(level==1) 
                {
                setActiveId1(item.id);
                setMainLevel(item.name)
                setSubCategoryList1(result.data)
                setSubCategoryList2(null)
                setSubCategoryList3(null)
                setLevel1(null)
                setLevel2(null)
                setLevel3(null)
                setActiveId2(null);
                setActiveId3(null);
                setActiveId4(null);
                setDisableCreate(false)
                setSelectedCategoryId(item.id);
                setParentIdLevel1(item.id);
                }
                if(level==2) 
                {
                setActiveId2(item.id);
                setLevel1(item.name)
                setSubCategoryList2(result.data)
                setSubCategoryList3(null)
                setLevel2(null)
                setLevel3(null)
                setActiveId3(null);
                setActiveId4(null);
                setDisableCreate(false)
                setSelectedCategoryId(item.id);
                setParentIdLevel2(item.id);
                }
                if(level==3) 
                {
                setActiveId3(item.id);
                setLevel2(item.name)
                setSubCategoryList3(result.data)
                setLevel3(null)
                setActiveId4(null);
                setDisableCreate(false)
                setSelectedCategoryId(item.id);
                setParentIdLevel3(item.id);
                }
            }
            else
            {
                if(level==1) 
                {
                setActiveId1(item.id);
                setMainLevel(item.name)
                setSubCategoryList1(null)
                setSubCategoryList2(null)
                setSubCategoryList3(null)
                setLevel1(null)
                setLevel2(null)
                setLevel3(null)
                setActiveId2(null);
                setActiveId3(null);
                setActiveId4(null);
                setDisableCreate(false)
                setSelectedCategoryId(item.id);
                setParentIdLevel1(item.id);
                }
                if(level==2) 
                {
                setActiveId2(item.id)
                setLevel1(item.name)
                setSubCategoryList2(null)
                setSubCategoryList3(null)
                setLevel2(null)
                setLevel3(null)
                setActiveId3(null);
                setActiveId4(null);
                setDisableCreate(false);
                setSelectedCategoryId(item.id);
                setParentIdLevel2(item.id);
                }
                if(level==3) 
                {
                setActiveId3(item.id)
                setLevel2(item.name)
                setSubCategoryList3(null) 
                setLevel3(null)
                setActiveId4(null);
                setDisableCreate(false);
                setParentIdLevel3(item.id);
                setSelectedCategoryId(item.id);
                }
                
            }
            if(level==4) 
            {
            setLevel3(item.name)
            setActiveId4(item.id)
            setDisableCreate(false)
          //  setParentIdLevel4(item.id);
            setSelectedCategoryId(item.id)
            }
                 })
                 .catch((error) => {
                   console.log(error);
                   alert(error);
                });


      }
     

  return (
    <div className="row">
    <div className="col-md-12">
        <h4 className="mb-3">Choose Category</h4>
    </div>
    <div className="col-md-2">
        <div className="wrapper">
            <ul>
            {mainCategoryList.map((item) => (
                <li><a className={ activeId1 === item.id && 'active' } onClick={(e) =>getSubCategory(e,item,1)}> {item.name} </a></li>
            ))}
            <li>
              <Button
                variant="contained"
                color="info"
                class="btn btn-info"
                // onClick={toggleModal}
                component={NavLink}
                to={"/home/categories/AddCategory?selectedParentId=main"}>
                Add Root Category
              </Button>
            </li>
                {/* <li><a class="active"> Fashion </a></li>
                <li><a> Beauty &amp; Grooming </a></li>
                <li><a> Toys &amp; Games </a></li>
                <li><a> Home &amp; Living </a></li>
                <li><a> Grocery &amp; Essentials </a></li>
                <li><a> Hobbies </a></li>
                <li><a> Handicrafts </a></li>
                <li><a> Gifts </a></li>
                <li><a> Books </a></li>
            <li><a> Business </a></li>*/}
            </ul>
        </div>
    </div>
    <div className="col-md-2">    
    {mainLevel!=null && (   
    <div className="wrapper">
    {subCategoryList1!=null && subCategoryList1.length>0 ?  (
            <ul>
            {subCategoryList1.map((item) => (
                <li><a className={ activeId2 === item.id && 'active' } onClick={(e) =>getSubCategory(e,item,2)}> {item.name} </a></li>
            ))}
                {/* <li><a> Women </a></li>
                <li><a> Men </a></li>
                <li><a class="active"> Kids </a></li>
                <li><a> Babies </a></li>
                <li><a> Footwear </a></li>
                <li><a> Jewellery &amp; Watches </a></li>
                <li><a> Bags &amp; Baggage </a></li>
                <li><a> Accessories </a></li>
                <li><a> Others </a></li> */}
            </ul>):""}
            <ul>              
            <li>
              <Button
                variant="contained"
                color="info"
                class="btn btn-info"
                // onClick={toggleModal}
                component={NavLink}
                to={"/home/categories/AddCategory?selectedParentId="+parentIdlevel1}
              >
                Add Sub-Category
              </Button>
            </li>
            </ul>
        </div>)}
    </div>
    <div className="col-md-2"> 
        {level1!=null && (   
        <div className="wrapper">
        {subCategoryList2!=null && subCategoryList2.length>0 ?  (
            <ul>
            {subCategoryList2.map((item) => (
                <li><a className={ activeId3 === item.id && 'active' } onClick={(e) =>getSubCategory(e,item,3)}> {item.name} </a></li>
            ))}
                {/*<li><a> Girl's Clothing </a></li>
                <li><a> Boy's Clothing </a></li>
                <li><a class="active"> Kid's Footwear </a></li>
                <li><a> Kid's Accessories </a></li>*/}                
            </ul>):""}
            <ul>
            <li>
              <Button
                variant="contained"
                color="primary"
                 // onClick={toggleModal}
                component={NavLink}
                to={"/home/categories/AddCategory?selectedParentId="+parentIdlevel2}
              >
                Add SubCategory
              </Button>
            </li>
            </ul>
        </div>)}
    </div>
    <div className="col-md-2">    
        {level2!=null && (
        <div className="wrapper">
        {subCategoryList3!=null && subCategoryList3.length>0 ?  (
            <ul>
            {subCategoryList3.map((item) => (
                <li><a className={ activeId4 === item.id && 'active' } onClick={(e) =>getSubCategory(e,item,4)}> {item.name} </a></li>
            ))}
                {/*<li><a> Boy's Footwear </a></li>
                <li><a class="active"> Girl's Footwear </a></li> */}
            </ul>):""}           
            <ul>
            <li>
              <Button
                variant="contained"
                color="primary"
                 // onClick={toggleModal}
                component={NavLink}
                to={"/home/categories/AddCategory?selectedParentId="+parentIdlevel3}
              >
                Add SubCategory
              </Button>
            </li>
            </ul>
        </div>)}
    </div>
    <div className="col-md-4 ml-auto">
        <div className="wrapper">
          <div className="align-items-center d-flex h-100 justify-content-center text-center">
            <div>
            {subCategoryList1!=null && subCategoryList2!=null && subCategoryList3!=null &&(
            <h5 className="mb-5 pb-5">{mainLevel}>{level1}>{level2}>{level3}</h5>)}

            {subCategoryList1!=null && subCategoryList2!=null && subCategoryList3==null &&(
            <h5 className="mb-5 pb-5">{mainLevel}>{level1}>{level2}</h5>)}

            {subCategoryList1!=null && subCategoryList2==null && subCategoryList3==null &&(
            <h5 className="mb-5 pb-5">{mainLevel}>{level1}</h5>)}
            
            {subCategoryList1==null && subCategoryList2==null && subCategoryList3==null &&(
            <h5 className="mb-5 pb-5">{mainLevel}></h5>)}

              <div className="btn-inline-group">
              <Button variant="contained" color="primary"  
              disabled={disableCreate} component={NavLink} 
              to={"/home/categories/AddCategory?id="+selectedCategoryId}
              >Update Category</Button>
              {/*<button className="btn btn-danger text-uppercase ml-3">Change</button>*/}
              </div>

            </div>
          </div>
        </div>
    </div>
</div>

      )
}

export default ManageCategories


