import React, { useEffect, useState } from "react"
import { useStore, useAuth } from "../context"
import { Stores } from "."
import MUIDataTable from "mui-datatables"
import Button from "@material-ui/core/Button"
import AddRoundedIcon from "@material-ui/icons/AddRounded"
import { NavLink } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom";
//import Modal from "react-modal";
import {Modal} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import API from "../utils/API";
import Moment from 'react-moment';
import {Form} from 'react-bootstrap';

const useStyles = makeStyles(() => ({
  tab: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    padding: "0 10px",
    boxShadow: "0 0 15px silver",
  },
  button: {
    "&:hover": {
      color: "#fff",
    },
  },
}))





function ManageCatalog(props) {

  let isFromDetails=new URLSearchParams(props.location.search).get("details");
  const [cataLogList, setCataLogList] = useState([]);
  const { userInfo,loggedUserInfo } = useAuth();
  const classes = useStyles()
  const [companyList, setCompanyList] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const history = useHistory();
  const { companyData, storeData, getCompanyByUser, getStoreByCompany } =
    useStore();


    const options = {
      rowsPerPage: 10,
      print: false,
      selectableRowsOnClick: false,
      selectToolbarPlacement: "none",
      selectableRows:"none",
      page:Number(localStorage.getItem('catLogPageNumber')),
      onChangePage (currentPage) {
       // setPageNumber(currentPage);
        localStorage.setItem('catLogPageNumber', currentPage);
        console.log("page number click>>>>>>>>>>>",currentPage);
      },
     /* customToolbar: () => (
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddRoundedIcon />}
         // onClick={toggleModal}
          component={NavLink}
          to={"/home/categories/AddCategory"}
        >
          Add Category
        </Button>
      ),*/
      
    }


  useEffect(() => {
   // console.log("location.pathname>>>>>>>>>",location.pathname);
   if(isFromDetails==undefined||isFromDetails==null||isFromDetails=="")
    localStorage.setItem('catLogPageNumber', "0");

    if(loggedUserInfo!=undefined)
   {
    API.getAllCompany(loggedUserInfo.email).then((companyResult) => {  
       
      if(companyResult.data!=undefined && companyResult.data.length>0)
      {
        setCompanyList(companyResult.data)
       
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });

         
          if(localStorage.getItem('branchFilterSyncCatalog')!=undefined && localStorage.getItem('branchFilterSyncCatalog')!=null && localStorage.getItem('branchFilterSyncCatalog')!='')
          {
            getStoreByCompany(localStorage.getItem('companyFilterSyncCatalog'));
            API.getBranchProductCatalog(localStorage.getItem('branchFilterSyncCatalog')).then((result) => {  
       
              if(result.data!=undefined && result.data.length>0)
              {
                setCataLogList(result.data)
              }
              else
              {
                setCataLogList([])
              }
                   })
                   .catch((error) => {
                     console.log(error);
                     alert(error);
                  });
            
          }
          else
          {
     API.getCompanyProductCatalog(loggedUserInfo.companyId).then((result) => {  
       
      if(result.data!=undefined && result.data.length>0)
      {
        setCataLogList(result.data)
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });
        }
      }
    
  }, [loggedUserInfo]);


  const columns = [
    {
      name: "catalogName",
      label: "Catalog Name",
      options: {
        filter: false,
        sort: true,
       
      },
    },
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
            <a href="javascript:void(0)"onClick={(e) =>syncDetails(value.id,value.type)} >{value.id}</a>
          );
        }
      },
    },  
    {
      name: "type",
      label: "Type",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "syncDate",
      label: "Sync Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
          <Moment  tz="CST" format="YYYY/MM/DD hh:mm:ss A">
          {value}
          </Moment>
          );
         } 
      },
      
    },
  
   
   /* {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
            <Button
            variant="contained"
            color="primary"
           // startIcon={<AddRoundedIcon />}
            component={NavLink}
            to={"/home/categories/AddCategory?id="+value}
          >
            Update
          </Button>
          );
        }
      },
    },*/
    
  ];

  const syncDetails =  (value,type) => {
    if(type=="Product")
    history.push(`/home/catalog/catalogDetail?id=`+value);
    else
    {
      history.push(`/home/catalog/catalogCollectionDetail?id=`+value);
    }
   // alert(value);

  };

  const rowData = cataLogList.map((item) => ({
    catalogName: item.name,
    id: item,
    type:item.type,
    status:item.totalSync+" Shopify Catalog Sync",
    syncDate:item.createdOn
  }));


  const getCompanyBranches = async (e) => {
    console.log("event>>>>>>>>>",e.target.value);
    if(e.target.value!=undefined && e.target.value!='')
    {
      localStorage.setItem('companyFilterSyncCatalog', e.target.value);
      getStoreByCompany(e.target.value);
        }
        
  };

  const handleBranchChanage = async (e) => {
    console.log("event>>>>>>>>>",e.target.value);
    if(e.target.value!=undefined && e.target.value!='')
    {
      localStorage.setItem('branchFilterSyncCatalog', e.target.value);
      API.getBranchProductCatalog(e.target.value).then((result) => {  
       
        if(result.data!=undefined && result.data.length>0)
        {
          setCataLogList(result.data)
        }
        else
        {
          setCataLogList([])
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            });
    }
  };


  return (

    <div>
    <div className={classes.tab}>
    {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")) ? (
      <div className="d-flex ml-auto py-2">
      <Form.Control
        as="select"
        className=" mr-3"
        name="company"
        value={localStorage.getItem('companyFilterSyncCatalog')}
        onChange={getCompanyBranches}
        required
      >
        <option>Select Company</option>
        {companyList.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Control>
      
      <Form.Control
        as="select"
        className="mr-sm-0"
        name="companyBranch"          
        value={localStorage.getItem('branchFilterSyncCatalog')}
        onChange={handleBranchChanage}
        required
      >
        <option>Select Branch</option>
        {storeData.map((item, index) => (
          <option key={item.id} value={item.id}>
            {item.code}
          </option>
        ))}
      </Form.Control>
      </div>
       ) : null}
</div>
   
    <MUIDataTable
        className="mb-3"
        title="Sync Catalog"
        data={rowData}
        columns={columns}
        options={options}
      />
      </div>
  )
}

export default ManageCatalog
