import React, { useEffect, useState,useRef } from "react"
import { useStore, useAuth } from "../context"
import { Stores } from "."
import MUIDataTable from "mui-datatables"
import Button from "@material-ui/core/Button"
import AddRoundedIcon from "@material-ui/icons/AddRounded"
import { NavLink } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom";
//import Modal from "react-modal";
import {Modal} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import API from "../utils/API";
import Moment from 'react-moment';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import ReactDOM from 'react-dom';
import { PDFViewer } from '@react-pdf/renderer';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";


const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

function ViewInvoice(props) {

  const invoiceId=new URLSearchParams(props.location.search).get("id");
  const [invoiceData, setInvoiceData] = useState();
  const [companyData, setCompanyData] = useState();
  const [campaignData, setCampaignData] = useState();
  const { userInfo,loggedUserInfo } = useAuth();
  

  useEffect(() => {
    if(loggedUserInfo!=undefined)
   {
    API.getInvoiceById(invoiceId).then((result) => {  
       
      if(result.data!=undefined)
      {
        setInvoiceData(result.data)
        API.getCompanyId(result.data.companyId).then((companyResult) => {  
       
          if(companyResult.data!=undefined)
          {
            setCompanyData(companyResult.data)
          }
               })
               .catch((error) => {
                 console.log(error);
                 alert(error);
              })

              if(result.data.campaignId!=undefined && result.data.campaignId!=null && result.data.campaignId!='')
              {
              API.getCampaignById(result.data.campaignId).then((campaignResult) => {  
       
                if(campaignResult.data!=undefined)
                {
                  setCampaignData(campaignResult.data)
                }
                     })
                     .catch((error) => {
                       console.log(error);
                       alert(error);
                    })
                  }
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });
    }
  }, [loggedUserInfo]);

 
 

  const pdfExportComponent = useRef(null);

  const handleExportWithComponent = () => {
  
    pdfExportComponent.current.save();
   // setIsPdfSet(false);
  
  };


  return (
  
    <div className="size-a4 m-auto">
     {//invoiceData!=undefined && invoiceData!=null ? (
       <Button
       variant="contained"
       color="primary"
       className="float-right"
      // startIcon={<AddRoundedIcon />}
      onClick={handleExportWithComponent}
     >
       Download Pdf
      </Button>
}

{invoiceData!=null && invoiceData!=undefined && invoiceData!='' ? (
      <div id="printDiv" className="page-container hidden-on-narrow mt-5 pt-5">
      
        <PDFExport ref={pdfExportComponent}>
          <div className={`pdf-page size-a4`}>
            <div className="inner-page">
              <div className="pdf-header">
                <div className="company-logo">
                  <h6 className="mb-0">Receipt for {companyData!=undefined?companyData.regName:""}</h6>
                   {/*<small>Account ID: 205386327079797</small>*/}
                </div>
                <div>ShopiAds</div>
                {/* <span className="invoice-number">Invoice #13124233223</span> */}
              </div>
              <div className="pdf-footer">
               <div>
              {/* <p>
                  Blauer See Delikatessen1111
                  <br />
                  Lützowplatz 456
                  <br />
                  Berlin, Germany, 10785
              </p>*/}
              </div>

               <div className="text-right">
               <p>
               ShopiAdsShopiAds Inc
                  <br />
                  Mississauga, L5B3W4
                 
                </p>
               </div>
              </div>
              <div className="addresses">
                <div className="for">
                  <div className="pb-3">
                    <div>Invoice/payment date</div>
                  <p><Moment  tz="CST" format="YYYY/MM/DD hh:mm:ss A">
                    {invoiceData.createdOn}
                    </Moment></p>
                  </div>

                  <div className="pb-3">
                    <div>Payment method</div>
                  <p className="mb-0">{invoiceData.paymentType}</p>
                  <div>Reference number: {invoiceData.paymentId}</div>
                  </div>

                 {/* <div className="pb-3">
                    <div>Transaction ID</div>
                  <p>4115387901910482-7998023</p>
</div>*/}

                  <div className="pb-3">
                    <div>Product type</div>
                  <p>{invoiceData.invoiceType}</p>
                  </div>




                </div>

                <div className="from text-right">
                  <h6>Paid</h6>
                  <h3>${invoiceData.paymentAmount} CAD</h3>
                  <div>Subtotal: {invoiceData.paymentAmount} CAD</div>
                  <div>GST/HST/PST/QST: CAD {invoiceData.taxAmount}</div>
                 {/* <div className="note">You're being billed because you've reached your ${invoiceData.taxAmount} billing threshold.</div>*/}
                </div>
              </div>

              <div className="order-details">
              <h6 className="border-bottom py-3">Campaign</h6>
                  <div className="description-wrapper">
                  <div className="description-left">
                  <div className="description-details">{campaignData!=undefined?campaignData.campaignName:""}</div>
                  <p className="description-timeline">From <Moment  tz="CST" format="YYYY/MM/DD hh:mm:ss A">
                    {campaignData!=undefined?campaignData.validFrom:""}
                    </Moment> to <Moment  tz="CST" format="YYYY/MM/DD hh:mm:ss A">
                    {campaignData!=undefined?campaignData.validTill:""}
                    </Moment></p>
                  </div>
                  <div className="balance">${Number(invoiceData.paymentAmount)+Number(invoiceData.taxAmount)}</div>
                  </div>
                  <div className="order-total">
                 {/*<div className="m-auto text-center w-75">
                  [New All-weather Car Mats to protect your vehicle from Dust & Rust. Availab...4,287 Impressions
                  </div>
                  <div className="balance">${invoiceData.paymentAmount}</div>*/}
                  </div>
                  
              </div>
             
              {/* <div className="pdf-body">
                <div id="grid" />
                <p className="signature">
                  Signature: ________________ <br />
                  <br />
                  Date: 12.03.2014
                </p>
              </div> */}
            </div>
          </div>
        </PDFExport>
      </div>
     ) : null}
    
      </div>
  )
}

export default ViewInvoice
