import React, { useEffect, useState } from "react"
import { useStore, useAuth } from "../context"
import { Stores } from "."
import MUIDataTable from "mui-datatables"
import Button from "@material-ui/core/Button"
import AddRoundedIcon from "@material-ui/icons/AddRounded"
import { NavLink } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom";
//import Modal from "react-modal";
import {Modal} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import API from "../utils/API";
import Moment from 'react-moment';
import { findAllByDisplayValue } from "@testing-library/react"
import moment from 'moment';



const sponsoredColumns = [
  {
    name: "section",
    label: "Section/Keyword",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "type",
    label: "Type",
    options: {
      filter: false,
      sort: true,
    },
  }, 
  {
    name: "bid",
    label: "Bid",
    options: {
      filter: false,
      sort: true,
    },
  }, 
  {
    name: "suggestedBid",
    label: "Suggested Bid",
    options: {
      filter: false,
      sort: true,
    },
  },  
  
 /* {
    name: "action",
    label: "Action",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        //alert(value);
        return (
          <Button
          variant="contained"
          color="primary"
         // startIcon={<AddRoundedIcon />}
          component={NavLink}
          to={"/home/categories/AddCategory?id="+value}
        >
          Update
        </Button>
        );
      }
    },
  },*/
  
];

const useStyles = makeStyles(() => ({
  tab: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    padding: "0 10px",
    boxShadow: "0 0 15px silver",
  },
  button: {
    "&:hover": {
      color: "#fff",
    },
  },
}))

const rowData=[];

var pageNumber=0;

function CampaignDeatils(props) {

  const campaignId=props.campaignId;
  const type=props.type;
  console.log("onItemSelect>>>>>>>>>>>>>>>>>>",props.onItemSelect);
  console.log("campaignId>>>>>>>>>>>>>>>>>>",props.campaignId);
  console.log("offer[0]>>>>>>>>>>>>>>>>>>",props.offer);
  const classes = useStyles()

  const [offerCampaign, setOfferCampaign] = useState([]);
  const [productCampaign, setProductCampaign] = useState([]);
  const [campaignData, setCampaignData] = useState();
  const [isInactive, setIsInactive] = useState(false);
  const { userInfo,loggedUserInfo } = useAuth();



  

  useEffect(() => {
    if(campaignId!=undefined)
   {

    setOfferCampaign([])
    setProductCampaign([])
          API.getCampaignById(campaignId).then((campaignResult) => {  
       
            if(campaignResult.data!=undefined )
            {

              setCampaignData(campaignResult.data)
              var date1 = moment(campaignResult.data.validTill)
                    var nowDate= moment();
                    console.log("date1.isBefore(nowDate) date>>>>>>>>>>>",date1.isBefore(nowDate))
                    console.log("nowDate date>>>>>>>>>>>",nowDate)
                    console.log("to date>>>>>>>>>>>",date1)
                    if(date1.isBefore(nowDate))
                    {
                      setIsInactive(true);
                    }
                    else
                    {
                      setIsInactive(false);
                    }

            }
                 })
                 .catch((error) => {
                   console.log(error);
                   alert(error);
                });

                API.getCampaignOffer(campaignId,type,undefined,undefined).then((result) => {  
       
                  if(result.data!=undefined && result.data.length>0)
                  {
                    setOfferCampaign(result.data)
                    
                  }
                       })
                       .catch((error) => {
                         console.log(error);
                         alert(error);
                      });

                      API.getCampaignProduct(campaignId,type,undefined,undefined).then((result) => {  
       
                        if(result.data!=undefined && result.data.length>0)
                        {
                          setProductCampaign(result.data)
                        }
                             })
                             .catch((error) => {
                               console.log(error);
                               alert(error);
                            });
    }
  }, [campaignId]);

 
  const allCampaignItem = [...offerCampaign, ...productCampaign];


  const columns = [
    {
      name: "offer",
      label: "Offer/Collection/Product",
      options: {
        filter: false,
        sort: true,
      customBodyRender: (item, tableMeta, updateValue) => {
        return (
          <div>
            
          {item.type=="Offer" || item.type=="Collection"?
          <div className="d-flex align-items-center">
         <a href="#"  onClick={(e) =>props.onItemSelect(item.campaignId,item.id,"offer")}>{item.heading}</a>
          
          </div>
                :
                <div>
          
          <a href="#"  onClick={(e) =>props.onItemSelect(item.campaignId,item.id,"product")}>{item.name}</a>
          </div>}
            </div>
           
        )
      }
    }
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: false,
        sort: true,
      },
    }, 
    /*{
      name: "StartDate",
      label: "Start Date",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
          <Moment tz="CST" format="YYYY/MM/DD hh:mm:ss A">
          {value}
          </Moment>
          );
         } 
      },
    },
    {
      name: "EndDate",
      label: "End Date",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
          <Moment tz="CST" format="YYYY/MM/DD hh:mm:ss A">
          {value}
          </Moment>
          );
         } 
      },
    },*/
    {
      name: "budget",
      label: "Budget",
      options: {
        filter: false,
        sort: true,
      },
    },  
    
    /*{
      name: "cpc",
      label: "CPC",
      options: {
        filter: false,
        sort: false,
      },
    },  */
    {
      name: "impressions",
      label: "Impressions",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "clicks",
      label: "Clicks",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "clickCon",
      label: "CTR",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "buyNow",
      label: "Buy Now",
      options: {
        filter: false,
        sort: true,
      },
    },  
    {
      name: "snip",
      label: "Snips",
      options: {
        filter: false,
        sort: true,
      },
    },
   /* {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
            <Button
            variant="contained"
            color="primary"
           // startIcon={<AddRoundedIcon />}
            component={NavLink}
            to={"/home/categories/AddCategory?id="+value}
          >
            Update
          </Button>
          );
        }
      },
    },*/
    
  ];

  var rowPerPage=10;
  const options = {
    rowsPerPage: 10,
    print: false,
    download: false,
    filter:false,
    viewColumns:false,
    searchable:false,
    pagination:false,
    selectableRowsOnClick: false,
    selectToolbarPlacement: "none",
    selectableRows:"none",
    onChangePage: (currentPage) => {
      console.log("onChangePage>>>>>>>>>>>",currentPage);
      pageNumber=currentPage;
      for(var i=0;i<=allCampaignItem.length-1;i++)
      {
        let row1 = document.getElementById(`MUIDataTableBodyRow-${i}`);
        console.log("row1 selection>>>>>>>>>>>>>",row1);
        if(row1!=null && row1!=undefined && row1!='')
        {
        row1.removeAttribute('style')
        }
      }
      
    },
    onRowSelectionChange: (curRowSelected,allRowsSelected) => {
      console.log("curRowSelected>>>>>>>>>>>>>>>>>>",curRowSelected[0])
      var selectedIndex=0;
      if(pageNumber>0)
      selectedIndex=(pageNumber*rowPerPage)+curRowSelected[0].index
      else
      {
        selectedIndex=curRowSelected[0].index  
      }

      console.log("selectedIndex row>>>>>>>>>>>",selectedIndex);
      const selectedRow = allCampaignItem[selectedIndex];
      console.log("selected row>>>>>>>>>>>",selectedRow);
    for(var i=0;i<=allCampaignItem.length-1;i++)
    {
      let row1 = document.getElementById(`MUIDataTableBodyRow-${i}`);
      console.log("row1 selection>>>>>>>>>>>>>",row1);
      if(row1!=null && row1!=undefined && row1!='')
      {
      row1.removeAttribute('style')
      }
    }
      
      let row = document.getElementById(`MUIDataTableBodyRow-${selectedIndex}`);
      console.log("row selection>>>>>>>>>>>>>",row);
      if(row!=null && row!=undefined && row!='')
      {
      row.setAttribute('style', 'background: #D3D3D3;');
      }
      alert("selected ids>>>>>>>>>>>>>>>>>>",selectedRow.id);
      props.onItemSelect(selectedRow.id)
      //showChart(selectedRow.id);
    },
   /* customToolbar: () => (
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddRoundedIcon />}
       // onClick={toggleModal}
        component={NavLink}
        to={"/home/categories/AddCategory"}
      >
        Add Category
      </Button>
    ),*/
    
  }

console.log("productCampaign>>>>>>>>>>>>>>",productCampaign);
console.log("offerCampaign>>>>>>>>>>>>>>",offerCampaign);
console.log("offer id>>>>>>>>>>",'offerId' in offerCampaign);
  
  const rowData = allCampaignItem.map((item) => ({
    
    offer: item,
    StartDate: item.validFrom,
    EndDate:item.validTill,
    budget:item.budget,
    type:'offerId' in item?"Product":item.type,
   // cpc:item.cpc,
    impressions:'offerId' in item?item.productBeacon.map(item => item.impressions).reduce((prev, curr) => prev + curr, 0):item.offerBeacon.map(item => item.impressions).reduce((prev, curr) => prev + curr, 0),
    clicks:'offerId' in item?item.productBeacon.map(item => item.clicks).reduce((prev, curr) => prev + curr, 0):item.offerBeacon.map(item => item.clicks).reduce((prev, curr) => prev + curr, 0),
    clickCon:'offerId' in item?Number(item.productBeacon.map(item => item.clicks).reduce((prev, curr) => prev + curr, 0))>0?Math.round(Number(item.productBeacon.map(item => item.impressions).reduce((prev, curr) => prev + curr, 0))/Number(item.productBeacon.map(item => item.clicks).reduce((prev, curr) => prev + curr, 0))).toFixed(2)+"%":"0%":Number(item.offerBeacon.map(item => item.clicks).reduce((prev, curr) => prev + curr, 0))>0?Math.round(Number(item.offerBeacon.map(item => item.impressions).reduce((prev, curr) => prev + curr, 0))/Number(item.offerBeacon.map(item => item.clicks).reduce((prev, curr) => prev + curr, 0))).toFixed(2)+"%":"0%",
    buyNow:'offerId' in item?item.productBeacon.map(item => item.buyNowClicks).reduce((prev, curr) => prev + curr, 0):item.offerBeacon.map(item => item.buyNowClicks).reduce((prev, curr) => prev + curr, 0),
    snip:'offerId' in item?item.productBeacon.map(item => item.snips).reduce((prev, curr) => prev + curr, 0):item.offerBeacon.map(item => item.snips).reduce((prev, curr) => prev + curr, 0),
    
  }));

  let sponsoredRowData=[];
  if(type=="Sponsored" && campaignData!=undefined)
  {
    const allSponsoredCampaignItem = [...campaignData.sponsoredSection, ...campaignData.keywords];
    console.log("allSponsoredCampaignItem>>>>>>>>>>>>>>>>>",allSponsoredCampaignItem);
     sponsoredRowData = allSponsoredCampaignItem.map((item) => ({
    
      section: 'section' in item?item.section:item.keyword,
      bid: item.bid,
      suggestedBid:item.suggestedBid,
      budget:item.budget,
      type:'section' in item?"Section":"Keyword",
     // cpc:item.cpc,
      impressions:item.impressions,
      clicks:item.clicks
    }));
 /* if(campaignData.sponsoredSection==undefined && campaignData.sponsoredSection!=null && campaignData.sponsoredSection.length>0)
  {

  }*/
}



  const allSponsoredCampaignItem = [...offerCampaign, ...productCampaign];
  

  return (
    <div className="table-exclude-search">
     {type=="Sponsored"?
    <MUIDataTable
        className="mb-3"
        title={""}
        data={sponsoredRowData}
        columns={sponsoredColumns}
        options={options}
      />
      :<MUIDataTable
      className="mb-3"
      title={""}
      data={rowData}
      columns={columns}
      options={options}
    />}
      </div>
  )
}

export default CampaignDeatils
