
import { Form, InputGroup } from "react-bootstrap"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import React, { useEffect, useState } from "react";
import { useAuth, useUser, useStore } from "../context";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Navigation } from "@material-ui/icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import {createUser} from "../utils/API";
import API from "../utils/API";
import { useForm, useStep } from "react-hooks-helper";
import { useHistory } from "react-router-dom";
import { uuid } from '../utils/uuid'
import eventBus from '../utils/eventBus'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { tr } from "date-fns/locale";
import { toDate } from "date-fns";
import PaypalExpressBtn from "react-paypal-express-checkout";
import { PayPalButton } from "react-paypal-button-v2";
import { clientNew } from "../utils/paypalConfig";
import MUIDataTable from "mui-datatables"
import { maxCharge, minCharge, minItem } from "../utils/campaignConfig";
import Moment from 'react-moment';
import moment from 'moment';
import SelectSponsoredSection from "./SelectSponsoredSection";
import {Modal} from 'react-bootstrap';
import SelectSponsoredKeyword from "./SelectSponsoredKeyword";
import MuiAlert from "@material-ui/lab/Alert";
import Tooltip1 from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const defaultData = {
  promotionArea: "",
  cityList:[],
  stateList: [],
  promocode:"",
  fromDate:"",
  toDate:"",
  campaignName:"",
  campaignFor:"",
  budget:"",
  cpc:"",
  companyId:"",
  storeId:"",
  tab:"",
  category:"",
  section:"",
  sponsoredTypeSection:"section",
  sponsoredTypeKeyword:"",
  keywords:"",
  suggestedBid:"",
  bid:"",
  
};

const offerColumns = [
  {
    name: "offerImage",
    label: " ",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        //alert(value);
        return (
          <img height='100' width='100'  src={value}/>
        );
      }
    },
  },
  {
    name: "offerHeading",
    label: "Offer Heading",
    options: {
      filter: false,
      sort: false,
      
    },
  },
 
  
]

const productColumns = [
  {
    name: "productImage",
    label: " ",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        //alert(value);
        return (
          <img height='100' width='100'  src={value}/>
        );
      }
    },
  },
  {
    name: "ProductName",
    label: "Product Name",
    options: {
      filter: true,
      sort: true,
      
    },
  },
  
]

function ShopifySponsored(props) {
  //const areEqual = (prevProps, nextProps) => true;
 // const StartPromotionBatch = React.memo(props => {
  const campagainType=new URLSearchParams(props.location.search).get("type");
  const campagainId=new URLSearchParams(props.location.search).get("id");
  console.log("campagainType>>>>>>>>>>>>>>>>>>>",campagainType);
  console.log("campagainId>>>>>>>>>>>>>>>>>>>",campagainId);
  const [form, setForm] = useForm(defaultData);
  const [pendingCompanyOffer, setPendingCompanyOffer] = useState([]);
  const [validated, setValidate] = useState(false);
  const { signUp, userInfo, loggedUserInfo } = useAuth();
  const { categoryData, storeData, getMainCategories, getStoreByCompany } = useStore();
  const { promotionArea, cityList, stateList,promocode,fromDate,toDate,campaignName,campaignFor,budget,cpc,companyId,storeId,tab,category,section,keywords,sponsoredTypeSection,sponsoredTypeKeyword,suggestedBid,bid } = form;
  const [selectedCity, setSelectedCity] = React.useState([])
  const [selectedState, setSelectedState] = React.useState([])
  const [stateCityCount, setstateCityCount] = React.useState(0)
  const [offerStateData, setOfferstateData] = React.useState()
  const [discountValue, setDiscountValue] = React.useState(0)
  const [isCustomPlan, setCustomPlan] = React.useState(false)
  const [discountType, setDiscountType] = React.useState(0)
  const [campaignPlan, setCampaignPlan] = React.useState([])
  const [selectedCampaignPlan, setSelectedCampaignPlan] = React.useState()
  const [cities,setCities]=useState([]);
  const { stateData, getState} =useStore();
  const animatedComponents = makeAnimated();
  const [offerData, setOfferData] = React.useState([])
  const [offerStoreData, setOfferStoreData] = React.useState()
  const [totalItem, setTotalItem] = React.useState(0)
  const [totalSelectedOffer, setTotalSelectedOffer] = React.useState([])
  const [totalSelectedProduct, setTotalSelectedProduct] = React.useState([])
  const [productList, setProductList] = useState([]);
  const [itemTaxAmount, setItemTaxAmount] = useState([]);
  const [gstTax, setGstTax] = useState(0);
  const [hstTax, setHstTax] = useState(0);
  const [qstTax, setQstTax] = useState(0);
  const [pstTax, setPstTax] = useState(0);
  const [companyList, setCompanyList] = useState([]);
  const [selectedStore, setSelectedStore] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [sponsoredTypeSection1, setSponsoredTypeSection1] = useState(true);
  const [sponsoredTypeKeyword1, setSponsoredTypeKeyword1] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [selectedSection, setSelectedSection] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState([]);
  //const {storeData,getStoreByCompany } =useStore();

    
  const renderTooltip = props => (
    <Tooltip1 {...props}>Budget puts a limit on how much you want to spend on a daily basis</Tooltip1>
  );

  const renderTooltip1 = props => (
    <Tooltip1 {...props}>Dates should be between  active campaign of selected offer/product</Tooltip1>
  );


  let selectedOffer=[];
  let selectedProduct=[];
  let stateTaxAmount=[];
  let gst =0;
	let hst =0;
	let qst = 0;
	let pst = 0;
  
  useEffect(() => {
    console.log("selected section>>>>>>>>",selectedSection);
    if (loggedUserInfo != undefined) {
      getState(loggedUserInfo.email);
      setSelectedSection([]);
      getMainCategories();
      getStoreByCompany(loggedUserInfo.companyId);
      API.getAllCompany(loggedUserInfo.email).then((companyResult) => {  
       
        if(companyResult.data!=undefined && companyResult.data.length>0)
        {
          setCompanyList(companyResult.data)
         
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            }); 
      

               

      //***********************get offer data */   
      API.getShopifyCampignOffer(userInfo?.email,loggedUserInfo.companyId,"Offer").then((result) => {  
       
        if(result.data!=undefined && result.data.length>0)
        {
          setPendingCompanyOffer(result.data)
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            });        

            API.getShopifyCampignProductOnly(userInfo?.email,loggedUserInfo.companyId).then((productResult) => {  
                     
              if(productResult.data!=undefined && productResult.data.length>0)
              {
                setProductList(productResult.data);
            
              }
                   })
                   .catch((error) => {
                     console.log(error);
                     alert(error);
                  });


              

    }
  }, [loggedUserInfo]);

  const rows = [];
  console.log("totalSelectedOffer5555555>>>>>>>>>>>",totalSelectedOffer)
  if(totalSelectedOffer!=undefined && totalSelectedOffer!=null && totalSelectedOffer.length>0)
  {
  
  console.log("pendingCompanyOffer555555555>>>>>>>>>>>",pendingCompanyOffer)
  pendingCompanyOffer.forEach((element, index) => {
    totalSelectedOffer.forEach((element1, index1) => {
      console.log("element1>>>>>>>>>>>",element1)
      if(element.id==element1.id)
      rows.push(index)
      });
  });
}
  const offerOptions = {
    download: false,
    print: false,
    filter:false,
    search:false,
    viewColumns:false,
    rowsPerPage: 5,
    rowsSelected: rows,
    selectableRows:"single",
    selectToolbarPlacement: "none",
   /* customToolbar: () => (
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddRoundedIcon />}
        component={NavLink}
        onClick={(e) =>handleSubmit(e)}
        to={"/home/company/addCompany"}
      >
       Start Promotion
      </Button>
    ),*/
    selectableRowsOnClick: false,
    onRowSelectionChange: (curRowSelected,allRowsSelected,rowsSelected) => {
       selectedOffer=[];
       
       setSelectedCampaignPlan("")
      //console.log("curRowSelected>>>>>>>>>>>",curRowSelected)
      console.log("allRowsSelected>>>>>>>>>>>",allRowsSelected)
      //console.log("rowsSelected>>>>>>>>>>>",rowsSelected)
      for(var i=0;i<allRowsSelected.length;i++)
      {
      const offerData = pendingCompanyOffer[allRowsSelected[i].index];
      selectedOffer.push(offerData);
      
      }
  
    
      
     setTotalSelectedOffer(selectedOffer)
     setTotalSelectedProduct([])
     console.log("totalSelectedOffer>>>>>>>>>>>",totalSelectedOffer)
    },
  }

  const productRows = [];
  console.log("totalSelectedProduct444444444>>>>>>>>>>>",totalSelectedProduct)
  if(totalSelectedProduct!=undefined  && totalSelectedProduct!=null && totalSelectedProduct.length>0)
  {
  console.log("clicked>>>>>>>>>>>",totalSelectedProduct)
  productList.forEach((element, index) => {
    totalSelectedProduct.forEach((element1, index1) => {
      console.log("element1>>>>>>>>>>>",element1)
      if(element.id==element1.id)
      productRows.push(index)
      });
  });
}

  const productOptions = {
    download: false,
    print: false,
    filter:false,
    search:false,
    viewColumns:false,
    rowsPerPage: 5,
    rowsSelected: productRows,
    
    selectToolbarPlacement: "none",
   /* customToolbar: () => (
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddRoundedIcon />}
        component={NavLink}
        onClick={(e) =>handleSubmit(e)}
        to={"/home/company/addCompany"}
      >
       Start Promotion
      </Button>
    ),*/
    selectableRowsOnClick: false,
    onRowSelectionChange: (curRowSelected,allRowsSelected,rowsSelected) => {
      selectedProduct=[];
       
     
       setSelectedCampaignPlan("")
       setTotalSelectedOffer([])
      //console.log("curRowSelected>>>>>>>>>>>",curRowSelected)
      console.log("allRowsSelected>>>>>>>>>>>",allRowsSelected)
      //console.log("rowsSelected>>>>>>>>>>>",rowsSelected)
      for(var i=0;i<allRowsSelected.length;i++)
      {
      const offerData = productList[allRowsSelected[i].index];
      selectedProduct.push(offerData);
      
      }
   
      
      setTotalSelectedProduct(selectedProduct)
     console.log("totalSelectedProduct>>>>>>>>>>>",totalSelectedProduct)
    },
  }

  const onlyCompanyOffer = pendingCompanyOffer.filter((item) => item.type == "Offer");
  const OfferData = onlyCompanyOffer.map((item) => ({

    offerHeading: item.heading,
    type: item.type,
    offerImage:item.fullImage!=undefined && item.fullImage!="" && item.fullImage!=null?item.fullImage:"https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg",
  }));

  const productData = productList.map((item) => ({
    ProductName: item.name,
    productImage:item.images[0]!=undefined && item.images[0]!="" && item.images[0]!=null?item.images[0]:"https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg",
  }));

  let cityOption=[];
  cities.map((item) => {
    let tempObj ={"label":item.name,"value":item.id}
    cityOption.push(tempObj)
   
  });

  let stateOption=[];
  stateData.map((item) => {
    let tempObj ={"label":item.name,"value":item.id}
    stateOption.push(tempObj)
   
  });

  const history = useHistory();


 


  

const getCompanyBranches = async (e) => {
  console.log("event>>>>>>>>>",e.target.value);
  if(e.target.value!=undefined && e.target.value!='')
  {
    form.companyId=e.target.value
    getStoreByCompany(e.target.value);
      }
      
};

const handleBranchChanage = async (e) => {
  console.log("event>>>>>>>>>",e.target.value);
  if(e.target.value!=undefined && e.target.value!='')
  {
    form.storeId=e.target.value
    setSelectedStore(e.target.value);
     //***********************get offer data */   
     API.getShopifyCampignOffer(userInfo?.email,form.companyId,"Offer").then((result) => {  
       
      if(result.data!=undefined && result.data.length>0)
      {
        setPendingCompanyOffer(result.data)
      }
      else
      {
        setPendingCompanyOffer([])
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });        

          API.getShopifyCampignProductOnly(userInfo?.email,form.companyId).then((productResult) => {  
                   
            if(productResult.data!=undefined && productResult.data.length>0)
            {
              setProductList(productResult.data);
          
            }
            else
            {
              setProductList([]);
            }
                 })
                 .catch((error) => {
                   console.log(error);
                   alert(error);
                });
    //getStoreByCompany(e.target.value);
      }
      
};






function sectionModal() {

  if(form.campaignFor==undefined|| form.campaignFor==null || form.campaignFor=="")
  {
toast.error("First select Sponsored Campaign for either product or offer")
  }
  else
  {
  setIsOpen(!isOpen);
  }

}
function handleSectionClose() {
  setIsOpen(false);
}

function keywordModal() {

  setIsOpen1(!isOpen1);

}
function handleKeywordClose() {
  setIsOpen1(false);
}

const selectSection = (section) => {
  console.log("selected section>>>>>>",section);
  setSelectedSection(section);
  //this.setState({language: langValue});
}



const saveSelectedSection=async(e)=>  {

  console.log("saveSelectedSection>>>>>>>>>>>>>>>>>>>>",form.campaignFor);
  let isProcess=true;
   if(form.storeId==undefined || form.storeId=='')
  {
    toast.error("Please select branch")
    isProcess=false;
  }
  else if(form.campaignFor==undefined || form.campaignFor=='')
  {
    toast.error("Please select section create for offer/product")
    isProcess=false;
  }


  else if(selectedSection==undefined || selectedSection==null )
  {
    toast.error("Please configure section")
    isProcess=false;
  }

  else if(selectedSection!=undefined && selectedSection.length==0 )
  {
    toast.error("Please add section")
    isProcess=false;
  }

  else if(form.campaignFor=='offer' && (totalSelectedOffer==undefined || totalSelectedOffer==null || totalSelectedOffer.length==0))
  {
    toast.error("Please select offers")
    isProcess=false;
  }

  else if(form.campaignFor=='product' && (totalSelectedProduct==undefined || totalSelectedProduct==null || totalSelectedProduct.length==0))
  {
    toast.error("Please select product")
    isProcess=false;
  }

  if(isProcess)
  {
  if(form.campaignFor=='product')
  {
    let shopifyProductIds=[]
    totalSelectedProduct.forEach((element1, index1) => {
      shopifyProductIds.push(element1.shopifyProductId)
      });

    console.log("selectedProduct>>>>>>>>>>>>>>>>>>>>",totalSelectedProduct);
  let sectionBody = {
    id: uuid(),
    section: selectedSection,
    products: shopifyProductIds,
    type: "product",
  };
  const res1 = await API.configureShopifySponsoredCampaign(sectionBody);
  setSelectedSection([]);
  setTotalSelectedProduct([]);
  toast.success("Selected Product added in section successfully ")
}
else
{
  let shopifyOfferIds=[]
    totalSelectedOffer.forEach((element1, index1) => {
      shopifyOfferIds.push(element1.shopifyCollectionId)
      });

  let sectionBody = {
    id: uuid(),
    section: selectedSection,
    products: shopifyOfferIds,
    type: "offer",
  };
  const res1 = await API.configureShopifySponsoredCampaign(sectionBody);
  toast.success("Selected Offer added in section successfully ")
  setSelectedSection([]);
  setTotalSelectedOffer([]);
}
  }

}


  return (
    <Form noValidate validated={validated} action="#">
      <h4 class="mb-3"><strong>Add Section for Shopify Store</strong></h4> 
      <div className="row mb-4">
      
        <div className="col-md-6 mt-2">
          <div className="bg-white p-4 shadow-sm rounded-lg">
          {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")) ? (
            <Form.Group>
        <Form.Control
          as="select"
          className=" mr-3"
          name="company"
          value={companyId}
          onChange={getCompanyBranches}
          required
        >
          <option>Select Company</option>
          {companyList.map((item) => (
            item.isShopify=="Y"?
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
            :""
          ))}
        </Form.Control>
        </Form.Group>
         ) : null}
         <Form.Group>
          
          <Form.Control as="select" name="storeId" value={selectedStore} 
          //onChange={setForm} 
          onChange={handleBranchChanage}
          required 
          className="mr-sm-2" custom>
          <option value="">Select Branch</option>
              {storeData.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.code}
                </option>
              ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid" >Please select branch</Form.Control.Feedback>
        </Form.Group>
         

          <p>Add Section for</p>
          <Form.Group>
            <Form.Check
              inline
              label="Offer"
              value="offer"
              name="campaignFor"
              checked={form.campaignFor=='offer'?true:false}
              type="radio"
              onChange={setForm}
              id="offer"
            />
            <Form.Check
              inline
              label="Product"
              value="product"
              name="campaignFor"
              checked={form.campaignFor=='product'?true:false}
              type="radio"
              onChange={setForm}
              id="product"
            />
            </Form.Group>

            {form.campaignFor == "offer"  && (
            <MUIDataTable
            title={"Select Offers"}
            data={OfferData}
            columns={offerColumns}
            options={offerOptions}
          />
         )}

         
      {form.campaignFor == "product" && (
                  <MUIDataTable
                  title={"Select Product"}
                  data={productData}
                  columns={productColumns}
                  options={productOptions}
                />
              )}
         
            <br/>
            
         

            {sponsoredTypeSection == "section"  && (
           selectedSection!=undefined&&selectedSection!=null&&selectedSection.length>0?
           <Button  variant="outlined"><a href="#" className="text-secondary" onClick={sectionModal} >Click here to update  Section</a></Button>
           :<Button  variant="outlined"><a href="#" className="text-secondary" onClick={sectionModal} >Click here to configure  Section</a></Button>
          
         )}
         
          

              
             
              
                 <div className="text-center">
                 <Button variant="contained" color="primary" onClick={(e) =>saveSelectedSection(e)} className="btn btn-primary mt-2">Save</Button>
                 </div>
                 {/* ):""}*/}
          </div>
        </div>

        </div>





     
      <Modal
        show={isOpen}
        onHide={handleSectionClose}
        centered
        size="xl"
      >
        <SelectSponsoredSection onSelectSection={selectSection} selectedSection={selectedSection}  campaignFor={form.campaignFor} setIsOpen={setIsOpen}  />;
      </Modal>
    

      <ToastContainer />
    </Form>
  )
}

export default ShopifySponsored

// const style = {
//   form: {
//     backgroundColor: "white",
//     padding: "25px",
//     boxShadow: "0 0 15px silver",
//   },
// }
