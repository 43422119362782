import React,{ useEffect, useState } from "react"
import clsx from "clsx"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import StoreIcon from "@material-ui/icons/Store"
import AccountTreeIcon from "@material-ui/icons/AccountTree"
import LocalOfferRoundedIcon from "@material-ui/icons/LocalOfferRounded"
import ExtensionRoundedIcon from "@material-ui/icons/ExtensionRounded"
import CategoryRoundedIcon from "@material-ui/icons/CategoryRounded"
import PeopleAltRoundedIcon from "@material-ui/icons/PeopleAltRounded"
import Tooltip from "@material-ui/core/Tooltip"
import { NavLink } from "react-router-dom"
import PaymentIcon from '@material-ui/icons/Payment';
import AirplanemodeActiveIcon from '@material-ui/icons/AirplanemodeActive';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ReportIcon from '@material-ui/icons/GraphicEq';
import SyncIcon from '@material-ui/icons/Sync';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import API from "../utils/API";
import { useUser, useAuth } from "../context"
import { useHistory } from "react-router-dom";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import eventBus from '../utils/eventBus'

const drawerWidth = 240

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
));



const useStylesAcord = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));




const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);



const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}))



const DrawerMenuItems = () => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const { userInfo,loggedUserInfo } = useAuth();
  const [companyCampaign, setCompanyCampaign] = useState([]);
  const history = useHistory();


let menuList = [
  {
    title: "Stores",
    tooltip: "Manage Stores",
    icon: StoreIcon,
    url: "/home/company",
    group:"storeManagemet",
  },
  {
    title: "Offers/Collection",
    tooltip: "Manage Offers",
    icon: LocalOfferRoundedIcon,
    url: "/home/offers",
    group:"storeManagemet",
  },
  {
    title: "Products",
    tooltip: "Manage Products",
    icon: ExtensionRoundedIcon,
    url: "/home/products",
    group:"storeManagemet",
  },
 
]

if((loggedUserInfo!=undefined && loggedUserInfo.isMaster=='Y') || (loggedUserInfo!=undefined && loggedUserInfo.isShopify=='Y'))
{
menuList.push(
  {
    title: "Product Variant",
    tooltip: "Manage Product Variant",
    icon: ExtensionRoundedIcon,
    url: "/home/productVariant",
    group:"storeManagemet",
  }
  )
}


menuList.push(

  {
    title: "Users",
    tooltip: "Manage Users",
    icon: PeopleAltRoundedIcon,
    url: "/home/users",
    group:"groupLast",
  },
{
  title: "Flyer Campaign",
  tooltip: "Manage Campaign",
  icon: AirplanemodeActiveIcon,
  url: "/home/campaign/campaignList",
},
{
  title: "Sponsored Campaign",
  tooltip: "Manage Campaign",
  icon: AirplanemodeActiveIcon,
  url: "/home/campaign/sponsoredCampaignList",
},

  )
   
if(loggedUserInfo!=undefined && loggedUserInfo.isMaster=='Y')
 {
 
  
  menuList.push(

    {
      title: "Shopify Sponsored",
      tooltip: "Manage Shopify Sponsored",
      icon: AirplanemodeActiveIcon,
      url: "/home/shopifySponsored",
    },
 
    {
      title: "Transactions",
      tooltip: "Manage Transactions",
      icon: PaymentIcon,
      url: "/home/transactions",
    },
 
    {
    title: "Invoice",
    tooltip: "Manage Invoice",
    icon: PaymentIcon,
    url: "/home/invoice",
  }
   
  )

 }

 if(loggedUserInfo!=undefined && loggedUserInfo.isMaster!='Y' && loggedUserInfo.isShopify!='Y' && loggedUserInfo.privilegeCode!=undefined&& loggedUserInfo.privilegeCode.includes("VIEW_INVOICE"))
 {
 
  
  menuList.push(
    {
      title: "Invoice",
      tooltip: "Manage Invoice",
      icon: PaymentIcon,
      url: "/home/invoice",
    }

  )

 }

 if((loggedUserInfo!=undefined && loggedUserInfo.isMaster=='Y') || (loggedUserInfo!=undefined && loggedUserInfo.isShopify=='Y'))
 {
 menuList.push(
  {
title: "Shopify Catalog",
tooltip: "Manage Shopify Sync Product",
icon: SyncIcon,
url: "/home/catalog",
})
 }


if(loggedUserInfo!=undefined && loggedUserInfo.isShopify!='Y')
 {

menuList.push(
  {
title: "Payment Method",
tooltip: "Manage Payment Method",
icon: PaymentIcon,
url: "/home/paymentMethod",
group:"groupLast",
})
 }
 
if(loggedUserInfo!=undefined && loggedUserInfo.isMaster=='Y')
 {
  menuList.push(
    {
  title: "Categories",
  tooltip: "Manage Categories",
  icon: CategoryRoundedIcon,
  url: "/home/categories",
})
 }

 if((loggedUserInfo!=undefined && loggedUserInfo.isMaster=='Y') || (loggedUserInfo!=undefined && loggedUserInfo!=null && loggedUserInfo.privilegeCode!=undefined && loggedUserInfo.privilegeCode.includes("VIEW_BILLS")) )
 {
 menuList.push(
  {
title: "Bills",
tooltip: "Manage Upload Bills",
icon: ReceiptIcon,
url: "/home/bills",
})
 }

 if((loggedUserInfo!=undefined && loggedUserInfo.isMaster=='Y') )
 {
 menuList.push(
  {
title: "Sponsored Section",
tooltip: "Manage Sponsored Section",
icon: ReceiptIcon,
url: "/home/section",
group:"groupLast",
})
 }

 if((loggedUserInfo!=undefined && loggedUserInfo.isMaster=='Y') || (loggedUserInfo!=undefined && loggedUserInfo.isShopify=='Y'))
 {
 menuList.push(
  {
title: "Conversion Report",
tooltip: "Conversion Report",
icon: ReportIcon,
url: "/home/report",
group:"report",
})
 }

 if((loggedUserInfo!=undefined && loggedUserInfo.isMaster=='Y') )
 {
  menuList.push(
    {
  title: "Consolidate Conversion",
  tooltip: "Consolidate Conversion Report",
  icon: ReportIcon,
  url: "/home/consolidateConversionReport",
  group:"report",
  })
 }


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCampaign = (e,id) => {
   
    history.push(`/home/campaign/manage?id=`+id);
    
  };

  useEffect(() => {

    /*eventBus.on("campaignCreated", (data) =>{
     console.log("data message>>>>>>>>>>>>", data.message);
     if(loggedUserInfo!=undefined && loggedUserInfo.companyId!=undefined && loggedUserInfo.companyId!=null && loggedUserInfo.companyId!='')
     {
     API.getCompanyCampaign(loggedUserInfo.companyId).then((result) => {  
      
       if(result.data!=undefined && result.data.length>0)
       {
         setCompanyCampaign(result.data);
       }
   
     })
     .catch((error) => {
       console.log(error);
       alert(error);
    });
   }
    }
    );

    if(loggedUserInfo!=undefined && loggedUserInfo.companyId!=undefined && loggedUserInfo.companyId!=null && loggedUserInfo.companyId!='')
    {
  API.getCompanyCampaign(loggedUserInfo.companyId).then((result) => {  
   
    if(result.data!=undefined && result.data.length>0)
    {
      setCompanyCampaign(result.data);
    }

  })
  .catch((error) => {
    console.log(error);
    alert(error);
 });
 
}*/

}, [loggedUserInfo]);

const classes = useStylesAcord();
const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div>
      
    <List>
      {menuList.map((item, index) => (
    item.title != "Campaign"?  (
      <div>
        <ListItem button key={index} component={NavLink} to={item.url}>
          <Tooltip title={item.tooltip} arrow placement="right-end">
            <ListItemIcon>{<item.icon />}</ListItemIcon>
          </Tooltip>
          <ListItemText primary={item.title} />
        </ListItem>
       { item.group=="groupLast"?<hr/>:""}
        </div>
        ):
        <ListItem button key={index} component={NavLink} to={item.url}>
          <Tooltip title={item.tooltip} arrow placement="right-end">
            <ListItemIcon>{<item.icon />}</ListItemIcon>
          </Tooltip>
          <ListItemText primary={item.title} />
        </ListItem>
      
       
      ),

      )}
      
    </List>
          </div>
            )
          }

function DrawerMenu(props) {
  const open = props.drawerState
  const classes = useStyles()
  const theme = useTheme()


  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={props.handleDrawer}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      <DrawerMenuItems />
    </Drawer>
  )
}

export default DrawerMenu
