import React,{useEffect,useState} from "react"
import { Form, Button, Alert, InputGroup } from "react-bootstrap"
import styled from "styled-components"
import { useAuth,useUser,useStore } from "../context";
const FormContainer = styled.form`
  width: 500px;
  // background-color: #fff;
  padding: 50px;
  // margin-top: 75px;
  box-shadow: 0 0 20px silver;
  border-radius: 10px;
`

function ChooseCategoryFormModel({ form, setForm, navigation }) {
  const { mainCategory } = form
  console.log("mainCategory data>>>>>>>>>>>>>>>>>>",mainCategory);
  const { signUp, userInfo,loggedUserInfo } = useAuth();
  const { categoryData,getMainCategories } =useStore();
  const [validated,setValidate]=useState(false);
  const [selectedCategory,setSelectedCategory]=useState("");
  useEffect(() => {
    console.log("useEffect>>>>>>>>>>>>>>>>>>");
      getMainCategories();
      if(mainCategory!=undefined && mainCategory!=null  && mainCategory.length>0)
      {
        setSelectedCategory(mainCategory[0].id);
      }
  }, [loggedUserInfo]);

  const handleSubmit = async (e) => {
    setValidate(true);

    if (form.mainCategory == '' || form.mainCategory == undefined) {
      e.preventDefault();
      e.stopPropagation();
  }
  else if(form.mainCategory!='')
  {
    navigation.next();
  }

  };

  const handleCategory = async (e) => {
    form.mainCategory=[];
    console.log("e>>>>>>>>>>>>>>>>>",e.target.value);
    setSelectedCategory(e.target.value);
    categoryData.map((item, index) => 
      {
      if(item.id==e.target.value)
      form.mainCategory.push(item)
        
      }
      )
      console.log("form.mainCategory>>>>>>>>>>>>>>>>",form.mainCategory);
  };
  return (
    <FormContainer className="mx-auto">
      <h3 className="text-center mb-3">Category</h3>
      <Form noValidate validated={validated} action="#">
      <Form.Group>
        <Form.Label>
          Choose your main selling category (you can show Ads in other
          categories as well)
        </Form.Label>
        <div className="row ml-0 mb-3 mt-3">
        {categoryData.map((item, index) => (
          <Form.Check
           className="col"
            label={item.name}
            name="mainCategory"
            type="radio"
            value={item.id}
           // checked={mainCategory!=undefined && mainCategory!=null && Array.isArray(mainCategory) && mainCategory.length>0?mainCategory[0].name==item.id?true:false:mainCategory==item.id?true:false}
           checked={selectedCategory==item.id?true:false}
            onChange={handleCategory}
            id={item.id}
            required
          />
       
         ))}
         </div>
         <Form.Control.Feedback type="invalid" >Please select category</Form.Control.Feedback>
      </Form.Group>
      <div className="w-100 d-flex justify-content-around">
        <Button
          className="mt-3 w-50 mr-1"
          onClick={() => navigation.previous()}
        >
          Back
        </Button>
        <Button className="mt-3 w-50 ml-1"  onClick={handleSubmit}>
          Next
        </Button>
      </div>
      </Form>
    </FormContainer>
  )
}

export default ChooseCategoryFormModel
