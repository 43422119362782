import React, { useEffect, useState } from "react"
import { useStore, useAuth } from "../context"
import { Stores } from "."
import MUIDataTable from "mui-datatables"
import Button from "@material-ui/core/Button"
import AddRoundedIcon from "@material-ui/icons/AddRounded"
import { NavLink } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom";
//import Modal from "react-modal";
import {Modal} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import API from "../utils/API";
import Moment from 'react-moment';
import {Form} from 'react-bootstrap';
import Tab from "@material-ui/core/Tab"
import TabContext from "@material-ui/lab/TabContext"
import TabList from "@material-ui/lab/TabList"
import { TabPanel } from "@material-ui/lab"
import LinearProgress from '@material-ui/core/LinearProgress';
import RatingView from "./RatingView"
import BillImageView from "./BillImageView"
const useStyles = makeStyles(() => ({
  tab: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    padding: "0 10px",
    boxShadow: "0 0 15px silver",
  },
  button: {
    "&:hover": {
      color: "#fff",
    },
  },
}))




function ManageSection() {

  const [newBills, setNewBills] = useState([]);
  const [activeSection, setActiveSection] = useState([]);
  const [inActiveSection, setInActiveSection] = useState([]);
  const { userInfo,loggedUserInfo } = useAuth();
  const classes = useStyles()
  const [companyList, setCompanyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedBill, setSelectedBill] = useState("");
  const { companyData, storeData, getCompanyByUser, getStoreByCompany } =
    useStore();
    const [ratingForm, setRatingForm] = useState(false);
    const [billView, setBillView] = useState(false);
    const [selectedBillImage, setSelectedBillImage] = useState("");

    const [value, setValue] = React.useState("1")
  
    

  useEffect(() => {
    if(loggedUserInfo!=undefined)
   {
    
      API.getSectionList("active").then((result) => {  
       
        if(result.data!=undefined && result.data.length>0)
        {
          setActiveSection(result.data)
        }
        else
        {
          setActiveSection([])
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            });
            API.getSectionList("inActive").then((result) => {  
       
              if(result.data!=undefined && result.data.length>0)
              {
                setInActiveSection(result.data)
              }
              else
              {
                setInActiveSection([])
              }
                   })
                   .catch((error) => {
                     console.log(error);
                     alert(error);
                  });

   
    }
  }, [loggedUserInfo]);


  const reloadData=()=>
  {

   
    API.getSectionList("active").then((result) => {  
       
      if(result.data!=undefined && result.data.length>0)
      {
        setActiveSection(result.data)
      }
      else
      {
        setActiveSection([])
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });
          API.getSectionList("inActive").then((result) => {  
     
            if(result.data!=undefined && result.data.length>0)
            {
              setInActiveSection(result.data)
            }
            else
            {
              setInActiveSection([])
            }
                 })
                 .catch((error) => {
                   console.log(error);
                   alert(error);
                });
  };

 

  const handleChangeStatus = async (e,action,id) => {

    if(action=="A")
    {
    if(window.confirm('Are you sure to active this section?'))
          { setLoading(true);
            API.changeSectionStatus(userInfo.email,id,"Y").then((result) => {  
              console.log("Approve response>>>>>>>>",result);
              if(result.data!=undefined)
              {
                setLoading(false);
               toast.success("Section has been activated.")
               reloadData();
              }
              else
              {
                setLoading(false);
                toast.error("something went wrong... try again")
              }
                   })
                   .catch((error) => {
                    setLoading(false);
                     console.log(error);
                     alert(error);
                  });
          }
        else
          {

          }
        }

        if(action=="D")
    {
    if(window.confirm('Are you sure to deactivate this section?'))
          { 
            setLoading(true);
            API.changeSectionStatus(userInfo.email,id,"N").then((result) => {  
              console.log("Reject response>>>>>>>>",result);
              if(result.data!=undefined)
              {
                setLoading(false);
               toast.success("Section has been deactivate.")
               reloadData();
              }
              else
              {
                setLoading(false);
                toast.error("something went wrong... try again")
              }
                   })
                   .catch((error) => {
                    setLoading(false);
                     console.log(error);
                     alert(error);
                  });
          }
        else
          {

          }
        }

  

  };

  const handleViewBill = async (e,ImageUrl) => {
    setSelectedBillImage(ImageUrl)
    setBillView(true);

  };

  const newColumns = [
    {
      name: "name",
      label: "Section Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "tab",
      label: "Page Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "category",
      label: "Category",
      options: {
        filter: false,
        sort: true,
      },
    },  
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
            <div>
            <Button
            variant="contained"
            color="primary"
           // startIcon={<AddRoundedIcon />}
           onClick={(e) =>handleChangeStatus(e,"D",value)}
          >
            Deactivate
          </Button>
           <Button
           variant="contained"
           className="ml-2"
           color="primary"
           component={NavLink}
           to={"/home/section/AddSection?id="+value}
          // startIcon={<AddRoundedIcon />}
          // component={NavLink}
           //to={"/home/categories/AddCategory?id="+value}
         >
           Update
         </Button>
         
         </div>
          );
        }
      },
    },
    
  ];

  const InactiveColumns = [
    {
      name: "name",
      label: "Section Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "tab",
      label: "Page Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "category",
      label: "Category",
      options: {
        filter: false,
        sort: true,
      },
    },  
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
            <div>
            <Button
            variant="contained"
            color="primary"
           // startIcon={<AddRoundedIcon />}
           onClick={(e) =>handleChangeStatus(e,"A",value)}
          >
            Activate
          </Button>
           <Button
           variant="contained"
           className="ml-2"
           color="primary"
          // onClick={(e) =>handleChangeStatus(e,"R",value)}
          // startIcon={<AddRoundedIcon />}
           component={NavLink}
           to={"/home/section/AddSection?id="+value}
         >
           Update
         </Button>
         
         </div>
          );
        }
      },
    },
    
  ];

  
  
  const options = {
    download: false,
    print: false,
    rowsPerPage: 10,
    selectableRowsOnClick: false,
    selectToolbarPlacement: "none",
    selectableRows:"none",
  }

  const newData = activeSection.map((item) => ({
    name: item.name,
    tab: item.tabName,
    category:item.categoryName,
    action:item.id
  }));

  const inActiveData = inActiveSection.map((item) => ({
    name: item.name,
    tab: item.tabName,
    category:item.categoryName,
    action:item.id
  }));

 

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (

    
    <TabContext value={value}>
      {loading  ? (
       <LinearProgress />
       ) : null}
      <div className={classes.tab}>
        <TabList
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="simple tabs example"
          centered
        >
          <Tab
            className={value === "1" && "font-weight-bold"}
            label={"Active Section"}
            value="1"
          />
          <Tab
            className={value === "2" && "font-weight-bold"}
            label={"InActive Section"}
            value="2"
          />
         
        </TabList>
        
        <div className="d-flex">
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<AddRoundedIcon />}
          //   size="small"
          component={NavLink}
          to={"/home/section/addSection"}
        >
          Add Section
        </Button>
        </div>
        
       
      </div>
      <TabPanel value="1">
        <MUIDataTable
          title={"Active Section"}
          data={newData}
          columns={newColumns}
          options={options}
        />
      </TabPanel>
      <TabPanel value="2">
        <MUIDataTable
          title={"InActive Section"}
          data={inActiveData}
          columns={InactiveColumns}
          options={options}
        />
      </TabPanel>
      
      <ToastContainer />
     
    </TabContext>
  )
}

export default ManageSection
