import React, { useState } from "react";
import { Form, Button, InputGroup, Alert } from "react-bootstrap";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { useAuth } from "../context";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Navigation } from "@material-ui/icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import {createUser} from "../utils/API";
import API from "../utils/API";
import { auth } from "../utils/FirebaseConfig"


const FormContainer = styled.form`
  width: 400px;
  background-color: #fff;
  padding: 50px;
  margin-top: 150px;
  box-shadow: 0 0 20px silver;
  border-radius: 10px;
`;
const RegisterLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px 25px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px silver;
  padding: 25px;
  :hover {
    text-decoration: none;
  }
`;

const SignUp = ({ navigation, form, setForm, otpResult }) => {
  
  const [mobile, setMobile] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [errors, setErrors] = useState({});
  const [validated,setValidate]=useState(false);
  const { resetPassword } = useAuth();

  const { password, email, firstName,mobileNo,captcha } = form;
  const { signUp, userInfo } = useAuth();
  const [errorMessage, setErrorMessage] = useState('')
  const handleSubmit = async (e) => {
    try {
      setValidate(true);
      setErrors({});
      setErrorMessage("");
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          
     // if (validateCaptcha(user_captcha)==true) {

      //}
      console.log("otp>>>>>>>>>>>>>>>>>>",form.otp);
    if (form.mobileNo == '' || form.mobileNo == undefined) {
      setErrors({"mobile":"Please enter mobile number."});
        e.preventDefault();
        e.stopPropagation();
    }
    else if (form.mobileNo != '' && form.mobileNo != undefined && form.mobileNo.length<10) {
      setErrors({"mobile":"Please enter valid mobile number."});
      e.preventDefault();
      e.stopPropagation();
  }
     else if (form.firstName.trim() == '' || form.firstName == undefined) {
      setErrors({"firstName":"Please enter name."});
      e.preventDefault();
      e.stopPropagation();
     // e.stopNavigation();
     // return;
    }
    else if (form.email.trim() == '' || form.email == undefined) {
      setErrors({"email":"Please enter email address."});
      e.preventDefault();
      e.stopPropagation();
     // e.stopNavigation();
     // return;
    }
    else if (!pattern.test(form.email)) {
      
          setErrors({"email":"Please enter valid email address."});
          e.preventDefault();
          e.stopPropagation();
       
     // e.stopNavigation();
     // return;
    }
    else if (form.password.trim() == '' || form.password == undefined) {
      setErrors({"password":"Please enter password."});
      e.preventDefault();
      e.stopPropagation();
     // e.stopNavigation();
     // return;
    }
    else if(form.password != '' && form.email != '' && form.firstName != ''  )
    {
      API.getUserById(form.email).then(userData => {
        console.log("user>>>>>>>>>>>>>>>",userData.data);
       if(userData.data!=undefined && userData.data!=null && userData.data.authType=="A")
       {
         console.log("user alreday onboard>>>>>>>>>>>>>>>");
         setErrorMessage("Already onboard for this given emaild , if you are existing user please login.")
       }
       else
       {
   navigation.next();
       }
  }) 
    }
    
     /* else if (form.otp) {
      navigation.next();
        otpResult
           .confirm(form.otp)
           .then((result) => {
             console.log(result.user, "user");
             navigation.next();
            auth.createUserWithEmailAndPassword(email, password).then((signUpresult) => {
             const fbUserId = signUpresult.user.uid;
             let body = {
              email:form.email,
              emailOk:"Y",
              emailSubcription:"Y",
              name:form.firstName,
              mob:form.mobileNo,
              mobOk:"Y",
              authType:"F",
              password:""
             };
             API.createUser(form.email,body)
             navigation.next();
            })
            .catch((error) => {
              console.log(error);
              //alert(error);
              toast.error(error);
            });;
           })
           .catch((error) => {
             console.log(error);
             //alert(error);
             toast.error(error.message);
           });
       } */
    } catch (e) {
      console.log(e);
    }
   
  };

  const history = useHistory();
  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
        this.validateSignUp(e);
    }
}

  return (
    <>
      {/* <RegisterLink to="register">New User ? Register here .</RegisterLink> */}
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <FormContainer className="mx-auto">
          <i
            style={style.backButton}
            className="fa fa-arrow-circle-left"
            aria-hidden="true"
            title="Back to Login"
            onClick={() => history.push(`/Login`)}
          ></i>
          <Form noValidate validated={validated} action="#">
          <span className="mb-2" style={{
          //fontWeight: 'bold',
          color: 'red',
        }}>{errorMessage}</span>
          <Form.Group controlId="validationmobileNo" className="mb-4">
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
              name="mobileNo"
              onInput = {(e) =>{
                e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
              }}
              value={mobileNo}
              type="number"
              onChange={setForm}
              required
            />
             <div className="text-danger">{errors.mobile}</div>
          </Form.Group>
        { /* <Form.Group controlId="formBasicEmail" className="mb-4">
            <Form.Label>Enter OTP</Form.Label>
            <Form.Control
              type="number"
              name="otp"
              value={form.otp}
              onChange={setForm}
              required
            />
            <Form.Control.Feedback type="invalid" >Please enter valid otp</Form.Control.Feedback>
  </Form.Group>*/}
          <Form.Group controlId="validationfirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              name="firstName"
              type="text"
              value={firstName}
              onChange={setForm}
              required
            />
              <div className="text-danger">{errors.firstName}</div>
          </Form.Group>
          <Form.Group controlId="validationemail">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              name="email"
              type="email"
              value={email}
              onChange={setForm}
              required
            />
             <div className="text-danger">{errors.email}</div>
          </Form.Group>
          <Form.Group controlId="validationpassword">
            <Form.Label>Set Password</Form.Label>
            <Form.Control
              name="password"
              type="password"
              autoComplete="off"
              value={password}
              onChange={setForm}
              required
            />
              <div className="text-danger">{errors.password}</div>
          </Form.Group>
         
          <Button
          onClick={(e) =>handleSubmit(e)}
            variant="primary"
            type="button"
            className="w-100"
          >
            Submit
          </Button>
          </Form>
        </FormContainer>
      </motion.div>
      <ToastContainer />
    </>
  );
};

export default SignUp;

const style = {
  backButton: {
    fontSize: "25px",
    marginBottom: "10px",
    color: "blue",
    cursor: "pointer",
  },
};
