import React, { useEffect, useState } from "react"
import { useStore, useAuth } from "../context"
import { Stores } from "."
import MUIDataTable from "mui-datatables"
import Button from "@material-ui/core/Button"
import AddRoundedIcon from "@material-ui/icons/AddRounded"
import { NavLink } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom";
//import Modal from "react-modal";
import {Modal} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import API from "../utils/API";
import Moment from 'react-moment';
import {Form} from 'react-bootstrap';

const useStyles = makeStyles(() => ({
  tab: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    padding: "0 10px",
    boxShadow: "0 0 15px silver",
  },
  button: {
    "&:hover": {
      color: "#fff",
    },
  },
}))

const columns = [
  {
    name: "paymentId",
    label: "Transaction ID",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "date",
    label: "Date",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        //alert(value);
        return (
        <Moment format="YYYY/MM/DD">
        {value}
        </Moment>
        );
       } 
    },
  },
  {
    name: "amount",
    label: "Amount",
    options: {
      filter: false,
      sort: true,
    },
  },  
  {
    name: "paymentType",
    label: "Payment Method",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "status",
    label: "Payment Status",
    options: {
      filter: false,
      sort: true,
    },
  },
  
 /* {
    name: "action",
    label: "Action",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        //alert(value);
        return (
          <Button
          variant="contained"
          color="primary"
         // startIcon={<AddRoundedIcon />}
          component={NavLink}
          to={"/home/categories/AddCategory?id="+value}
        >
          Update
        </Button>
        );
      }
    },
  },*/
  
];


function ManageTransactions() {

  const [transactionList, setTransactionList] = useState([]);
  const { userInfo,loggedUserInfo } = useAuth();
  const classes = useStyles()
  const [companyList, setCompanyList] = useState([]);
  const { companyData, storeData, getCompanyByUser, getStoreByCompany } =
    useStore();
  const options = {
    rowsPerPage: 10,
    print: false,
    selectableRowsOnClick: false,
    selectToolbarPlacement: "none",
    selectableRows:"none",
   /* customToolbar: () => (
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddRoundedIcon />}
       // onClick={toggleModal}
        component={NavLink}
        to={"/home/categories/AddCategory"}
      >
        Add Category
      </Button>
    ),*/
    
  }

  useEffect(() => {
    if(loggedUserInfo!=undefined)
   {
    API.getAllCompany(loggedUserInfo.email).then((companyResult) => {  
       
      if(companyResult.data!=undefined && companyResult.data.length>0)
      {
        setCompanyList(companyResult.data)
       
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });

          if(localStorage.getItem('branchFilterTransactions')!=undefined && localStorage.getItem('branchFilterTransactions')!=null && localStorage.getItem('branchFilterTransactions')!='')
          {
            getStoreByCompany(localStorage.getItem('companyFilterTransactions'));
            API.getCompanyBranchTransactions(localStorage.getItem('branchFilterTransactions')).then((result) => {  
             
              if(result.data!=undefined && result.data.length>0)
              {
                setTransactionList(result.data)
              }
              else
              {
                setTransactionList([])
              }
                   })
                   .catch((error) => {
                     console.log(error);
                     alert(error);
                  });
          }
          else{
     API.getCompanyTransactions(loggedUserInfo.companyId).then((result) => {  
       
      if(result.data!=undefined && result.data.length>0)
      {
        setTransactionList(result.data)
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });
        }
    }
  }, [loggedUserInfo]);

  const rowData = transactionList.map((item) => ({
    paymentId: item.paymentId,
    date: item.createdOn,
    amount:item.amount,
    paymentType:item.paymentType,
    status:item.status
  }));


  const getCompanyBranches = async (e) => {
    console.log("event>>>>>>>>>",e.target.value);
    if(e.target.value!=undefined && e.target.value!='')
    {
      localStorage.setItem('companyFilterTransactions', e.target.value);
      getStoreByCompany(e.target.value);
        }
        
  };

  const handleBranchChanage = async (e) => {
    console.log("event>>>>>>>>>",e.target.value);
    if(e.target.value!=undefined && e.target.value!='')
    {
      localStorage.setItem('branchFilterTransactions', e.target.value);
      API.getCompanyBranchTransactions(e.target.value).then((result) => {  
       
        if(result.data!=undefined && result.data.length>0)
        {
          setTransactionList(result.data)
        }
        else
        {
          setTransactionList([])
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            });
    }
  };


  return (

    <div>
    <div className={classes.tab}>
    {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")) ? (
      <div className="d-flex ml-auto py-2">
      <Form.Control
        as="select"
        className=" mr-3"
        name="company"
        value={localStorage.getItem('companyFilterTransactions')}
        onChange={getCompanyBranches}
        required
      >
        <option>Select Company</option>
        {companyList.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Control>
      
      <Form.Control
        as="select"
        className="mr-sm-0"
        name="companyBranch"          
        value={localStorage.getItem('branchFilterTransactions')}
        onChange={handleBranchChanage}
        required
      >
        <option>Select Branch</option>
        {storeData.map((item, index) => (
          <option key={item.id} value={item.id}>
            {item.code}
          </option>
        ))}
      </Form.Control>
      </div>
       ) : null}
</div>
   
    <MUIDataTable
        className="mb-3"
        title="Payment Transactions"
        data={rowData}
        columns={columns}
        options={options}
      />
      </div>
  )
}

export default ManageTransactions
