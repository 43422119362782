import React, { useEffect, useState } from "react"
import { useAuth } from "../context"
import { Form, InputGroup } from "react-bootstrap"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import { NavLink } from "react-router-dom"
import API from "../utils/API"
import { useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables"
import Moment from 'react-moment';


function ViewCampaignAreaList(props) {
  
    const [mainCategoryList, setMainCategoryList] = useState([]);
    
    const { userInfo } = useAuth();
    const history = useHistory();
    useEffect(() => {
       
        
      }, []);

      
     
     

      const columns = [
        {
         
        name: "name",
        label: "Name",
        options: {
          filter: true,
          sort: true,
          
        },
      },   
        
      ]
      
      const options = {
        download: false,
        print: false,
        rowsPerPage: 10,
        selectableRowsOnClick: false,
        selectToolbarPlacement: "none",
        selectableRows:"none",
        selectableRowsOnClick: false,
        selectableRows:"none",
      }

      const activeProductData = props.areaList.map((item) => ({
        name: item.name,
        
      }));


  return (
    <div className="">
        <div className="">
     <MUIDataTable
          title={"Campaign Area List"}
          data={activeProductData}
          columns={columns}
          options={options}
        />
</div>
    </div>

      )
}

export default ViewCampaignAreaList


