import React,{ useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import MUIDataTable from "mui-datatables"
import Button from "@material-ui/core/Button"
import AddRoundedIcon from "@material-ui/icons/AddRounded"
import Tab from "@material-ui/core/Tab"
import Paper from "@material-ui/core/Paper"
import TabContext from "@material-ui/lab/TabContext"
import Tabs from "@material-ui/core/Tabs"
import { TabPanel } from "@material-ui/lab"
import { NavLink } from "react-router-dom"
import { useUser, useAuth } from "../context"
import AddUser from "./AddUser"
import {Modal,Form} from 'react-bootstrap';
import API from "../utils/API";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
})






const roleColumns = [
  {
    name: "roleName",
    label: "Role Name",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "privileges",
    label: "Privileges",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "actions",
    label: "Actions",
    options: {
      filter: false,
      sort: true,
    },
  },
]



function Users() {
  const classes = useStyles()
  const { roles, getRoles } = useUser();
  const { users, getUsersByCompany } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const { userInfo,loggedUserInfo } = useAuth();
  console.log("loggedUserInfo>>>>>>>>>>>",loggedUserInfo);
  const [value, setValue] = React.useState(0)
  const [companyList, setCompanyList] = useState([]);

  useEffect(() => {
    getRoles(userInfo.email);
    if(loggedUserInfo!=undefined)
    {
      API.getAllCompany(loggedUserInfo.email).then((companyResult) => {  
       
        if(companyResult.data!=undefined && companyResult.data.length>0)
        {
          setCompanyList(companyResult.data)
         
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            });
            if(localStorage.getItem('companyFilterUsers')!=undefined && localStorage.getItem('companyFilterUsers')!=null && localStorage.getItem('companyFilterUsers')!='')
          {
              
             getUsersByCompany(localStorage.getItem('companyFilterUsers'));
                }
                else
                {
            if(loggedUserInfo.companyId!=undefined && loggedUserInfo.companyId!=null && loggedUserInfo.companyId!='')      
            {
      getUsersByCompany(loggedUserInfo.companyId);
            }
          }
    }
   
  }, [loggedUserInfo]);

  const handleChange = (event, newValue) => {
    setValue(newValue)

  }

  const userColumns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
      
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
            loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.privilegeCode.includes("CAN_ADD_UPDATE")) ? (  
            <Button
            variant="contained"
            color="primary"
           // startIcon={<AddRoundedIcon />}
            component={NavLink}
            to={"/home/users/addUser?id="+value}
          >
            Update
          </Button>
           ) : null
          );
        }
      },
    },
  ]


  let userOptions = {};
  let roleOptions = {};
  if(loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")))
  {
    userOptions = {
      rowsPerPage: 10,
      print: false,
      selectableRowsOnClick: false,
      selectToolbarPlacement: "none",
      selectableRows:"none",
      customToolbar: () => (
        <React.Fragment>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddRoundedIcon />}
         // onClick={toggleModal}
          component={NavLink}
          to={"/home/users/addUser"}
        >
          Add User
        </Button>
         
          <Form.Control
            as="select"
            className="mr-3"
            name="company"
            value={localStorage.getItem('companyFilterUsers')}
            onChange={getCompanyUsers}
            required
          >
            <option>Select Company</option>
            {companyList.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Form.Control>
         </React.Fragment>
          
      ),
      
    }

    roleOptions = {
      rowsPerPage: 10,
      print: false,
      selectableRowsOnClick: false,
      selectToolbarPlacement: "none",
      selectableRows:"none",
      customToolbar: () => (
        <React.Fragment>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddRoundedIcon />}
          component={NavLink}
          to={"/home/users/addRole"}
        >
          Add Roles
        </Button>
         <Form.Control
         as="select"
         className="mr-3"
         name="company"
         value={localStorage.getItem('companyFilterRoles')}
         onChange={getCompanyRoles}
         required
       >
         <option>Select Company</option>
         {companyList.map((item) => (
           <option key={item.id} value={item.id}>
             {item.name}
           </option>
         ))}
       </Form.Control>
      </React.Fragment>
      ),
    }
  }

  else
  {
    userOptions = {
      rowsPerPage: 10,
      print: false,
      selectableRowsOnClick: false,
      selectToolbarPlacement: "none",
      selectableRows:"none",
      customToolbar: () => (
      loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.privilegeCode.includes("CAN_ADD_UPDATE")) ? (  
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddRoundedIcon />}
         // onClick={toggleModal}
          component={NavLink}
          to={"/home/users/addUser"}
        >
          Add User
        </Button>   
        ) : null
          
      ),
      
    }
   roleOptions = {
      rowsPerPage: 10,
      print: false,
      selectableRowsOnClick: false,
      selectToolbarPlacement: "none",
      selectableRows:"none",
      customToolbar: () => (
        loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.privilegeCode.includes("CAN_ADD_UPDATE")) ? (  
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddRoundedIcon />}
          component={NavLink}
          to={"/home/users/addRole"}
        >
          Add Roles
        </Button>
          ) : null
      ),
    }
  }
  

  const rowRoleData = roles.map((item) => ({
    roleName: item.roleName,
    privileges:item.privileges.map((item1) => (item1.privilegeName+", ")),
  }));

  const rowUserData = users.map((item) => ({
    name: item.name,
    email:item.email,
    action:item.email,
  }));

  function toggleModal() {
  
    setIsOpen(!isOpen);
    
  }
  function handleClose() {
    setIsOpen(false);
  }

  const getCompanyUsers = async (e) => {
    console.log("event>>>>>>>>>",e.target.value);
    if(e.target.value!=undefined && e.target.value!='')
    {
      localStorage.setItem('companyFilterUsers', e.target.value);
     getUsersByCompany(e.target.value);
        }
        
  };

  const getCompanyRoles = async (e) => {
    console.log("event>>>>>>>>>",e.target.value);
    if(e.target.value!=undefined && e.target.value!='')
    {
      localStorage.setItem('companyFilterRoles', e.target.value);
      getRoles(e.target.value);
        }
        
  };

  return (
    <TabContext value={value}>
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Users" />
          {loggedUserInfo!=null && loggedUserInfo!=undefined && loggedUserInfo.isMaster=='Y' ? (  
          <Tab label="Roles" />
          ) : null}
        </Tabs>
        
      </Paper>
      
      <TabPanel value={0} index={0}>
        <MUIDataTable
          title={"User Management"}
          data={rowUserData}
          columns={userColumns}
          options={userOptions}
        />
      </TabPanel>
      <TabPanel value={1} index={1}>
        <MUIDataTable
          title={"Role Management"}
          data={rowRoleData}
          columns={roleColumns}
          options={roleOptions}
        />
      </TabPanel>
     
    </TabContext>
    
  )
}

export default Users
