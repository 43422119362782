import React, { useState,useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button, Alert } from "react-bootstrap";
import { motion } from "framer-motion";
import { Rating } from "@material-ui/lab";
import API from "../utils/API";
import { useAuth } from "../context";


export default function BillImageView(props) {
  const { userInfo } = useAuth();
  const { handleClose, handleOpen, open,imageUrl} = props;
 

  useEffect(() => {


  },[props]);

 

  


  return (
    <Dialog
      open={open}
      keepMounted
      maxWidth={"lg"}
      onClose={() => handleClose("login")}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      
        <Form  className="billImageView">
          
                  <img height="100%" width="100%" src={imageUrl}/>
          
        </Form>
       
      </motion.div>
    
    </Dialog>
  );
}
