import React, { useEffect, useState } from "react";
import { useForm, useStep } from "react-hooks-helper";
import {
  StoreInfoForm,
  ChooseCategoryForm,
  PersonalInfoForm,
  AddressForm,
  MobileNo,
  SignUp,
} from "../domain";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {Modal} from 'react-bootstrap';
import StoreInfoFormModel from "../domain/StoreInfoFormModel";
import ChooseCategoryFormModel from "../domain/ChooseCategoryFormModel";
import AddressFormModel from "../domain/AddressFormModel";
import PersonalInfoFormModel from "../domain/PersonalInfoFormModel";
import API from "../utils/API";
import { useStore, useAuth } from "../context"
import { Form, Button, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
const defaultData = {
 
  storeId:"",
  storeDescription:"",
  returnPolicy:"",
  deliveryPolicy:"",
  warrantyPolicy:"",
  deliveryType:"",
  deliveryNotes:"",
};



const FormContainer = styled.form`
  width: 500px;
  background-color: #fff;
  padding: 50px;
  margin-top: 75px;
  box-shadow: 0 0 20px silver;
  border-radius: 10px;
  position: relative;
`;

function AddStorePolicy(parentProps) {
  const [form, setForm] = useForm(defaultData);
 console.log("parentProps>>>>>>>>>>",parentProps);
 const [storeData1, setStoreData] = useState();
 const [deliveryType, setDeliveryType] = useState("");
 const [secondDeliveryType, setSecondDeliveryType] = useState("");
 const {storeData, getStoreByCompany } =
 useStore();
 const storeId=parentProps.storeId
if(storeId==null || storeId==undefined) 
{
 // reset();
form.companyId=parentProps.companyId;
form.businessName=parentProps.businessName
form.companyLogo=parentProps.selectedCompanyLogo
//form.reset();

}
const {storeDescription, returnPolicy,deliveryPolicy,warrantyPolicy,deliveryNotes } = form;
const history = useHistory();
const { signUp, userInfo } = useAuth();
//parentProps.setIsOpen(false);
//setIsOpen(false);
  
  const setIsOpen=parentProps.setIsOpen;

  useEffect(() => {

    console.log("storeId>>>>>>>>>>",storeId);

    if(storeId!=undefined && storeId!=null)
    {
    API.getStoreById(storeId).then((result) => {  
       
      if(result.data!=undefined)
      {
        
        //console.log("parentProps222222222222222222222",parentProps);
       form.storeId=result.data.id
      form.storeDescription=result.data.storeDescription    
      form.warrantyPolicy=result.data.warrantyPolicy
      form.returnPolicy=result.data.returnPolicy
      form.deliveryPolicy=result.data.deliveryPolicy
      setDeliveryType(result.data.deliveryType);
      setSecondDeliveryType(result.data.secondDeliveryType)
      form.deliveryNotes=result.data.deliveryNotes
      
      setStoreData(result.data);
  
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });
        }
        else
        {
          form.storeId=""
          form.storeDescription=""    
          form.warrantyPolicy=""
          form.returnPolicy=""
          form.deliveryPolicy=""
          setDeliveryType("")
          setSecondDeliveryType("")
          form.deliveryNotes=""
        }
        
  },[storeId]);

  const onSubmit = async (e) => {

    let body = {
      id:storeId,
      storeDescription:form.storeDescription,
      warrantyPolicy:form.warrantyPolicy,
      returnPolicy:form.returnPolicy,
      deliveryPolicy:form.deliveryPolicy,
      deliveryType:deliveryType,
      secondDeliveryType:secondDeliveryType,
      deliveryNotes:form.deliveryNotes

    };
    API.updateStorePolicy(userInfo.email, body);
    parentProps.setIsOpen(false);
   //history.replace("/home");
   history.push(`/home`)
  };
  
  const changeDeliveryType =  (e) => {

    if(e.target.checked)
        {
      //  api call here
      
      setDeliveryType("Expedite")
        }
        else
        {
          setDeliveryType("")
        }
  }

  const changeDeliveryType1 =  (e) => {

    if(e.target.checked)
        {
      //  api call here
      
      setSecondDeliveryType("Standard")
        }
        else
        {
          setSecondDeliveryType("")
        }
  }


  return (
    <>
    <FormContainer className="mx-auto">
    <h3 className="text-center mb-3">Store Policy</h3>
    <Form noValidate  action="#">

    <Form.Label>Delivery Type</Form.Label>
      <Form.Group controlId="validationbusinessName">
        <Form.Check
          inline
          label="Expedite"
          name="deliveryType"
          type="checkbox"
          value="Expedite"
          checked={deliveryType=="Expedite"?true:false}
          onChange={changeDeliveryType}
          id="inline-radio-1"
          
          
        />
        <Form.Check
          inline
          label="Standard"
          name="deliveryType"
          type="checkbox"
          value="Standard"
          checked={secondDeliveryType=="Standard"?true:false}
          onChange={changeDeliveryType1}
          id="inline-radio-2"
          required
          
        />
       <Form.Control.Feedback type="invalid" >Please select shipping type</Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>Store-wide free shipping if any </Form.Label>
        <Form.Control
          required
          name="deliveryNotes"
          type="number"
          value={deliveryNotes}
          onChange={setForm}
          
        />
        <Form.Control.Feedback type="invalid" >Please enter Delivery Notes</Form.Control.Feedback>
      </Form.Group>

    <Form.Group controlId="validationbusinessName">
    <Form.Label>Store Description</Form.Label>
          <Form.Control
          name="storeDescription"
          value={storeDescription}
          onChange={setForm}
          as="textarea" rows={2}
          
           />
      
      <Form.Control.Feedback type="invalid" >Store Description</Form.Control.Feedback>
    </Form.Group >
    <Form.Group controlId="validationbusinessName">
    <Form.Label>Return Policy</Form.Label>
          <Form.Control
          name="returnPolicy"
          value={returnPolicy}
          onChange={setForm}
          as="textarea" rows={2}
          
           />
      
      <Form.Control.Feedback type="invalid" >Return Policy</Form.Control.Feedback>
    </Form.Group >

    <Form.Group controlId="validationbusinessName">
    <Form.Label>Delivery Policy</Form.Label>
          <Form.Control
          name="deliveryPolicy"
          value={deliveryPolicy}
          onChange={setForm}
          as="textarea" rows={2}
          
           />
      
      <Form.Control.Feedback type="invalid" >Delivery Policy</Form.Control.Feedback>
    </Form.Group >
    <Form.Group controlId="validationbusinessName">
    <Form.Label>Warranty Policy</Form.Label>
          <Form.Control
          name="warrantyPolicy"
          value={warrantyPolicy}
          onChange={setForm}
          as="textarea" rows={2}
          
           />
      
      <Form.Control.Feedback type="invalid" >Warranty Policy</Form.Control.Feedback>
    </Form.Group >
    
    <div className="w-100 d-flex justify-content-around">
     
      <Button className="mt-3 w-50 ml-1" onClick={onSubmit}>
        Save
      </Button>
    </div>
    </Form>
   
    </FormContainer>
   

    </> 
  );
}



export default AddStorePolicy;
