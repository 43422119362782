import React, { useState,useEffect } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import firebase from "firebase/app";
import { useStore, useAuth } from "../context";
import { useHistory } from "react-router-dom";
import { EmailOutlined } from "@material-ui/icons";
import { uuid } from '../utils/uuid'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import API from "../utils/API";
import { ToastContainer, toast } from 'react-toastify';

const FormContainer = styled.form`
  width: 500px;
  background-color: #fff;
  padding: 50px;
  margin-top: 25px;
  box-shadow: 0 0 20px silver;
  border-radius: 10px;
`;

const week = [
  {"label":"Sunday","value":"Sunday"},
  {"label":"Monday","value":"Monday"},
  {"label":"Tuesday","value":"Tuesday"},
  {"label":"Wednesday","value":"Wednesday"},
  {"label":"Thursday","value":"Thursday"},
  {"label":"Friday","value":"Friday"},
  {"label":"Saturday","value":"Saturday"},
];

function  PersonalInfoForm({ form, setForm, navigation, selectedLogoFile, setSelectedLogoFile }) {
  const bucketURL = 'https://shopiadsimages.blob.core.windows.net/storeimages/';
  const [otp, setotp] = React.useState();
  const [otpResult, setOtpResult] = React.useState(null);
  const [mobileNum, setMobileNum] = React.useState("");
  const [isOtpClicked, setOtpClicked] = React.useState(false);
  const { createStore } = useStore();
  const { signUp,resetPassword, userInfo } = useAuth();
  const { login } = useAuth()
  const [validated,setValidate]=useState(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T10:00:00'));
  const [selectedToDate, setToSelectedDate] = React.useState(new Date('2014-08-18T21:00:00'));
  form.fromHrs=selectedDate.getHours()+":"+selectedDate.getMinutes()+":"+selectedDate.getSeconds();
  form.toHrs=selectedToDate.getHours()+":"+selectedToDate.getMinutes()+":"+selectedToDate.getSeconds();
  const animatedComponents = makeAnimated();
  const [selectedWeekOff, setSelectedWeekOff] = React.useState([])
  const [errors, setErrors] = useState({});
  var userId;


  const history = useHistory();
 // const {logoFile}=selectedLogoFile;
  console.log("selectedLogoFile>>>>>>>>>>>>>",selectedLogoFile);

  useEffect(() => {
    loadCaptchaEnginge(6); 
  }, []);

  const {
    mobileNo,

    firstName,
    password,
    email,
    branchName,
    storeName,
    businessName,
    corporationId,
    websiteUrl,
    companyLogo,
    fileObject,
    mainCategory,

    countryBA,
    streetBA,
    cityBA,
    provinceBA,
    postalCodeBA,
    AppartmentName,
    isPhysicalStore,
    isPhysicalSameAsRegisteredAddress,
    secStreetBA,
    secCityBA,
    secProvinceBA,
    secPostalCodeBA,
    secAppartmentName,

    countryPA,
    streetPA,
    cityPA,
    provincePA,
    postalCodePA,

    ownerName,
    managerEmail,
    fromHrs,
    toHrs,
    timeNote,
    contactNumber,
    operatingHoursFrom,
    operatingHoursTo,
    weeklyOff=[],
    marketPlace,
    agreement,
    isExistUser,
    captcha,
  } = form;

  console.log("`${fileObject}`>>>>>>>>>>>>",form.fileObject);
  console.log(JSON.stringify(`${fileObject}`.toString()));
  console.log("logo name>>>>>>>>>>>>",`${companyLogo}`);
  const Register = async (e) => {

    setValidate(true);
    setErrors({});
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    console.log("form.managerEmail>>>>>>>>>>>",form.agreement);
    if (form.ownerName == '' || form.ownerName == undefined) {
      e.preventDefault();
      e.stopPropagation();
  }
  else if (form.managerEmail == '' || form.managerEmail == undefined) {
    setErrors({"email":"Please enter email address."});
    e.preventDefault();
    e.stopPropagation();
  }
  else if (!pattern.test(form.managerEmail)) {
      
    setErrors({"email":"Please enter valid email address."});
    e.preventDefault();
    e.stopPropagation();
 
// e.stopNavigation();
// return;
}
  else if (form.contactNumber == '' || form.contactNumber == undefined) {
    setErrors({"mobile":"Please enter contact number."});
    e.preventDefault();
    e.stopPropagation();
  }

  else if (form.contactNumber != '' && form.contactNumber != undefined && form.contactNumber.length<10) {
    setErrors({"mobile":"Please enter valid mobile number."});
    e.preventDefault();
    e.stopPropagation();
}


  else if (form.agreement == '' || form.agreement == undefined) {
    e.preventDefault();
    e.stopPropagation();
  } 

  else if (form.captcha == '' || form.captcha == undefined) {
    e.preventDefault();
    e.stopPropagation();
  }

 

    else if(form.agreement != '' && form.contactNumber != '' &&  form.contactNumber.length==10 && form.managerEmail != '' &&  pattern.test(form.managerEmail) && form.ownerName != '' && form.captcha!='' )
    {
      
     if (validateCaptcha(form.captcha)==true) {

      if(selectedLogoFile!=undefined)
      var imageExtension = selectedLogoFile.name.substring(selectedLogoFile.name.indexOf('.'),selectedLogoFile.name.length)

      var uploadImageName="";
      var uploadFolder="";
      if(businessName!=undefined)
      {
         uploadImageName=businessName.replace(/\s+/g, '')
        uploadFolder=businessName.replace(/\s+/g, '')
      }
        uploadImageName=uploadImageName+"_logo"

      console.log("form.imageExtension>111111111111>>>>>>>>>>",imageExtension);
      
      const storeId=uuid();

    let body = {
      id: storeId,
      code: branchName ? branchName : "Primary",
      gstin: "",
      storeName: storeName,

      companyBusinessName: businessName,
      corporationId:corporationId,
      websiteUrl: websiteUrl,
     // companyLogo: bucketURL+"images/"+businessName+"/"+storeId+"/"+businessName+imageExtension,
     companyLogo: selectedLogoFile!=undefined?API.fullBucketURL+uploadFolder+"/"+uploadImageName+imageExtension:"",
     categories: mainCategory,
      countryId: localStorage.getItem("connectSite")=="CA"?"canada":"US",
      address1: `${AppartmentName}`,
      address2: `${streetBA}`,
      stateId: `${provinceBA}`,
      cityId: `${cityBA}`,
      pincode: `${postalCodeBA}`,
      isphysical: `${isPhysicalStore}`,
      isSameAddress: `${isPhysicalSameAsRegisteredAddress}`,
      storeCountryId: localStorage.getItem("connectSite")=="CA"?"canada":"US",
      storeAddress1: `${secAppartmentName} `,
      storeAddress2: `${secStreetBA}`,
      storeStateId: `${secProvinceBA}`,
      storeCityId: `${secCityBA}`,
      storePincode: postalCodeBA,
      managerName: ownerName,
      managerMobile: contactNumber,
      managerEmail: managerEmail,
      fromHrs: [fromHrs] ,
      toHrs: [toHrs],
      marketPlace: marketPlace,
      timeNote:timeNote,
      weeklyOff: `${weeklyOff}`,
      featuredFrom: new Date().toISOString(),
      featuredTill: new Date().toISOString(),
      companyId: "",
      active: "Y",
      isPremium: "Y",
      isApprove:"N",
      areas: [],
      createdOn: new Date().toISOString(),
      updatedOn: new Date().toISOString(),
      createdBy: `${email}`,
      updatedBy: `${email}`,
      area: "",
    };

    try {
     
 //userId = val.user.uid;
    userId = `${email}`;
   API.createStore(`${email}`, body).then(storeData => {
   //  console.log("created store>>>>>>>>>>>>>>>",storeData)
 let userBody = {
        email:`${email}`,
        emailOk:"Y",
        emailSubcription:"Y",
        name:`${firstName}`,
        mob:`${mobileNo}`,
        mobOk:"Y",
        authType:"A",
        password:"",
        country:"",
        storeId:storeData.data.id,
        companyId:storeData.data.companyId,
        roleId:"76ba3d972069c439c9df95f8e570fd1c"//Store Admin role id

       };

       let userBodyForSecondServer = {
        email:`${email}`,
        emailOk:"Y",
        emailSubcription:"Y",
        name:`${firstName}`,
        mob:`${mobileNo}`,
        mobOk:"Y",
        authType:"AU",
        password:"",
        country:"",
        storeId:"",
        companyId:"",
        roleId:""//Store Admin role id

       };

       API.getUserById(`${email}`).then(userData => {
         console.log("user>>>>>>>>>>>>>>>",userData.data);
        if(userData.data==undefined || userData.data==null || userData.data=="")
        {
          console.log("new user>>>>>>>>>>>>>>>",userData.data);
       API.createUser(form.email,userBody);
       API.createUserUS(form.email,userBodyForSecondServer);
       const val =  signUp(`${email}`, `${password}`,`${firstName}`);
        }
       else
       {
        console.log("exist user>>>>>>>>>>>>>>>",userData.data);
       API.updateUser(form.email,userBody);
       API.updateUserUS(form.email,userBodyForSecondServer);
       form.isExistUser=true;
       resetPassword(`${email}`);

       }
       console.log("selectedLogoFile name>>>>>>>>>>>",selectedLogoFile);
       if(selectedLogoFile!=undefined)
       {
       uploadLogo(selectedLogoFile,uploadFolder,storeData.data.id);
       }
       
       navigation.next();
      }) 
      
      })
     // await login(`${email}`, `${password}`)
      //history.replace("/home")
      //    history.replace("/home");
    } catch (err) {
      console.log(err);
    }
  }
  else
{
  toast.error("Captcha does not match");
 // loadCaptchaEnginge(6); 
}
}

  };

  const uploadLogo = async (logoFile,companyName,storeId) => {
    const formData = new FormData()
    formData.append('file', logoFile)
    console.log("logoFile>>>>>>>>>>>",logoFile);
    if (logoFile !== null) {
      //store.dispatch('setOverlay', { showing: true })
      var imageName = logoFile.name.substring(0, logoFile.name.indexOf('.'))
      API.uploadCompanyLogo(formData, companyName,storeId)
        .then(response => {
          if (response.isAxiosError) {
           // store.dispatch('setOverlay', { showing: false })
            //store.dispatch('setSnackbar', { color: 'error', text: 'Something went wrong please try again' })
          } else {
            //store.dispatch('setOverlay', { showing: false })
            //store.dispatch('setSnackbar', { text: 'Company Logo Uploaded successfully' })
          }
        }).catch(response => {
         // store.dispatch('setOverlay', { showing: false })
          // error callback
        })
    }
    // API.setUploadLogo(formData)
  }

  // const onRegister = () => {
  //   if (otp) {
  //     otpResult
  //       .confirm(otp)
  //       .then((result) => {
  //         console.log(result.user, "user");
  //         alert("number verified Successfully");
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         alert(error);
  //       });
  //   } else {
  //     alert("Please enter Otp");
  //   }
  // };
  // const onGetOtp = (mobileNo) => {
  //   if (mobileNo) {
  //     setOtpClicked(true);
  //     let reCaptcha = new firebase.auth.RecaptchaVerifier("recaptcha");
  //     let number = "+1" + mobileNo;
  //     firebase
  //       .auth()
  //       .signInWithPhoneNumber(number, reCaptcha)
  //       .then((res) => {
  //         setOtpResult(res);
  //       });
  //   } else {
  //     alert("Mobile number is mandatory");
  //   }
  // };

  const handleDateChange = (date) => {
    //setSelectedDate(date);
    //form.fromHrs=date.toString();
    form.fromHrs=date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
    setSelectedDate(date);
  };
  const handleDateChange1 = (date) => {
   // setSelectedDate(date);
   // form.toHrs=date.toString();
   form.toHrs=date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
    setToSelectedDate(date);
  };

  
  const handleWeeakOffChange = (selectedOptions) => {
    console.log("selectedOptions>>>>>>>>>>>>>>>>",selectedOptions);
    form.weeklyOff=selectedOptions.map((item1) => (item1.value+", "))
    //form.weeklyOff=selectedOptions.value;
    setSelectedWeekOff(selectedOptions);
  };

  return (
    <FormContainer className="mx-auto">
      <h3 className="text-center mb-3">Personal Info</h3>
      <Form noValidate validated={validated} action="#">
      <Form.Group>
        <Form.Label>Owner / Manager name</Form.Label>
        <Form.Control
          name="ownerName"
          type="text"
          value={ownerName}
          onChange={setForm}
          required
        />
        <Form.Control.Feedback type="invalid" >Please enter owner/Manager</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Email Address</Form.Label>
        <Form.Control
          name="managerEmail"
          type="email"
          value={managerEmail}
          onChange={setForm}
          required
        />
         <div className="text-danger">{errors.email}</div>
      </Form.Group>
      <Form.Group>
        <Form.Label>Contact Number</Form.Label>
        <Form.Control
          name="contactNumber"
          type="number"
          onInput = {(e) =>{
            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
          }}
          value={contactNumber}
          onChange={setForm}
          required
        />
         <div className="text-danger">{errors.mobile}</div>
      </Form.Group>

      <Form.Label>Store operating hours</Form.Label>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Form.Row>
        <Row>
          <Col>
            <Form.Group>
            
        <KeyboardTimePicker
          margin="normal"
          id="fromHrsr"
          name="fromHrs"
          label="From Hrs"    
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
            <KeyboardTimePicker
          margin="normal"
          id="toHrs"
          name="toHrs"
          value={selectedToDate}
          label="To Hrs"
          onChange={handleDateChange1}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        />
            </Form.Group>
          </Col>
        </Row>
      </Form.Row>
      </MuiPickersUtilsProvider>

      <Form.Group controlId="exampleForm.ControlTextarea1">
        <Form.Label>Specify time other than regular time </Form.Label>
        <Form.Control
          as="textarea"
          rows={2}
          name="timeNote"
          value={timeNote}
          onChange={setForm}
        />
         <Form.Control.Feedback type="invalid" >Please enter other than regular time</Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>Weekly Off</Form.Label>
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          isMulti
          name="weeklyOff"
          value={selectedWeekOff}
          options={week}
          onChange={handleWeeakOffChange}
      />
       
      </Form.Group>
      <Form.Group controlId="formBasicCheckbox">
        <Form.Check
          type="checkbox"
          className="text-muted"
          name="agreement"
          value={agreement}
          onChange={setForm}
          label="I agree to comply with Advertiser Agreement of ShopiAds business solution aggreement"
          required
        />
        <Form.Text className="text-muted ml-4">
          <a href="https://shopiads.de/advertiserAgreement" target="_blank">View Advertiser Agreement</a>
        </Form.Text>
        <Form.Control.Feedback type="invalid" >I agree terms and conditions </Form.Control.Feedback>
      </Form.Group>

      <LoadCanvasTemplateNoReload />
      <Form.Group>
        <Form.Label>Enter Captcha</Form.Label>
        <Form.Control
          name="captcha"
          type="text"
          value={captcha}
          onChange={setForm}
          required
        />
        <Form.Control.Feedback type="invalid" >Please enter Captcha</Form.Control.Feedback>
      </Form.Group>

      

      <div className="w-100 d-flex justify-content-around">
        <Button
          className="mt-3 w-50 mr-1"
          onClick={() => navigation.previous()}
        >
          Back
        </Button>
        <Button onClick={Register} className="mt-3 w-50 ml-1">
          {"Submit"}
        </Button>
      </div>
      </Form>
      <ToastContainer/>
    </FormContainer>
  );
}

export default PersonalInfoForm;
