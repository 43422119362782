import React, { useEffect,useState } from "react";
import { Form, Button, InputGroup, Alert } from "react-bootstrap";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { useAuth,useUser } from "../context";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Navigation } from "@material-ui/icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import {createUser} from "../utils/API";
import API from "../utils/API";
import { auth } from "../utils/FirebaseConfig"
import { useForm, useStep } from "react-hooks-helper";


const defaultData = {
  mobileNo: "",
  firstName: "",
  password: "",
  email: "",  
  roleId:"",
  agent:"N",
  companyId:"",
};

const FormContainer = styled.form`
  width: 400px;
  background-color: #fff;
  padding: 50px;
  margin-top: 150px;
  box-shadow: 0 0 20px silver;
  border-radius: 10px;
`;
const RegisterLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px 25px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px silver;
  padding: 25px;
  :hover {
    text-decoration: none;
  }
`;

const AddUser = (props) => {
  let userId=new URLSearchParams(props.location.search).get("id");
  if(userId!=undefined && userId!=null)
  userId=userId.replace(" ", "+");

  console.log("updated user id>>>>>>>>>>>>>",userId);
  const [form, setForm] = useForm(defaultData);
  const [mobile, setMobile] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [btnDisable, setBtnDisable] = useState("");
  const [validated,setValidate]=useState(false);
  const [userData,setUserData]=useState(false);
  const { resetPassword } = useAuth();
  const { roles, getRoles } = useUser();
  const [isAgent, setIsAgent] = React.useState(false)
  //const { userInfo } = useAuth();
  const { password, email, firstName,mobileNo,roleId,agent,companyId } = form;
  const { signUp, userInfo,loggedUserInfo } = useAuth();
  const [companyList, setCompanyList] = useState([]);
  const [errors, setErrors] = useState({});
  console.log("loggedUserInfo>>>>>>>>>>>",loggedUserInfo);
  console.log("userInfo>>>>>>>>>>>",userInfo);
  useEffect(() => {
    getRoles(userInfo.email);
    API.getAllCompany(userInfo.email).then((companyResult) => {  
       
      if(companyResult.data!=undefined && companyResult.data.length>0)
      {
        setCompanyList(companyResult.data)
       
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          }); 

    if(userId!=undefined && userId!=null)
    {
      API.getUserById(userId).then((result) => {  
       
        console.log("user data>>>>>>>>>>>>>>",result)
        if(result.data!=undefined)
        {
          
          form.firstName=result.data.name
          form.mobileNo=result.data.mob
          form.roleId=result.data.roleId
          form.email=result.data.email
          form.password="dummy"
          form.agent=result.data.isAgent;
          form.companyId=result.data.companyId;
          if(result.data.isAgent=='Y')
          setIsAgent(true);
          else
          setIsAgent(false);
          setUserData(result.data)
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error);
     });

    }
    else
    {
          form.firstName=""
          form.mobileNo=""
          form.roleId=""
          form.email=""
          form.password=""
          form.agent="N";
    }

  }, [userId]);

  /*if(userId!=undefined && userId!=null && form.email=='')
  {
    form.firstName=userData.name
          form.mobileNo=userData.mob
          form.roleId=userData.roleId
          form.email=userData.email
          form.password="dummy"
  }*/

  const getCompanyBranches = async (e) => {
    console.log("event>>>>>>>>>",e.target.value);
    if(e.target.value!=undefined)
    {
      form.companyId=e.target.value
      setErrors({});
     // getStoreByCompany(e.target.value);
        }
        
  };

  const handleSubmit = async (e) => {
    try {
      setValidate(true);
      setErrors({});
      console.log("form.companyId>>>>>>>>>>",form.companyId);
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

      console.log("otp>>>>>>>>>>>>>>>>>>",form.otp);
   if (form.mobileNo == '' || form.mobileNo == undefined) {
      setErrors({"mobile":"Please enter mobile number."});
        e.preventDefault();
        e.stopPropagation();
    }
    else if (form.mobileNo != '' && form.mobileNo != undefined && form.mobileNo.length<10) {
      setErrors({"mobile":"Please enter valid mobile number."});
      e.preventDefault();
      e.stopPropagation();
  }
     if (form.firstName == '' || form.firstName == undefined) {
      e.preventDefault();
      e.stopPropagation();
     // e.stopNavigation();
     // return;
    }
    else if (form.email == '' || form.email == undefined) {
      e.preventDefault();
      e.stopPropagation();
     // e.stopNavigation();
     // return;
    }
    else if (!pattern.test(form.email)) {
      
      setErrors({"email":"Please enter valid email address."});
      e.preventDefault();
      e.stopPropagation();
   
 // e.stopNavigation();
 // return;
}

    else if (form.password == '' || form.password == undefined) {
      e.preventDefault();
      e.stopPropagation();
     // e.stopNavigation();
     // return;
    }
    else if (form.roleId == '' || form.roleId == undefined) {
      e.preventDefault();
      e.stopPropagation();
     // e.stopNavigation();
     // return;
    }
    else if (loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || 
    loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")) &&
    (form.companyId == '' || form.companyId == undefined))
    {
      toast.error("Please select company");
      e.preventDefault();
      e.stopPropagation();
    }

    else if(form.password != '' && form.email != '' && form.mobileNo != '' && form.mobileNo.length==10 && pattern.test(form.email) && form.firstName != '' && form.roleId != '' 
    || 
    (loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || 
    loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")) &&
    (form.companyId !='' ) )
    )
    {
      try {
        let userBody = {
          email:userId!=undefined && userId!=null?userId:form.email,
          emailOk:"Y",
          emailSubcription:"Y",
          name:form.firstName,
          mob:form.mobileNo,
          mobOk:"Y",
          authType:"A",
          password:form.password,
          country:"",
          roleId:form.roleId,
          createdBy:userInfo.email,
          updatedBy:userInfo.email,
          companyId:form.companyId!=undefined && form.companyId!=null && form.companyId!=''?form.companyId:loggedUserInfo.companyId,
          isAgent:form.agent
         };
         if(userId!=undefined && userId!=null)
         {
          API.updateFirebaseUser(userInfo.email,userBody);
         }
         else
         {
         API.createFirebaseUser(userInfo.email,userBody);
         }
         setTimeout(() => { 
            
          history.push(`/home/users`)
        
        }, 3000);
      
       // await login(`${email}`, `${password}`)
        //history.replace("/home")
        //    history.replace("/home");
      } catch (err) {
        console.log(err);
      }
    }
    
     /* else if (form.otp) {
      navigation.next();
        otpResult
           .confirm(form.otp)
           .then((result) => {
             console.log(result.user, "user");
             navigation.next();
            auth.createUserWithEmailAndPassword(email, password).then((signUpresult) => {
             const fbUserId = signUpresult.user.uid;
             let body = {
              email:form.email,
              emailOk:"Y",
              emailSubcription:"Y",
              name:form.firstName,
              mob:form.mobileNo,
              mobOk:"Y",
              authType:"F",
              password:""
             };
             API.createUser(form.email,body)
             navigation.next();
            })
            .catch((error) => {
              console.log(error);
              //alert(error);
              toast.error(error);
            });;
           })
           .catch((error) => {
             console.log(error);
             //alert(error);
             toast.error(error.message);
           });
       } */
    } catch (e) {
      console.log(e);
    }
   
  };

  const history = useHistory();
  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
        this.validateSignUp(e);
    }
}

const handleRoleChange = async (e) => {
  //console.log("event>>>>>>>>>",e.target.value);
  form.roleId=e.target.value;

};

    const handleAgent =  (e) => {
      var checked = e.target.checked;
      if(e.target.checked)
      {
    //  api call here
    setIsAgent(true);
    form.agent='Y'
      }
      else
      {
      setIsAgent(false);
      form.agent='N'

      }

};
  return (
    <>
          <Form noValidate validated={validated} action="#" style={style.form}>
          <InputGroup>
      <Form.Group className="col-1">
          <Button onClick={(e) =>history.goBack()} variant="primary" color="primary" type="button">
            Back
          </Button>
        </Form.Group>
      </InputGroup>
      <center className="mb-5">
        <Form.Label as="legend">User Form Details</Form.Label>
      </center>
      
      {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")) ? (
         <InputGroup className="mb-3">
            <Form.Group className="col-4">
        <Form.Control
          as="select"
          className=" mr-3"
          name="company"
          value={companyId}
          onChange={getCompanyBranches}
          required
        >
          <option value="">Select Company</option>
          {companyList.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Form.Control>
        </Form.Group>
       </InputGroup>
         ) : null}
         
      <InputGroup className="mb-2">
          <Form.Group controlId="validationmobileNo" className="col-3">
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
              name="mobileNo"
              onInput = {(e) =>{
                e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
              }}
              value={mobileNo}
              type="number"
              maxLength={"10"}
              onChange={setForm}
              required
            />
             <div className="text-danger">{errors.mobile}</div>
          </Form.Group>
        { /* <Form.Group controlId="formBasicEmail" className="mb-4">
            <Form.Label>Enter OTP</Form.Label>
            <Form.Control
              type="number"
              name="otp"
              value={form.otp}
              onChange={setForm}
              required
            />
            <Form.Control.Feedback type="invalid" >Please enter valid otp</Form.Control.Feedback>
  </Form.Group>*/}
          <Form.Group controlId="validationfirstName" className="col-3">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              name="firstName"
              type="text"
              value={firstName}
              onChange={setForm}
              required
            />
             <Form.Control.Feedback type="invalid" >Please enter name</Form.Control.Feedback>
          </Form.Group>
          </InputGroup>
          <InputGroup className="mb-2">
          <Form.Group controlId="validationemail" className="col-3">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
            readOnly={userId==null || userId==undefined?false:true}
              name="email"
              type="email"
              value={email}
              onChange={setForm}
              required
            />
             <div className="text-danger">{errors.email}</div>
          </Form.Group>
          {userId==null || userId==undefined || userId=='' ?  (
          <Form.Group controlId="validationpassword" className="col-3">
            <Form.Label>Set Password</Form.Label>
            <Form.Control
              name="password"
              type="password"
              autoComplete="off"
              value={password}
              onChange={setForm}
              required
            />
             <Form.Control.Feedback type="invalid" >Please enter password</Form.Control.Feedback>
          </Form.Group>
          ):""}
          </InputGroup>
          <InputGroup className="mb-2">
          {loggedUserInfo!=null && loggedUserInfo!=undefined && loggedUserInfo.isMaster=='Y' ?  (
          <Form.Group className="col-3 mt-2">
         
        <Form.Check
          type="checkbox"
          className="text-muted"
          name="isAgent"
          //defaultChecked={form.isPrimary=="Y"?true:false}
          checked={isAgent}
          onChange={handleAgent}
          label="Is Agent/Support"
          
        />
          </Form.Group>
          ):""}
          <Form.Group className="col-3">
            <Form.Label>User Role</Form.Label>
            <Form.Control
              as="select"
              className="mr-sm-2"
              id="inlineFormCustomSelect"
              name="roleId"
              custom
              value={roleId}
              onChange={setForm}
              required
            >
              <option></option>
              {roles.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.roleName}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid" >Please select role</Form.Control.Feedback>
          </Form.Group>
         
          </InputGroup>

          <InputGroup className="mb-3">
          <Form.Group className="col-2">
          <Button
          onClick={(e) =>handleSubmit(e)}
            variant="primary"
            type="button"
            className="w-100"
          >
            Submit
          </Button>
          </Form.Group>
      </InputGroup>
          </Form>
       
      <ToastContainer />
    </>
  );
};

export default AddUser;

const style = {
  backButton: {
    fontSize: "25px",
    marginBottom: "10px",
    color: "blue",
    cursor: "pointer",
  },
};
