
import { Form, InputGroup } from "react-bootstrap"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import React, { useEffect, useState } from "react";
import { useAuth, useUser, useStore } from "../context";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Navigation } from "@material-ui/icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import {createUser} from "../utils/API";
import API from "../utils/API";
import { useForm, useStep } from "react-hooks-helper";
import { useHistory } from "react-router-dom";
import { uuid } from '../utils/uuid'
import eventBus from '../utils/eventBus'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { tr } from "date-fns/locale";
import { toDate } from "date-fns";
import PaypalExpressBtn from "react-paypal-express-checkout";
import { PayPalButton } from "react-paypal-button-v2";
import { clientNew } from "../utils/paypalConfig";
import MUIDataTable from "mui-datatables"
import { maxCharge, minCharge, minItem } from "../utils/campaignConfig";
import Moment from 'react-moment';
import moment from 'moment';
import MuiAlert from "@material-ui/lab/Alert";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {PaymentElement} from '@stripe/react-stripe-js';
import CheckoutForm from "./CheckoutForm";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";


const useStylesTop = makeStyles((theme) => ({
  root: {
      '& > *': {
          margin: theme.spacing(1),
      },
  },
  extendedIcon: {
      marginRight: theme.spacing(1),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));




const defaultData = {
  promotionArea: "",
  cityList:[],
  stateList: [],
  promocode:"",
  fromDate:"",
  toDate:"",
  campaignName:"",
  campaignFor:"",
  budget:"",
  cpc:"",
  companyId:"",
  storeId:"",
  
};

const offerColumns = [
  {
    name: "offerImage",
    label: " ",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        //alert(value);
        return (
          <img height='100' width='100'  src={value}/>
        );
      }
    },
  },
  {
    name: "offerHeading",
    label: "Offer/Collection heading",
    options: {
      filter: false,
      sort: false,
      
    },
  },
  {
    name: "type",
    label: "Type",
    options: {
      filter: false,
      sort: false,
      
    },
  },
  
]

const productColumns = [
  {
    name: "productImage",
    label: " ",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        //alert(value);
        return (
          <img height='100' width='100'  src={value}/>
        );
      }
    },
  },
  {
    name: "ProductName",
    label: "Product Name",
    options: {
      filter: true,
      sort: true,
      
    },
  },
  
]

const stripePromise = loadStripe('pk_test_51KNKwXAAZoI1RaarGpFnvqCiaE6w2xz52BZa2emBXU5ohEsI2kcYM70nPJzKtu002nWGZUBOGtJkJxKG0tKmZGil00LIoP11RU');

function StartPromotionBatch(props) {

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  //const areEqual = (prevProps, nextProps) => true;
 // const StartPromotionBatch = React.memo(props => {
  const appearance = {
    theme: 'stripe'
  }

  
 // elements = stripe.elements({options, appearance});
 const [clientSecret, setClientSecret] = useState("");
 const [stripPaymentoptions, setStripPaymentoptions] = useState("");
 const [cardList, setCardList] = useState([]);
 const [cardId, setCardId] = useState("");
 const [paymentIntent, setPaymentIntent] = useState("");
 const [returnURL, setReturnURL] = useState("");
 const [isPayLoading, setIsPayLoading] = useState(false);
  const campagainType=new URLSearchParams(props.location.search).get("type");
  const campagainId=new URLSearchParams(props.location.search).get("id");
  console.log("campagainType>>>>>>>>>>>>>>>>>>>",campagainType);
  console.log("campagainId>>>>>>>>>>>>>>>>>>>",campagainId);
  const [form, setForm] = useForm(defaultData);
  const [pendingCompanyOffer, setPendingCompanyOffer] = useState([]);
  const [validated, setValidate] = useState(false);
  const { signUp, userInfo, loggedUserInfo } = useAuth();
  const { categoryData, storeData, getMainCategories, getStoreByCompany } = useStore();
  const { promotionArea, cityList, stateList,promocode,fromDate,toDate,campaignName,campaignFor,budget,cpc,companyId,storeId } = form;
  const [selectedCity, setSelectedCity] = React.useState([])
  const [selectedState, setSelectedState] = React.useState([])
  const [stateCityCount, setstateCityCount] = React.useState(0)
  const [offerStateData, setOfferstateData] = React.useState()
  const [discountValue, setDiscountValue] = React.useState(0)
  const [isCustomPlan, setCustomPlan] = React.useState(false)
  const [discountType, setDiscountType] = React.useState(0)
  const [campaignPlan, setCampaignPlan] = React.useState([])
  const [selectedCampaignPlan, setSelectedCampaignPlan] = React.useState()
  const [cities,setCities]=useState([]);
  const { stateData, getState} =useStore();
  const animatedComponents = makeAnimated();
  const [offerData, setOfferData] = React.useState([])
  const [offerStoreData, setOfferStoreData] = React.useState()
  const [totalItem, setTotalItem] = React.useState(0)
  const [totalSelectedOffer, setTotalSelectedOffer] = React.useState([])
  const [totalSelectedProduct, setTotalSelectedProduct] = React.useState([])
  const [productList, setProductList] = useState([]);
  const [itemTaxAmount, setItemTaxAmount] = useState([]);
  const [gstTax, setGstTax] = useState(0);
  const [hstTax, setHstTax] = useState(0);
  const [qstTax, setQstTax] = useState(0);
  const [pstTax, setPstTax] = useState(0);
  const [companyList, setCompanyList] = useState([]);
  const [selectedStore, setSelectedStore] = useState([]);
  const [selectedStoreData, setSelectedStoreData] = useState([]);
  //const {storeData,getStoreByCompany } =useStore();

  const renderTooltip = props => (
    <Tooltip {...props}>Card Number : 4242424242424242 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; CVC : Any 3 digits &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; DATE: Any future date</Tooltip>
  );

  let selectedOffer=[];
  let selectedProduct=[];
  let stateTaxAmount=[];
  let gst =0;
	let hst =0;
	let qst = 0;
	let pst = 0;
  useEffect(() => {
    if (loggedUserInfo != undefined) {
      setReturnURL(window.location.href);
      getState(loggedUserInfo.email);
      getStoreByCompany(loggedUserInfo.companyId);
      API.getAllCompany(loggedUserInfo.email).then((companyResult) => {  
       
        if(companyResult.data!=undefined && companyResult.data.length>0)
        {
          setCompanyList(companyResult.data)
         
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            }); 
      API.getAllCities(loggedUserInfo.email).then((result) => {  
       
        if(result.data!=undefined && result.data.length>0)
        {
          setCities(result.data);
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            });

            API.getCampaignPlan(loggedUserInfo.companyId,"offer").then((planResult) => {  
       
              if(planResult.data!=undefined && planResult.data.length>0)
              {
                setCampaignPlan(planResult.data);
              }
                   })
                   .catch((error) => {
                     console.log(error);
                     alert(error);
                  });

               

      //***********************get offer data */   
      API.getCompanyOffer(loggedUserInfo.companyId,"all").then((result) => {  
       
        if(result.data!=undefined && result.data.length>0)
        {
          setPendingCompanyOffer(result.data)
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            });        

            API.getCompanyProduct(loggedUserInfo.companyId,"all").then((productResult) => {  
                     
              if(productResult.data!=undefined && productResult.data.length>0)
              {
                setProductList(productResult.data);
            
              }
                   })
                   .catch((error) => {
                     console.log(error);
                     alert(error);
                  });


       if(campagainId!=undefined && campagainId!=null && campagainId!='')
       {
        API.getCampaignById(campagainId).then((camResult) => {  
                     
          if(camResult.data!=undefined )
          {
            
            API.getCompanyBranchOffer(camResult.data.storeId,"all").then((result) => {  
       
              if(result.data!=undefined && result.data.length>0)
              {
                setPendingCompanyOffer(result.data)
                if(camResult.data.campaignFor=='offer')
                {
                  var existingOffer=[];
                for(var i=0;i<camResult.data.offers.length;i++)
                {
                  const obj = result.data.filter((item) => item.id == camResult.data.offers[i].id);
                  console.log("existing offer>>>>>>>>>>>>>>>>>>>",obj);
                  console.log("pendingCompanyOffer offer>>>>>>>>>>>>>>>>>>>",pendingCompanyOffer);
                  if(obj!=null && obj!=undefined && obj.length>0)
                  {
                  existingOffer.push(obj[0]);
                }
                }
                setTotalSelectedOffer(existingOffer);
              }
              }
              else
              {
                setPendingCompanyOffer([])
              }
                   })
                   .catch((error) => {
                     console.log(error);
                     alert(error);
                  });        
        
                  API.getCompanyBranchProduct(camResult.data.storeId,"all").then((productResult) => {  
                           
                    if(productResult.data!=undefined && productResult.data.length>0)
                    {
                      setProductList(productResult.data);
                      if(camResult.data.campaignFor=='product')
                      {
                        var existingProduct=[];
                        for(var i=0;i<camResult.data.products.length;i++)
                  {   
                        const obj = productResult.data.filter((item) => item.id == camResult.data.products[i].id);
                        if(obj!=null && obj!=undefined && obj.length>0)
                        {
                          existingProduct.push(obj[0]);
                      }
                      }
                      setTotalSelectedProduct(existingProduct);
                    }
                    }
                    else
                    {
                      setProductList([]);
                    }
                         })
                         .catch((error) => {
                           console.log(error);
                           alert(error);
                        });

            form.campaignName=camResult.data.campaignName
            form.campaignFor=camResult.data.campaignFor
            form.promotionArea=camResult.data.campaignArea
            form.cityList=camResult.data.cityList
            form.stateList=camResult.data.stateList
            form.budget=camResult.data.budget
            form.companyId=camResult.data.companyId
            form.storeId=camResult.data.storeId
            getStoreByCompany(camResult.data.companyId);
            setSelectedStore(camResult.data.storeId);
           // form.fromDate=camResult.data.validFrom
            //form.toDate=camResult.data.validTill
            
            
            if(camResult.data.campaignArea=='city')
            {
              API.getAllCities(loggedUserInfo.email).then((citiesResult) => {  
       
                if(citiesResult.data!=undefined && citiesResult.data.length>0)
                {
                  var existCity=[]
                  form.cityList = [];
                  citiesResult.data.map((item) => {
              if(camResult.data.cityList.includes(item.id))
              {
              var tempObj ={"label":item.name, "value":item.id}
              existCity.push(tempObj)
              form.cityList.push(item.id);
              }
              
            });
            setSelectedCity(existCity);
                }
                     })
                     .catch((error) => {
                       console.log(error);
                       alert(error);
                    });

              
      }
        if(camResult.data.campaignArea=='state')
            {
              var existState=[]
              form.stateList = [];
  
               var totalCity=0;
               console.log("stateData>>>>>>>>>>>>>>>>>>",stateData);
               API.getState(loggedUserInfo.email).then((stateData) => {  
       
                if(stateData.data!=undefined && stateData.data.length>0)
                {
                  stateData.data.map((item) => {
                    if(camResult.data.stateList.includes(item.id))
                    {
                    var tempObj ={"label":item.name, "value":item.id}
                    existState.push(tempObj)
                    form.stateList.push(item.id);
                    API.getCitiesByState("gust",item.id).then((result) => {  
                      
                      if(result.data!=undefined && result.data.length>0)
                      {
                        totalCity=totalCity+result.data.length
                        setstateCityCount(totalCity);
                      }
                          })
                          .catch((error) => {
                            console.log(error);
                            alert(error);
                          });
          
                          }
                    
                  });
                  
                  setSelectedState(existState)
                }
                     })
                     .catch((error) => {
                       console.log(error);
                       alert(error);
                    });
             
            }
          
            
           
            if(camResult.data.campaignFor=='product')
            {
            for(var i=0;i<camResult.data.products.length;i++)
      {   
          API.getStoreById(camResult.data.products[i].storeId).then((storeData) => {  
  
            if(storeData.data!=undefined)
            {
              //setOfferStoreData(storeData.data);
              setSelectedStoreData(storeData.data);
              API.getStateById(storeData.data.stateId).then((stateResult) => {  
  
                if(stateResult.data!=undefined)
                {
                  stateTaxAmount.push(stateResult.data.totalTax);
                  gst=gst+Number(stateResult.data.gst);
                  hst=hst+Number(stateResult.data.hst);
                  pst=pst+Number(stateResult.data.pst);
                  qst=qst=Number(stateResult.data.qst);
                  console.log("setGstTax>2222222>>>>>>>>>>",gst)
                  setItemTaxAmount(stateTaxAmount);
                  setGstTax(gst);
                  setHstTax(hst);
                  setPstTax(pst);
                  setQstTax(qst);
                }
                     })
                     .catch((error) => {
                       console.log(error);
                       alert(error);
                    });
            }
          })
          .catch((error) => {
            console.log(error);
            alert(error);
         });
        
       
    }
   
  }
            if(camResult.data.campaignFor=='offer')
            {
            
            for(var i=0;i<camResult.data.offers.length;i++)
          {   
          API.getStoreById(camResult.data.offers[i].storeId).then((storeData) => {  

            if(storeData.data!=undefined)
            {
              //setOfferStoreData(storeData.data);
              setSelectedStoreData(storeData.data);
              API.getStateById(storeData.data.stateId).then((stateResult) => {  

                if(stateResult.data!=undefined)
                {
                  stateTaxAmount.push(stateResult.data.totalTax);
                  gst=gst+Number(stateResult.data.gst);
                  hst=hst+Number(stateResult.data.hst);
                  pst=pst+Number(stateResult.data.pst);
                  qst=qst=Number(stateResult.data.qst);
                  console.log("setGstTax>2222222>>>>>>>>>>",gst)
                  setItemTaxAmount(stateTaxAmount);
                  setGstTax(gst);
                  setHstTax(hst);
                  setPstTax(pst);
                  setQstTax(qst);
                }
                    })
                    .catch((error) => {
                      console.log(error);
                      alert(error);
                    });
            }
          })
          .catch((error) => {
            console.log(error);
            alert(error);
          });

          
          }
       
          }
            
          }
               })
               .catch((error) => {
                 console.log(error);
                 alert(error);
              });

       } 
       else
       {
         form.companyId="";
         form.storeId="";
         form.promocode="OFF98"//default promocode for now
         setSelectedStore("");
       }          

    }
  }, [loggedUserInfo]);

  const rows = [];
  console.log("totalSelectedOffer5555555>>>>>>>>>>>",totalSelectedOffer)
  if(totalSelectedOffer!=undefined && totalSelectedOffer!=null && totalSelectedOffer.length>0)
  {
  
  console.log("pendingCompanyOffer555555555>>>>>>>>>>>",pendingCompanyOffer)
  pendingCompanyOffer.forEach((element, index) => {
    totalSelectedOffer.forEach((element1, index1) => {
      console.log("element1>>>>>>>>>>>",element1)
      if(element.id==element1.id)
      rows.push(index)
      });
  });
}
  const offerOptions = {
    download: false,
    print: false,
    filter:false,
    search:false,
    viewColumns:false,
    rowsPerPage: 5,
    rowsSelected: rows,
    selectToolbarPlacement: "none",
   /* customToolbar: () => (
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddRoundedIcon />}
        component={NavLink}
        onClick={(e) =>handleSubmit(e)}
        to={"/home/company/addCompany"}
      >
       Start Promotion
      </Button>
    ),*/
    selectableRowsOnClick: false,
    onRowSelectionChange: (curRowSelected,allRowsSelected,rowsSelected) => {
       selectedOffer=[];
       stateTaxAmount=[];
       gst = 0;
       hst = 0;
       qst = 0;
       pst = 0;
       setSelectedCampaignPlan("")
      //console.log("curRowSelected>>>>>>>>>>>",curRowSelected)
      console.log("allRowsSelected>>>>>>>>>>>",allRowsSelected)
      //console.log("rowsSelected>>>>>>>>>>>",rowsSelected)
      for(var i=0;i<allRowsSelected.length;i++)
      {
      const offerData = pendingCompanyOffer[allRowsSelected[i].index];
      selectedOffer.push(offerData);
      
      }
      for(var i=0;i<selectedOffer.length;i++)
      {   
          API.getStoreById(selectedOffer[i].storeId).then((storeData) => {  
  
            if(storeData.data!=undefined)
            {
              //setOfferStoreData(storeData.data);
              setSelectedStoreData(storeData.data);
              API.getStateById(storeData.data.stateId).then((stateResult) => {  
  
                if(stateResult.data!=undefined)
                {
                  stateTaxAmount.push(stateResult.data.totalTax);
                  gst=gst+Number(stateResult.data.gst);
                  hst=hst+Number(stateResult.data.hst);
                  pst=pst+Number(stateResult.data.pst);
                  qst=qst=Number(stateResult.data.qst);
                  console.log("setGstTax>2222222>>>>>>>>>>",gst)
                  setItemTaxAmount(stateTaxAmount);
                  setGstTax(gst);
                  setHstTax(hst);
                  setPstTax(pst);
                  setQstTax(qst);
                }
                     })
                     .catch((error) => {
                       console.log(error);
                       alert(error);
                    });
            }
          })
          .catch((error) => {
            console.log(error);
            alert(error);
         });
        
       
    }
    
      
     setTotalSelectedOffer(selectedOffer)
     setTotalSelectedProduct([])
     console.log("totalSelectedOffer>>>>>>>>>>>",totalSelectedOffer)
    },
  }

  const productRows = [];
  console.log("totalSelectedProduct444444444>>>>>>>>>>>",totalSelectedProduct)
  if(totalSelectedProduct!=undefined  && totalSelectedProduct!=null && totalSelectedProduct.length>0)
  {
  console.log("clicked>>>>>>>>>>>",totalSelectedProduct)
  productList.forEach((element, index) => {
    totalSelectedProduct.forEach((element1, index1) => {
      console.log("element1>>>>>>>>>>>",element1)
      if(element.id==element1.id)
      productRows.push(index)
      });
  });
}

  const productOptions = {
    download: false,
    print: false,
    filter:false,
    search:false,
    viewColumns:false,
    rowsPerPage: 5,
    rowsSelected: productRows,
    selectToolbarPlacement: "none",
   /* customToolbar: () => (
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddRoundedIcon />}
        component={NavLink}
        onClick={(e) =>handleSubmit(e)}
        to={"/home/company/addCompany"}
      >
       Start Promotion
      </Button>
    ),*/
    selectableRowsOnClick: false,
    onRowSelectionChange: (curRowSelected,allRowsSelected,rowsSelected) => {
      selectedProduct=[];
       stateTaxAmount=[];
        gst = 0;
         hst = 0;
         qst = 0;
         pst = 0;
     
       setSelectedCampaignPlan("")
       setTotalSelectedOffer([])
      //console.log("curRowSelected>>>>>>>>>>>",curRowSelected)
      console.log("allRowsSelected>>>>>>>>>>>",allRowsSelected)
      //console.log("rowsSelected>>>>>>>>>>>",rowsSelected)
      for(var i=0;i<allRowsSelected.length;i++)
      {
      const offerData = productList[allRowsSelected[i].index];
      selectedProduct.push(offerData);
      
      }
      for(var i=0;i<selectedProduct.length;i++)
      {   
          API.getStoreById(selectedProduct[i].storeId).then((storeData) => {  
  
            if(storeData.data!=undefined)
            {
              //setOfferStoreData(storeData.data);
              setSelectedStoreData(storeData.data);
              API.getStateById(storeData.data.stateId).then((stateResult) => {  
  
                if(stateResult.data!=undefined)
                {
                  stateTaxAmount.push(stateResult.data.totalTax);
                  gst=gst+stateResult.data.gst;
                  hst=hst+stateResult.data.hst;
                  pst=pst+stateResult.data.pst;
                  qst=qst=stateResult.data.qst;
                  setItemTaxAmount(stateTaxAmount);
                  setGstTax(gst);
                  setHstTax(hst);
                  setPstTax(pst);
                  setQstTax(qst);
                }
                     })
                     .catch((error) => {
                       console.log(error);
                       alert(error);
                    });
            }
          })
          .catch((error) => {
            console.log(error);
            alert(error);
         });
        
       
    }
      
      setTotalSelectedProduct(selectedProduct)
     console.log("totalSelectedProduct>>>>>>>>>>>",totalSelectedProduct)
    },
  }

  const OfferData = pendingCompanyOffer.map((item) => ({
    offerHeading: item.heading,
    type: item.type,
    offerImage:item.fullImage!=undefined && item.fullImage!="" && item.fullImage!=null?item.fullImage:"https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg",
  }));

  const productData = productList.map((item) => ({
    ProductName: item.name,
    productImage:item.images[0]!=undefined && item.images[0]!="" && item.images[0]!=null?item.images[0]:"https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg",
  }));

  let cityOption=[];
  cities.map((item) => {
    let tempObj ={"label":item.name,"value":item.id}
    cityOption.push(tempObj)
   
  });

  let stateOption=[];
  stateData.map((item) => {
    let tempObj ={"label":item.name,"value":item.id}
    stateOption.push(tempObj)
   
  });

  const history = useHistory();


  /*const handleSubmit = async (e) => {

    let offerBody=
    {
      id:"dasd",
      price:"20"
    };
      
    API.makeOfferPayment(userInfo.email,offerBody)
    if(selectedCampaignPlan==undefined || selectedCampaignPlan==null || selectedCampaignPlan=='')
    {
      toast.error("Please select campaign plan")
    }

  };*/

  const handleCustomPlan = async (e) => {
      
    setCustomPlan(true);
    setSelectedCampaignPlan("")

  };

  const createCustomPlan = async (e) => {

    setSelectedCampaignPlan("")
   // var date1 = new Date(form.fromDate);
    //var date2 = new Date(form.toDate);
    var date1 = moment(form.fromDate)
    var date2 = moment(form.toDate)
   var nowDate= moment().format("yyyy-MM-DD");
    /*var currentDate=new Date();
    var day = currentDate.getDate()
    var month = currentDate.getMonth() + 1
  var year = currentDate.getFullYear()
  var newCurrentDate=year+"-"+month+"-"+day;
  var today = new Date(newCurrentDate);
  console.log("currentDate date>>>>>>>>>>>",newCurrentDate)
  console.log("form.fromDate date>>>>>>>>>>>",form.fromDate)
    console.log("currentDate date>>>>>>>>>>>",today.getTime())
    console.log("date1 date>>>>>>>>>>>",date1.getTime())*/
    console.log("date1.isBefore(nowDate) date>>>>>>>>>>>",date1.isBefore(nowDate))
    console.log("date1.isSame(nowDate)>>>>>>>>>>>",date1.isSame(nowDate))
    console.log("nowDate date>>>>>>>>>>>",nowDate)
    console.log("from date>>>>>>>>>>>",date1)
    
      
    if(form.fromDate==undefined || form.fromDate==null || form.fromDate=='')
    {
      toast.error("Please enter from date")
    }
    else if(date1.isBefore(nowDate))
    {
      toast.error("Selected from date is invalid")
    }

    else if(form.toDate==undefined || form.toDate==null || form.toDate=='')
    {
      toast.error("Please enter to date")
    }

    else if(date2<=date1)
    {
      toast.error("from date can not greater than or equal to date")
    }

    else if(form.storeId==undefined || form.storeId=='')
    {
      toast.error("Please select branch")
    }
    else if(form.campaignName==undefined || form.campaignName=='')
    {
      toast.error("Please enter campaign name")
    }

    else if(form.campaignName==undefined || form.campaignName=='')
    {
      toast.error("Please enter campaign name")
    }

    else if(form.promotionArea==undefined || form.promotionArea=='')
    {
      toast.error("Please select campaign promotion area")
    }

    else if(form.campaignFor==undefined || form.campaignFor=='')
    {
      toast.error("Please select campaign create for offer/product")
    }

    else if(form.campaignFor=='offer' && (totalSelectedOffer==undefined || totalSelectedOffer==null || totalSelectedOffer.length==0))
    {
      toast.error("Please select offers")
    }

    else if(cityList.length==0 && stateList.length==0  && form.promotionArea != "country")
    {
      toast.error("Please select city/state area")
    }

    /*else if(form.budget==undefined || form.budget=='')
    {
      toast.error("Please enter budget")
    }

    else if(form.cpc==undefined || form.cpc=='')
    {
      toast.error("Please enter cpc")
    }*/

    else if(form.toDate!='' && form.fromDate!='' && (date1.isAfter(nowDate) || date1.isSame(nowDate)) && date2>date1 && form.campaignName!=''
     //&& form.budget!=''&& form.cpc!=''
     && form.promotionArea!='' && form.storeId!='' 
    && (cityList.length>0 || stateList.length>0  || form.promotionArea == "country")
    && form.campaignFor!='' && (totalSelectedOffer!=undefined && totalSelectedOffer!=null || totalSelectedOffer.length>0)
    
    )
    {
      console.log("to date>>>>>>>>>>>",form.toDate)
      console.log("from date>>>>>>>>>>>",form.fromDate)
      var totalItemCount=0;
      var from = new Date(form.toDate);
      var to = new Date(form.fromDate);
      const diffTime = Math.abs(to - from);
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
      console.log(diffTime + " milliseconds");
      console.log(diffDays + " days");
     // diffDays=diffDays+1;
      let temp={};
      if(form.campaignFor=="offer")
      {
        totalItemCount=totalSelectedOffer.length;
        var ids=[];
        console.log("totalItemCount>>>>>>>>",totalItemCount);
        totalSelectedOffer.forEach((element, index) => {
         ids.push(element.id);
  
      });
        API.getOfferProducts(ids.join(",")).then((product) => {  
          if(product.data!=undefined && product.data.length>0)
          {
            totalItemCount=totalItemCount+product.data.length;
           
          }
          setTotalItem(totalItemCount)
          if(totalItemCount<=minItem)
          {
            temp={"days":diffDays,price:diffDays*minCharge}
          }
          else
          {
            temp={"days":diffDays,price:diffDays*maxCharge}
          }
          setSelectedCampaignPlan(temp);

          })
          .catch((error) => {
            console.log(error);
            alert(error);
        });
       
      }

      if(form.campaignFor=="product")
      {
        totalItemCount=totalSelectedProduct.length;
        setTotalItem(totalItemCount)
      if(totalItemCount<=minItem)
      {
        temp={"days":diffDays,price:diffDays*minCharge}
      }
      else
      {
        temp={"days":diffDays,price:diffDays*maxCharge}
      }
      setSelectedCampaignPlan(temp);
       /* totalSelectedProduct.forEach((element, index) => {
          if(element.offerId!=undefined && element.offerId!=null)
          totalItemCount=totalItemCount+1;
  
      });*/
       
      }

    window.scrollTo(0, 0)
    if(campagainId==null || campagainId==undefined || campagainId=="" )
    {
    handleApplyPromocode(temp);
    }
    API.createPaymentIntent(userInfo.email,"0.5" ).then((intentResult) => {  
      console.log("intentResult>>>>>>>>>>>>",intentResult);
     if(intentResult.data!=undefined)
     {
       var clientSecret=intentResult.data.clientSecret;
       setStripPaymentoptions({
         clientSecret,
         appearance,
       });
       setClientSecret(intentResult.data.clientSecret)
       setCardList(intentResult.data.card)
       if(intentResult.data.card!=undefined&&intentResult.data.card!=null&&intentResult.data.card.length>0)
       setCardId(intentResult.data.card[0].cardId)
       
       setPaymentIntent(intentResult.data.paymentIntentId)
      
     }
          })
          .catch((error) => {
            console.log(error);
            alert(error);
         });
    


    }

  };

  const handleCityChange = (selectedOptions) => {
    form.cityList = [];
    console.log("selectedOptions>>>>>>>>>>>>>>>>", selectedOptions)
    setSelectedCity(selectedOptions)
    selectedOptions.map((item1) => {
    form.cityList.push(item1.value);
    });
    //form.weeklyOff=selectedOptions.value;
    // console.log("selectedOptions>>>>>>>>>>>>>>>>",form.mainCategory)

  };

  const handleStateChange = (selectedOptions) => {
    form.stateList = [];
    console.log("selectedOptions>>>>>>>>>>>>>>>>", selectedOptions)
    setSelectedState(selectedOptions)
    var totalCity=0;
    selectedOptions.map((item1) => {
    form.stateList.push(item1.value);
    API.getCitiesByState("gust",item1.value).then((result) => {  
       
      if(result.data!=undefined && result.data.length>0)
      {
        totalCity=totalCity+result.data.length
        setstateCityCount(totalCity);
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });
    });
    
  
    //form.weeklyOff=selectedOptions.value;
    // console.log("selectedOptions>>>>>>>>>>>>>>>>",form.mainCategory)

  };
  const  handlePlanChange= (selectedOptions) => {
    console.log("selectedPlan>>>>>>>>>>>>>>>>",selectedOptions.target.value)
    campaignPlan.map((item, index) => (
      selectedOptions.target.value==item.id?setSelectedCampaignPlan(item):""

      ))
     
      setCustomPlan(false);
      console.log("setSelectedCampaignPlan>>>>>>>>>>>>>>>>",selectedCampaignPlan)
  };
  

  const  handleApplyPromocode= (selectedOptions) => {

    if(form.promocode!=undefined && form.promocode!=null && form.promocode!='')
    {
    API.checkPromocode(form.promocode).then((result) => {  
       
      console.log("promocode response>>>>>>>>>>>>>>>>",result.data)
      if(result.data!=undefined)
      {
        if(result.data!='' && result.data!=null)
        {
          toast.success("promocode apply successfully");
          setDiscountValue(result.data.discount)
          setDiscountType(result.data.type)

        }
        else
        {
          toast.error("Invalid promocode");
        }
       
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });
        }
        else
        {
          toast.error("Please enter promocode");
        }
    
      console.log("handleApplyPromocode>>>>>>>>>>>>>>>>",selectedCampaignPlan)
  };

  const  handleRemovePromocode= (selectedOptions) => {
    setDiscountValue(0)
    toast.success("promocode remove successfully");

  };

  let env = 'sandbox'; // you can set here to 'production' for production
  let currency = 'CAD'; // or you can set this value from your props or state
  let total =0; // same as above, this is the total amount (based on currency) to be paid by using Paypal express checkout
  let totalPerItem=0
  let invoiceAmount=0;
  let invoiceTax=[];
  let discountAmount=0;
  let discountPerItem=0;
  let paymentTotal=0;
  let totalAferDiscount =0
  let totalTax=0;
  if(selectedCampaignPlan!=undefined && selectedCampaignPlan!='' && selectedCampaignPlan!=null)
  {
    if(form.promotionArea == "city")
    {
      console.log("total item>>>>>>>>>>",totalItem);
      if(totalItem>minItem)
      {
      total=(selectedCampaignPlan.price*cityList.length)*totalItem
      totalPerItem=(selectedCampaignPlan.price*cityList.length)
      }
      else
      {
        total=(selectedCampaignPlan.price*cityList.length)
      totalPerItem=(selectedCampaignPlan.price*cityList.length)/totalItem
      
      }
    }
    else if(form.promotionArea == "state")
    {
      console.log("total item>>>>>>>>>>",totalItem);
      if(totalItem>minItem)
      {
      total=(selectedCampaignPlan.price*stateCityCount)*totalItem
      totalPerItem=(selectedCampaignPlan.price*stateCityCount)
    }
    else
    {
      total=(selectedCampaignPlan.price*stateCityCount)
      totalPerItem=(selectedCampaignPlan.price*stateCityCount)/totalItem
    }
    }
    else if(form.promotionArea == "country")
    {
      if(totalItem>minItem)
      {
      total=(selectedCampaignPlan.price*cities.length)*totalItem
      totalPerItem=(selectedCampaignPlan.price*cities.length)
      }
      else
    {
      total=(selectedCampaignPlan.price*cities.length)
      totalPerItem=(selectedCampaignPlan.price*cities.length)/totalItem
    }

      invoiceAmount=total;
    }

    totalAferDiscount=total;//for futher apply discount
    paymentTotal=total; // for send to payment gateway with futher add tax
    if(discountValue!=undefined && discountValue!=null && discountValue!=0)
    {
      if(discountType=="F")
      {
        discountAmount=discountValue;
        discountPerItem=discountValue;
      }
      else
      {
        discountAmount=(total*discountValue)/100
        discountPerItem=(totalPerItem*discountValue)/100
      } 

      console.log("discountAmount>>>>>>>>>>>>>>>",discountAmount)
      console.log("total>>>>>>>>>>>>>>>",total)
      totalAferDiscount=totalAferDiscount-discountAmount;
      paymentTotal=paymentTotal-discountAmount;
      totalPerItem=totalPerItem-discountPerItem;

    } 
       

                
                if(form.campaignFor=='offer' && totalSelectedOffer!=undefined )
                {
                for(var i=0;i<totalSelectedOffer.length;i++)
                {    console.log("itemTaxAmount[i]>>>>>>>>>>>>>>>",itemTaxAmount[i])
                    if(itemTaxAmount[i]!=undefined)
                    {
                      var taxAmount=(totalAferDiscount*Number(itemTaxAmount[i]))/100;
                        invoiceTax[i]=taxAmount;
                        totalTax=totalTax+taxAmount;
                    }
                    else
                    invoiceTax[i]=0;
                        
              }
            }

            if(form.campaignFor=='product'&&  totalSelectedProduct!=undefined)
                {
                for(var i=0;i<totalSelectedProduct.length;i++)
                {   
                      var taxAmount=(totalAferDiscount*Number(itemTaxAmount[i]))/100;
                        invoiceTax[i]=taxAmount;
                        totalTax=totalTax+taxAmount;
                      
              }
            }


 // total=total+totalTax;
  paymentTotal=paymentTotal+totalTax;
  console.log("total  tax>>>>>>>>>>>>>>>",totalTax)


      if(total!=undefined && total!=null)
      {
        total=Math.round((total + Number.EPSILON) * 100) / 100
        totalPerItem=Math.round((totalPerItem + Number.EPSILON) * 100) / 100
        paymentTotal=Math.round((paymentTotal + Number.EPSILON) * 100) / 100
        totalAferDiscount=Math.round((totalAferDiscount + Number.EPSILON) * 100) / 100
        totalTax=Math.round((totalTax + Number.EPSILON) * 100) / 100
        console.log("total after round>>>>>>>>>>>>>>>",total)
      }
       if(paymentIntent!=undefined&&paymentIntent!=null&&paymentIntent!="" && paymentTotal>0.5)
       {
      API.updatePaymentIntent(userInfo.email, paymentTotal,paymentIntent).then((intentResult) => {  
        console.log("updateintentResult>>>>>>>>>>>>",intentResult);
       if(intentResult.data!=undefined)
       {
        
        
       }
            })
            .catch((error) => {
              console.log(error);
              alert(error);
           });

          }
  }
  
  // Document on Paypal's currency code: https://developer.paypal.com/docs/classic/api/currency_codes/

  const client = {
      sandbox:    'AdElUsjWuE6MJ9FqCG78ig4hYeUswF3avBEkOa3lXt6ayBxKl0Jp1nEepFePV053MFbQOEzVaJ2FneMm',
      production: 'YOUR-PRODUCTION-APP-ID',
  }



  const onSuccess = (payment) => {
    // Congratulation, it came here means everything's fine!
    onPaymentSuccessUpdateData(payment,"paypal")
        // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
}

const onCancel = (data) => {
    // User pressed "cancel" or close Paypal's popup!
    console.log('The payment was cancelled!', data);
    toast.error("Payment cancelled")
    for(var i=0;i<totalSelectedOffer.length;i++)
    {
    let ptBody = {
      id:uuid(),
      transactionType:"Offer Campaign",
      status:"Cancelled",
      offerId:totalSelectedOffer[i].id,
      userId:userInfo.email,
      companyId:totalSelectedOffer[i].companyId,
      storeId:totalSelectedOffer[i].storeId,
      paymentId:data.paymentID!=undefined && data.paymentID!='' && data.paymentID!=null?data.paymentID:data.orderID,
      payerId:data.payerID,
      paymentType:"PayPal",
      amount:paymentTotal,
     };
     API.createPaymentTransaction(userInfo.email,ptBody);
    }
    // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
}

const onError = (err) => {
    // The main Paypal's script cannot be loaded or somethings block the loading of that script!
    onPaymentErrorUpdateData(err,"paypal")
    
}


const getCompanyBranches = async (e) => {
  console.log("event>>>>>>>>>",e.target.value);
  if(e.target.value!=undefined && e.target.value!='')
  {
    form.companyId=e.target.value
    getStoreByCompany(e.target.value);
      }
      
};

const handleBranchChanage = async (e) => {
  console.log("event>>>>>>>>>",e.target.value);
  if(e.target.value!=undefined && e.target.value!='')
  {
    form.storeId=e.target.value
    setSelectedStore(e.target.value);
     //***********************get offer data */   
     API.getCompanyBranchOffer(e.target.value,"all").then((result) => {  
       
      if(result.data!=undefined && result.data.length>0)
      {
        setPendingCompanyOffer(result.data)
      }
      else
      {
        setPendingCompanyOffer([])
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });        

          API.getCompanyBranchProduct(e.target.value,"all").then((productResult) => {  
                   
            if(productResult.data!=undefined && productResult.data.length>0)
            {
              setProductList(productResult.data);
          
            }
            else
            {
              setProductList([]);
            }
                 })
                 .catch((error) => {
                   console.log(error);
                   alert(error);
                });
    //getStoreByCompany(e.target.value);
      }
      
};

const onPaymentSuccessUpdateData = (payment,type) => {
  // Congratulation, it came here means everything's fine!
      console.log("The payment was succeeded!", payment);
      
      var paymentType="";
      var paymentId="";
      var payerId="";
      var paymentStatus="";
      if(type=="paypal")
      {
      if(payment.intent!=undefined && payment.intent!=null && payment.intent=="CAPTURE")
      {
        paymentType="Card";
        paymentId=payment.id;
        payerId=payment.payer.payer_id;
        if(payment.status=="COMPLETED")
        {
          paymentStatus="Paid"
        }
        else
        {
          paymentStatus="Fail"
        }
      }
      else
      {
        paymentType="PayPal";
        paymentId=payment.paymentID;
        payerId=payment.payerID; 
        if(payment.paid)
        {
          paymentStatus="Paid"
        }
        else
        {
          paymentStatus="Fail"
        }
      }
    }
    if(type=="stripeExist")
    {
      paymentType="card";
      paymentId=payment["id"];
      payerId="";
      paymentStatus=payment["status"];
    }
    else
    {
      paymentType="card";
      paymentId=payment.id;
      payerId=payment.payment_method;
      paymentStatus=payment.status;

    }

      var validFrom=new Date();
      var toDate=new Date(validFrom);
      toDate.setDate(toDate.getDate()+ Number(selectedCampaignPlan.days));
      console.log("The payment validFrom", validFrom);
      console.log("The payment was toDate!", toDate);
      
      let campaignBody = {
        id:campagainId!=undefined && campagainId!=null && campagainId!=''?campagainId:uuid(),
        campaignName:form.campaignName,
        offers:totalSelectedOffer,
        products:totalSelectedProduct,
        userId:userInfo.email,
        companyId:form.companyId!=undefined && form.companyId!=null && form.companyId!=''?form.companyId:loggedUserInfo.companyId,
        storeId:form.storeId,
        campaignType:campagainType,
        campaignFor:form.campaignFor,
        campaignPrice:totalAferDiscount,
        campaignArea:form.promotionArea,
        cityList:cityList,
        stateList:stateList,
        budget:form.budget,
        validFrom:validFrom,
        validTill:toDate,

       };

      API.createCampaign(userInfo.email,campaignBody).then((campaognResult) => {  
        console.log("totalSelectedOffer>>>>>>>>>>>>>",totalSelectedOffer);
        if(form.campaignFor=="offer")
        {
          let ptBody = {
            id:uuid(),
            transactionType:"Offer Campaign",
            status:paymentStatus,
            offerId:totalSelectedOffer,
            userId:userInfo.email,
            companyId:totalSelectedOffer[0].companyId,
            storeId:form.storeId,
            paymentId:paymentId,
            payerId:payerId,
            paymentType:paymentType,
            campaignId:campaognResult.data.id,
            amount:paymentTotal,
           };
  
           let invoiceBody = {
            id:uuid(),
            invoiceType:"Offer Campaign",
            status:paymentStatus,
            offerId:totalSelectedOffer,
            userId:userInfo.email,
            companyId:totalSelectedOffer[0].companyId,
            storeId:form.storeId,
            paymentId:paymentId,
            payerId:payerId,
            paymentType:paymentType,
            paymentAmount:paymentTotal,
            amount:total,
            discountAmount:discountAmount,
            taxAmount:totalTax,
            campaignId:campaognResult.data.id,
            gst:gstTax,
            hst:hstTax,
            pst:pstTax,
            qst:qstTax,
           };
           API.createPaymentTransaction(userInfo.email,ptBody);
    
           API.createInvoice(userInfo.email,invoiceBody);
        for(var i=0;i<totalSelectedOffer.length;i++)
        {
            let offerBody = {
              id:totalSelectedOffer[i].id,
              heading:totalSelectedOffer[i].heading,
              subHeading:totalSelectedOffer[i].subHeading,
              description:totalSelectedOffer[i].description,
             validFrom:validFrom,
              validTill:toDate,
              fullImage:totalSelectedOffer[i].fullImage,
              active:selectedStoreData!=undefined&&selectedStoreData.isApprove=="Y"?"Y":"N",
              isPrimary:totalSelectedOffer[i].isPrimary,
              categories:totalSelectedOffer[i].categories,
              storeId:form.storeId,
              storeName:totalSelectedOffer[i].storeName,
              logo:totalSelectedOffer[i].logo,
              logoSmall:totalSelectedOffer[i].logoSmall,
              logoMedium:totalSelectedOffer[i].logoMedium,
              totalRating:totalSelectedOffer[i].totalRating,
              avgRating:totalSelectedOffer[i].avgRating,
              totalLikes:totalSelectedOffer[i].totalLikes,
              vendorUrl:totalSelectedOffer[i].vendorUrl,
              type:totalSelectedOffer[i].type,
              webStoreName:totalSelectedOffer[i].webStoreName,
              campaignArea:form.promotionArea,
              cityList:cityList,
              stateList:stateList,
              budget:form.budget,
              cpc:totalSelectedOffer[i].cpc,
              pageName:totalSelectedOffer[i].tab,
              categorySection:totalSelectedOffer[i].category,
              sectionId:totalSelectedOffer[i].section,
              sponsoredCampaignId:totalSelectedOffer[i].sponsoredCampaignId,
              sponsoredCampaignName:totalSelectedOffer[i].campaignName,
              sponsoredType:totalSelectedOffer[i].sponsoredType,
              keywords:totalSelectedOffer[i].keywords,
              suggestedBid:totalSelectedOffer[i].suggestedBid,
              bid:totalSelectedOffer[i].bid,
              isShopifyCollection:totalSelectedOffer[i].isShopifyCollection,
              shopifySyncId:totalSelectedOffer[i].shopifySyncId,
              shopifyCollectionId:totalSelectedOffer[i].shopifyCollectionId,
              campaignId:campaognResult.data.id,
              campaignName:form.campaignName,
              products:totalSelectedOffer[i].products,
              createdBy:totalSelectedOffer[i].createdBy,
              marketPlace: totalSelectedOffer[i].marketPlace,
              updatedBy:userInfo.email,
              companyId:totalSelectedOffer[i].companyId
             };
    

    
             API.activeOfferCampaign(userInfo.email,offerBody);
            
            }
          }

          if(form.campaignFor=="product")
          {
            let ptBody = {
              id:uuid(),
              transactionType:"Product Campaign",
              status:paymentStatus,
              productId:totalSelectedProduct,
              userId:userInfo.email,
              companyId:totalSelectedProduct[0].companyId,
              storeId:form.storeId,
              paymentId:paymentId,
              payerId:payerId,
              paymentType:paymentType,
              campaignId:campaognResult.data.id,
              amount:paymentTotal,
             };
    
             let invoiceBody = {
              id:uuid(),
              invoiceType:"Product Campaign",
              status:paymentStatus,
              productId:totalSelectedProduct,
              userId:userInfo.email,
              companyId:totalSelectedProduct[0].companyId,
              storeId:form.storeId,
              paymentId:paymentId,
              payerId:payerId,
              paymentType:paymentType,
              paymentAmount:paymentTotal,
              amount:total,
              discountAmount:discountAmount,
              taxAmount:totalTax,
              campaignId:campaognResult.data.id,
              gst:gstTax,
              hst:hstTax,
              pst:pstTax,
              qst:qstTax,
             };
             API.createPaymentTransaction(userInfo.email,ptBody);
      
             API.createInvoice(userInfo.email,invoiceBody);

          for(var i=0;i<totalSelectedProduct.length;i++)
          {
              let productBody = {
                id:totalSelectedProduct[i].id,
                name:totalSelectedProduct[i].name,
                heading:totalSelectedProduct[i].heading,
                brand:totalSelectedProduct[i].brand,
                description:totalSelectedProduct[i].description,
                vendorUrl:totalSelectedProduct[i].website,
                actualPrice:totalSelectedProduct[i].price,
                offerPrice:totalSelectedProduct[i].offerPrice,
                discountPerc:totalSelectedProduct[i].discount,
                validFrom:validFrom,
                validTill:toDate,
                offerId:totalSelectedProduct[i].offerId,
                fullImage:totalSelectedProduct[i].fullImage,
                images:totalSelectedProduct[i].images,
                logo:totalSelectedProduct[i].logo,
                logoSmall:totalSelectedProduct[i].logoSmall,
                logoMedium:totalSelectedProduct[i].logoMedium,
                active:selectedStoreData!=undefined&&selectedStoreData.isApprove=="Y"?"Y":"N",
                categories:totalSelectedProduct[i].categories,
                mainCategory:totalSelectedProduct[i].mainCategory,
                discountType:totalSelectedProduct[i].discountType,
                discountMessage:totalSelectedProduct[i].discountMessage,
                storeId:totalSelectedProduct[i].storeId,
                storeName:totalSelectedProduct[i].storeName,
                totalRating:totalSelectedProduct[i].totalRating,
                avgRating:totalSelectedProduct[i].avgRating,
                totalLikes:totalSelectedProduct[i].totalLikes,
                attribute:totalSelectedProduct[i].attribute,
                shippingType:totalSelectedProduct[i].shippingType,
                shippingInstructions:totalSelectedProduct[i].shippingInstructions,
                campaignArea:form.promotionArea,
                cityList:cityList,
                stateList:stateList,
                budget:form.budget,
                cpc:totalSelectedProduct[i].cpc,
                pageName:totalSelectedProduct[i].tab,
                categorySection:totalSelectedProduct[i].category,
                sectionId:totalSelectedProduct[i].section,
                sponsoredCampaignId:totalSelectedProduct[i].sponsoredCampaignId,
                sponsoredCampaignName:totalSelectedProduct[i].campaignName,
                sponsoredType:totalSelectedProduct[i].sponsoredType,
                keywords:totalSelectedProduct[i].keywords,
                suggestedBid:totalSelectedProduct[i].suggestedBid,
                webStoreName:totalSelectedProduct[i].webStoreName,
                bid:totalSelectedProduct[i].bid,
                campaignId:campaognResult.data.id,
                campaignName:form.campaignName,
                products:totalSelectedProduct[i].products,
                isShopifyProduct:totalSelectedProduct[i].isShopifyProduct,
                shopifySyncId:totalSelectedProduct[i].shopifySyncId,
                shopifyProductId:totalSelectedProduct[i].shopifyProductId,
                shopifyVarientId:totalSelectedProduct[i].shopifyVarientId,
                createdBy:totalSelectedProduct[i].createdBy,
                marketPlace: totalSelectedProduct[i].marketPlace,
                updatedBy:userInfo.email,
                companyId:totalSelectedProduct[i].companyId
               };
      
              
               API.activeProductCampaign(userInfo.email,productBody);
              }
            }
            toast.success("yah!! offer live successfully")
       setTimeout(() => { 
        history.push(`/home/campaign/manage?id=`+campaognResult.data.id)
        eventBus.dispatch("campaignCreated", { message: "campaign created" });
       // toast.success("yah!! offer live successfully")
      
      }, 3000);
      })
      .catch((error) => {
        console.log(error);
        alert(error);
     });
     
}

const onPaymentErrorUpdateData = (err,type) => {
  // The main Paypal's script cannot be loaded or somethings block the loading of that script!
  toast.error("Error,please try again")
  console.log("Error!", err);
  var paymentId=""
  var payerId=""
  var paymentType=""
  if(type=="paypal")
  {
    paymentId=err.paymentID;
    payerId=err.payerID;
    paymentType="PayPal";
  }
  if(type=="stripeExist")
    {
      paymentType="card";
      paymentId=err["id"];
      payerId="";
    }
  else
  {
    paymentId=err.id;
    payerId=err.payment_method;
    paymentType="card";
  }
  if(form.campaignFor=="offer")
  {
  let ptBody = {
    id:uuid(),
    status:"Error",
    transactionType:"Offer Campaign",
    offerId:totalSelectedOffer[i],
    userId:userInfo.email,
    companyId:totalSelectedOffer[0].companyId,
    storeId:form.storeId,
    paymentId:  paymentId,
    payerId:  payerId,
    paymentType:paymentType,
    amount:paymentTotal,
   };
   API.createPaymentTransaction(userInfo.email,ptBody);
  }
  if(form.campaignFor=="product")
  {
    let ptBody = {
      id:uuid(),
      transactionType:"Product Campaign",
      status:"Error",
      productId:totalSelectedProduct,
      userId:userInfo.email,
      companyId:totalSelectedProduct[0].companyId,
      storeId:form.storeId,
      paymentId:paymentId,
      paymentId:paymentId,
      payerId:payerId,
      paymentType:paymentType,
      amount:paymentTotal,
     };
     API.createPaymentTransaction(userInfo.email,ptBody);
  }
  
  
  // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
  // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
}

const payWithExistingCard = async (e) => {

  console.log("cardId>>>>>>>>>>>>>>",cardId);
  if(cardId==null || cardId==undefined || cardId=="")
  {
    toast.error("Please select store card for process payment")
  }
  else
  {
    setIsPayLoading(true);
  API.payWithExistingCard(userInfo.email,paymentTotal,cardId).then((paymentResult) => {  
                   
    if(paymentResult.data!=undefined )
    {
      if(paymentResult.data.response=="successful")
      {
        var data=paymentResult.data.paymentIntent;
        console.log("paymentResult.data.paymentIntent>>>>>>>>>>>>",data)
        
        var jsonParsedArray = JSON.parse(data);
        console.log("paymentId>>>>>>>>>>>>",jsonParsedArray["id"])
        console.log("payment_method>>>>>>>>>>>>",data.payment_method)
        console.log("paymentStatus>>>>>>>>>>>>",jsonParsedArray["status"])
        onPaymentSuccessUpdateData(jsonParsedArray,"stripeExist");
        setIsPayLoading(false);
      }
      else
      {
        var data=paymentResult.data.paymentIntent;
        var jsonParsedArray = JSON.parse(data);
        onPaymentErrorUpdateData(jsonParsedArray,"stripeExist")
        setIsPayLoading(false);
      }
  
    }
    else
    {
      setIsPayLoading(false);
    }
         })
         .catch((error) => {
           console.log(error);
           alert(error);
        });

      }
}

const paymentSucessByNewCard = (paymentIntent,isCardSave) => {
console.log("paymentIntent>>>>>>>>>>>>>>>>>>>>>>>>>>>>",paymentIntent);
console.log("isCardSave>>>>>>>>>>>>>>>>>>>>>>>>>>>>",isCardSave);
switch (paymentIntent.status) {
  case "succeeded":
    if(isCardSave=="on")
    {
    API.addStripCardToCustomer(userInfo.email,paymentIntent.id);
    }
    onPaymentSuccessUpdateData(paymentIntent,"stripe");
    break;
  default:
    onPaymentErrorUpdateData(paymentIntent,"stripe")
    break;
}

}


const checkSaveCard = (e) => {

}

  return (
    <Form noValidate validated={validated} action="#">
      <h4 class="mb-3"><strong>Start Offer/Product Campaign</strong></h4> 
      <div className="row mb-4">
      <MuiAlert className="ml-4" severity="info">Minimum charge is $1 per day/location which includes 5 offer/product, Additional product is 0.2 cent.</MuiAlert>
        <div className="col-md-6 mt-2">
          <div className="bg-white p-4 shadow-sm rounded-lg">
          {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")) ? (
            <Form.Group>
        <Form.Control
          as="select"
          className=" mr-3"
          name="company"
          value={companyId}
          onChange={getCompanyBranches}
          required
        >
          <option>Select Company</option>
          {companyList.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Form.Control>
        </Form.Group>
         ) : null}
         <Form.Group>
          
          <Form.Control as="select" name="storeId" value={selectedStore} 
          //onChange={setForm} 
          onChange={handleBranchChanage}
          required 
          className="mr-sm-2" custom>
          <option value="">Select Branch</option>
              {storeData.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.code}
                </option>
              ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid" >Please select branch</Form.Control.Feedback>
        </Form.Group>
          <Form.Group controlId="validationfirstName">
            <Form.Label>Enter Campaign Name</Form.Label>
            <Form.Control
              name="campaignName"
              type="text"
             value={campaignName}
              onChange={setForm}
              //onChange={e =>  form.campaignName=e.target.value }
              required
            />
          </Form.Group>
          <p>Create Campaign for.</p>
          <Form.Group>
            <Form.Check
              inline
              label="Offer/Collection"
              value="offer"
              name="campaignFor"
              checked={form.campaignFor=='offer'?true:false}
              type="radio"
              onChange={setForm}
              id="offer"
            />
            <Form.Check
              inline
              label="Product"
              value="product"
              name="campaignFor"
              checked={form.campaignFor=='product'?true:false}
              type="radio"
              onChange={setForm}
              id="product"
            />
            </Form.Group>

            {form.campaignFor == "offer"  && (
            <MUIDataTable
            title={"Select Offers"}
            data={OfferData}
            columns={offerColumns}
            options={offerOptions}
          />
         )}

         
      {form.campaignFor == "product" && (
                  <MUIDataTable
                  title={"Select Product"}
                  data={productData}
                  columns={productColumns}
                  options={productOptions}
                />
              )}

          <p className="mt-4">City State/Province All-Over {localStorage.getItem("connectSite")=="CA"?"Canada":"US"}.</p>
          <Form.Group>
            <Form.Check
              inline
              label="City"
              value="city"
              name="promotionArea"
              type="radio"
              checked={form.promotionArea=='city'?true:false}
              onChange={setForm}
              id="city"
            />
            <Form.Check
              inline
              label="State/Province"
              value="state"
              name="promotionArea"
              checked={form.promotionArea=='state'?true:false}
              type="radio"
              onChange={setForm}
              id="state"
            />
             <Form.Check
              inline
              label={localStorage.getItem("connectSite")=="CA"?"Canada-Wide":"US-Wide"}
              value="country"
              name="promotionArea"
              checked={form.promotionArea=='country'?true:false}
              type="radio"
              onChange={setForm}
              id="country"
            />
          </Form.Group>
          {form.promotionArea == "city" && (
          <Form.Group className="col">
        <Form.Label>Select City</Form.Label>
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          isMulti
          name="cityList"
          value={selectedCity}
          options={cityOption}
          onChange={handleCityChange}
      />
        </Form.Group>
          )}
          {form.promotionArea == "state" && (
        <Form.Group className="col">
        <Form.Label>Select State</Form.Label>
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          isMulti
          name="stateList"
          value={selectedState}
          options={stateOption}
          onChange={handleStateChange}
      />
        </Form.Group>
         )}
         
            <br/>
           {/* <InputGroup className="mb-3">
                   <Form.Group className="col-6">
                   <Form.Label>Enter budget</Form.Label>
                   <Form.Control 
                   name="budget"
                   value={budget}
                   onChange={setForm}
                   type="number"
                   required
                   />
                    <Form.Control.Feedback type="invalid" >Please enter Valid from</Form.Control.Feedback>
                 </Form.Group>
                 <Form.Group className="col-6">
                   <Form.Label>Enter CPC</Form.Label>
                   <Form.Control 
                   name="cpc"
                   value={cpc}
                   onChange={setForm}
                   type="number"
                   required
                   />
                    <Form.Control.Feedback type="invalid" >Please enter Valid from</Form.Control.Feedback>
          </Form.Group>
                 </InputGroup>*/}

              {/*cityList.length>0 || stateList.length>0  || form.promotionArea == "country"?  (*/}
                 <InputGroup className="mb-3">
                   <Form.Group className="col-6">
                   <Form.Label>From Date</Form.Label>
                   <Form.Control 
                   name="fromDate"
                   value={fromDate}
                   onChange={setForm}
                   type="date" 
                   required
                   />
                    <Form.Control.Feedback type="invalid" >Please enter Valid from</Form.Control.Feedback>
                 </Form.Group>
         
                 <Form.Group className="col-6">
                   <Form.Label>To date</Form.Label>
                   <Form.Control  name="toDate"
                   value={toDate}
                   onChange={setForm}
                  // max={moment().format("2022-10-31")}
                   type="date"
                   required
                   />
                    <Form.Control.Feedback type="invalid" >Please enter valid till</Form.Control.Feedback>
                 </Form.Group>
                 
                 </InputGroup>
                 <div className="text-center">
                 <Button variant="contained" color="primary" onClick={(e) =>createCustomPlan(e)} className="btn btn-primary">Set Plan</Button>
                 </div>
                 {/* ):""}*/}
          </div>
        </div>

        <div className="col-md-6">
            <div >
            
              <h5 className="mt-2">Payment Summary</h5>
              {selectedCampaignPlan!=undefined && selectedCampaignPlan!='' && selectedCampaignPlan!=null && paymentTotal>0 ?  (
                <div>
                  <div className="amount-wrapper bg-white">
              <div className="reach">Your ad will run for {selectedCampaignPlan?.days} days</div>
              {/*<div className="budget">
                <div>
                  <h6>Total Budget</h6>
                
                </div>
               
                  <div className="total">${total}</div>
               
              </div>*/}
              {discountValue!=undefined && discountValue!=null && discountValue!=0 ?  (
              <div>
              {/*<div className="budget">
                <div>
                  <h6>Discount</h6>
                 
                </div>
                
                  <div className="total">{discountType=="F"? "$"+discountValue: discountValue+"%"}</div>
                 
              </div>
              <div className="budget">
                <div>
                  <h6>Total Budget after Discount</h6>
                 
                </div>
               
                  <div className="total">${totalAferDiscount}</div>
                 
              </div>*/}
              <div className="budget">
                <div>
                  <h6>Total</h6>
                 
                </div>
               
                  <div className="total">${totalAferDiscount}</div>
                 
              </div>
              </div>
              ):""}
                    </div>


                    {//form.promotionArea == "state" || form.promotionArea == "country" ?  (
                      <div>
                        <InputGroup className="mb-3">
              <Form.Group className="ml-4">
        <Form.Label >Enter promoccode (if you have)</Form.Label>
        <Form.Control
          type="text"
          readOnly={discountValue!=undefined && discountValue!=null && discountValue!=0?true:false}
          name="promocode"
          value={promocode}
          onChange={setForm}
        />
      </Form.Group>
      <Form.Group  className="ml-2 mt-4">
      <Form.Label ></Form.Label>
      {discountValue==undefined || discountValue==null || discountValue==0 ?  (
      <Button className=" ml-1" color="primary" onClick={handleApplyPromocode}>
      Apply Promocode
    </Button>
      
         ):<Button className=" ml-1" color="primary" onClick={handleRemovePromocode}>
         Remove Promocode
       </Button>}
       </Form.Group>
       </InputGroup>
        </div>
          // ): ""}
      }
         
{selectedCampaignPlan!=undefined && selectedCampaignPlan!='' && selectedCampaignPlan!=null && paymentTotal>0?  (
  <div>
    <div className={classes.root}>
      <Paper position="static" square>
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example"  indicatorColor="primary" textColor="primary">
          <Tab label="Pay with Stripe" {...a11yProps(0)} />
          <Tab label="Pay with Paypal" {...a11yProps(1)} />
        </Tabs>
      </Paper>
      
      <TabPanel value={value} index={0}>
      <div>
      
    <div>
    <h5 className="py-3 mb-0">Your saved Credit/Debit cards</h5>
    <table class="table highlight bordered" id = "">
              <tr>
							  <th>Brand</th>
							  <th>last 4 digit</th>
							  <th>Expiry</th>							  
							</tr>	
              {cardList.map((listValue, index) => (
                <tbody>
					<tr>
							  <td className="align-items-center d-flex">
                  
							  	<input type="radio" checked={cardId==listValue.cardId?true:""} onChange={(e) =>setCardId(e.target.value)}  id="paymentMethodId" name="paymentMethodId" value={listValue.cardId} />
      							<label for={index} className="mb-0 ml-2">{listValue.brand}</label>
                    

							  </td>
							  <td>**** **** **** {listValue.last4digit}</td>
							  <td> { listValue.expMonth } / { listValue.expYear }</td>
					 {/* <td width="1%"><button type = "button"onclick="deleteCard('${listValue.cardId}')"><i class="fa fa-trash" aria-hidden="true"></i></button></td>*/}
 						 </tr>
              
              
               </tbody>                																									
                        ))}
                        <tr>
              <td colspan="3"><button type="button" className="paymentButton" onClick={(e) =>payWithExistingCard(e)} id = "submit" >
              <span id="button-text">
          {isPayLoading ? <div className="spinner" id="spinner"></div> : " Pay With Selected Card"}
        </span>
               </button></td>
              </tr>
                        </table>
                        <h6 className="py-3 mb-0"><strong> You are in trial period so use test card for payment, Find details by hovering over card icon --></strong>
                        <OverlayTrigger placement="top" overlay={renderTooltip}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="26" fill="currentColor" class="bi bi-credit-card" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"/>
  <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z"/>
</svg>
      </OverlayTrigger>
                        </h6>
  </div>

  {stripPaymentoptions!=undefined&&stripPaymentoptions!=""?
<Elements stripe={stripePromise} options={stripPaymentoptions}>
      <CheckoutForm returnURL={window.location.href} onSucessPayment={paymentSucessByNewCard} />
    </Elements>
    :""}


  </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <div class="float-center mb-4">
{/* <PaypalExpressBtn  env={env} client={client} currency={currency} total={total} onError={onError} onSuccess={onSuccess} onCancel={onCancel} />*/}
<PayPalButton  options={clientNew}  shippingPreference={"NO_SHIPPING"} vault={false} amount={paymentTotal} onError={onError} onSuccess={onSuccess} onCancel={onCancel} />

</div>
      </TabPanel>
    </div>


  </div>
):""}
              </div>

              
              ):"Please set plan for payment."}
            </div>
          </div>
        </div>



     {/* <div className="row">
        <div className="col-md-6">
          <div className="amount-wrapper bg-white">
            <h5>Ad Library visibility
              
            </h5>
            <div className="reach">Your ad and information from your Marketplace profile may appear publicly in Ad Library search results. <a href="">Learn more</a></div>
          </div>
        </div>
  </div>*/}

       {/* <div className="row">
        <div className="col-md-6">
          <div className="amount-wrapper bg-white">
            <h5>Payment Method</h5>
            <div className="reach">You may receive more then one bill for this ad. Learn more <a href="">Learn more</a></div>
            <div className="card-deatils">
              <div className="card-side">
              <img src="https://static-00.iconduck.com/assets.00/visa-icon-512x322-wb39y8f5.png" alt="" className="mr-3" />
              <b>....4383</b>
              </div>
              <Button variant="contained" class="btn btn-secondary  ">Change</Button>
            </div>
          </div>
        </div>
      </div>*/}

    { /* <div className="promote-now amount-wrapper bg-white d-flex justify-content-between flex-wrap align-items-center">
        <div>By clicking Promote Now, you agree to Offer Dhamaka <a href="">Terms & Condition</a> | <a href="">Help Center</a></div>

        {selectedCampaignPlan==undefined || selectedCampaignPlan=='' || selectedCampaignPlan==null ?  (
      <Button variant="contained" color="primary" onClick={(e) =>handleSubmit(e)} className="btn btn-primary">Promote Now</Button>
      ):""}


      </div>*/}
      <ToastContainer />
    </Form>
  )
}



export default StartPromotionBatch

// const style = {
//   form: {
//     backgroundColor: "white",
//     padding: "25px",
//     boxShadow: "0 0 15px silver",
//   },
// }
