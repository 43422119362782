import React from "react"
import PublicRoute from "../utils/PublicRoute"
import { Switch, Redirect } from "react-router-dom"
import Login from "./Login"
import ForgotPassword from "./ForgotPassword"
import Register from "./Register"
import Help from "./help/Help"
import GettingStarted from "./help/GettingStarted"
import StartWithShopAds from "./help/StartWithShopAds"
import SignUp from "./help/SignUp"
import Dashboard from "./help/Dashboard"
import LoginHelp from "./help/LoginHelp"
import ForgotPasswordHelp from "./help/ForgotPasswordHelp"
import Company from "./help/Company"
import HelpStore from "./help/HelpStore"
import AddStoreBranch from "./help/AddStoreBranch"
import EditStoreBranch from "./help/EditStoreBranch"
import AddEditStorePolicy from "./help/AddEditStorePolicy"
import OfferCollection from "./help/OfferCollection"
import CreateNewOffer from "./help/CreateNewOffer"
import EditOffer from "./help/EditOffer"
import OfferCopy from "./help/OfferCopy"
import OfferAscProduct from "./help/OfferAscProduct"
import Collection from "./help/Collection"
import HelpProducts from "./help/HelpProducts"
import CreateNewProducts from "./help/CreateNewProducts"
import EditProducts from "./help/EditProducts"
import ProductCopy from "./help/ProductCopy"
import Campaign from "./help/Campaign"
import Invoice from "./help/Invoice"
import UpdateUser from "./help/UpdateUser"
import Roles from "./help/Roles"
import AddNewRole from "./help/AddNewRoll"
import AccountToAgency from "./help/AccountToAgency"
import ManagerRole from "./help/ManagerRole"
import ShopifyHelp from "./help/ShopifyHelp"
import ShopifyProductHelp from "./help/ShopifyProductHelp"
import ShopifyCollectionHelp from "./help/ShopifyCollectionHelp"
import ShopifyErrorHelp from "./help/ShopifyErrorHelp"
import ShopifyCategoryHelp from "./help/ShopifyCategoryHelp"
import ShopifyDepartmentHelp from "./help/ShopifyDepartmentHelp"
import ShopifyOccasionHelp from "./help/ShopifyOccasionHelp"
import ShopifyHelpMode from "./help/ShopifyHelpMode"
import ShopifyOfferHelp from "./help/ShopifyOfferHelp"





function LandingPage() {
  return (
    <div className="LandingPage">
      <Switch>
        <PublicRoute path="/login" exact component={Login} />
        <PublicRoute path="/help" exact component={Help} />
        <PublicRoute path="/help/gettingStarted" exact component={GettingStarted} />
        <PublicRoute path="/help/startWithShopAds" exact component={StartWithShopAds} />
        <PublicRoute path="/help/signup" exact component={SignUp} />
        <PublicRoute path="/help/dashboard" exact component={Dashboard} />
        <PublicRoute path="/help/login-help" exact component={LoginHelp} />
        <PublicRoute path="/help/forgot-password-help" exact component={ForgotPasswordHelp} />
        <PublicRoute path="/help/company" exact component={Company} />
        <PublicRoute path="/help/help-store" exact component={HelpStore} />
        <PublicRoute path="/help/add-store-branch" exact component={AddStoreBranch} />
        <PublicRoute path="/help/edit-store-branch" exact component={EditStoreBranch} />
        <PublicRoute path="/help/add-edit-store-policy" exact component={AddEditStorePolicy} />
        <PublicRoute path="/help/offer-collection" exact component={OfferCollection} />
        <PublicRoute path="/help/create-new-offer" exact component={CreateNewOffer} />
        <PublicRoute path="/help/edit-offer" exact component={EditOffer} />
        <PublicRoute path="/help/offer-copy" exact component={OfferCopy} />
        <PublicRoute path="/help/offer-associated-product" exact component={OfferAscProduct} />
        <PublicRoute path="/help/collection" exact component={Collection} />
        <PublicRoute path="/help/help-products" exact component={HelpProducts} />
        <PublicRoute path="/help/create-new-products" exact component={CreateNewProducts} />
        <PublicRoute path="/help/edit-products" exact component={EditProducts} />
        <PublicRoute path="/help/product-copy" exact component={ProductCopy} />
        <PublicRoute path="/help/campaign" exact component={Campaign} />
        <PublicRoute path="/help/invoice" exact component={Invoice} />
        <PublicRoute path="/help/update-user" exact component={UpdateUser} />
        <PublicRoute path="/help/roles" exact component={Roles} />
        <PublicRoute path="/help/add-new-roll" exact component={AddNewRole} />
        <PublicRoute path="/help/how-to-convert-your-account-into-an-agency" exact component={AccountToAgency} />
        <PublicRoute path="/help/manager-role" exact component={ManagerRole} />
        <PublicRoute path="/help/shopifyHelp" exact component={ShopifyHelp} />
        <PublicRoute path="/help/synchelp" exact component={ShopifyHelp} />
        <PublicRoute path="/help/synchelpOfferMode" exact component={ShopifyHelpMode} />
        <PublicRoute path="/help/syncProducthelp" exact component={ShopifyProductHelp} />
        <PublicRoute path="/help/syncCollectionhelp" exact component={ShopifyCollectionHelp} />
        <PublicRoute path="/help/syncOfferhelp" exact component={ShopifyOfferHelp} />
        <PublicRoute path="/help/syncCategoryhelp" exact component={ShopifyCategoryHelp} />
        <PublicRoute path="/help/syncDepartmenthelp" exact component={ShopifyDepartmentHelp} />
        <PublicRoute path="/help/syncOccasionHelp" exact component={ShopifyOccasionHelp} />
        <PublicRoute path="/help/syncErrorhelp" exact component={ShopifyErrorHelp} />

        
        <PublicRoute path="/forgotPassword" exact component={ForgotPassword} />
        <PublicRoute path="/register" exact component={Register} />
        <Redirect to="/login" />
      </Switch>
    </div>
  )
}

export default LandingPage
