import firebase from "firebase/app";
import "firebase/auth";

/*const app = firebase.initializeApp({
  apiKey: "AIzaSyDBeYTU9DffgJzqm11rMI96nCWSVYUVDFk",
  authDomain: "offerdhamaka.firebaseapp.com",
  databaseURL: "https://offerdhamaka.firebaseio.com",
  projectId: "offerdhamaka",
  storageBucket: "offerdhamaka.appspot.com",
  messagingSenderId: "787226436881",
  appId: "1:787226436881:web:4413816f7129a05d6f58b0",
  measurementId: "G-V64RYXPB30",
});*/
let app;
if (!firebase.apps.length) {
 app = firebase.initializeApp({
  apiKey: "AIzaSyCHeLJPFc-n3M9At1HT3wrjFq_j_P1XkNw",
  authDomain: "shopiadsca.firebaseapp.com",
  databaseURL: "https://shopiadsca.firebaseio.com",
  projectId: "shopiadsca",
  storageBucket: "shopiadsca.appspot.com",
  messagingSenderId: "15194957769",
  appId: "1:15194957769:web:09cdaed2adf6536c752a18",
  measurementId: "G-FP6BXMDFFV"
});
}
else {
  app= firebase.app(); // if already initialized, use that one
}

export const auth = app.auth();
export default app;
