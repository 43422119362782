import React from "react"
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom"
import { AddUser, AddRole, ManageUsers } from "../domain"
import AddCategory from "../domain/AddCategory"
import ManageBills from "../domain/ManageBills"
import ManageCategories from "../domain/ManageCategories"

function Bills() {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/manage`} component={ManageBills} />
      <Redirect to={`${path}/manage`} />
    </Switch>
  )
}

export default Bills
