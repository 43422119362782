import React from "react"
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom"
import { AddProduct, ManageProducts } from "../domain"
import AddProductVariant from "../domain/AddProductVariant"
import ManageProductVariant from "../domain/ManageProductVariant"
import SelectProductCategory from "../domain/SelectProductCategory"

function ProductVariant() {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/manage`} component={ManageProductVariant} />
      <Route path={`${path}/addProductVariant`} component={AddProductVariant} />
      <Route path={`${path}/add`} component={SelectProductCategory} />
      <Redirect to={`${path}/manage`} />
    </Switch>
  )
}

export default ProductVariant
