
import { Form, InputGroup } from "react-bootstrap"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import React, { useEffect, useState } from "react";
import { useAuth, useUser, useStore } from "../context";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Navigation } from "@material-ui/icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import {createUser} from "../utils/API";
import API from "../utils/API";
import { useForm, useStep } from "react-hooks-helper";
import { useHistory } from "react-router-dom";
import { uuid } from '../utils/uuid'
import eventBus from '../utils/eventBus'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { tr } from "date-fns/locale";
import { toDate } from "date-fns";
import PaypalExpressBtn from "react-paypal-express-checkout";
import { PayPalButton } from "react-paypal-button-v2";
import { clientNew } from "../utils/paypalConfig";
import MUIDataTable from "mui-datatables"
import { maxCharge, minCharge, minItem } from "../utils/campaignConfig";
import Moment from 'react-moment';
import moment from 'moment';
import SelectSponsoredSection from "./SelectSponsoredSection";
import {Modal} from 'react-bootstrap';
import SelectSponsoredKeyword from "./SelectSponsoredKeyword";
import MuiAlert from "@material-ui/lab/Alert";
import Tooltip1 from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const defaultData = {
  promotionArea: "",
  cityList:[],
  stateList: [],
  promocode:"",
  fromDate:"",
  toDate:"",
  campaignName:"",
  campaignFor:"",
  budget:"",
  cpc:"",
  companyId:"",
  storeId:"",
  tab:"",
  category:"",
  section:"",
  sponsoredTypeSection:"section",
  sponsoredTypeKeyword:"",
  keywords:"",
  suggestedBid:"",
  bid:"",
  
};

const offerColumns = [
  {
    name: "offerImage",
    label: " ",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        //alert(value);
        return (
          <img height='100' width='100'  src={value}/>
        );
      }
    },
  },
  {
    name: "offerHeading",
    label: "Offer Heading",
    options: {
      filter: false,
      sort: false,
      
    },
  },
 
  
]

const productColumns = [
  {
    name: "productImage",
    label: " ",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        //alert(value);
        return (
          <img height='100' width='100'  src={value}/>
        );
      }
    },
  },
  {
    name: "ProductName",
    label: "Product Name",
    options: {
      filter: true,
      sort: true,
      
    },
  },
  
]

function SponsoredPromotion(props) {
  //const areEqual = (prevProps, nextProps) => true;
 // const StartPromotionBatch = React.memo(props => {
  const campagainType=new URLSearchParams(props.location.search).get("type");
  const campagainId=new URLSearchParams(props.location.search).get("id");
  console.log("campagainType>>>>>>>>>>>>>>>>>>>",campagainType);
  console.log("campagainId>>>>>>>>>>>>>>>>>>>",campagainId);
  const [form, setForm] = useForm(defaultData);
  const [pendingCompanyOffer, setPendingCompanyOffer] = useState([]);
  const [validated, setValidate] = useState(false);
  const { signUp, userInfo, loggedUserInfo } = useAuth();
  const { categoryData, storeData, getMainCategories, getStoreByCompany } = useStore();
  const { promotionArea, cityList, stateList,promocode,fromDate,toDate,campaignName,campaignFor,budget,cpc,companyId,storeId,tab,category,section,keywords,sponsoredTypeSection,sponsoredTypeKeyword,suggestedBid,bid } = form;
  const [selectedCity, setSelectedCity] = React.useState([])
  const [selectedState, setSelectedState] = React.useState([])
  const [stateCityCount, setstateCityCount] = React.useState(0)
  const [offerStateData, setOfferstateData] = React.useState()
  const [discountValue, setDiscountValue] = React.useState(0)
  const [isCustomPlan, setCustomPlan] = React.useState(false)
  const [discountType, setDiscountType] = React.useState(0)
  const [campaignPlan, setCampaignPlan] = React.useState([])
  const [selectedCampaignPlan, setSelectedCampaignPlan] = React.useState()
  const [cities,setCities]=useState([]);
  const { stateData, getState} =useStore();
  const animatedComponents = makeAnimated();
  const [offerData, setOfferData] = React.useState([])
  const [offerStoreData, setOfferStoreData] = React.useState()
  const [totalItem, setTotalItem] = React.useState(0)
  const [totalSelectedOffer, setTotalSelectedOffer] = React.useState([])
  const [totalSelectedProduct, setTotalSelectedProduct] = React.useState([])
  const [productList, setProductList] = useState([]);
  const [itemTaxAmount, setItemTaxAmount] = useState([]);
  const [gstTax, setGstTax] = useState(0);
  const [hstTax, setHstTax] = useState(0);
  const [qstTax, setQstTax] = useState(0);
  const [pstTax, setPstTax] = useState(0);
  const [companyList, setCompanyList] = useState([]);
  const [selectedStore, setSelectedStore] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [sponsoredTypeSection1, setSponsoredTypeSection1] = useState(true);
  const [sponsoredTypeKeyword1, setSponsoredTypeKeyword1] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [selectedSection, setSelectedSection] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState([]);
  //const {storeData,getStoreByCompany } =useStore();

    
  const renderTooltip = props => (
    <Tooltip1 {...props}>Budget puts a limit on how much you want to spend on a daily basis</Tooltip1>
  );

  const renderTooltip1 = props => (
    <Tooltip1 {...props}>Dates should be between  active campaign of selected offer/product</Tooltip1>
  );


  let selectedOffer=[];
  let selectedProduct=[];
  let stateTaxAmount=[];
  let gst =0;
	let hst =0;
	let qst = 0;
	let pst = 0;
  
  useEffect(() => {
    console.log("selected section>>>>>>>>",selectedSection);
    if (loggedUserInfo != undefined) {
      getState(loggedUserInfo.email);
      setSelectedSection([]);
      getMainCategories();
      getStoreByCompany(loggedUserInfo.companyId);
      API.getAllCompany(loggedUserInfo.email).then((companyResult) => {  
       
        if(companyResult.data!=undefined && companyResult.data.length>0)
        {
          setCompanyList(companyResult.data)
         
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            }); 
      API.getAllCities(loggedUserInfo.email).then((result) => {  
       
        if(result.data!=undefined && result.data.length>0)
        {
          setCities(result.data);
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            });

            API.getCampaignPlan(loggedUserInfo.companyId,"offer").then((planResult) => {  
       
              if(planResult.data!=undefined && planResult.data.length>0)
              {
                setCampaignPlan(planResult.data);
              }
                   })
                   .catch((error) => {
                     console.log(error);
                     alert(error);
                  });

               

      //***********************get offer data */   
      API.getCompanyOffer(loggedUserInfo.companyId,"active").then((result) => {  
       
        if(result.data!=undefined && result.data.length>0)
        {
          setPendingCompanyOffer(result.data)
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            });        

            API.getCompanyProduct(loggedUserInfo.companyId,"active").then((productResult) => {  
                     
              if(productResult.data!=undefined && productResult.data.length>0)
              {
                setProductList(productResult.data);
            
              }
                   })
                   .catch((error) => {
                     console.log(error);
                     alert(error);
                  });


       if(campagainId!=undefined && campagainId!=null && campagainId!='')
       {
        API.getCampaignById(campagainId).then((camResult) => {  
                     
          if(camResult.data!=undefined )
          {
            
            API.getCompanyBranchOffer(camResult.data.storeId,"active").then((result) => {  
       
              if(result.data!=undefined && result.data.length>0)
              {
                setPendingCompanyOffer(result.data)
                if(camResult.data.campaignFor=='offer')
                {
                  var existingOffer=[];
                for(var i=0;i<camResult.data.offers.length;i++)
                {
                  const obj = result.data.filter((item) => item.id == camResult.data.offers[i].id);
                  console.log("existing offer>>>>>>>>>>>>>>>>>>>",obj);
                  console.log("pendingCompanyOffer offer>>>>>>>>>>>>>>>>>>>",pendingCompanyOffer);
                  if(obj!=null && obj!=undefined && obj.length>0)
                  {
                  existingOffer.push(obj[0]);
                }
                }
                setTotalSelectedOffer(existingOffer);
              }
              }
              else
              {
                setPendingCompanyOffer([])
              }
                   })
                   .catch((error) => {
                     console.log(error);
                     alert(error);
                  });        
        
                  API.getCompanyBranchProduct(camResult.data.storeId,"active").then((productResult) => {  
                           
                    if(productResult.data!=undefined && productResult.data.length>0)
                    {
                      setProductList(productResult.data);
                      if(camResult.data.campaignFor=='product')
                      {
                        var existingProduct=[];
                        for(var i=0;i<camResult.data.products.length;i++)
                  {   
                        const obj = productResult.data.filter((item) => item.id == camResult.data.products[i].id);
                        if(obj!=null && obj!=undefined && obj.length>0)
                        {
                          existingProduct.push(obj[0]);
                      }
                      }
                      setTotalSelectedProduct(existingProduct);
                    }
                    }
                    else
                    {
                      setProductList([]);
                    }
                         })
                         .catch((error) => {
                           console.log(error);
                           alert(error);
                        });

            form.campaignName=camResult.data.campaignName
            form.campaignFor=camResult.data.campaignFor
            form.promotionArea=camResult.data.campaignArea
            form.cityList=camResult.data.cityList
            form.stateList=camResult.data.stateList
            form.budget=camResult.data.budget
            form.companyId=camResult.data.companyId
            form.storeId=camResult.data.storeId
            getStoreByCompany(camResult.data.companyId);
            setSelectedStore(camResult.data.storeId);
           // form.fromDate=camResult.data.validFrom
            //form.toDate=camResult.data.validTill
            
            
            if(camResult.data.campaignArea=='city')
            {
              API.getAllCities(loggedUserInfo.email).then((citiesResult) => {  
       
                if(citiesResult.data!=undefined && citiesResult.data.length>0)
                {
                  var existCity=[]
                  form.cityList = [];
                  citiesResult.data.map((item) => {
              if(camResult.data.cityList.includes(item.id))
              {
              var tempObj ={"label":item.name, "value":item.id}
              existCity.push(tempObj)
              form.cityList.push(item.id);
              }
              
            });
            setSelectedCity(existCity);
                }
                     })
                     .catch((error) => {
                       console.log(error);
                       alert(error);
                    });

              
      }
        if(camResult.data.campaignArea=='state')
            {
              var existState=[]
              form.stateList = [];
  
               var totalCity=0;
               console.log("stateData>>>>>>>>>>>>>>>>>>",stateData);
               API.getState(loggedUserInfo.email).then((stateData) => {  
       
                if(stateData.data!=undefined && stateData.data.length>0)
                {
                  stateData.data.map((item) => {
                    if(camResult.data.stateList.includes(item.id))
                    {
                    var tempObj ={"label":item.name, "value":item.id}
                    existState.push(tempObj)
                    form.stateList.push(item.id);
                    API.getCitiesByState("gust",item.id).then((result) => {  
                      
                      if(result.data!=undefined && result.data.length>0)
                      {
                        totalCity=totalCity+result.data.length
                        setstateCityCount(totalCity);
                      }
                          })
                          .catch((error) => {
                            console.log(error);
                            alert(error);
                          });
          
                          }
                    
                  });
                  
                  setSelectedState(existState)
                }
                     })
                     .catch((error) => {
                       console.log(error);
                       alert(error);
                    });
             
            }
          
            
           
            if(camResult.data.campaignFor=='product')
            {
            for(var i=0;i<camResult.data.products.length;i++)
      {   
          API.getStoreById(camResult.data.products[i].storeId).then((storeData) => {  
  
            if(storeData.data!=undefined)
            {
              //setOfferStoreData(storeData.data);
              API.getStateById(storeData.data.stateId).then((stateResult) => {  
  
                if(stateResult.data!=undefined)
                {
                  stateTaxAmount.push(stateResult.data.totalTax);
                  gst=gst+Number(stateResult.data.gst);
                  hst=hst+Number(stateResult.data.hst);
                  pst=pst+Number(stateResult.data.pst);
                  qst=qst=Number(stateResult.data.qst);
                  console.log("setGstTax>2222222>>>>>>>>>>",gst)
                  setItemTaxAmount(stateTaxAmount);
                  setGstTax(gst);
                  setHstTax(hst);
                  setPstTax(pst);
                  setQstTax(qst);
                }
                     })
                     .catch((error) => {
                       console.log(error);
                       alert(error);
                    });
            }
          })
          .catch((error) => {
            console.log(error);
            alert(error);
         });
        
       
    }
   
  }
            if(camResult.data.campaignFor=='offer')
            {
            
            for(var i=0;i<camResult.data.offers.length;i++)
          {   
          API.getStoreById(camResult.data.offers[i].storeId).then((storeData) => {  

            if(storeData.data!=undefined)
            {
              //setOfferStoreData(storeData.data);
              API.getStateById(storeData.data.stateId).then((stateResult) => {  

                if(stateResult.data!=undefined)
                {
                  stateTaxAmount.push(stateResult.data.totalTax);
                  gst=gst+Number(stateResult.data.gst);
                  hst=hst+Number(stateResult.data.hst);
                  pst=pst+Number(stateResult.data.pst);
                  qst=qst=Number(stateResult.data.qst);
                  console.log("setGstTax>2222222>>>>>>>>>>",gst)
                  setItemTaxAmount(stateTaxAmount);
                  setGstTax(gst);
                  setHstTax(hst);
                  setPstTax(pst);
                  setQstTax(qst);
                }
                    })
                    .catch((error) => {
                      console.log(error);
                      alert(error);
                    });
            }
          })
          .catch((error) => {
            console.log(error);
            alert(error);
          });

          
          }
       
          }
            
          }
               })
               .catch((error) => {
                 console.log(error);
                 alert(error);
              });

       } 
       else
       {
         form.companyId="";
         form.storeId="";
         setSelectedStore("");
       }          

    }
  }, [loggedUserInfo]);

  const rows = [];
  console.log("totalSelectedOffer5555555>>>>>>>>>>>",totalSelectedOffer)
  if(totalSelectedOffer!=undefined && totalSelectedOffer!=null && totalSelectedOffer.length>0)
  {
  
  console.log("pendingCompanyOffer555555555>>>>>>>>>>>",pendingCompanyOffer)
  pendingCompanyOffer.forEach((element, index) => {
    totalSelectedOffer.forEach((element1, index1) => {
      console.log("element1>>>>>>>>>>>",element1)
      if(element.id==element1.id)
      rows.push(index)
      });
  });
}
  const offerOptions = {
    download: false,
    print: false,
    filter:false,
    search:false,
    viewColumns:false,
    rowsPerPage: 5,
    rowsSelected: rows,
    selectableRows:"single",
    selectToolbarPlacement: "none",
   /* customToolbar: () => (
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddRoundedIcon />}
        component={NavLink}
        onClick={(e) =>handleSubmit(e)}
        to={"/home/company/addCompany"}
      >
       Start Promotion
      </Button>
    ),*/
    selectableRowsOnClick: false,
    onRowSelectionChange: (curRowSelected,allRowsSelected,rowsSelected) => {
       selectedOffer=[];
       stateTaxAmount=[];
       gst = 0;
       hst = 0;
       qst = 0;
       pst = 0;
       setSelectedCampaignPlan("")
      //console.log("curRowSelected>>>>>>>>>>>",curRowSelected)
      console.log("allRowsSelected>>>>>>>>>>>",allRowsSelected)
      //console.log("rowsSelected>>>>>>>>>>>",rowsSelected)
      for(var i=0;i<allRowsSelected.length;i++)
      {
      const offerData = pendingCompanyOffer[allRowsSelected[i].index];
      selectedOffer.push(offerData);
      
      }
      for(var i=0;i<selectedOffer.length;i++)
      {   
          API.getStoreById(selectedOffer[i].storeId).then((storeData) => {  
  
            if(storeData.data!=undefined)
            {
              //setOfferStoreData(storeData.data);
              API.getStateById(storeData.data.stateId).then((stateResult) => {  
  
                if(stateResult.data!=undefined)
                {
                  stateTaxAmount.push(stateResult.data.totalTax);
                  gst=gst+Number(stateResult.data.gst);
                  hst=hst+Number(stateResult.data.hst);
                  pst=pst+Number(stateResult.data.pst);
                  qst=qst=Number(stateResult.data.qst);
                  console.log("setGstTax>2222222>>>>>>>>>>",gst)
                  setItemTaxAmount(stateTaxAmount);
                  setGstTax(gst);
                  setHstTax(hst);
                  setPstTax(pst);
                  setQstTax(qst);
                }
                     })
                     .catch((error) => {
                       console.log(error);
                       alert(error);
                    });
            }
          })
          .catch((error) => {
            console.log(error);
            alert(error);
         });
        
       
    }
    
      
     setTotalSelectedOffer(selectedOffer)
     setTotalSelectedProduct([])
     console.log("totalSelectedOffer>>>>>>>>>>>",totalSelectedOffer)
    },
  }

  const productRows = [];
  console.log("totalSelectedProduct444444444>>>>>>>>>>>",totalSelectedProduct)
  if(totalSelectedProduct!=undefined  && totalSelectedProduct!=null && totalSelectedProduct.length>0)
  {
  console.log("clicked>>>>>>>>>>>",totalSelectedProduct)
  productList.forEach((element, index) => {
    totalSelectedProduct.forEach((element1, index1) => {
      console.log("element1>>>>>>>>>>>",element1)
      if(element.id==element1.id)
      productRows.push(index)
      });
  });
}

  const productOptions = {
    download: false,
    print: false,
    filter:false,
    search:false,
    viewColumns:false,
    rowsPerPage: 5,
    rowsSelected: productRows,
    selectableRows:"single",
    selectToolbarPlacement: "none",
   /* customToolbar: () => (
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddRoundedIcon />}
        component={NavLink}
        onClick={(e) =>handleSubmit(e)}
        to={"/home/company/addCompany"}
      >
       Start Promotion
      </Button>
    ),*/
    selectableRowsOnClick: false,
    onRowSelectionChange: (curRowSelected,allRowsSelected,rowsSelected) => {
      selectedProduct=[];
       stateTaxAmount=[];
        gst = 0;
         hst = 0;
         qst = 0;
         pst = 0;
     
       setSelectedCampaignPlan("")
       setTotalSelectedOffer([])
      //console.log("curRowSelected>>>>>>>>>>>",curRowSelected)
      console.log("allRowsSelected>>>>>>>>>>>",allRowsSelected)
      //console.log("rowsSelected>>>>>>>>>>>",rowsSelected)
      for(var i=0;i<allRowsSelected.length;i++)
      {
      const offerData = productList[allRowsSelected[i].index];
      selectedProduct.push(offerData);
      
      }
      for(var i=0;i<selectedProduct.length;i++)
      {   
          API.getStoreById(selectedProduct[i].storeId).then((storeData) => {  
  
            if(storeData.data!=undefined)
            {
              //setOfferStoreData(storeData.data);
              API.getStateById(storeData.data.stateId).then((stateResult) => {  
  
                if(stateResult.data!=undefined)
                {
                  stateTaxAmount.push(stateResult.data.totalTax);
                  gst=gst+stateResult.data.gst;
                  hst=hst+stateResult.data.hst;
                  pst=pst+stateResult.data.pst;
                  qst=qst=stateResult.data.qst;
                  setItemTaxAmount(stateTaxAmount);
                  setGstTax(gst);
                  setHstTax(hst);
                  setPstTax(pst);
                  setQstTax(qst);
                }
                     })
                     .catch((error) => {
                       console.log(error);
                       alert(error);
                    });
            }
          })
          .catch((error) => {
            console.log(error);
            alert(error);
         });
        
       
    }
      
      setTotalSelectedProduct(selectedProduct)
     console.log("totalSelectedProduct>>>>>>>>>>>",totalSelectedProduct)
    },
  }

  const onlyCompanyOffer = pendingCompanyOffer.filter((item) => item.type == "Offer");
  const OfferData = onlyCompanyOffer.map((item) => ({

    offerHeading: item.heading,
    type: item.type,
    offerImage:item.fullImage!=undefined && item.fullImage!="" && item.fullImage!=null?item.fullImage:"https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg",
  }));

  const productData = productList.map((item) => ({
    ProductName: item.name,
    productImage:item.images[0]!=undefined && item.images[0]!="" && item.images[0]!=null?item.images[0]:"https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg",
  }));

  let cityOption=[];
  cities.map((item) => {
    let tempObj ={"label":item.name,"value":item.id}
    cityOption.push(tempObj)
   
  });

  let stateOption=[];
  stateData.map((item) => {
    let tempObj ={"label":item.name,"value":item.id}
    stateOption.push(tempObj)
   
  });

  const history = useHistory();


  /*const handleSubmit = async (e) => {

    let offerBody=
    {
      id:"dasd",
      price:"20"
    };
      
    API.makeOfferPayment(userInfo.email,offerBody)
    if(selectedCampaignPlan==undefined || selectedCampaignPlan==null || selectedCampaignPlan=='')
    {
      toast.error("Please select campaign plan")
    }

  };*/

  const handleCustomPlan = async (e) => {
      
    setCustomPlan(true);
    setSelectedCampaignPlan("")

  };

  const createCustomPlan = async (e) => {

    setSelectedCampaignPlan("")
   // var date1 = new Date(form.fromDate);
    //var date2 = new Date(form.toDate);
    var date1 = moment(form.fromDate)
    var date2 = moment(form.toDate)
   var nowDate= moment().format("yyyy-MM-DD");
    /*var currentDate=new Date();
    var day = currentDate.getDate()
    var month = currentDate.getMonth() + 1
  var year = currentDate.getFullYear()
  var newCurrentDate=year+"-"+month+"-"+day;
  var today = new Date(newCurrentDate);
  console.log("currentDate date>>>>>>>>>>>",newCurrentDate)
  console.log("form.fromDate date>>>>>>>>>>>",form.fromDate)
    console.log("currentDate date>>>>>>>>>>>",today.getTime())
    console.log("date1 date>>>>>>>>>>>",date1.getTime())*/
    //console.log("date1.isBefore(nowDate) date>>>>>>>>>>>",date1.isBefore(nowDate))
    //console.log("form.campaignFor>>>>>>>>>>>",form.campaignFor)
    //console.log("date2>>>>>>>>>>>",date2)
   // console.log("date2>moment(totalSelectedOffer[0].validTill)>>>>>>>>>>>",date2>moment(totalSelectedOffer[0].validTill))
    
      
    if(form.fromDate==undefined || form.fromDate==null || form.fromDate=='')
    {
      toast.error("Please enter from date")
    }
    else if(date1.isBefore(nowDate))
    {
      toast.error("Selected from date is invalid")
    }

    else if(form.toDate==undefined || form.toDate==null || form.toDate=='')
    {
      toast.error("Please enter to date")
    }

    else if(date2<=date1)
    {
      toast.error("from date can not greater than or equal to date")
    }

     else if(form.storeId==undefined || form.storeId=='')
    {
      toast.error("Please select branch")
    }
    else if(form.campaignName==undefined || form.campaignName=='')
    {
      toast.error("Please enter campaign name")
    }

    else if(form.campaignName==undefined || form.campaignName=='')
    {
      toast.error("Please enter campaign name")
    }

    /*else if(form.promotionArea==undefined || form.promotionArea=='')
    {
      toast.error("Please select campaign promotion area")
    }*/

    else if(form.campaignFor==undefined || form.campaignFor=='')
    {
      toast.error("Please select campaign create for offer/product")
    }

    else if(form.campaignFor=='offer' && (totalSelectedOffer==undefined || totalSelectedOffer==null || totalSelectedOffer.length==0))
    {
      toast.error("Please select offers")
    }

    else if(form.campaignFor=='product' && (totalSelectedProduct==undefined || totalSelectedProduct==null || totalSelectedProduct.length==0))
    {
      toast.error("Please select product")
    }

    else if(form.campaignFor=='offer' && (totalSelectedOffer!=undefined && totalSelectedOffer!=null && totalSelectedOffer.length>0) && date2>moment(totalSelectedOffer[0].validTill) )
    {
      toast.error("end date should be less than or equal to selected offer live date")
    }

    else if(form.campaignFor=='product' && (totalSelectedProduct!=undefined && totalSelectedProduct!=null && totalSelectedProduct.length>0) && date2>moment(totalSelectedProduct[0].validTill))
    {
      toast.error("end date should be less than or equal to selected product live date")
    }

    else if(selectedKeyword.length==0 && selectedSection.length==0 )
    {
      toast.error("Please configure section/keyword")
    }

    else if(form.budget==undefined || form.budget=='')
    {
      toast.error("Please enter budget")
    }

   

    else if(form.toDate!='' && form.fromDate!='' && (date1.isAfter(nowDate) || date1.isSame(nowDate)) && date2>date1 && form.campaignName!='' && form.budget!=''
      && form.storeId!='' && (selectedKeyword.length>0 || selectedSection.length>0) 
    && form.campaignFor!='' && (totalSelectedOffer!=undefined && totalSelectedOffer!=null || totalSelectedOffer.length>0)
    
    )
    {
      console.log("to date>>>>>>>>>>>",form.toDate)
      console.log("from date>>>>>>>>>>>",form.fromDate)
      var totalItemCount=0;
      var from = new Date(form.toDate);
      var to = new Date(form.fromDate);
      const diffTime = Math.abs(to - from);
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
      console.log(diffTime + " milliseconds");
      console.log(diffDays + " days");
     // diffDays=diffDays+1;
        var validFrom=new Date();
        var toDate=new Date(validFrom);
        toDate.setDate(toDate.getDate()+ Number(diffDays));
        console.log("The payment validFrom", validFrom);
        console.log("The payment was toDate!", toDate);

      let temp={};
      let campaignBody = {
        id:campagainId!=undefined && campagainId!=null && campagainId!=''?campagainId:uuid(),
        campaignName:form.campaignName,
        offers:totalSelectedOffer,
        products:totalSelectedProduct,
        userId:userInfo.email,
        companyId:form.companyId!=undefined && form.companyId!=null && form.companyId!=''?form.companyId:loggedUserInfo.companyId,
        storeId:form.storeId,
        campaignType:campagainType,
        campaignFor:form.campaignFor,
        sponsoredType:form.sponsoredType,
        keywords:selectedKeyword,
        sponsoredSection:selectedSection,
        bid:form.bid,
        campaignPrice:"",
        campaignArea:"",
        cityList:[],
        stateList:[],
        budget:form.budget,
       sponsoredValidFrom:validFrom,
       sponsoredValidTill:toDate,

       };

      API.createCampaign(userInfo.email,campaignBody).then((campaognResult) => {  
if(form.campaignFor=="offer")
        {
for(var i=0;i<totalSelectedOffer.length;i++)
        {
            let offerBody = {
              id:totalSelectedOffer[i].id,
              storeId:form.storeId,
              budget:form.budget,
              cpc:form.cpc,
              sponsoredCampaignId:campaognResult.data.id,
              sponsoredCampaignName:form.campaignName,
              sponsoredType:form.sponsoredType,
              keywords:selectedKeyword,
              sponsoredSection:selectedSection,
              sponsoredValidFrom:validFrom,
              sponsoredValidTill:toDate,
              //products:totalSelectedOffer[i].products,
              //createdBy:userInfo.email,
              updatedBy:userInfo.email,
              companyId:totalSelectedOffer[i].companyId
             };
    

    
             API.activeSponsoredOfferCampaign(userInfo.email,offerBody);
            
}
}
if(form.campaignFor=="product")
          {
for(var i=0;i<totalSelectedProduct.length;i++)
          {
            let productBody = {
              id:totalSelectedProduct[i].id,
              storeId:form.storeId,
              budget:form.budget,
              cpc:form.cpc,
              sponsoredCampaignId:campaognResult.data.id,
              sponsoredCampaignName:form.campaignName,
              sponsoredType:form.sponsoredType,
              keywords:selectedKeyword,
              sponsoredSection:selectedSection,
              sponsoredValidFrom:validFrom,
              sponsoredValidTill:toDate,
              //products:totalSelectedProduct[i].products,
              //createdBy:userInfo.email,
              updatedBy:userInfo.email,
              companyId:totalSelectedProduct[i].companyId
             };
      
              
               API.activeSponsoredProductCampaign(userInfo.email,productBody);
              }
            }
      toast.success("yah!! sponsored campaign created successfully")
       setTimeout(() => { 
      //  history.push(`/home/campaign/manage?id=`+campaognResult.data.id+"&type=sponsored")
      history.push(`/home/campaign/campaignList`)
        //eventBus.dispatch("campaignCreated", { message: "campaign created" });
       // toast.success("yah!! offer live successfully")
      
      }, 3000);
      })
      .catch((error) => {
        console.log(error);
        alert(error);
     });



    }

  };

  const handleCityChange = (selectedOptions) => {
    form.cityList = [];
    console.log("selectedOptions>>>>>>>>>>>>>>>>", selectedOptions)
    setSelectedCity(selectedOptions)
    selectedOptions.map((item1) => {
    form.cityList.push(item1.value);
    });
    //form.weeklyOff=selectedOptions.value;
    // console.log("selectedOptions>>>>>>>>>>>>>>>>",form.mainCategory)

  };

  const handleStateChange = (selectedOptions) => {
    form.stateList = [];
    console.log("selectedOptions>>>>>>>>>>>>>>>>", selectedOptions)
    setSelectedState(selectedOptions)
    var totalCity=0;
    selectedOptions.map((item1) => {
    form.stateList.push(item1.value);
    API.getCitiesByState("gust",item1.value).then((result) => {  
       
      if(result.data!=undefined && result.data.length>0)
      {
        totalCity=totalCity+result.data.length
        setstateCityCount(totalCity);
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });
    });
    
  
    //form.weeklyOff=selectedOptions.value;
    // console.log("selectedOptions>>>>>>>>>>>>>>>>",form.mainCategory)

  };
  const  handlePlanChange= (selectedOptions) => {
    console.log("selectedPlan>>>>>>>>>>>>>>>>",selectedOptions.target.value)
    campaignPlan.map((item, index) => (
      selectedOptions.target.value==item.id?setSelectedCampaignPlan(item):""

      ))
     
      setCustomPlan(false);
      console.log("setSelectedCampaignPlan>>>>>>>>>>>>>>>>",selectedCampaignPlan)
  };
  

  const  handleApplyPromocode= (selectedOptions) => {

    if(form.promocode!=undefined && form.promocode!=null && form.promocode!='')
    {
    API.checkPromocode(form.promocode).then((result) => {  
       
      console.log("promocode response>>>>>>>>>>>>>>>>",result.data)
      if(result.data!=undefined)
      {
        if(result.data!='' && result.data!=null)
        {
          toast.success("promocode apply successfully");
          setDiscountValue(result.data.discount)
          setDiscountType(result.data.type)

        }
        else
        {
          toast.error("Invalid promocode");
        }
       
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });
        }
        else
        {
          toast.error("Please enter promocode");
        }
    
      console.log("handleApplyPromocode>>>>>>>>>>>>>>>>",selectedCampaignPlan)
  };

  const  handleRemovePromocode= (selectedOptions) => {
    setDiscountValue(0)
    toast.success("promocode remove successfully");

  };

  let env = 'sandbox'; // you can set here to 'production' for production
  let currency = 'CAD'; // or you can set this value from your props or state
  let total =0; // same as above, this is the total amount (based on currency) to be paid by using Paypal express checkout
  let totalPerItem=0
  let invoiceAmount=0;
  let invoiceTax=[];
  let discountAmount=0;
  let discountPerItem=0;
  let paymentTotal=0;
  let totalAferDiscount =0
  let totalTax=0;
  if(selectedCampaignPlan!=undefined && selectedCampaignPlan!='' && selectedCampaignPlan!=null)
  {
    if(form.promotionArea == "city")
    {
      console.log("total item>>>>>>>>>>",totalItem);
      if(totalItem>minItem)
      {
      total=(selectedCampaignPlan.price*cityList.length)*totalItem
      totalPerItem=(selectedCampaignPlan.price*cityList.length)
      }
      else
      {
        total=(selectedCampaignPlan.price*cityList.length)
      totalPerItem=(selectedCampaignPlan.price*cityList.length)/totalItem
      
      }
    }
    else if(form.promotionArea == "state")
    {
      console.log("total item>>>>>>>>>>",totalItem);
      if(totalItem>minItem)
      {
      total=(selectedCampaignPlan.price*stateCityCount)*totalItem
      totalPerItem=(selectedCampaignPlan.price*stateCityCount)
    }
    else
    {
      total=(selectedCampaignPlan.price*stateCityCount)
      totalPerItem=(selectedCampaignPlan.price*stateCityCount)/totalItem
    }
    }
    else if(form.promotionArea == "country")
    {
      if(totalItem>minItem)
      {
      total=(selectedCampaignPlan.price*cities.length)*totalItem
      totalPerItem=(selectedCampaignPlan.price*cities.length)
      }
      else
    {
      total=(selectedCampaignPlan.price*cities.length)
      totalPerItem=(selectedCampaignPlan.price*cities.length)/totalItem
    }

      invoiceAmount=total;
    }

    totalAferDiscount=total;//for futher apply discount
    paymentTotal=total; // for send to payment gateway with futher add tax
    if(discountValue!=undefined && discountValue!=null && discountValue!=0)
    {
      if(discountType=="F")
      {
        discountAmount=discountValue;
        discountPerItem=discountValue;
      }
      else
      {
        discountAmount=(total*discountValue)/100
        discountPerItem=(totalPerItem*discountValue)/100
      } 

      console.log("discountAmount>>>>>>>>>>>>>>>",discountAmount)
      console.log("total>>>>>>>>>>>>>>>",total)
      totalAferDiscount=totalAferDiscount-discountAmount;
      paymentTotal=paymentTotal-discountAmount;
      totalPerItem=totalPerItem-discountPerItem;

    } 
       

                
                if(form.campaignFor=='offer' && totalSelectedOffer!=undefined )
                {
                for(var i=0;i<totalSelectedOffer.length;i++)
                {    console.log("itemTaxAmount[i]>>>>>>>>>>>>>>>",itemTaxAmount[i])
                    if(itemTaxAmount[i]!=undefined)
                    {
                      var taxAmount=(totalAferDiscount*Number(itemTaxAmount[i]))/100;
                        invoiceTax[i]=taxAmount;
                        totalTax=totalTax+taxAmount;
                    }
                    else
                    invoiceTax[i]=0;
                        
              }
            }

            if(form.campaignFor=='product'&&  totalSelectedProduct!=undefined)
                {
                for(var i=0;i<totalSelectedProduct.length;i++)
                {   
                      var taxAmount=(totalAferDiscount*Number(itemTaxAmount[i]))/100;
                        invoiceTax[i]=taxAmount;
                        totalTax=totalTax+taxAmount;
                      
              }
            }


 // total=total+totalTax;
  paymentTotal=paymentTotal+totalTax;
  console.log("total  tax>>>>>>>>>>>>>>>",totalTax)


      if(total!=undefined && total!=null)
      {
        total=Math.round((total + Number.EPSILON) * 100) / 100
        totalPerItem=Math.round((totalPerItem + Number.EPSILON) * 100) / 100
        paymentTotal=Math.round((paymentTotal + Number.EPSILON) * 100) / 100
        totalAferDiscount=Math.round((totalAferDiscount + Number.EPSILON) * 100) / 100
        totalTax=Math.round((totalTax + Number.EPSILON) * 100) / 100
        console.log("total after round>>>>>>>>>>>>>>>",total)
      }
       

  }
  
  // Document on Paypal's currency code: https://developer.paypal.com/docs/classic/api/currency_codes/

  const client = {
      sandbox:    'AdElUsjWuE6MJ9FqCG78ig4hYeUswF3avBEkOa3lXt6ayBxKl0Jp1nEepFePV053MFbQOEzVaJ2FneMm',
      production: 'YOUR-PRODUCTION-APP-ID',
  }



  const onSuccess = (payment) => {
    // Congratulation, it came here means everything's fine!
        console.log("The payment was succeeded!", payment);
        var paymentType="";
        var paymentId="";
        var payerId="";
        var paymentStatus="";
        if(payment.intent!=undefined && payment.intent!=null && payment.intent=="CAPTURE")
        {
          paymentType="Card";
          paymentId=payment.id;
          payerId=payment.payer.payer_id;
          if(payment.status=="COMPLETED")
          {
            paymentStatus="Paid"
          }
          else
          {
            paymentStatus="Fail"
          }
        }
        else
        {
          paymentType="PayPal";
          paymentId=payment.paymentID;
          payerId=payment.payerID; 
          if(payment.paid)
          {
            paymentStatus="Paid"
          }
          else
          {
            paymentStatus="Fail"
          }
        }
        var validFrom=new Date();
        var toDate=new Date(validFrom);
        toDate.setDate(toDate.getDate()+ Number(selectedCampaignPlan.days));
        console.log("The payment validFrom", validFrom);
        console.log("The payment was toDate!", toDate);
        
        let campaignBody = {
          id:campagainId!=undefined && campagainId!=null && campagainId!=''?campagainId:uuid(),
          campaignName:form.campaignName,
          offers:totalSelectedOffer,
          products:totalSelectedProduct,
          userId:userInfo.email,
          companyId:form.companyId!=undefined && form.companyId!=null && form.companyId!=''?form.companyId:loggedUserInfo.companyId,
          storeId:form.storeId,
          campaignType:campagainType,
          campaignFor:form.campaignFor,
          campaignPrice:totalAferDiscount,
          campaignArea:form.promotionArea,
          cityList:cityList,
          stateList:stateList,
          budget:form.budget,
          validFrom:validFrom,
          validTill:toDate,

         };

        API.createCampaign(userInfo.email,campaignBody).then((campaognResult) => {  
          console.log("totalSelectedOffer>>>>>>>>>>>>>",totalSelectedOffer);
          if(form.campaignFor=="offer")
          {
            let ptBody = {
              id:uuid(),
              transactionType:"Offer Campaign",
              status:paymentStatus,
              offerId:totalSelectedOffer,
              userId:userInfo.email,
              companyId:totalSelectedOffer[0].companyId,
              storeId:form.storeId,
              paymentId:paymentId,
              payerId:payerId,
              paymentType:paymentType,
              campaignId:campaognResult.data.id,
              amount:paymentTotal,
             };
    
             let invoiceBody = {
              id:uuid(),
              invoiceType:"Offer Campaign",
              status:paymentStatus,
              offerId:totalSelectedOffer,
              userId:userInfo.email,
              companyId:totalSelectedOffer[0].companyId,
              storeId:form.storeId,
              paymentId:paymentId,
              payerId:payerId,
              paymentType:paymentType,
              paymentAmount:paymentTotal,
              amount:total,
              discountAmount:discountAmount,
              taxAmount:totalTax,
              campaignId:campaognResult.data.id,
              gst:gstTax,
              hst:hstTax,
              pst:pstTax,
              qst:qstTax,
             };
             API.createPaymentTransaction(userInfo.email,ptBody);
      
             API.createInvoice(userInfo.email,invoiceBody);
          for(var i=0;i<totalSelectedOffer.length;i++)
          {
              let offerBody = {
                id:totalSelectedOffer[i].id,
                heading:totalSelectedOffer[i].heading,
                subHeading:totalSelectedOffer[i].subHeading,
                description:totalSelectedOffer[i].description,
               validFrom:validFrom,
                validTill:toDate,
                fullImage:totalSelectedOffer[i].fullImage,
                active:"Y",
                isPrimary:totalSelectedOffer[i].isPrimary,
                categories:totalSelectedOffer[i].categories,
                storeId:form.storeId,
                storeName:totalSelectedOffer[i].storeName,
                logo:totalSelectedOffer[i].logo,
                logoSmall:totalSelectedOffer[i].logoSmall,
                logoMedium:totalSelectedOffer[i].logoMedium,
                totalRating:totalSelectedOffer[i].totalRating,
                avgRating:totalSelectedOffer[i].avgRating,
                vendorUrl:totalSelectedOffer[i].vendorUrl,
                totalLikes:totalSelectedProduct[i].totalLikes,
                type:totalSelectedOffer[i].type,
                isShopifyCollection:totalSelectedOffer[i].isShopifyCollection,
              shopifySyncId:totalSelectedOffer[i].shopifySyncId,
              shopifyCollectionId:totalSelectedOffer[i].shopifyCollectionId,
                webStoreName:totalSelectedOffer[i].webStoreName,
                campaignArea:form.promotionArea,
                cityList:cityList,
                stateList:stateList,
                budget:form.budget,
                cpc:form.cpc,
                campaignId:campaognResult.data.id,
                campaignName:form.campaignName,
                products:totalSelectedOffer[i].products,
                //createdBy:userInfo.email,
                updatedBy:userInfo.email,
                companyId:totalSelectedOffer[i].companyId
               };
      

      
               API.activeOfferCampaign(userInfo.email,offerBody);
              
              }
            }

            if(form.campaignFor=="product")
            {
              let ptBody = {
                id:uuid(),
                transactionType:"Product Campaign",
                status:paymentStatus,
                productId:totalSelectedProduct,
                userId:userInfo.email,
                companyId:totalSelectedProduct[0].companyId,
                storeId:form.storeId,
                paymentId:paymentId,
                payerId:payerId,
                paymentType:paymentType,
                campaignId:campaognResult.data.id,
                amount:paymentTotal,
               };
      
               let invoiceBody = {
                id:uuid(),
                invoiceType:"Product Campaign",
                status:paymentStatus,
                productId:totalSelectedProduct,
                userId:userInfo.email,
                companyId:totalSelectedProduct[0].companyId,
                storeId:form.storeId,
                paymentId:paymentId,
                payerId:payerId,
                paymentType:paymentType,
                paymentAmount:paymentTotal,
                amount:total,
                discountAmount:discountAmount,
                taxAmount:totalTax,
                campaignId:campaognResult.data.id,
                gst:gstTax,
                hst:hstTax,
                pst:pstTax,
                qst:qstTax,
               };
               API.createPaymentTransaction(userInfo.email,ptBody);
        
               API.createInvoice(userInfo.email,invoiceBody);

            for(var i=0;i<totalSelectedProduct.length;i++)
            {
                let productBody = {
                  id:totalSelectedProduct[i].id,
                  name:totalSelectedProduct[i].name,
                  heading:totalSelectedProduct[i].heading,
                  brand:totalSelectedProduct[i].brand,
                  description:totalSelectedProduct[i].description,
                  vendorUrl:totalSelectedProduct[i].website,
                  actualPrice:totalSelectedProduct[i].price,
                  offerPrice:totalSelectedProduct[i].offerPrice,
                  discountPerc:totalSelectedProduct[i].discount,
                  validFrom:validFrom,
                  validTill:toDate,
                  offerId:totalSelectedProduct[i].offerId,
                  fullImage:totalSelectedProduct[i].fullImage,
                  images:totalSelectedProduct[i].images,
                  logo:totalSelectedProduct[i].logo,
                  logoSmall:totalSelectedProduct[i].logoSmall,
                  logoMedium:totalSelectedProduct[i].logoMedium,
                  active:"Y",
                  categories:totalSelectedProduct[i].categories,
                  mainCategory:totalSelectedProduct[i].mainCategory,
                  discountType:totalSelectedProduct[i].discountType,
                  discountMessage:totalSelectedProduct[i].discountMessage,
                  storeId:totalSelectedProduct[i].storeId,
                  storeName:totalSelectedProduct[i].storeName,
                  totalRating:totalSelectedProduct[i].totalRating,
                  avgRating:totalSelectedProduct[i].avgRating,
                  totalLikes:totalSelectedProduct[i].totalLikes,
                  attribute:totalSelectedProduct[i].attribute,
                  shippingType:totalSelectedProduct[i].shippingType,
                  shippingInstructions:totalSelectedProduct[i].shippingInstructions,
                  webStoreName:totalSelectedProduct[i].webStoreName,
                  isShopifyProduct:totalSelectedProduct[i].isShopifyProduct,
                  shopifySyncId:totalSelectedProduct[i].shopifySyncId,
                  shopifyProductId:totalSelectedProduct[i].shopifyProductId,
                  campaignArea:form.promotionArea,
                  cityList:cityList,
                  stateList:stateList,
                  budget:form.budget,
                  cpc:form.cpc,
                  campaignId:campaognResult.data.id,
                  campaignName:form.campaignName,
                  products:totalSelectedProduct[i].products,
                  //createdBy:userInfo.email,
                  updatedBy:userInfo.email,
                  companyId:totalSelectedProduct[i].companyId
                 };
        
                
                 API.activeProductCampaign(userInfo.email,productBody);
                }
              }
              toast.success("yah!! offer live successfully")
         setTimeout(() => { 
          history.push(`/home/campaign/manage?id=`+campaognResult.data.id)
          eventBus.dispatch("campaignCreated", { message: "campaign created" });
         // toast.success("yah!! offer live successfully")
        
        }, 3000);
        })
        .catch((error) => {
          console.log(error);
          alert(error);
       });
       
         

        // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
}

const onCancel = (data) => {
    // User pressed "cancel" or close Paypal's popup!
    console.log('The payment was cancelled!', data);
    toast.error("Payment cancelled")
    for(var i=0;i<totalSelectedOffer.length;i++)
    {
    let ptBody = {
      id:uuid(),
      transactionType:"Offer Campaign",
      status:"Cancelled",
      offerId:totalSelectedOffer[i].id,
      userId:userInfo.email,
      companyId:totalSelectedOffer[i].companyId,
      storeId:totalSelectedOffer[i].storeId,
      paymentId:data.paymentID!=undefined && data.paymentID!='' && data.paymentID!=null?data.paymentID:data.orderID,
      payerId:data.payerID,
      paymentType:"PayPal",
      amount:paymentTotal,
     };
     API.createPaymentTransaction(userInfo.email,ptBody);
    }
    // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
}

const onError = (err) => {
    // The main Paypal's script cannot be loaded or somethings block the loading of that script!
    toast.error("Error,please try again")
    console.log("Error!", err);
    for(var i=0;i<totalSelectedOffer.length;i++)
    {
    let ptBody = {
      id:uuid(),
      status:"Error",
      transactionType:"Offer Campaign",
      offerId:totalSelectedOffer[i].id,
      userId:userInfo.email,
      companyId:totalSelectedOffer[i].companyId,
      storeId:totalSelectedOffer[i].storeId,
      paymentId:err.paymentID,
      payerId:err.payerID,
      paymentType:"PayPal",
      amount:paymentTotal,
     };
     API.createPaymentTransaction(userInfo.email,ptBody);
    }
    // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
    // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
}


const getCompanyBranches = async (e) => {
  console.log("event>>>>>>>>>",e.target.value);
  if(e.target.value!=undefined && e.target.value!='')
  {
    form.companyId=e.target.value
    getStoreByCompany(e.target.value);
      }
      
};

const handleBranchChanage = async (e) => {
  console.log("event>>>>>>>>>",e.target.value);
  if(e.target.value!=undefined && e.target.value!='')
  {
    form.storeId=e.target.value
    setSelectedStore(e.target.value);
     //***********************get offer data */   
     API.getCompanyBranchOffer(e.target.value,"active").then((result) => {  
       
      if(result.data!=undefined && result.data.length>0)
      {
        setPendingCompanyOffer(result.data)
      }
      else
      {
        setPendingCompanyOffer([])
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });        

          API.getCompanyBranchProduct(e.target.value,"active").then((productResult) => {  
                   
            if(productResult.data!=undefined && productResult.data.length>0)
            {
              setProductList(productResult.data);
          
            }
            else
            {
              setProductList([]);
            }
                 })
                 .catch((error) => {
                   console.log(error);
                   alert(error);
                });
    //getStoreByCompany(e.target.value);
      }
      
};

const handleCategoryChanage = async (e) => {
  console.log("event>>>>>>>>>",e.target.value);
  if(e.target.value!=undefined && e.target.value!='')
  {
   // setSelectedCategory(e.target.value);
    form.category=e.target.value;
    if(form.tab!=''&& form.tab!=undefined)
    {
      API.getSectionByCategory(e.target.value,form.tab).then((sectionResult) => {  
                   
        if(sectionResult.data!=undefined && sectionResult.data.length>0)
        {
          setSectionList(sectionResult.data);
      
        }
        else
        {
          setSectionList([]);
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            });
    }
    //form.categoryName=e.nativeEvent.target[index].text;
  }
};


const checkedSection =  (e) => {
  if(e.target.checked)
        {
      //  api call here
      setSponsoredTypeSection1(true);
      form.sponsoredTypeSection="section"
        }
        else
        {
          form.sponsoredTypeSection=""
          setSponsoredTypeSection1(false);
        }

};

const checkedKeyWord =  (e) => {
  if(e.target.checked)
        {
      //  api call here
      setSponsoredTypeKeyword1(true);
      form.sponsoredTypeKeyword="keyword"
        }
        else
        {
          form.sponsoredTypeKeyword=""
          setSponsoredTypeKeyword1(false);
        }

};

function sectionModal() {

  if(form.campaignFor==undefined|| form.campaignFor==null || form.campaignFor=="")
  {
toast.error("First select Sponsored Campaign for either product or offer")
  }
  else
  {
  setIsOpen(!isOpen);
  }

}
function handleSectionClose() {
  setIsOpen(false);
}

function keywordModal() {

  setIsOpen1(!isOpen1);

}
function handleKeywordClose() {
  setIsOpen1(false);
}

const selectSection = (section) => {
  console.log("selected section>>>>>>",section);
  setSelectedSection(section);
  //this.setState({language: langValue});
}

const selectKeyword = (keyword) => {
  console.log("selected keyword>>>>>>",keyword);
  setSelectedKeyword(keyword);
  //this.setState({language: langValue});
}


  return (
    <Form noValidate validated={validated} action="#">
      <h4 class="mb-3"><strong>Start Sponsored Campaign</strong></h4> 
      <div className="row mb-4">
      <MuiAlert className="ml-4" severity="info">There are no charges and limits on creating sponsored campaign during the beta period.</MuiAlert>
        <div className="col-md-6 mt-2">
          <div className="bg-white p-4 shadow-sm rounded-lg">
          {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")) ? (
            <Form.Group>
        <Form.Control
          as="select"
          className=" mr-3"
          name="company"
          value={companyId}
          onChange={getCompanyBranches}
          required
        >
          <option>Select Company</option>
          {companyList.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Form.Control>
        </Form.Group>
         ) : null}
         <Form.Group>
          
          <Form.Control as="select" name="storeId" value={selectedStore} 
          //onChange={setForm} 
          onChange={handleBranchChanage}
          required 
          className="mr-sm-2" custom>
          <option value="">Select Branch</option>
              {storeData.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.code}
                </option>
              ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid" >Please select branch</Form.Control.Feedback>
        </Form.Group>
          <Form.Group controlId="validationfirstName">
            <Form.Label>Enter Campaign Name</Form.Label>
            <Form.Control
              name="campaignName"
              type="text"
             value={campaignName}
              onChange={setForm}
              //onChange={e =>  form.campaignName=e.target.value }
              required
            />
          </Form.Group>

          <p>Create Sponsored Campaign for</p>
          <Form.Group>
            <Form.Check
              inline
              label="Offer"
              value="offer"
              name="campaignFor"
              checked={form.campaignFor=='offer'?true:false}
              type="radio"
              onChange={setForm}
              id="offer"
            />
            <Form.Check
              inline
              label="Product"
              value="product"
              name="campaignFor"
              checked={form.campaignFor=='product'?true:false}
              type="radio"
              onChange={setForm}
              id="product"
            />
            </Form.Group>

            {form.campaignFor == "offer"  && (
            <MUIDataTable
            title={"Select Offers"}
            data={OfferData}
            columns={offerColumns}
            options={offerOptions}
          />
         )}

         
      {form.campaignFor == "product" && (
                  <MUIDataTable
                  title={"Select Product"}
                  data={productData}
                  columns={productColumns}
                  options={productOptions}
                />
              )}
         
            <br/>
            
          <p>Sponsored Type</p>
          <Form.Group>
            <Form.Check
              inline
              label="Section"
              //value="Section"
              name="sponsoredTypeSection"
              checked={sponsoredTypeSection1}
              type="checkbox"
              onChange={checkedSection}
              id="sponsoredTypeSection"
            />
            <Form.Check
              inline
              label="Keywords"
             // value="Keywords"
              name="sponsoredTypeKeyword"
              checked={sponsoredTypeKeyword1}
              type="checkbox"
              onChange={checkedKeyWord}
              id="sponsoredTypeKeyword"
            />
            </Form.Group>

            {sponsoredTypeSection == "section"  && (
           selectedSection!=undefined&&selectedSection!=null&&selectedSection.length>0?
           <Button  variant="outlined"><a href="#" className="text-secondary" onClick={sectionModal} >Click here to update  Section</a></Button>
           :<Button  variant="outlined"><a href="#" className="text-secondary" onClick={sectionModal} >Click here to configure  Section</a></Button>
          
         )}
            {sponsoredTypeKeyword == "keyword"  && (
           selectedKeyword!=undefined&&selectedKeyword!=null&&selectedKeyword.length>0?
           <Button  variant="outlined" className="ml-2"><a href="#" className="text-secondary" onClick={keywordModal} >Click here to update keyword</a></Button>
           :<Button  variant="outlined" className="ml-2"><a href="#" className="text-secondary" onClick={keywordModal} >Click here to configure keyword</a></Button>
          
         )}

     

           
         
          
            <InputGroup className="mb-3 mt-2">
                   <Form.Group className="col-6">
                   <Form.Label>Enter Budget <OverlayTrigger placement="top" overlay={renderTooltip}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
</svg>
      </OverlayTrigger></Form.Label>
                   
                   <Form.Control 
                   name="budget"
                   value={budget}
                   onChange={setForm}
                   type="number"
                   required
                   />
                    <Form.Control.Feedback type="invalid" >Please enter budget</Form.Control.Feedback>
                 </Form.Group>
                 
                 </InputGroup>

              
                 <InputGroup className="mb-3">
                   <Form.Group className="col-6">
                   <Form.Label>From Date <OverlayTrigger placement="top" overlay={renderTooltip1}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
</svg>
      </OverlayTrigger></Form.Label>
                   <Form.Control 
                   name="fromDate"
                   value={fromDate}
                   onChange={setForm}
                   type="date" 
                   required
                   />
                    <Form.Control.Feedback type="invalid" >Please enter Valid from</Form.Control.Feedback>
                 </Form.Group>
         
                 <Form.Group className="col-6">
                   <Form.Label>To Date</Form.Label>
                   <Form.Control  name="toDate"
                   value={toDate}
                   onChange={setForm}
                   type="date"
                   required
                   />
                    <Form.Control.Feedback type="invalid" >Please enter valid till</Form.Control.Feedback>
                 </Form.Group>
                 
                 </InputGroup>
              
                 <div className="text-center">
                 <Button variant="contained" color="primary" onClick={(e) =>createCustomPlan(e)} className="btn btn-primary">Save</Button>
                 </div>
                 {/* ):""}*/}
          </div>
        </div>

        <div className="col-md-6">
            <div >
              {/*<h5>Payment Summary</h5>*/}
              {selectedCampaignPlan!=undefined && selectedCampaignPlan!='' && selectedCampaignPlan!=null && paymentTotal>0 ?  (
                <div>
                  <div className="amount-wrapper bg-white">
              <div className="reach">Your ad will run for {selectedCampaignPlan.days} days</div>
              <div className="budget">
                <div>
                  <h6>Total Budget</h6>
                 {/* <div>$2.00 a day x {selectedCampaignPlan.days} days</div>*/}
                </div>
               
                  <div className="total">${total}</div>
               
              </div>
              {discountValue!=undefined && discountValue!=null && discountValue!=0 ?  (
              <div>
              <div className="budget">
                <div>
                  <h6>Discount</h6>
                 {/* <div>$2.00 a day x {selectedCampaignPlan.days} days</div>*/}
                </div>
                
                  <div className="total">{discountType=="F"? "$"+discountValue: discountValue+"%"}</div>
                 
              </div>
              <div className="budget">
                <div>
                  <h6>Total Budget after Discount</h6>
                 {/* <div>$2.00 a day x {selectedCampaignPlan.days} days</div>*/}
                </div>
               
                  <div className="total">${totalAferDiscount}</div>
                 
              </div>
              </div>
              ):""}
                    </div>


                    {form.promotionArea == "state" || form.promotionArea == "country" ?  (
                      <div>
                        <InputGroup className="mb-3">
              <Form.Group className="ml-4">
        <Form.Label >Enter promoccode (if you have)</Form.Label>
        <Form.Control
          type="text"
          readOnly={discountValue!=undefined && discountValue!=null && discountValue!=0?true:false}
          name="promocode"
          value={promocode}
          onChange={setForm}
        />
      </Form.Group>
      <Form.Group  className="ml-2 mt-4">
      <Form.Label ></Form.Label>
      {discountValue==undefined || discountValue==null || discountValue==0 ?  (
      <Button className=" ml-1" color="primary" onClick={handleApplyPromocode}>
      Apply Promocode
    </Button>
      
         ):<Button className=" ml-1" color="primary" onClick={handleRemovePromocode}>
         Remove Promocode
       </Button>}
       </Form.Group>
       </InputGroup>
        </div>
         ): ""}
         
{selectedCampaignPlan!=undefined && selectedCampaignPlan!='' && selectedCampaignPlan!=null && paymentTotal>0 ?  (
  <div class="float-center mb-4">
{/* <PaypalExpressBtn  env={env} client={client} currency={currency} total={total} onError={onError} onSuccess={onSuccess} onCancel={onCancel} />*/}
<PayPalButton  options={clientNew}  shippingPreference={"NO_SHIPPING"} vault={false} amount={paymentTotal} onError={onError} onSuccess={onSuccess} onCancel={onCancel} />
</div>
):""}
              </div>

              
             // ):"Please set plan for payment."}
             ):""}
            </div>
          </div>
        </div>



     {/* <div className="row">
        <div className="col-md-6">
          <div className="amount-wrapper bg-white">
            <h5>Ad Library visibility
              
            </h5>
            <div className="reach">Your ad and information from your Marketplace profile may appear publicly in Ad Library search results. <a href="">Learn more</a></div>
          </div>
        </div>
  </div>*/}

       {/* <div className="row">
        <div className="col-md-6">
          <div className="amount-wrapper bg-white">
            <h5>Payment Method</h5>
            <div className="reach">You may receive more then one bill for this ad. Learn more <a href="">Learn more</a></div>
            <div className="card-deatils">
              <div className="card-side">
              <img src="https://static-00.iconduck.com/assets.00/visa-icon-512x322-wb39y8f5.png" alt="" className="mr-3" />
              <b>....4383</b>
              </div>
              <Button variant="contained" class="btn btn-secondary  ">Change</Button>
            </div>
          </div>
        </div>
      </div>*/}

    { /* <div className="promote-now amount-wrapper bg-white d-flex justify-content-between flex-wrap align-items-center">
        <div>By clicking Promote Now, you agree to Offer Dhamaka <a href="">Terms & Condition</a> | <a href="">Help Center</a></div>

        {selectedCampaignPlan==undefined || selectedCampaignPlan=='' || selectedCampaignPlan==null ?  (
      <Button variant="contained" color="primary" onClick={(e) =>handleSubmit(e)} className="btn btn-primary">Promote Now</Button>
      ):""}


      </div>*/}
      <Modal
        show={isOpen}
        onHide={handleSectionClose}
        centered
        size="xl"
      >
        <SelectSponsoredSection onSelectSection={selectSection} selectedSection={selectedSection}  campaignFor={form.campaignFor} setIsOpen={setIsOpen}  />;
      </Modal>
      <Modal
        show={isOpen1}
        onHide={handleKeywordClose}
        centered
        size="xl"
      >
        <SelectSponsoredKeyword onSelectKeyword={selectKeyword} selectedKeyword={selectedKeyword}  setIsOpen1={setIsOpen1}  />;
      </Modal>

      <ToastContainer />
    </Form>
  )
}

export default SponsoredPromotion

// const style = {
//   form: {
//     backgroundColor: "white",
//     padding: "25px",
//     boxShadow: "0 0 15px silver",
//   },
// }
