import React from "react"
import category from '../../assets/cat.PNG'
import signuppng from '../../assets/sign-up.png'
import storeinfo from '../../assets/store-info.png'
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import NavigationIcon from '@material-ui/icons/Navigation';
import Fab from '@material-ui/core/Fab';
import Sidebar from "./Sidebar"
import SearchBar from "./SearchBar"
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'




const useStylesTop = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));



// const FormContainer = styled.form`
//   width: 400px;
//   background-color: #fff;
//   padding: 50px;
//   margin-top: 150px;
//   box-shadow: 0 0 20px silver;
//   border-radius: 10px;
// `
// const RegisterLink = styled(Link)`
//   position: absolute;
//   top: 0;
//   left: 0;
//   margin: 10px 25px;
//   background-color: #fff;
//   border-radius: 10px;
//   box-shadow: 0 0 20px silver;
//   padding: 25px;
//   :hover {
//     text-decoration: none;
//   }
// `


function ForgotPasswordHelp() {

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  const classesTop = useStylesTop();

  

  const history = useHistory();

  const handleSubmit = async (e) => {

    history.push(`/login`)
  }

  return (
    <>
            <SearchBar/>

      <div className={classes.root}>
        <AppBar position="static" color="#fff" position="sticky">
          <div className="container-fluid px-3 px-lg-5">
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" indicatorColor="primary">
              <Tab label="Functional">Functional</Tab>
              <Tab label="Technical"></Tab>
            </Tabs>
          </div>
        </AppBar>
        <TabPanel value={value} index={0}>
          <div className="container-fluid px-0 px-lg-5">
            <div className="row mt-3">
              <div className="col-md-3 ">
                <Sidebar/>

              </div>

              <div className="col-md-9">
                <div className="help-content">
                <section id="Forgot-Password">
                    <h3>Forgot Password</h3>
                    <p>In case if the user has forgotten the password to login, then the user can click on the Forgot Password link from the login screen to reset the password. Below are the steps to reset the password. </p>
                    <Zoom>
                                            <picture>
                            <source media="(max-width: 800px)" srcSet={require('../../assets/pas1.PNG').default} />
                            <img
                              alt="that wanaka tree"
                              src={require('../../assets/pas1.PNG').default}
                              width="500"
                            />
                          </picture>

                          </Zoom>    

                  
                    <ul>
                      <li>Go to the login screen.</li>
                      <li>Click on the Forgot Password link. </li>
                      <li>In the forgot password screen, enter the Email ID.</li>
                      <li>Click on the Submit button.</li>
                      <li>Go to your mail box and click on the reset password link</li>
                    </ul>
                    <p>From the reset password screen, the user can enter a new password and click on the Save button. </p>
                    <Zoom>
                                            <picture>
                            <source media="(max-width: 800px)" srcSet={require('../../assets/pas2.png').default} />
                            <img
                              alt="that wanaka tree"
                              src={require('../../assets/pas2.png').default}
                              width="500"
                            />
                          </picture>

                          </Zoom>  
                    <p>A success message is displayed and the user can proceed to the login screen to login with the newly created password to access ShopiAds.</p>
                    <Zoom>
                                            <picture>
                            <source media="(max-width: 800px)" srcSet={require('../../assets/pas3.png').default} />
                            <img
                              alt="that wanaka tree"
                              src={require('../../assets/pas3.png').default}
                              width="500"
                            />
                          </picture>

                          </Zoom>  
                  </section>

                </div>

              </div>

            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          Technical
        </TabPanel>
      </div>

      <div className="backtoptop">
        <a href="#top">
          <Fab variant="extended">
            <NavigationIcon className={classesTop.extendedIcon} />
            Top
          </Fab>
        </a>
      </div>




    </>
  )
}

export default ForgotPasswordHelp