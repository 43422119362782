import React from "react"

import category from '../../assets/cat.PNG'
import signuppng from '../../assets/sign-up.png'
import storeinfo from '../../assets/store-info.png'
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


import NavigationIcon from '@material-ui/icons/Navigation';
import Fab from '@material-ui/core/Fab';
import Sidebar from "./Sidebar"
import SearchBar from "./SearchBar"
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'




const useStylesTop = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const useStylesAcord = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {

        fontWeight: theme.typography.fontWeightRegular,
    },
}));

// const FormContainer = styled.form`
//   width: 400px;
//   background-color: #fff;
//   padding: 50px;
//   margin-top: 150px;
//   box-shadow: 0 0 20px silver;
//   border-radius: 10px;
// `
// const RegisterLink = styled(Link)`
//   position: absolute;
//   top: 0;
//   left: 0;
//   margin: 10px 25px;
//   background-color: #fff;
//   border-radius: 10px;
//   box-shadow: 0 0 20px silver;
//   padding: 25px;
//   :hover {
//     text-decoration: none;
//   }
// `


function Collection() {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const classesTop = useStylesTop();

    

    const history = useHistory();

    const handleSubmit = async (e) => {

        history.push(`/login`)
    }

    return (
        <>
<SearchBar/>

            <div className={classes.root}>
                <AppBar position="static" color="#fff" position="sticky">
                    <div className="container-fluid px-3 px-lg-5">
                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" indicatorColor="primary">
                            <Tab label="Functional">Functional</Tab>
                            <Tab label="Technical"></Tab>
                        </Tabs>
                    </div>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <div className="container-fluid px-0 px-lg-5">
                        <div className="row mt-3">
                            <div className="col-md-3 ">
                            <Sidebar/>

                            </div>

                            <div className="col-md-9">
                                <div className="help-content">
                                <section id="Collections">
                    <h3>Collections</h3>
                    <p>When creating a new offer, users can also select the option “Collection” to create a new collection. So, what is the difference between an offer and a collection? We will be discussing this in detail here.</p>
                    <p>Stores in order to attract customers, would offer products at reduced price as a part of an offer sale. By this way, they believe they can get more sales and more business.</p>
                    <p>A collection is different from an offer. Here, stores list products as collections without giving any special discount. Stores will list one or more products as a part of collections and these products will be displayed in the gallery. The users can click on the product image to go into the product details screen and know more about the product.</p>
                    <p>To create a new collection, the user needs to follow below steps</p>
                    <ul>
                      <li>Click on the Offer menu link.</li>
                      <li>Click on the Add Offer/Collection button.</li>
                      <li>In the offer details screen, select a branch and then click on the radio button next to Collection.</li>
                      <li>Enter an heading for the collection</li>
                      <li>Click on the drop down in Mapped Products to this Collection and select a product from the list.</li>
                      <li>Click on the Add Collection button.</li>
                    </ul>
                   

                    <Zoom>
                                            <picture>
                            <source media="(max-width: 800px)" srcSet={require('../../assets/collection.PNG').default} />
                            <img
                              alt="that wanaka tree"
                              src={require('../../assets/collection.PNG').default}
                              width="500"
                            />
                          </picture>

                          </Zoom>    

                  </section>

                                </div>

                            </div>

                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    Technical
                </TabPanel>
            </div>

            <div className="backtoptop">
                <a href="#top">
                    <Fab variant="extended">
                        <NavigationIcon className={classesTop.extendedIcon} />
                        Top
                    </Fab>
                </a>
            </div>




        </>
    )
}

export default Collection