import React from "react"
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom"
import ManageInvoice from "../domain/ManageInvoice"
import ViewInvoice from "../domain/ViewInvoice"

function Invoice() {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/manage`} component={ManageInvoice} />
      <Route path={`${path}/viewInvoice`} component={ViewInvoice} />
      <Redirect to={`${path}/manage`} />
    </Switch>
  )
}

export default Invoice
