import React from "react"
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom"
import ManageTransactions from "../domain/ManageTransactions"

function Transactions() {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/manage`} component={ManageTransactions} />
      <Redirect to={`${path}/manage`} />
    </Switch>
  )
}

export default Transactions
