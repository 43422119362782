import React, { useEffect, useState } from "react"
import { useStore, useAuth } from "../context"
import { Stores } from "."
import MUIDataTable from "mui-datatables"
import Button from "@material-ui/core/Button"
import AddRoundedIcon from "@material-ui/icons/AddRounded"
import { NavLink } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom";
//import Modal from "react-modal";
import {Modal} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import API from "../utils/API";
import Moment from 'react-moment';
import {Form} from 'react-bootstrap';
import Tab from "@material-ui/core/Tab"
import TabContext from "@material-ui/lab/TabContext"
import TabList from "@material-ui/lab/TabList"
import { TabPanel } from "@material-ui/lab"
import LinearProgress from '@material-ui/core/LinearProgress';
import RatingView from "./RatingView"
import BillImageView from "./BillImageView"
const useStyles = makeStyles(() => ({
  tab: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    padding: "0 10px",
    boxShadow: "0 0 15px silver",
  },
  button: {
    "&:hover": {
      color: "#fff",
    },
  },
}))




function ManageBills() {

  const [newBills, setNewBills] = useState([]);
  const [approveBills, setApproveBills] = useState([]);
  const [rejectBills, setRejectBills] = useState([]);
  const { userInfo,loggedUserInfo } = useAuth();
  const classes = useStyles()
  const [companyList, setCompanyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedBill, setSelectedBill] = useState("");
  const { companyData, storeData, getCompanyByUser, getStoreByCompany } =
    useStore();
    const [ratingForm, setRatingForm] = useState(false);
    const [billView, setBillView] = useState(false);
    const [selectedBillImage, setSelectedBillImage] = useState("");

    const [value, setValue] = React.useState("1")
  
    const handleOpenForm = () => setRatingForm(true);

    const handleCloseForm = () => setRatingForm(false);

    const handleBillOpenForm = () => setBillView(true);

    const handleBillCloseForm = () => setBillView(false);


  useEffect(() => {
    if(loggedUserInfo!=undefined)
   {
    API.getAllCompany(loggedUserInfo.email).then((companyResult) => {  
       
      if(companyResult.data!=undefined && companyResult.data.length>0)
      {
        setCompanyList(companyResult.data)
       
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });

          if(localStorage.getItem('branchFilterBills')!=undefined && localStorage.getItem('branchFilterBills')!=null && localStorage.getItem('branchFilterBills')!='')
    {
      getStoreByCompany(localStorage.getItem('companyFilterBills'));
      API.getBillsByBranch(localStorage.getItem('branchFilterBills'),"N").then((result) => {  
       
        if(result.data!=undefined && result.data.length>0)
        {
          setNewBills(result.data)
        }
        else
        {
          setNewBills([])
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            });
            API.getBillsByBranch(localStorage.getItem('branchFilterBills'),"A").then((result) => {  
       
              if(result.data!=undefined && result.data.length>0)
              {
                setApproveBills(result.data)
              }
              else
              {
                setApproveBills([])
              }
                   })
                   .catch((error) => {
                     console.log(error);
                     alert(error);
                  });


                  API.getBillsByBranch(localStorage.getItem('branchFilterBills'),"R").then((result) => {  
       
                    if(result.data!=undefined && result.data.length>0)
                    {
                      setRejectBills(result.data)
                    }
                    else
                    {
                      setRejectBills([])
                    }
                         })
                         .catch((error) => {
                           console.log(error);
                           alert(error);
                        });
    }
    else{
     API.getBillsByCompany(loggedUserInfo.companyId,"N").then((result) => {  
       
      if(result.data!=undefined && result.data.length>0)
      {
        setNewBills(result.data)
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });
          API.getBillsByCompany(loggedUserInfo.companyId,"A").then((result) => {  
       
            if(result.data!=undefined && result.data.length>0)
            {
              setApproveBills(result.data)
            }
                 })
                 .catch((error) => {
                   console.log(error);
                   alert(error);
                });

                API.getBillsByCompany(loggedUserInfo.companyId,"R").then((result) => {  
       
                  if(result.data!=undefined && result.data.length>0)
                  {
                    setRejectBills(result.data)
                  }
                       })
                       .catch((error) => {
                         console.log(error);
                         alert(error);
                      });
                    }
    }
  }, [loggedUserInfo]);


  const reloadData=()=>
  {

    if(localStorage.getItem('branchFilterBills')!=undefined && localStorage.getItem('branchFilterBills')!=null && localStorage.getItem('branchFilterBills')!='')
    {
      //getStoreByCompany(localStorage.getItem('companyFilterBills'));
      API.getBillsByBranch(localStorage.getItem('branchFilterBills'),"N").then((result) => {  
       
        if(result.data!=undefined && result.data.length>0)
        {
          setNewBills(result.data)
        }
        else
        {
          setNewBills([])
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            });
            API.getBillsByBranch(localStorage.getItem('branchFilterBills'),"A").then((result) => {  
       
              if(result.data!=undefined && result.data.length>0)
              {
                setApproveBills(result.data)
              }
              else
              {
                setApproveBills([])
              }
                   })
                   .catch((error) => {
                     console.log(error);
                     alert(error);
                  });


                  API.getBillsByBranch(localStorage.getItem('branchFilterBills'),"R").then((result) => {  
       
                    if(result.data!=undefined && result.data.length>0)
                    {
                      setRejectBills(result.data)
                    }
                    else
                    {
                      setRejectBills([])
                    }
                         })
                         .catch((error) => {
                           console.log(error);
                           alert(error);
                        });
    }
    else{
    API.getBillsByCompany(loggedUserInfo.companyId,"N").then((result) => {  
       
      if(result.data!=undefined && result.data.length>0)
      {
        setNewBills(result.data)
      }
      else
      setNewBills([])
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });
          API.getBillsByCompany(loggedUserInfo.companyId,"A").then((result) => {  
       
            if(result.data!=undefined && result.data.length>0)
            {
              setApproveBills(result.data)
            }
            else
            setApproveBills([])
                 })
                 .catch((error) => {
                   console.log(error);
                   alert(error);
                });

                API.getBillsByCompany(loggedUserInfo.companyId,"R").then((result) => {  
       
                  if(result.data!=undefined && result.data.length>0)
                  {
                    setRejectBills(result.data)
                  }
                  else
                  setRejectBills([])
                       })
                       .catch((error) => {
                         console.log(error);
                         alert(error);
                      });
                    }
  };

  const getCompanyBranches = async (e) => {
    console.log("event>>>>>>>>>",e.target.value);
    if(e.target.value!=undefined && e.target.value!='')
    {
      localStorage.setItem('companyFilterBills', e.target.value);
      getStoreByCompany(e.target.value);
        }
        
  };

  const handleBranchChanage = async (e) => {
    console.log("event>>>>>>>>>",e.target.value);
    if(e.target.value!=undefined && e.target.value!='')
    {
      localStorage.setItem('branchFilterBills', e.target.value);
      API.getBillsByBranch(e.target.value,"N").then((result) => {  
       
        if(result.data!=undefined && result.data.length>0)
        {
          setNewBills(result.data)
        }
        else
        {
          setNewBills([])
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            });
            API.getBillsByBranch(e.target.value,"A").then((result) => {  
       
              if(result.data!=undefined && result.data.length>0)
              {
                setApproveBills(result.data)
              }
              else
              {
                setApproveBills([])
              }
                   })
                   .catch((error) => {
                     console.log(error);
                     alert(error);
                  });


                  API.getBillsByBranch(e.target.value,"R").then((result) => {  
       
                    if(result.data!=undefined && result.data.length>0)
                    {
                      setRejectBills(result.data)
                    }
                    else
                    {
                      setRejectBills([])
                    }
                         })
                         .catch((error) => {
                           console.log(error);
                           alert(error);
                        });
    }
  };

  const handleChangeStatus = async (e,action,id) => {

    if(action=="A")
    {
    if(window.confirm('Are you sure to approve this bill?'))
          { setLoading(true);
            API.changeBillReviewStatus("A","Approve",id,userInfo.email).then((result) => {  
              console.log("Approve response>>>>>>>>",result);
              if(result.data!=undefined && result.data=="Success")
              {
                setLoading(false);
               toast.success("Bill/Rating has been approved.")
               reloadData();
              }
              else
              {
                setLoading(false);
                toast.error("something went wrong... try again")
              }
                   })
                   .catch((error) => {
                     console.log(error);
                     alert(error);
                  });
          }
        else
          {

          }
        }

        if(action=="R")
    {
    if(window.confirm('Are you sure to reject this bill?'))
          { 
            setLoading(true);
            API.changeBillReviewStatus("R","Reject",id,userInfo.email).then((result) => {  
              console.log("Reject response>>>>>>>>",result);
              if(result.data!=undefined && result.data=="Success")
              {
                setLoading(false);
               toast.success("Bill/Rating has been rejected.")
               reloadData();
              }
              else
              {
                setLoading(false);
                toast.error("something went wrong... try again")
              }
                   })
                   .catch((error) => {
                     console.log(error);
                     alert(error);
                  });
          }
        else
          {

          }
        }

        if(action=="V")
        {
          setSelectedBill(id);
          setRatingForm(true);
            }

  };

  const handleViewBill = async (e,ImageUrl) => {
    setSelectedBillImage(ImageUrl)
    setBillView(true);

  };

  const newColumns = [
    {
      name: "uploadBy",
      label: "Upload By",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "uploadOn",
      label: "Upload On",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
          <Moment format="YYYY/MM/DD">
          {value}
          </Moment>
          );
         } 
      },
    },
    {
      name: "recipt",
      label: "View Recipt",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
            <img style={{ cursor: "pointer" }} onClick={(e) =>handleViewBill(e,value)} height="35" width="35" src={value}/>
            /* <a href={value} target="_new">Recipt</a>*/
        
          );
         } 
      },
    },
    {
      name: "storeName",
      label: "Store Name",
      options: {
        filter: false,
        sort: true,
      },
    },  
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
            <div>
            <Button
            variant="contained"
            color="primary"
           // startIcon={<AddRoundedIcon />}
           onClick={(e) =>handleChangeStatus(e,"A",value)}
          >
            Approve
          </Button>
           <Button
           variant="contained"
           className="ml-2"
           color="primary"
           onClick={(e) =>handleChangeStatus(e,"R",value)}
          // startIcon={<AddRoundedIcon />}
          // component={NavLink}
           //to={"/home/categories/AddCategory?id="+value}
         >
           Reject
         </Button>
         <Button
           variant="contained"
           className="ml-2"
           color="primary"
          // startIcon={<AddRoundedIcon />}
          onClick={(e) =>handleChangeStatus(e,"V",value)}
         >
          Rating Details
         </Button>
         </div>
          );
        }
      },
    },
    
  ];

  const approveColumns = [
    {
      name: "uploadBy",
      label: "Upload By",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "uploadOn",
      label: "Upload On",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
          <Moment format="YYYY/MM/DD">
          {value}
          </Moment>
          );
         } 
      },
    },
    {
      name: "recipt",
      label: "View Recipt",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
            <img style={{ cursor: "pointer" }} onClick={(e) =>handleViewBill(e,value)} height="35" width="35" src={value}/>
            /* <a href={value} target="_new">Recipt</a>*/
          );
         } 
      },
    },
    {
      name: "storeName",
      label: "Store Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "approveBy",
      label: "Approve By",
      options: {
        filter: false,
        sort: true,
      },
    },  
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
            <Button
            variant="contained"
            color="primary"
           // startIcon={<AddRoundedIcon />}
           onClick={(e) =>handleChangeStatus(e,"V",value)}
          >
            Rating Details
          </Button>
          );
        }
      },
    },
    
  ];

  const rejectColumns = [
    {
      name: "uploadBy",
      label: "Upload By",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "uploadOn",
      label: "Upload On",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
          <Moment format="YYYY/MM/DD">
          {value}
          </Moment>
          );
         } 
      },
    },
    {
      name: "recipt",
      label: "View Recipt",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
            <img style={{ cursor: "pointer" }} onClick={(e) =>handleViewBill(e,value)} height="35" width="35" src={value}/>
            /* <a href={value} target="_new">Recipt</a>*/
          );
         } 
      },
    },
    {
      name: "storeName",
      label: "Store Name",
      options: {
        filter: false,
        sort: true,
      },
    }, 
    {
      name: "rejectBy",
      label: "Reject By",
      options: {
        filter: false,
        sort: true,
      },
    },   
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
            <Button
            variant="contained"
            color="primary"
           // startIcon={<AddRoundedIcon />}
           onClick={(e) =>handleChangeStatus(e,"V",value)}
          >
             Rating Details
          </Button>
          );
        }
      },
    },
    
  ];
  const options = {
    download: false,
    print: false,
    rowsPerPage: 10,
    selectableRowsOnClick: false,
    selectToolbarPlacement: "none",
    selectableRows:"none",
  }

  const newData = newBills.map((item) => ({
    uploadBy: item.userId,
    uploadOn: item.createdOn,
    recipt:item.image[0],
    storeName:item.storeName,
    action:item.id
  }));

  const approveData = approveBills.map((item) => ({
    uploadBy: item.userId,
    uploadOn: item.createdOn,
    recipt:item.image[0],
    storeName:item.storeName,
    approveBy:item.updatedBy,
    action:item.id
  }));

  const rejectData = rejectBills.map((item) => ({
    uploadBy: item.userId,
    uploadOn: item.createdOn,
    recipt:item.image[0],
    storeName:item.storeName,
    rejectBy:item.updatedBy,
    action:item.id
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (

    
    <TabContext value={value}>
      {loading  ? (
       <LinearProgress />
       ) : null}
      <div className={classes.tab}>
        <TabList
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="simple tabs example"
          centered
        >
          <Tab
            className={value === "1" && "font-weight-bold"}
            label={"New Bills"}
            value="1"
          />
          <Tab
            className={value === "2" && "font-weight-bold"}
            label={"Approve Bills"}
            value="2"
          />
          <Tab
            className={value === "3" && "font-weight-bold"}
            label={"Reject Bills"} 
            value="3"
          />
        </TabList>
        {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")) ? (
        <div className="d-flex">
        <Form.Control
          as="select"
          className="mr-3"
          name="company"
          value={localStorage.getItem('companyFilterBills')}
          onChange={getCompanyBranches}
          required
        >
          <option>Select Company</option>
          {companyList.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Form.Control>
        
        <Form.Control
          as="select"
          className="mr-sm-0"
          name="companyBranch"          
          value={localStorage.getItem('branchFilterBills')}
          onChange={handleBranchChanage}
          required
        >
          <option>Select Branch</option>
          {storeData.map((item, index) => (
            <option key={item.id} value={item.id}>
              {item.code}
            </option>
          ))}
        </Form.Control>
        </div>
         ) : null}
       
      </div>
      <TabPanel value="1">
        <MUIDataTable
          title={"New Bills"}
          data={newData}
          columns={newColumns}
          options={options}
        />
      </TabPanel>
      <TabPanel value="2">
        <MUIDataTable
          title={"Approve Bills"}
          data={approveData}
          columns={approveColumns}
          options={options}
        />
      </TabPanel>
      <TabPanel value="3">
        <MUIDataTable
          title={"Reject Bills"}
          data={rejectData}
          columns={rejectColumns}
          options={options}
        />
      </TabPanel>
      <ToastContainer />
      <RatingView
        handleOpen={handleOpenForm}
        handleClose={handleCloseForm}
        open={ratingForm}
        billId={selectedBill}
        
      />
      <BillImageView
        handleOpen={handleBillOpenForm}
        handleClose={handleBillCloseForm}
        open={billView}
        imageUrl={selectedBillImage}
        
      />
    </TabContext>
  )
}

export default ManageBills
