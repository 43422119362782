import React,{ useState,useEffect,useRef,Fragment } from "react"
import { Form, InputGroup } from "react-bootstrap"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import { NavLink } from "react-router-dom"
import { useAuth,useUser,useStore } from "../context";
import API from "../utils/API";
import { useHistory } from "react-router-dom";
import { uuid } from '../utils/uuid'
import { useForm, useStep } from "react-hooks-helper";
import SelectProductCategory from "./SelectProductCategory"
import {Modal} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import AliceCarousel from 'react-alice-carousel';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


const defaultData = {
  offerId:"",
  name:"",
  heading: "",
  brand: "",
  description: "",  
  validFrom:"",
  validTo:"",
  price:"",
  discount:"",
  offerPrice:"",
  imageUrl:"",
  storeId:"",
  storeName:"",
  website:"",
  categoryId:"",
  productImage:"",
  mainCategory:"",
  discountType:",",
  discountMessage:"",
  images:[],
  companyId:"",
  shippingInstructions:"",
  shippingType:"",
};

function AddProductVariant(props) {
  
const updateId=new URLSearchParams(props.location.search).get("id");
const catId=new URLSearchParams(props.location.search).get("catId");
const [form, setForm] = useForm(defaultData);
const [validated,setValidate]=useState(false);
const { signUp, userInfo,loggedUserInfo } = useAuth();
const { categoryData, storeData,getMainCategories,getStoreByCompany } =useStore();

const { offerId,name,heading, brand,description,validFrom,validTo,imageUrl,storeId,storeName,website,categoryId,price,discount,offerPrice,mainCategory,discountType,discountMessage,images,companyId,shippingInstructions,shippingType } = form;
const [selectedProductImage, setSelectedProductImage] = React.useState([])

const [fileUrl,setFileUrl]=useState('');
const [productData, setProductData] = React.useState([])
const [activeCompanyOffer, setActiveCompanyOffer] = useState([]);
const [isOpen, setIsOpen] = useState(false);
const [selectedCategory,setSelectedCategory]=useState('');
const [selectedCategoryName,setSelectedCategoryName]=useState('');
const [mainCategoryList, setMainCategoryList] = useState([]);
const [offerData, setOfferData] = useState([]);
const [image1, setImage1] = useState("");
const [image2, setImage2] = useState("");
const [image3, setImage3] = useState("");
const [image4, setImage4] = useState("");
const [image5, setImage5] = useState("");
const [updateBranch, setUpdateBranch] = useState(false);
const inputFile = useRef(null) 
const [companyList, setCompanyList] = useState([]);
const [productList, setProductList] = useState([]);
const [selectedBranch, setSelectedBranch] = useState("");
const [selectedBranchName, setSelectedBranchName] = useState("");
const [selectedCompanyName, setSelectedCompanyName] = useState("");
const [selectedBranchLogo, setSelectedBranchLogo] = useState("");
const [selectedProduct, setselectedProduct] = useState("");
const [inputFields, setInputFields] = useState([]);
const renderTooltip = props => (
  <Tooltip {...props}>For Example <br/>Fashion> Womens Fashion> Jeans</Tooltip>
);

var productId=""
      if(updateId!=undefined && updateId!=null)
      {
         productId=updateId;
      }
      else
      {
        productId=uuid();
      }

var categoryIds=[];
categoryIds.push(catId);

useEffect(() => {
  console.log("useEffect>>>>>>>>>>>>>>>>>>");
    if(loggedUserInfo!=undefined)
    {
    getStoreByCompany(loggedUserInfo.companyId);
    API.getAllCompany(loggedUserInfo.email).then((companyResult) => {  
       
      if(companyResult.data!=undefined && companyResult.data.length>0)
      {
        setCompanyList(companyResult.data)
       
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          }); 
    API.getCompanyOffer(loggedUserInfo.companyId,"all").then((result) => {  
       
      if(result.data!=undefined && result.data.length>0)
      {
        setActiveCompanyOffer(result.data)
      }
    })
    .catch((error) => {
      console.log(error);
      alert(error);
   });

   API.getMainCategories(userInfo.email).then((result) => {  
           
    if(result.data!=undefined && result.data.length>0)
    {
        //alert('data=='+result.data);
      setMainCategoryList(result.data)
    }
         })
         .catch((error) => {
           console.log(error);
           alert(error);
        });

        if(updateId!=undefined && updateId!=null && updateId!='')
        {
          
        API.getProductById(userInfo.email,updateId).then((productResult) => {  
           
          if(productResult.data!=undefined)
          {
              //alert('data=='+result.data);
              form.offerId=productResult.data.offerId;
              form.storeId=productResult.data.storeId;
              form.storeName=productResult.data.storeName
              form.name=productResult.data.name;
              form.heading=productResult.data.heading;
              form.brand=productResult.data.brand;
              form.description=productResult.data.description;
              form.website=productResult.data.vendorUrl;
              form.price=productResult.data.actualPrice;
              form.discount=productResult.data.discountPerc;
              form.offerPrice=productResult.data.offerPrice;
              form.mainCategory=productResult.data.mainCategory;
              form.imageUrl=productResult.data.fullImage;
              form.discountType=productResult.data.discountType;
              form.discountMessage=productResult.data.discountMessage;
              form.images=productResult.data.images;
              form.companyId=productResult.data.companyId;
              form.shippingType=productResult.data.shippingType;
              form.shippingInstructions=productResult.data.shippingInstructions;
              
              getStoreByCompany(productResult.data.companyId);
              setSelectedBranch(productResult.data.storeId);
              setSelectedBranchName(productResult.data.storeName)
              setSelectedBranchLogo(productResult.data.logo)
              API.getCompanyBranchProduct(productResult.data.storeId,"allincludeActive").then((result) => {  
               
                if(result.data!=undefined)
                {
                  setProductList(result.data)
                  setselectedProduct(productResult.data.variantProductId)
                }
                     })
                     .catch((error) => {
                       console.log(error);
                       alert(error);
                    });

              if(productResult.data.canUpdateBranch=="Y")
                setUpdateBranch(false);
                else
                setUpdateBranch(true);

               // console.log("productResult.data.attribute>>>>>>>>>>>>",productResult.data.attribute);
                if(productResult.data.attribute!=undefined && productResult.data.attribute!=null &&productResult.data.attribute.length>0 )
               {const values = [];
                productResult.data.attribute.map((item, index) => {
                
              values.push({ column: item.column, value: item.value });
               
                })
                setInputFields(values);
               }
               else
               {
                const values = [{ column: '', value: '' }];
                setInputFields(values);
               }

               //console.log("setInputFields>>>>>>>>>>>>",inputFields);

              if(productResult.data.images!=undefined && productResult.data.images!=null && productResult.data.images.length>0)
              {
                if(typeof productResult.data.images[0] != 'undefined') {
                  setImage1(productResult.data.images[0])
                  // does not exist
              }
              if(typeof productResult.data.images[1] != 'undefined') {
                setImage2(productResult.data.images[1])
                // does not exist
            }
            if(typeof productResult.data.images[2] != 'undefined') {
              setImage3(productResult.data.images[2])
              // does not exist
          }
          if(typeof productResult.data.images[3] != 'undefined') {
            setImage4(productResult.data.images[3])
            // does not exist
        }
        if(typeof productResult.data.images[4] != 'undefined') {
          setImage5(productResult.data.images[4])
          // does not exist
      }
              }
              console.log("productResult.data.categories>>>>>>>>>>>>>>",productResult.data.categories);
              if(productResult.data.categories!=undefined && productResult.data.categories.length>0)
              {
              setSelectedCategory(productResult.data.categories[0]);
              API.getCategoriesById(userInfo.email,productResult.data.categories[0]).then((CatResult) => {  
           
                if(CatResult.data!=undefined)
                {
                    //alert('data=='+result.data);
                    if(CatResult.data.maincategories!=undefined&&CatResult.data.maincategories!=null&&CatResult.data.maincategories.length>0)
                    {
                    API.getCategoriesById(userInfo.email,CatResult.data.maincategories[0]).then((CatResult1) => {  
                      var maniCatName="";
                      if(CatResult1.data!=undefined)
                      {
                          //alert('data=='+result.data);
                          maniCatName=CatResult1.data.name;
                          setSelectedCategoryName(CatResult1.data.name+" > "+CatResult.data.name);
                      }
                           })
                           .catch((error) => {
                             console.log(error);
                             alert(error);
                          });
                        }
                        else
                        {
                          setSelectedCategoryName(CatResult.data.name);
                        }
                    
                }
                     })
                     .catch((error) => {
                       console.log(error);
                       alert(error);
                    });
              }
              setProductData(productResult.data)
             

              API.getCompanyOffer(productResult.data.companyId,"active").then((result) => {  
       
                if(result.data!=undefined && result.data.length>0)
                {
                  setActiveCompanyOffer(result.data)
                }
              })
              .catch((error) => {
                console.log(error);
                alert(error);
             });


              
          }
               })
               .catch((error) => {
                 console.log(error);
                 alert(error);
              });
            }
            else
            {
              form.offerId="";
              form.storeId="";
              form.name="";
              form.heading="";
              form.brand="";
              form.description="";
              form.website="";
              form.price="";
              form.discount="";
              form.offerPrice="";
              form.mainCategory="";
              form.imageUrl="";
              form.discountType="";
              form.discountMessage="";
              form.images=[];
              form.companyId="";
              form.storeId=""
              form.shippingType="Free Shipping"
              form.shippingInstructions="";
              const values = [{ column: '', value: '' }];
              setInputFields(values);
              setSelectedBranch("");
              setSelectedBranchName("")
              setSelectedBranchLogo("")
            }

    }

  }, [updateId,loggedUserInfo]);
 
  



  const history = useHistory();

  const getCompanyBranches = async (e) => {
    console.log("event>>>>>>>>>",e.target.value);
    if(e.target.value!=undefined && e.target.value!='')
    {
      form.companyId=e.target.value
      var index = e.nativeEvent.target.selectedIndex;
      var companyName="";
      if(e.nativeEvent.target[index].text!=undefined)
      {
        companyName=e.nativeEvent.target[index].text.replace(/\s+/g, '')
      }
     setSelectedCompanyName(companyName);
      getStoreByCompany(e.target.value);
        }
        
  };
  

const handleSubmit = async (e) => {
  try {
    setValidate(true);

    console.log("in put fileds>>>>>>>>>>>>>>>>>>",inputFields);
/*  if (form.otp == '' || form.otp == undefined) {
      e.preventDefault();
      e.stopPropagation();
  }*/
  if (form.name == '' || form.name == undefined) {
    e.preventDefault();
    e.stopPropagation();
   // e.stopNavigation();
   // return;
  }
  if (form.storeId == '' || form.name == storeId) {
    e.preventDefault();
    e.stopPropagation();
   // e.stopNavigation();
   // return;
  }
   if (form.heading == '' || form.heading == undefined) {
    e.preventDefault();
    e.stopPropagation();
   // e.stopNavigation();
   // return;
  }
  else if (form.brand == '' || form.brand == undefined) {
    e.preventDefault();
    e.stopPropagation();
   // e.stopNavigation();
   // return;
  }

  else if (form.description == '' || form.description == undefined) {
    e.preventDefault();
    e.stopPropagation();
   // e.stopNavigation();
   // return;
  }
  else if (form.website == '' || form.website == undefined) {
    e.preventDefault();
    e.stopPropagation();
   // e.stopNavigation();
   // return;
  }
  else if (form.price == '' || form.price == undefined) {
    e.preventDefault();
    e.stopPropagation();
   // e.stopNavigation();
   // return;
  }
  else if (form.discountType == '' || form.discountType == undefined) {
    e.preventDefault();
    e.stopPropagation();
   // e.stopNavigation();
   // return;
  }
  else if (form.discountType=='percentage' && (form.discount == '' || form.discount == undefined)) {
    e.preventDefault();
    e.stopPropagation();
   // e.stopNavigation();
   // return;
  }
  else if (form.discountType=='message' && (form.discountMessage == '' || form.discountMessage == undefined)) {
    e.preventDefault();
    e.stopPropagation();
   // e.stopNavigation();
   // return;
  }
  else if (form.offerPrice == '' || form.offerPrice == undefined) {
    e.preventDefault();
    e.stopPropagation();
   // e.stopNavigation();
   // return;
  }
  else if (selectedCategory == '' || selectedCategory == undefined) {
    toast.error("Please configure subcategory")
   // e.stopNavigation();
   // return;
  }
  else if (form.mainCategory == '' || form.mainCategory == undefined) {
    e.preventDefault();
    e.stopPropagation();
   // e.stopNavigation();
   // return;
  }
  else if (loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || 
    loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")) &&
    (form.companyId == '' || form.companyId == undefined))
    {
      toast.error("Please select company");
      e.preventDefault();
      e.stopPropagation();
    }
 
  else if(form.name != '' && form.heading != '' && form.brand != '' && form.description != ''
  && form.website != '' && form.price != '' && (form.discount != '' || form.discountType=='message' ) && form.offerPrice != '' && form.storeId != '' 
  && form.mainCategory != '' && selectedCategory != '' && form.discountType!='' &&  (form.discountMessage != '' || form.discountType=='percentage' )
  || 
    (loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || 
    loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")) &&
    (form.companyId !='' ) )
  
  ) 
  {
    try {

      
      if(selectedProductImage!=undefined && selectedProductImage!='')
    var imageExtension = selectedProductImage.name.substring(selectedProductImage.name.indexOf('.'),selectedProductImage.name.length)

    var uploadFolder="";
    if(loggedUserInfo.companyName!=undefined)
    {
      // uploadImageName=loggedUserInfo.companyName.replace(/\s+/g, '')
      uploadFolder=loggedUserInfo.companyName.replace(/\s+/g, '');
      uploadFolder=uploadFolder+"/"+form.storeId;
    }
      var selectedCategoryArray=[]
      selectedCategoryArray.push(selectedCategory)
      let productBody = {
        id:productId,
        name:form.name,
        heading:form.heading,
        brand:form.brand,
        description:form.description,
        vendorUrl:form.website,
        actualPrice:form.price,
        offerPrice:form.offerPrice,
        discountPerc:form.discount,
        //offerId:form.offerId,
        offerId:"",
       validFrom:productData!=undefined && productData!=null && productData!=''?productData.validFrom:"",
        validTill:productData!=undefined && productData!=null && productData!=''?productData.validTill:"",
        campaignName:productData!=undefined && productData!=null && productData!=''?productData.campaignName:"",
        campaignId:productData!=undefined && productData!=null && productData!=''?productData.campaignId:"",
        campaignArea:productData!=undefined && productData!=null && productData!=''?productData.campaignArea:"",
          cityList:productData!=undefined && productData!=null && productData!=''?productData.cityList:[],
          stateList:productData!=undefined && productData!=null && productData!=''?productData.stateList:[],
          budget:productData!=undefined && productData!=null && productData!=''?productData.budget:"",
        fullImage:selectedProductImage!=undefined && selectedProductImage!=''?API.fullBucketURL+uploadFolder+"/"+productId+imageExtension:form.imageUrl,
        images:form.images,
        active:productData!=undefined && productData!=null && productData!=''?productData.active:"N",
        totalRating:productData!=undefined && productData!=null && productData!=''?productData.totalRating:0,
        avgRating:productData!=undefined && productData!=null && productData!=''?productData.avgRating:0,
        categories:selectedCategoryArray,
        mainCategory:form.mainCategory,
        discountType:form.discountType,
        discountMessage:form.discountMessage,
        storeId:form.storeId,
        shippingType:form.shippingType,
        shippingInstructions:form.shippingInstructions,
        storeName:form.storeName,
        createdBy:userInfo.email,
        updatedBy:userInfo.email,
        sponsoredCampaignId:productData!=undefined && productData!=null && productData!=''?productData.sponsoredCampaignId:"",
        sponsoredCampaignName:productData!=undefined && productData!=null && productData!=''?productData.sponsoredCampaignName:"",
        sponsoredType:productData!=undefined && productData!=null && productData!=''?productData.sponsoredType:"",
        isSponsored:productData!=undefined && productData!=null && productData!=''?productData.isSponsored:"",
        keywords:productData!=undefined && productData!=null && productData!=''?productData.keywords:[],
        sponsoredSection:productData!=undefined && productData!=null && productData!=''?productData.sponsoredSection:[],
        sponsoredValidFrom:productData!=undefined && productData!=null && productData!=''?productData.sponsoredValidFrom:"",
        sponsoredValidTill:productData!=undefined && productData!=null && productData!=''?productData.sponsoredValidTill:"",
        isShopifyProduct:productData!=undefined && productData!=null && productData!=''?productData.isShopifyProduct:"",
        shopifySyncId:productData!=undefined && productData!=null && productData!=''?productData.shopifySyncId:"",
        shopifyProductId:productData!=undefined && productData!=null && productData!=''?productData.shopifyProductId:"",
        canUpdateBranch:"N",
        isVariant:"Y",
        variantProductId:selectedProduct,
        attribute:inputFields,
        logo:selectedBranchLogo,
        companyId:form.companyId!=undefined && form.companyId!=null && form.companyId!=''?form.companyId:loggedUserInfo.companyId,
       };
       if(updateId!=undefined && updateId!=null)
      {
       API.updateProduct(userInfo.email,productBody);
      }
      else
      {
        API.createProduct(userInfo.email,productBody);
      }
       uploadOfferImage(selectedProductImage,loggedUserInfo.companyName,form.storeId,productId)
       setTimeout(() => { 
          
        history.push(`/home/productVariant`)
      
      }, 3000);
    
     // await login(`${email}`, `${password}`)
      //history.replace("/home")
      //    history.replace("/home");
    } catch (err) {
      console.log(err);
    }
  }
  
  } catch (e) {
    console.log(e);
  }
 
};

const uploadOfferImage = async (selectedProductImage,companyName,storeId,offerId) => {
  const formData = new FormData()
  formData.append('file', selectedProductImage)
  console.log("selectedOfferImage>>>>>>>>>>>",selectedProductImage);
  if (selectedProductImage !== null && selectedProductImage!='') {
    //store.dispatch('setOverlay', { showing: true })
    var imageName = selectedProductImage.name.substring(0, selectedProductImage.name.indexOf('.'))
    API.uploadOfferImage(formData, companyName,storeId,offerId)
      .then(response => {
        if (response.isAxiosError) {
         // store.dispatch('setOverlay', { showing: false })
          //store.dispatch('setSnackbar', { color: 'error', text: 'Something went wrong please try again' })
        } else {
          //store.dispatch('setOverlay', { showing: false })
          //store.dispatch('setSnackbar', { text: 'Company Logo Uploaded successfully' })
        }
      }).catch(response => {
       // store.dispatch('setOverlay', { showing: false })
        // error callback
      })
  }
  // API.setUploadLogo(formData)
}

const uploadImage =  (e) => {
  form.companyLogo=e.target.files[0].name;
  //form.fileObject=Array.from(e.target.files)
  setSelectedProductImage(e.target.files[0]);
  //setFileUrl(URL.createObjectURL(e.target.files[0]));
  setFileUrl(e.target.files[0].name);
  
  console.log("selectedProductImage>>>>>>>>>>>>",selectedProductImage);
  
    //setForm(form.companyLogo);
}

function toggleModal() {

  setIsOpen(!isOpen);

}
function handleClose() {
  setIsOpen(false);
}

const selectCategory = (cat,catNamne1,catNamne2,catNamne3,catNamne4,mainCat) => {
  console.log("selected category>>>>>>",cat);
  console.log("selected catNamne>>>>>>",catNamne1);
  console.log("selected catNamne>>>>>>",catNamne2);
  console.log("selected catNamne>>>>>>",catNamne3);
  console.log("selected catNamne>>>>>>",catNamne4);
  console.log("selected mainCat>>>>>>",mainCat);
  setSelectedCategory(cat);
  let catName="";
  if(catNamne1!=undefined&&catNamne1!="")
  catName=catNamne1;
  if(catNamne2!=undefined&&catNamne2!="")
  catName=catName+"/"+catNamne2;
  if(catNamne3!=undefined&&catNamne3!="")
  catName=catName+"/"+catNamne3;
  if(catNamne4!=undefined&&catNamne4!="")
  catName=catName+"/"+catNamne4;

  setSelectedCategoryName(catName);
  if (form.mainCategory == '' || form.mainCategory == undefined) {
    form.mainCategory=mainCat
  }
  //this.setState({language: langValue});
}

const handleOfferChange = async (e) => {
  //console.log("event>>>>>>>>>",e.target.value);
  form.offerId=e.target.value;
  if(e.target.value!=undefined && e.target.value!='')
  {
  API.getOfferById(userInfo.email,e.target.value).then((result) => {  
     
    if(result.data!=undefined)
    {
     // setCities(result.data);
     form.mainCategory=result.data.categories[0];
     form.storeId=result.data.storeId;
     setOfferData(result.data);
     console.log("event>>>>>>>>>",result.data.categories[0]);
    }
         })
         .catch((error) => {
           console.log(error);
           alert(error);
        });
      }
      else
      {
        setOfferData([]);
        form.offerId='';
      }
};

const handleDiscount = async (e) => {
  form.discount=e.target.value;
  if(form.price!=undefined)
  {
  var discountValue=(Number(form.price)*Number(e.target.value))/100;
  var offerAmount=Number(form.price)-discountValue;
  offerAmount=Math.round((offerAmount + Number.EPSILON) * 100) / 100
  form.offerPrice=offerAmount;
  }
  setOfferData([]);

}

const handlePrice = async (e) => {
  form.price=e.target.value;
  if(form.discount!=undefined)
  {
  var discountValue=(Number(form.price)*Number(form.discount))/100;
  var offerAmount=Number(form.price)-discountValue;
  offerAmount=Math.round((offerAmount + Number.EPSILON) * 100) / 100
  form.offerPrice=offerAmount;
  }
  setOfferData([]);

}

var selectSlider;
const onButtonClick = (index) => {
  // `current` points to the mounted file input element
  console.log("thubnail clikk>>>>>>>>>>>>",index);
  if(index==0 && (image1==undefined|| image1==null || image1==''))
  {
    selectSlider=index;
 inputFile.current.click();
 
  }
  if(index==1 && (image2==undefined|| image2==null || image2==''))
  {
    selectSlider=index;
 inputFile.current.click();
  }
  if(index==2 && (image3==undefined|| image3==null || image3==''))
  {
    selectSlider=index;
 inputFile.current.click();
  }
  if(index==3 && (image4==undefined|| image4==null || image4==''))
  {
    selectSlider=index;
 inputFile.current.click();
  }
  if(index==4 && (image5==undefined|| image5==null || image5==''))
  {
    selectSlider=index;
 inputFile.current.click();
  }
};

const uploadMultiImage =  (e) => {
  
  console.log("selectSlider>>>>>>>>>>>>>",selectSlider);

  console.log("selectedProductImage>>>>>>>>>>>>",e.target.files[0]);
   const formData = new FormData()
  formData.append('file', e.target.files[0])
  console.log("selectedOfferImage>>>>>>>>>>>",e.target.files[0]);
  if (e.target.files[0] !== null && e.target.files[0]!='') {
    //store.dispatch('setOverlay', { showing: true })
    var imageName = e.target.files[0].name.substring(0, e.target.files[0].name.indexOf('.'))

    API.uploadOfferImage(formData, selectedCompanyName!=undefined && selectedCompanyName!=null && selectedCompanyName!=""?selectedCompanyName:loggedUserInfo.companyName,selectedBranchName,productId)
      .then(response => {
        console.log("image response>>>>>>>>>",response);
        if (response.isAxiosError) {
         // store.dispatch('setOverlay', { showing: false })
          //store.dispatch('setSnackbar', { color: 'error', text: 'Something went wrong please try again' })
        } else {
           var imagePath=response.data.substring(1);
           
           form.images.push(API.bucketURL+imagePath);
          
              if(Number(selectSlider)==0 )
            {
              console.log("setImage1 response>>>>>>>>>",API.bucketURL+imagePath);
              setImage1(API.bucketURL+imagePath);
            }
            if(selectSlider==1 )
            {
              setImage2(API.bucketURL+imagePath);
            }
            if(selectSlider==2 )
            {
              setImage3(API.bucketURL+imagePath);
            }
            if(selectSlider==3 )
            {
              setImage4(API.bucketURL+imagePath);
            }
            if(selectSlider==4)
            {
              setImage5(API.bucketURL+imagePath);
            }
          
          //store.dispatch('setOverlay', { showing: false })
          //store.dispatch('setSnackbar', { text: 'Company Logo Uploaded successfully' })
        }
      }).catch(response => {
       // store.dispatch('setOverlay', { showing: false })
        // error callback
      })
  }
    //setForm(form.companyLogo);
}

var currentSlide=0;
const updateCurrentSlide = (index) => {

  if (currentSlide !== index) {
      currentSlide= index
      }
};

const onDeleteSlideClick = () => {

  console.log("going to delete >>>>>>>>>",currentSlide)
  //form.images[currentSlide]="";
  form.images.splice(currentSlide,1);
  if(currentSlide==0 )
  {
    setImage1("");
   /* setImage1(image2);
    setImage2(image3);
    setImage3(image4);
    setImage4(image5);
    setImage5("");*/
  }
  if(currentSlide==1 )
  {
    setImage2("");
    /*setImage2(image3);
    setImage3(image4);
    setImage4(image5);
    setImage5("");*/
  }
  if(currentSlide==2 )
  {
    setImage3("");
    /*setImage3(image4);
    setImage4(image5);
    setImage5("");*/
  }
  if(currentSlide==3 )
  {
    setImage4("");
    /*setImage4(image5);
    setImage5("");*/
  }
  if(currentSlide==4)
  {
    setImage5("");
  }
};

const handleBranchChanage = async (e) => {
  console.log("event>>>>>>>>>",e.target.value);
  if(e.target.value!=undefined && e.target.value!='')
  {
    form.storeId=e.target.value;
    var index = e.nativeEvent.target.selectedIndex;
    form.storeName=e.nativeEvent.target[index].text;
    setSelectedBranchName(e.nativeEvent.target[index].text);
    setSelectedBranch(e.target.value);
    API.getCompanyBranchOffer(e.target.value,"all").then((result) => {  
       
      if(result.data!=undefined && result.data.length>0)
      {
        setActiveCompanyOffer(result.data)
      }
    })
    .catch((error) => {
      console.log(error);
      alert(error);
   });

   storeData.map((item, index) => {
    item.id==e.target.value?setSelectedBranchLogo(item.companyLogo):setSelectedBranchLogo("")
    
  }
  )

  //****************set branch product */

  API.getCompanyBranchProduct(e.target.value,"allincludeActive").then((result) => {  
               
    if(result.data!=undefined)
    {
      setProductList(result.data)
    }
         })
         .catch((error) => {
           console.log(error);
           alert(error);
        });


  }
};

const handleAddFields = () => {
  const values = [...inputFields];
  values.push({ column: '', value: '' });
  setInputFields(values);
};

const handleRemoveFields = index => {
  const values = [...inputFields];
  values.splice(index, 1);
  setInputFields(values);
};

const handleInputChange = (index, event) => {
  const values = [...inputFields];
  if (event.target.name === "column") {
    values[index].column = event.target.value;
  } else {
    values[index].value = event.target.value;
  }

  setInputFields(values);
};

const getselectedProduct = async (e) => {
  console.log("event>>>>>>>>>",e.target.value);
  if(e.target.value!=undefined && e.target.value!='')
  {
    setselectedProduct(e.target.value)
    API.getProductById(userInfo.email,e.target.value).then((productResult) => {  
           
      if(productResult.data!=undefined)
      {
          //alert('data=='+result.data);
          form.offerId=productResult.data.offerId;
          form.storeId=productResult.data.storeId;
          form.storeName=productResult.data.storeName
          form.name=productResult.data.name;
          form.heading=productResult.data.heading;
          form.brand=productResult.data.brand;
          form.description=productResult.data.description;
          form.website=productResult.data.vendorUrl;
          form.price=productResult.data.actualPrice;
          form.discount=productResult.data.discountPerc;
          form.offerPrice=productResult.data.offerPrice;
          form.mainCategory=productResult.data.mainCategory;
          form.imageUrl=productResult.data.fullImage;
          form.discountType=productResult.data.discountType;
          form.discountMessage=productResult.data.discountMessage;
          form.images=productResult.data.images;
          form.companyId=productResult.data.companyId;
          form.shippingType=productResult.data.shippingType;
          form.shippingInstructions=productResult.data.shippingInstructions;
         /* getStoreByCompany(productResult.data.companyId);
          //setSelectedBranch(productResult.data.storeId);
          //setSelectedBranchName(productResult.data.storeName)
          ///setSelectedBranchLogo(productResult.data.logo)
          if(productResult.data.canUpdateBranch=="Y")
            setUpdateBranch(false);
            else
            setUpdateBranch(true);*/

           // console.log("productResult.data.attribute>>>>>>>>>>>>",productResult.data.attribute);
            if(productResult.data.attribute!=undefined && productResult.data.attribute!=null &&productResult.data.attribute.length>0 )
           {const values = [];
            productResult.data.attribute.map((item, index) => {
            
          values.push({ column: item.column, value: item.value });
           
            })
            setInputFields(values);
           }
           else
           {
            const values = [{ column: '', value: '' }];
            setInputFields(values);
           }

           //console.log("setInputFields>>>>>>>>>>>>",inputFields);

          if(productResult.data.images!=undefined && productResult.data.images!=null && productResult.data.images.length>0)
          {
            if(typeof productResult.data.images[0] != 'undefined') {
              setImage1(productResult.data.images[0])
              // does not exist
          }
          if(typeof productResult.data.images[1] != 'undefined') {
            setImage2(productResult.data.images[1])
            // does not exist
        }
        if(typeof productResult.data.images[2] != 'undefined') {
          setImage3(productResult.data.images[2])
          // does not exist
      }
      if(typeof productResult.data.images[3] != 'undefined') {
        setImage4(productResult.data.images[3])
        // does not exist
    }
    if(typeof productResult.data.images[4] != 'undefined') {
      setImage5(productResult.data.images[4])
      // does not exist
  }
          }
          console.log("productResult.data.categories>>>>>>>>>>>>>>",productResult.data.categories);
          if(productResult.data.categories!=undefined && productResult.data.categories.length>0)
          {
          setSelectedCategory(productResult.data.categories[0]);
          API.getCategoriesById(userInfo.email,productResult.data.categories[0]).then((CatResult) => {  
       
            if(CatResult.data!=undefined)
            {
                //alert('data=='+result.data);
                if(CatResult.data.maincategories!=undefined&&CatResult.data.maincategories!=null&&CatResult.data.maincategories.length>0)
                {
                API.getCategoriesById(userInfo.email,CatResult.data.maincategories[0]).then((CatResult1) => {  
                  var maniCatName="";
                  if(CatResult1.data!=undefined)
                  {
                      //alert('data=='+result.data);
                      maniCatName=CatResult1.data.name;
                      setSelectedCategoryName(CatResult1.data.name+" > "+CatResult.data.name);
                  }
                       })
                       .catch((error) => {
                         console.log(error);
                         alert(error);
                      });
                    }
                    else
                    {
                      setSelectedCategoryName(CatResult.data.name);
                    }
                
            }
                 })
                 .catch((error) => {
                   console.log(error);
                   alert(error);
                });
          }
          setProductData(productResult.data)
         

          API.getCompanyOffer(productResult.data.companyId,"active").then((result) => {  
   
            if(result.data!=undefined && result.data.length>0)
            {
              setActiveCompanyOffer(result.data)
            }
          })
          .catch((error) => {
            console.log(error);
            alert(error);
         });


          
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });

    
      }
      
};



  return (
    <div style={style.form}>
    <div className="row">
      <div className="col-md-6">
      <InputGroup>
      <Form.Group className="col-1">
          <Button onClick={(e) =>history.goBack()} variant="contained" color="primary" type="button">
            Back
          </Button>
        </Form.Group>
      </InputGroup>
      <Form noValidate validated={validated} action="#">
      <center className="mb-5">
        <Form.Label as="legend">Product Variant Form Details</Form.Label>
      </center>

      

      {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")) ? (
         <InputGroup className="mb-3">
            <Form.Group className="col-12">
        <Form.Control
          as="select"
          className=" mr-3"
          disabled={updateBranch}
          name="company"
          value={companyId}
          onChange={getCompanyBranches}
          required
        >
          <option>Select Company</option>
          {companyList.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Form.Control>
        </Form.Group>
       </InputGroup>
         ) : null}

      <InputGroup className="mb-3">
      <Form.Group className="col-12">
          <Form.Label>Select Branch</Form.Label>
          <Form.Control as="select" name="storeId" disabled={updateBranch} value={storeId} onChange={handleBranchChanage} required className="mr-sm-2" custom>
          <option value="">Select Branch</option>
              {storeData.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.code}
                </option>
              ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid" >Please select branch</Form.Control.Feedback>
        </Form.Group>
        {/*<Form.Group className="col-6">
          <Form.Label>Choose Existing Offer</Form.Label>
          <Form.Control as="select" name="offerId" disabled={updateBranch} value={offerId} onChange={handleOfferChange}  className="mr-sm-2" custom>
            <option value="">Select Offer</option>
            {activeCompanyOffer.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.heading}
                </option>
              ))}
            </Form.Control>
        </Form.Group>*/}
        {/* <Form.Label className="d-flex align-items-center">OR</Form.Label>
        <Form.Group className="col mt-4">
          <Button
            variant="contained"
            color="default"
            // type="button"
            component={NavLink}
            to={"/home/offers/add"}
            style={{ textDecoration: "none" }}
          >
            Create New Offer
          </Button>
        </Form.Group> */}

        
      </InputGroup>

      <InputGroup className="mb-3">
            <Form.Group className="col-12">
        <Form.Control
          as="select"
          className=" mr-3"
          //disabled={updateBranch}
          name="company"
          value={selectedProduct}
          onChange={getselectedProduct}
          required
        >
          <option>Select Product</option>
          {productList.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Form.Control>
        </Form.Group>
       </InputGroup>

      <InputGroup className="mb-3">
        <Form.Group className="col-6">
          <Form.Label>Product Name</Form.Label>
          <Form.Control 
          name="name"
          value={name}
          onChange={setForm}
          type="text"
          required
           />
            <Form.Control.Feedback type="invalid" >Please enter product name</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="col-6">
          <Form.Label>Brand</Form.Label>
          <Form.Control 
          name="brand"
          value={brand}
          onChange={setForm}
          type="text"
          required
           />
            <Form.Control.Feedback type="invalid" >Please enter brand</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="col-12">
          <Form.Label>Product Heading</Form.Label>
          <Form.Control name="heading"
          value={heading}
          onChange={setForm}
          type="text"
          required
          />
          <Form.Control.Feedback type="invalid" >Please enter product heading</Form.Control.Feedback>
        </Form.Group>
      </InputGroup>

      <InputGroup className="mb-3">
        <Form.Group className="col-12">
          <Form.Label>Product Description</Form.Label>
          <Form.Control
          name="description"
          value={description}
          onChange={setForm}
          as="textarea" rows={3}
          required
           />
           <Form.Control.Feedback type="invalid" >Please enter description</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="col-12">
          <Form.Label>Website Link</Form.Label>
          <Form.Control name="website"
          value={website}
          onChange={setForm}
          type="text"
          required
          />
          <Form.Control.Feedback type="invalid" >Please enter website url</Form.Control.Feedback>
        </Form.Group>
      </InputGroup>

      {/*<InputGroup className="mb-3">
        <Form.Group className="col-4">
          <Form.Label>Category</Form.Label>
          <Form.Control
            as="select"
            className="mr-sm-2"
            custom
            onChange={handleRootCategoryChange}
          >
            <option></option>
            {category.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group className="col-4">
          <Form.Label>Sub-Category-1</Form.Label>
          <Form.Control
            as="select"
            className="mr-sm-2"
            custom
            onChange={handleSubCategory1Change}
          >
            <option></option>
            {subCategory1.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group className="col-4">
          <Form.Label>Sub-Category-2</Form.Label>
          <Form.Control as="select" className="mr-sm-2" custom>
            <option></option>
            {subCategory2.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
            </InputGroup>*/}

      <InputGroup className="mb-3">
        <Form.Group className="col-12">
          <Form.Label>Actual Price</Form.Label>
          <Form.Control name="price"
          value={price}
          onChange={handlePrice}
          type="number"
          required
          />
          <Form.Control.Feedback type="invalid" >Please enter Actual Price</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="col-12 mt-4">
        <Form.Check
          inline
          label="Discount Message"
          name="discountType"
          type="radio"
          value="message"
          onChange={setForm}
          id="inline-radio-1"
          
        />
        <Form.Check
          inline
          label="Discount Percentage"
          name="discountType"
          type="radio"
          value="percentage"
          onChange={setForm}
          id="inline-radio-2"
          
        />
       
      </Form.Group>
      {form.discountType!= '' && form.discountType== 'percentage'?  (
        <React.Fragment>
        <Form.Group className="col-6">
          <Form.Label>Discount Percentage</Form.Label>
          <Form.Control name="discount"
          value={discount}
          onChange={handleDiscount}
          type="number"
          required
          />
          <Form.Control.Feedback type="invalid" >Please enter Discount Percentage</Form.Control.Feedback>
        </Form.Group>
       <Form.Group className="col-6">
       <Form.Label>Offer Price</Form.Label>
       <Form.Control name="offerPrice"
       readOnly
       value={offerPrice}
       onChange={setForm}
       type="number"
       required
       />
       <Form.Control.Feedback type="invalid" >Please enter offer price</Form.Control.Feedback>
     </Form.Group>
     </React.Fragment>
     
         ):"" }
         {form.discountType!= '' && form.discountType== 'message'?  (

<Form.Group className="col-6">
          <Form.Label>Discount Message</Form.Label>
          <Form.Control name="discountMessage"
          value={discountMessage}
          onChange={setForm}
          type="text"
          required
          />
          <Form.Control.Feedback type="invalid" >Please enter Discount Message</Form.Control.Feedback>
        </Form.Group>
):""}

        
      </InputGroup>

      <InputGroup className="mb-3">
      <Form.Label></Form.Label>
      <Form.Group className="col-12 mt-4">
        <Form.Check
          inline
          label="Free Shipping"
          name="shippingType"
          type="radio"
          value="Free Shipping"
          checked={shippingType=="Free Shipping"?true:false}
          onChange={setForm}
          id="inline-radio-1"
          
          
        />
        <Form.Check
          inline
          label="Standard shipping"
          name="shippingType"
          type="radio"
          value="Standard shipping"
          checked={shippingType=="Standard shipping"?true:false}
          onChange={setForm}
          id="inline-radio-2"
          required
          
        />
       <Form.Control.Feedback type="invalid" >Please select shipping type</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="col-12">
          <Form.Label>Shipping Instructions (If any)</Form.Label>
          <Form.Control name="shippingInstructions"
          value={shippingInstructions}
          onChange={setForm}
          type="text"
          
          />
          <Form.Control.Feedback type="invalid" >Please enter Shipping Instructions</Form.Control.Feedback>
        </Form.Group>

        </InputGroup>

      <InputGroup className="mb-3">
      {inputFields.map((inputField, index) => (
            <Fragment key={`${inputField}~${index}`}>
              <div className="form-group col-sm-6">
                <label htmlFor="column">Attribute</label>
                <input
                  type="text"
                  className="form-control"
                  id="column"
                  name="column"
                  value={inputField.column}
                  onChange={event => handleInputChange(index, event)}
                />
              </div>
              <div className="form-group col-sm-4">
                <label htmlFor="value">Value</label>
                <input
                  type="text" 
                  className="form-control" 
                  id="value"
                  name="value"
                  value={inputField.value}
                  onChange={event => handleInputChange(index, event)}
                />
              </div>
              {index>0?
              <div className="form-group col-sm-2">
                <button
                  className="btn btn-link mt-3"
                  color="red"
                  type="button"
                  onClick={() => handleRemoveFields(index)}
                >
                  Remove
                </button>
                
              </div>
              :""}
            </Fragment>
          ))}
          <button
                  className="btn btn-link"
                  type="button"
                  onClick={() => handleAddFields()}
                >
                  Add More Attribute
                </button>
      </InputGroup>

      <InputGroup className="mb-3">
        {/*<Form.Group className="col-4">
          <Form.Label>Valid From</Form.Label>
          <Form.Control type="date" />
        </Form.Group>

        <Form.Group className="col-4">
          <Form.Label>Valid Till</Form.Label>
          <Form.Control type="date" />
          </Form.Group>

        <Form.Group className="col-6">
          <Form.Label>Product Image</Form.Label>
          <Form.Control type="file"  onChange={uploadImage} />
        </Form.Group>*/}
        {/*<p>{fileUrl}</p>*/}
       
        <Form.Group className="col-12">
          <Form.Label>Category</Form.Label>
          <Form.Control as="select" name="mainCategory" value={mainCategory} onChange={setForm} required className="mr-sm-2" custom>
          <option></option>
              {mainCategoryList.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid" >Please select category</Form.Control.Feedback>
          </Form.Group>
            
        <Form.Group className="col-12 mt-4">
        {selectedCategory == ""?  (
          <Button  variant="outlined"><a href="#" className="text-secondary" onClick={toggleModal}>Click here to configure subcategory for Departmental view.</a></Button>
          ):
          <Form.Label >{selectedCategoryName}<Button  className="ml-2" variant="outlined"><a className="text-secondary" href="#" onClick={toggleModal}>Click here to Change subcategory for Departmental view</a></Button></Form.Label>
          }
          <OverlayTrigger placement="top" overlay={renderTooltip}>
        <Button><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
</svg></Button>
      </OverlayTrigger>
        </Form.Group>
      </InputGroup>

     {/* <InputGroup className="mb-3">
        <Form.Group className="col-4">
          <FormControlLabel
            control={<Checkbox name="checkedB" color="primary" />}
            label="Activate Product"
          />
        </Form.Group>
        </InputGroup>*/}

      <InputGroup className="mb-3">
        <Form.Group className="col-2">
          <Button onClick={(e) =>handleSubmit(e)} variant="contained" color="primary" type="button">
            Add
          </Button>
        </Form.Group>
      </InputGroup>
      <Modal
        show={isOpen}
        onHide={handleClose}
        centered
        size="xl"
      >
        <SelectProductCategory onSelectCategory={selectCategory} categoryId={"11"} setIsOpen={setIsOpen}  />;
      </Modal>
      <ToastContainer />
    </Form>
      </div>
      <div className="col-md-6">
      <input type='file' id='file'onChange={uploadMultiImage}  accept="image/*" ref={inputFile} style={{display: 'none'}}/>

      
        <div className="sslider-wrapper mt-5 pt-5">
        <button onClick={onDeleteSlideClick} className="delete-icon">
                <svg fill="red" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="30px" height="30px"><path d="M 13 3 A 1.0001 1.0001 0 0 0 11.986328 4 L 6 4 A 1.0001 1.0001 0 1 0 6 6 L 24 6 A 1.0001 1.0001 0 1 0 24 4 L 18.013672 4 A 1.0001 1.0001 0 0 0 17 3 L 13 3 z M 6 8 L 6 24 C 6 25.105 6.895 26 8 26 L 22 26 C 23.105 26 24 25.105 24 24 L 24 8 L 6 8 z"/></svg>
      </button>

                <Carousel onClickThumb={onButtonClick}  onClickItem={onButtonClick}  onChange={updateCurrentSlide} showIndicators >
        
                <div>
                {image1!=undefined && image1!='' && image1!=null ?  (
                    <img src={image1+"?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"} />
                    ): 
                    <img src="https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/bg-slider.png" />
                    }
                </div>
                <div>
                {image2!=undefined && image2!='' && image2!=null ?  (
                    <img src={image2+"?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"} />
                    ): 
                  
                    <img  src="https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/bg-slider.png" />
                   
                    
                   
                    }
                    
                </div>
                <div>
                {image3!=undefined && image3!='' && image3!=null ?  (
                    <img src={image3+"?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"} />
                    ): 
                    <img src="https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/bg-slider.png" />
                    }
                </div>
                <div>
                {image4!=undefined && image4!='' && image4!=null ?  (
                    <img src={image4+"?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"} />
                    ): 
                    <img src="https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/bg-slider.png" />
                    }
                    
                </div>
                <div>
                {image5!=undefined && image5!='' && image5!=null ?  (
                    <img src={image5+"?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"} />
                    ): 
                    <img src="https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/bg-slider.png" />
                    }
                    
                </div>
               
            </Carousel>



            
        </div>
      <div className="ml-4"><strong>For Best view please upload image with size 1000 x 1000</strong></div>
      </div>
    </div>
    </div>
  )
}

export default AddProductVariant

const style = {
  form: {
    backgroundColor: "white",
    padding: "25px",
    boxShadow: "0 0 15px silver",
  },
}
