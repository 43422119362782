import React,{ useEffect, useState } from "react"
import MUIDataTable from "mui-datatables"
import { makeStyles } from "@material-ui/core/styles"
import Tab from "@material-ui/core/Tab"
import TabContext from "@material-ui/lab/TabContext"
import TabList from "@material-ui/lab/TabList"
import Button from "@material-ui/core/Button"
import AddRoundedIcon from "@material-ui/icons/AddRounded"
import { TabPanel } from "@material-ui/lab"
import { NavLink } from "react-router-dom"
import API from "../utils/API";
import { useUser, useAuth,useStore } from "../context"
import Moment from 'react-moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import eventBus from "../utils/eventBus"
import {Modal,Form} from 'react-bootstrap';

const useStyles = makeStyles(() => ({
  tab: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    padding: "0 10px",
    boxShadow: "0 0 15px silver",
  },
  button: {
    "&:hover": {
      color: "#fff",
    },
  },
}))




const createCopy = async (e,id) => {
  API.createProductCopy(id).then((result) => {  
       
    if(result.data!=undefined)
    {
      toast.success("Product copy created.")
      //window.location.reload();
      eventBus.dispatch("copyCreated", { message: "product copy created created" });
    }
         })
         .catch((error) => {
           //console.log(error);
           toast.error("error during create copy,try again.")
           alert(error);
        });
      };


const data = []


const options = {
  download: false,
  print: false,
  rowsPerPage: 10,
}

function ManageProducts() {
  const classes = useStyles()
  const [value, setValue] = React.useState("1")
  const [activeCompanyProduct, setActiveCompanyProduct] = useState([]);
  const [inactiveCompanyProduct, setInActiveCompanyProduct] = useState([]);
  const [pendingCompanyProduct, setPendingCompanyProduct] = useState([]);
  const [activeCompanyProductCount, setActiveCompanyProductCount] = useState(0);
  const [inactiveCompanyProductCount, setInActiveCompanyProductCount] = useState(0);
  const [pendingCompanyProductCount, setPendingCompanyProductCount] = useState(0);
  const [companyList, setCompanyList] = useState([]);
  const { companyData, storeData, getCompanyByUser, getStoreByCompany } =useStore();
 // const [selectedProduct, setSelectedProduct] = useState([]);
  const { userInfo,loggedUserInfo } = useAuth();


  
  useEffect(() => {

    if(loggedUserInfo!=undefined)
    {

      API.getAllCompany(loggedUserInfo.email).then((companyResult) => {  
       
        if(companyResult.data!=undefined && companyResult.data.length>0)
        {
          setCompanyList(companyResult.data)
         
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            });
            if(localStorage.getItem('branchFilterProduct')!=undefined && localStorage.getItem('branchFilterProduct')!=null && localStorage.getItem('branchFilterProduct')!='')
            {
              getStoreByCompany(localStorage.getItem('companyFilterProduct'));
              API.getCompanyBranchProduct(localStorage.getItem('branchFilterProduct'),"active").then((result) => {  
               
                if(result.data!=undefined)
                {
                  setActiveCompanyProduct(result.data)
                  setActiveCompanyProductCount(result.data.length)
                }
                     })
                     .catch((error) => {
                       console.log(error);
                       alert(error);
                    });
          
                    API.getCompanyBranchProduct(localStorage.getItem('branchFilterProduct'),"pending").then((result) => {  
                 
                      if(result.data!=undefined)
                      {
                        setPendingCompanyProduct(result.data)
                        setPendingCompanyProductCount(result.data.length)
                      }
                           })
                           .catch((error) => {
                             console.log(error);
                             alert(error);
                          });
          
                    API.getCompanyBranchProduct(localStorage.getItem('branchFilterProduct'),"inactive").then((result) => {  
                 
                      if(result.data!=undefined)
                      {
                        setInActiveCompanyProduct(result.data)
                        setInActiveCompanyProductCount(result.data.length)
                      }
                           })
                           .catch((error) => {
                             console.log(error);
                             alert(error);
                          });
                }
                else{
      if(loggedUserInfo.companyId!=undefined && loggedUserInfo.companyId!=null && loggedUserInfo.companyId!='')      
{
    API.getCompanyProduct(loggedUserInfo.companyId,"active").then((result) => {  
       
      if(result.data!=undefined && result.data.length>0)
      {
        setActiveCompanyProduct(result.data)
        setActiveCompanyProductCount(result.data.length)
      }
           })
           .catch((error) => {
             console.log(error);
             //alert(error);
          });

          API.getCompanyProduct(loggedUserInfo.companyId,"pending").then((result) => {  
       
            if(result.data!=undefined && result.data.length>0)
            {
              setPendingCompanyProduct(result.data)
              setPendingCompanyProductCount(result.data.length)
            }
                 })
                 .catch((error) => {
                   console.log(error);
                   //alert(error);
                });

          API.getCompanyProduct(loggedUserInfo.companyId,"inactive").then((result) => {  
       
            if(result.data!=undefined && result.data.length>0)
            {
              setInActiveCompanyProduct(result.data)
              setInActiveCompanyProductCount(result.data.length)
            }
                 })
                 .catch((error) => {
                   console.log(error);
                  // alert(error);
                });
              }
            }
              }

              eventBus.on("copyCreated", (data) =>{
                console.log("data message>>>>>>>>>>>>", data.message);

                if(localStorage.getItem('branchFilterProduct')!=undefined && localStorage.getItem('branchFilterProduct')!=null && localStorage.getItem('branchFilterProduct')!='')
                {
                  getStoreByCompany(localStorage.getItem('companyFilterProduct'));
                  API.getCompanyBranchProduct(localStorage.getItem('branchFilterProduct'),"active").then((result) => {  
                   
                    if(result.data!=undefined)
                    {
                      setActiveCompanyProduct(result.data)
                      setActiveCompanyProductCount(result.data.length)
                    }
                         })
                         .catch((error) => {
                           console.log(error);
                           alert(error);
                        });
              
                        API.getCompanyBranchProduct(localStorage.getItem('branchFilterProduct'),"pending").then((result) => {  
                     
                          if(result.data!=undefined)
                          {
                            setPendingCompanyProduct(result.data)
                            setPendingCompanyProductCount(result.data.length)
                          }
                               })
                               .catch((error) => {
                                 console.log(error);
                                 alert(error);
                              });
              
                        API.getCompanyBranchProduct(localStorage.getItem('branchFilterProduct'),"inactive").then((result) => {  
                     
                          if(result.data!=undefined)
                          {
                            setInActiveCompanyProduct(result.data)
                            setInActiveCompanyProductCount(result.data.length)
                          }
                               })
                               .catch((error) => {
                                 console.log(error);
                                 alert(error);
                              });
                    }
                    else{
                if(loggedUserInfo!=undefined && loggedUserInfo.companyId!=undefined)
                {
                  API.getCompanyProduct(loggedUserInfo.companyId,"active").then((result) => {  
       
                    if(result.data!=undefined && result.data.length>0)
                    {
                      setActiveCompanyProduct(result.data)
                      setActiveCompanyProductCount(result.data.length)
                    }
                         })
                         .catch((error) => {
                           console.log(error);
                           //alert(error);
                        });
              
                        API.getCompanyProduct(loggedUserInfo.companyId,"pending").then((result) => {  
                     
                          if(result.data!=undefined && result.data.length>0)
                          {
                            setPendingCompanyProduct(result.data)
                            setPendingCompanyProductCount(result.data.length)
                          }
                               })
                               .catch((error) => {
                                 console.log(error);
                               //  alert(error);
                              });
              
                        API.getCompanyProduct(loggedUserInfo.companyId,"inactive").then((result) => {  
                     
                          if(result.data!=undefined && result.data.length>0)
                          {
                            setInActiveCompanyProduct(result.data)
                            setInActiveCompanyProductCount(result.data.length)
                          }
                               })
                               .catch((error) => {
                                 console.log(error);
                               //  alert(error);
                              });
              }
            }
               }
               );

         
    
  }, [loggedUserInfo]);



  const handleChange = (event, newValue) => {
    setValue(newValue)
  }


  const columns = [
    {
      name: "ProductImage",
      label: " ",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
            <img height='100' width='100'  src={value}/>
          );
        }
      },
    },
    {
    name: "ProductName",
    label: "Product Name",
    options: {
      filter: true,
      sort: true,
      
    },
  },
  {
    name: "brand",
    label: "Brand",
    options: {
      filter: true,
      sort: false,
    },
  },
    {
      name: "ProductHeading",
      label: "Product heading",
      options: {
        filter: true,
        sort: true,
        
      },
    },
    {
      name: "branch",
      label: "Branch",
      options: {
        filter: true,
        sort: false,
        display: loggedUserInfo!=null && loggedUserInfo!=undefined && loggedUserInfo.isMaster!='Y' && loggedUserInfo.isAgentUser!='Y' && !loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")?true:false
      },
    },
    {
      name: "campaignName",
      label: "Campaign",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "startDate",
      label: "Start Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
          <Moment tz="CST" format="YYYY/MM/DD hh:mm:ss A">
          {value}
          </Moment>
          );
         }      
      },
      
    },
    {
      name: "endDate",
      label: "End Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
          <Moment tz="CST" format="YYYY/MM/DD hh:mm:ss A">
          {value}
          </Moment>
          );
         } 
      },
      
    },
    {
      name: "Action",
      label: "Action",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("CAN_ADD_UPDATE")) ? ( 
              <div>
              
               <Button  variant="contained" component={NavLink}
                to={"/home/Products/addProduct?id="+value} 
                color="primary">
                    Edit
            </Button>
              
            <Button  variant="contained" className="ml-2"
              onClick={(e) =>createCopy(e,value)}
              color="primary">
                  Create Copy
          </Button>
                </div>
                ) : null
             
          )
      }
      },
      
    },
    
  ]

  

  const pendingColumns = [
    {
      name: "ProductImage",
      label: " ",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
            <img height='100' width='100'  src={value}/>
          );
        }
      },
    },
    {
      name: "ProductName",
      label: "Product Name",
      options: {
        filter: true,
        sort: true,
        
      },
    },
    {
      name: "brand",
      label: "Brand",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "ProductHeading",
      label: "Product heading",
      options: {
        filter: true,
        sort: true,
        
      },
    },
    {
      name: "branch",
      label: "Branch",
      options: {
        filter: true,
        sort: false,
        display: loggedUserInfo!=null && loggedUserInfo!=undefined && loggedUserInfo.isMaster!='Y' && loggedUserInfo.isAgentUser!='Y' && !loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")?true:false
      },
    },
    
    {
      name: "Action",
      label: "Action",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("CAN_ADD_UPDATE")) ? ( 
            <div>
            
             <Button  variant="contained" component={NavLink}
              to={"/home/Products/addProduct?id="+value} 
              color="primary">
                  Edit
          </Button>
            
          <Button  variant="contained" className="mt-2"
              onClick={(e) =>createCopy(e,value)}
              color="primary">
                  Create Copy
          </Button>
              </div>
              ) : null
             
          )
      }
      },
      
    },
    
  ]
  
  const inActiveColumns = [
    {
      name: "ProductImage",
      label: " ",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
            <img height='100' width='100'  src={value}/>
          );
        }
      },
    },
  
    {
      name: "ProductName",
      label: "Product Name",
      options: {
        filter: true,
        sort: true,
        
      },
    },
    {
      name: "brand",
      label: "Brand",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "ProductHeading",
      label: "Product heading",
      options: {
        filter: true,
        sort: true,
        
      },
    },
    {
      name: "branch",
      label: "Branch",
      options: {
        filter: true,
        sort: false,
        display: loggedUserInfo!=null && loggedUserInfo!=undefined && loggedUserInfo.isMaster!='Y' && loggedUserInfo.isAgentUser!='Y' && !loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")?true:false
      },
    },
    {
      name: "campaignName",
      label: "Campaign",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "startDate",
      label: "Start Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
          <Moment tz="CST" format="YYYY/MM/DD hh:mm:ss A">
          {value}
          </Moment>
          );
        }
      },
      
    },
    {
      name: "endDate",
      label: "End Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
          <Moment tz="CST" format="YYYY/MM/DD hh:mm:ss A">
          {value}
          </Moment>
          );
         } 
      },
      
    },
    {
      name: "Action",
      label: "Action",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("CAN_ADD_UPDATE")) ? ( 
            <div>
              
              <Button  variant="contained" component={NavLink}
              to={"/home/Products/addProduct?id="+value} 
              color="primary">
                  Edit
          </Button>
          
          <Button  variant="contained"  className="mt-2 "
              onClick={(e) =>createCopy(e,value)}
              color="primary">
                  Create Copy
          </Button>
          
              </div>
              ) : null
             
          )
      }
      },
      
    },
    
  ]

  

  const activeOptions = {
    download: false,
    print: false,
    rowsPerPage: 10,
    selectableRowsOnClick: false,
    selectToolbarPlacement: "none",
    selectableRows:"none",
  }


  const options = {
    download: false,
    print: false,
    rowsPerPage: 10,
    selectableRowsOnClick: false,
    selectToolbarPlacement: "none",
    selectableRows:"none",
   /* customToolbar: () => (
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddRoundedIcon />}
        component={NavLink}
        onClick={(e) =>handleSubmit(e)}
        to={"/home/company/addCompany"}
      >
       Start Promotion
      </Button>
    ),*/
    selectableRowsOnClick: false,
    selectableRows:"none",
  
  }


  const getCompanyBranches = async (e) => {
    console.log("event>>>>>>>>>",e.target.value);
    if(e.target.value!=undefined && e.target.value!='')
    {
      localStorage.setItem('companyFilterProduct', e.target.value);
      getStoreByCompany(e.target.value);
        }
        
  };

  const handleBranchChanage = async (e) => {
    console.log("event>>>>>>>>>",e.target.value);
    if(e.target.value!=undefined && e.target.value!='')
    {
      localStorage.setItem('branchFilterProduct', e.target.value);
      API.getCompanyBranchProduct(e.target.value,"active").then((result) => {  
       
        if(result.data!=undefined)
        {
          setActiveCompanyProduct(result.data)
          setActiveCompanyProductCount(result.data.length)
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            });
  
            API.getCompanyBranchProduct(e.target.value,"pending").then((result) => {  
         
              if(result.data!=undefined)
              {
                setPendingCompanyProduct(result.data)
                setPendingCompanyProductCount(result.data.length)
              }
                   })
                   .catch((error) => {
                     console.log(error);
                     alert(error);
                  });
  
            API.getCompanyBranchProduct(e.target.value,"inactive").then((result) => {  
         
              if(result.data!=undefined)
              {
                setInActiveCompanyProduct(result.data)
                setInActiveCompanyProductCount(result.data.length)
              }
                   })
                   .catch((error) => {
                     console.log(error);
                     alert(error);
                  });
        }
        
  };



  const activeProductData = activeCompanyProduct.map((item) => ({
    ProductName: item.name,
    ProductHeading: item.heading,
    branch:item.storeName,
    campaignName:item.campaignName,
    brand:item.brand,
    createdBy:item.createdBy,
    startDate:item.validFrom,
    endDate:item.validTill,
    ProductImage:item.images[0]!=undefined && item.images[0]!="" && item.images[0]!=null?item.images[0]:"https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg",
    Action:item.id,
  }));

  const inActiveProductData = inactiveCompanyProduct.map((item) => ({
    ProductName: item.name,
    ProductHeading: item.heading,
    branch:item.storeName,
    campaignName:item.campaignName,
    brand:item.brand,
    startDate:item.validFrom,
    endDate:item.validTill,
    ProductImage:item.images[0]!=undefined && item.images[0]!="" && item.images[0]!=null?item.images[0]:"https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg",
    Action:item.id,
  }));

  const pendingProductData = pendingCompanyProduct.map((item) => ({
    ProductName: item.name,
    ProductHeading: item.heading,
    branch:item.storeName,
    brand:item.brand,
    createdBy:item.createdBy,
    ProductImage:item.images[0]!=undefined && item.images[0]!="" && item.images[0]!=null?item.images[0]:"https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg",
    Action:item.id,
  }));


  return (
    <TabContext value={value}>
      <div className={classes.tab}>
        <TabList
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="simple tabs example"
          centered
        >
          <Tab
            className={value === "1" && "font-weight-bold"}
            label={"Live ("+activeCompanyProductCount+")"}
            value="1"
          />
          <Tab
            className={value === "2" && "font-weight-bold"}
            label={"Pending ("+pendingCompanyProductCount+")"}
            value="2"
          />
          <Tab
            className={value === "3" && "font-weight-bold"}
            label={"Inactive ("+inactiveCompanyProductCount+")"} 
            value="3"
          />
        </TabList>
        {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")) ? (
        <div className="d-flex">
        <Form.Control
          as="select"
          className="mr-3"
          name="company"
          value={localStorage.getItem('companyFilterProduct')}
          onChange={getCompanyBranches}
          required
        >
          <option>Select Company</option>
          {companyList.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Form.Control>
        
        <Form.Control
          as="select"
          className="mr-sm-0"
          name="companyBranch"          
          value={localStorage.getItem('branchFilterProduct')}
          onChange={handleBranchChanage}
          required
        >
          <option>Select Branch</option>
          {storeData.map((item, index) => (
            <option key={item.id} value={item.id}>
              {item.code}
            </option>
          ))}
        </Form.Control>
        </div>
         ) : null}
         {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("CAN_ADD_UPDATE")) ? (  
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<AddRoundedIcon />}
          component={NavLink}
          to={"/home/products/addProduct"}
        >
          Add Product
        </Button>
         ) : null}
      </div>
      <TabPanel value="1">
        <MUIDataTable
          title={"Live Products"}
          data={activeProductData}
          columns={columns}
          options={activeOptions}
        />
      </TabPanel>
      <TabPanel value="2">
        <MUIDataTable
          title={"Pending Products"}
          data={pendingProductData}
          columns={pendingColumns}
          options={options}
        />
      </TabPanel>
      <TabPanel value="3">
        <MUIDataTable
          title={"Inactive Products"}
          data={inActiveProductData}
          columns={inActiveColumns}
          options={options}
        />
      </TabPanel>
      <ToastContainer />
    </TabContext>
  )
}
export default ManageProducts
