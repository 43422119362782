
import { Form, InputGroup } from "react-bootstrap"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import React, { useEffect, useState } from "react";
import { useAuth, useUser, useStore } from "../context";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Navigation } from "@material-ui/icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import {createUser} from "../utils/API";
import API from "../utils/API";
import { useForm, useStep } from "react-hooks-helper";
import { useHistory } from "react-router-dom";
import { uuid } from '../utils/uuid'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { tr } from "date-fns/locale";
import { toDate } from "date-fns";
import PaypalExpressBtn from "react-paypal-express-checkout";
import { PayPalButton } from "react-paypal-button-v2";
import { clientNew } from "../utils/paypalConfig";


const defaultData = {
  promotionArea: "",
  cityList:[],
  stateList: [],
  promocode:"",
  fromDate:"",
  toDate:"",
  
};



function StartPromotion(props) {
  const offerId=new URLSearchParams(props.location.search).get("id");
  const [form, setForm] = useForm(defaultData);
  const [validated, setValidate] = useState(false);
  const { signUp, userInfo, loggedUserInfo } = useAuth();
  const { categoryData, storeData, getMainCategories, getStoreByCompany } = useStore();
  const { promotionArea, cityList, stateList,promocode,fromDate,toDate } = form;
  const [selectedCity, setSelectedCity] = React.useState([])
  const [selectedState, setSelectedState] = React.useState([])
  const [stateCityCount, setstateCityCount] = React.useState(0)
  const [offerStateData, setOfferstateData] = React.useState()
  const [discountValue, setDiscountValue] = React.useState(0)
  const [isCustomPlan, setCustomPlan] = React.useState(false)
  const [discountType, setDiscountType] = React.useState(0)
  const [campaignPlan, setCampaignPlan] = React.useState([])
  const [selectedCampaignPlan, setSelectedCampaignPlan] = React.useState()
  const [cities,setCities]=useState([]);
  const { stateData, getState} =useStore();
  const animatedComponents = makeAnimated();
  const [offerData, setOfferData] = React.useState([])
  const [offerStoreData, setOfferStoreData] = React.useState()
  
  useEffect(() => {
    if (loggedUserInfo != undefined) {
      getState(loggedUserInfo.companyId);
      API.getAllCities(loggedUserInfo.companyId).then((result) => {  
       
        if(result.data!=undefined && result.data.length>0)
        {
          setCities(result.data);
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            });

            API.getCampaignPlan(loggedUserInfo.companyId,"offer").then((planResult) => {  
       
              if(planResult.data!=undefined && planResult.data.length>0)
              {
                setCampaignPlan(planResult.data);
              }
                   })
                   .catch((error) => {
                     console.log(error);
                     alert(error);
                  });

                  API.getOfferById(loggedUserInfo.email,offerId).then((result) => {  
       
                    console.log("offer data>>>>>>>>>>>>>>",result)
                    if(result.data!=undefined)
                    {
                     
                      setOfferData(result.data)

                      API.getStoreById(result.data.storeId).then((storeData) => {  

                        if(storeData.data!=undefined)
                        {
                          setOfferStoreData(storeData.data);
                          API.getStateById(storeData.data.stateId).then((stateResult) => {  
       
                            if(stateResult.data!=undefined)
                            {
                      
                              setOfferstateData(stateResult.data);
                            }
                                 })
                                 .catch((error) => {
                                   console.log(error);
                                   alert(error);
                                });
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                        alert(error);
                     });
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    alert(error);
                 });

    }
  }, [loggedUserInfo]);

  let cityOption=[];
  cities.map((item) => {
    let tempObj ={"label":item.name,"value":item.id}
    cityOption.push(tempObj)
   
  });

  let stateOption=[];
  stateData.map((item) => {
    let tempObj ={"label":item.name,"value":item.id}
    stateOption.push(tempObj)
   
  });

  const history = useHistory();


  const handleSubmit = async (e) => {

    let offerBody=
    {
      id:"dasd",
      price:"20"
    };
      
    API.makeOfferPayment(userInfo.email,offerBody)
    if(selectedCampaignPlan==undefined || selectedCampaignPlan==null || selectedCampaignPlan=='')
    {
      toast.error("Please select campaign plan")
    }

  };

  const handleCustomPlan = async (e) => {
      
    setCustomPlan(true);
    setSelectedCampaignPlan("")

  };

  const createCustomPlan = async (e) => {

    var date1 = new Date(form.fromDate);
    var date2 = new Date(form.toDate);
    var currentDate=new Date();
    var day = currentDate.getDate()
    var month = currentDate.getMonth() + 1
  var year = currentDate.getFullYear()
  var newCurrentDate=year+"-"+month+"-"+day;
  var today = new Date(newCurrentDate);
  console.log("currentDate date>>>>>>>>>>>",newCurrentDate)
  console.log("form.fromDate date>>>>>>>>>>>",form.fromDate)
    console.log("currentDate date>>>>>>>>>>>",today.getTime())
    console.log("date1 date>>>>>>>>>>>",date1.getTime())
      
    if(form.fromDate==undefined || form.fromDate==null || form.fromDate=='')
    {
      toast.error("Please enter from date")
    }
    else if(today.getTime()>date1.getTime())
    {
      toast.error("Selected from date is invalid")
    }

    else if(form.toDate==undefined || form.toDate==null || form.toDate=='')
    {
      toast.error("Please enter to date")
    }

    else if(date2<date1)
    {
      toast.error("from date can not greater than to date")
    }

    else if(form.toDate!='' && form.fromDate!='' && today<=date1 && date2>date1)
    {
      console.log("to date>>>>>>>>>>>",form.toDate)
      console.log("from date>>>>>>>>>>>",form.fromDate)
      var from = new Date(form.toDate);
      var to = new Date(form.fromDate);
      const diffTime = Math.abs(to - from);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
      console.log(diffTime + " milliseconds");
      console.log(diffDays + " days");
      const temp={"days":diffDays,price:diffDays*1}
      setSelectedCampaignPlan(temp);


    }

  };

  const handleCityChange = (selectedOptions) => {
    form.cityList = [];
    console.log("selectedOptions>>>>>>>>>>>>>>>>", selectedOptions)
    setSelectedCity(selectedOptions)
    selectedOptions.map((item1) => {
    form.cityList.push(item1.value);
    });
    //form.weeklyOff=selectedOptions.value;
    // console.log("selectedOptions>>>>>>>>>>>>>>>>",form.mainCategory)

  };

  const handleStateChange = (selectedOptions) => {
    form.stateList = [];
    console.log("selectedOptions>>>>>>>>>>>>>>>>", selectedOptions)
    setSelectedState(selectedOptions)
    var totalCity=0;
    selectedOptions.map((item1) => {
    form.stateList.push(item1.value);
    API.getCitiesByState("gust",item1.value).then((result) => {  
       
      if(result.data!=undefined && result.data.length>0)
      {
        totalCity=totalCity+result.data.length
        setstateCityCount(totalCity);
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });
    });
    
  
    //form.weeklyOff=selectedOptions.value;
    // console.log("selectedOptions>>>>>>>>>>>>>>>>",form.mainCategory)

  };
  const  handlePlanChange= (selectedOptions) => {
    console.log("selectedPlan>>>>>>>>>>>>>>>>",selectedOptions.target.value)
    campaignPlan.map((item, index) => (
      selectedOptions.target.value==item.id?setSelectedCampaignPlan(item):""

      ))
     
      setCustomPlan(false);
      console.log("setSelectedCampaignPlan>>>>>>>>>>>>>>>>",selectedCampaignPlan)
  };
  

  const  handleApplyPromocode= (selectedOptions) => {

    if(form.promocode!=undefined && form.promocode!=null && form.promocode!='')
    {
    API.checkPromocode(form.promocode).then((result) => {  
       
      console.log("promocode response>>>>>>>>>>>>>>>>",result.data)
      if(result.data!=undefined)
      {
        if(result.data!='' && result.data!=null)
        {
          toast.success("promocode apply successfully");
          setDiscountValue(result.data.discount)
          setDiscountType(result.data.type)

        }
        else
        {
          toast.error("Invalid promocode");
        }
       
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });
        }
        else
        {
          toast.error("Please enter promocode");
        }
    
      console.log("handleApplyPromocode>>>>>>>>>>>>>>>>",selectedCampaignPlan)
  };

  const  handleRemovePromocode= (selectedOptions) => {
    setDiscountValue(0)
    toast.success("promocode remove successfully");

  };

  let env = 'sandbox'; // you can set here to 'production' for production
  let currency = 'CAD'; // or you can set this value from your props or state
  let total =0; // same as above, this is the total amount (based on currency) to be paid by using Paypal express checkout
  let invoiceAmount=0;
  let gst = "0";
	let hst = "0";
	let qst = "0";
	let pst = "0";
  let invoiceTax=0;
  let discountAmount=0;
  if(selectedCampaignPlan!=undefined && selectedCampaignPlan!='' && selectedCampaignPlan!=null)
  {
    if(form.promotionArea == "city")
    {
      total=selectedCampaignPlan.price*cityList.length
    }
    else if(form.promotionArea == "state")
    {
      total=selectedCampaignPlan.price*stateCityCount
    }
    else if(form.promotionArea == "country")
    {
      total=selectedCampaignPlan.price*cities.length
      invoiceAmount=total;
    }

    if(discountValue!=undefined && discountValue!=null && discountValue!=0)
    {
      if(discountType=="F")
      {
        discountAmount=discountValue;
      }
      else
      {
        discountAmount=(total*discountValue)/100
      } 

      console.log("discountAmount>>>>>>>>>>>>>>>",discountAmount)
      console.log("total>>>>>>>>>>>>>>>",total)
      total=total-discountAmount;

    } 
       
      if(offerStateData!=undefined)
      {
        var totalTax=offerStateData.totalTax
        var taxAmount=(total*totalTax)/100;
        console.log("totalTax>>>>>>>>>>>>>>>",totalTax)
      console.log("taxAmount>>>>>>>>>>>>>>>",taxAmount)
        total=total+taxAmount;
        console.log("total>>>>>>>>>>>>>>>",total)
        invoiceTax=taxAmount;
        gst=offerStateData.gst;
        hst=offerStateData.hst;
        pst=offerStateData.pst;
        qst=offerStateData.qst;
      }

      if(total!=undefined && total!=null)
      {
        total=Math.round((total + Number.EPSILON) * 100) / 100
        console.log("total after round>>>>>>>>>>>>>>>",total)
      }
       

  }
  
  // Document on Paypal's currency code: https://developer.paypal.com/docs/classic/api/currency_codes/

  const client = {
      sandbox:    'AdElUsjWuE6MJ9FqCG78ig4hYeUswF3avBEkOa3lXt6ayBxKl0Jp1nEepFePV053MFbQOEzVaJ2FneMm',
      production: 'YOUR-PRODUCTION-APP-ID',
  }



  const onSuccess = (payment) => {
    // Congratulation, it came here means everything's fine!
        console.log("The payment was succeeded!", payment);
        var paymentType="";
        var paymentId="";
        var payerId="";
        var paymentStatus="";
        if(payment.intent!=undefined && payment.intent!=null && payment.intent=="CAPTURE")
        {
          paymentType="Card";
          paymentId=payment.id;
          payerId=payment.payer.payer_id;
          if(payment.status=="COMPLETED")
          {
            paymentStatus="Paid"
          }
          else
          {
            paymentStatus="Fail"
          }
        }
        else
        {
          paymentType="PayPal";
          paymentId=payment.paymentID;
          payerId=payment.payerID; 
          if(payment.paid)
          {
            paymentStatus="Paid"
          }
          else
          {
            paymentStatus="Fail"
          }
        }
        var validFrom=new Date();
        var toDate=new Date(validFrom);
        toDate.setDate(toDate.getDate()+ Number(selectedCampaignPlan.days));
        console.log("The payment validFrom", validFrom);
        console.log("The payment was toDate!", toDate);
        let offerBody = {
          id:offerId,
          heading:offerData.heading,
          subHeading:offerData.subHeading,
          description:offerData.description,
         validFrom:validFrom,
          validTill:toDate,
          fullImage:offerData.fullImage,
          active:"Y",
          categories:offerData.categories,
          storeId:offerData.storeId,
          campaignArea:form.promotionArea,
          cityList:cityList,
          stateList:stateList,
          //createdBy:userInfo.email,
          updatedBy:userInfo.email,
          companyId:offerData.companyId
         };

         let ptBody = {
          id:uuid(),
          transactionType:"Offer Campaign",
          status:paymentStatus,
          offerId:offerId,
          userId:userInfo.email,
          companyId:offerData.companyId,
          storeId:offerData.storeId,
          paymentId:paymentId,
          payerId:payerId,
          paymentType:paymentType,
          amount:total,
         };

         let invoiceBody = {
          id:uuid(),
          invoiceType:"Offer Campaign",
          status:paymentStatus,
          offerId:offerId,
          userId:userInfo.email,
          companyId:offerData.companyId,
          storeId:offerData.storeId,
          paymentId:paymentId,
          payerId:payerId,
          paymentType:paymentType,
          amount:total,
          discountAmount:discountAmount,
          taxAmount:invoiceTax,
          gst:gst,
          hst:hst,
          pst:pst,
          qst:qst,
         };

         API.updateOffer(userInfo.email,offerBody);
         API.createPaymentTransaction(userInfo.email,ptBody);

         API.createInvoice(userInfo.email,invoiceBody);

         toast.success("yah!! offer live successfully")
         setTimeout(() => { 
          history.push(`/home/offers`)
         // toast.success("yah!! offer live successfully")
        
        }, 3000);

        // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
}

const onCancel = (data) => {
    // User pressed "cancel" or close Paypal's popup!
    console.log('The payment was cancelled!', data);
    toast.error("Payment cancelled")
    let ptBody = {
      id:uuid(),
      transactionType:"Offer Campaign",
      status:"Cancelled",
      offerId:offerId,
      userId:userInfo.email,
      companyId:offerData.companyId,
      storeId:offerData.storeId,
      paymentId:data.paymentID!=undefined && data.paymentID!='' && data.paymentID!=null?data.paymentID:data.orderID,
      payerId:data.payerID,
      paymentType:"PayPal",
      amount:total,
     };
     API.createPaymentTransaction(userInfo.email,ptBody);
    // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
}

const onError = (err) => {
    // The main Paypal's script cannot be loaded or somethings block the loading of that script!
    toast.error("Error,please try again")
    console.log("Error!", err);
    let ptBody = {
      id:uuid(),
      status:"Error",
      transactionType:"Offer Campaign",
      offerId:offerId,
      userId:userInfo.email,
      companyId:offerData.companyId,
      storeId:offerData.storeId,
      paymentId:err.paymentID,
      payerId:err.payerID,
      paymentType:"PayPal",
      amount:total,
     };
     API.createPaymentTransaction(userInfo.email,ptBody);
    // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
    // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
}

  return (
    <Form noValidate validated={validated} action="#">
      <h4 class="mb-3"><strong>Promote Offer listing</strong></h4> 
      <div className="row mb-4">
        <div className="col-md-6">
          <div className="bg-white p-4 shadow-sm rounded-lg">
          <p>City State/Province All-Over {localStorage.getItem("connectSite")=="CA"?"Canada":"US"}.</p>
          <Form.Group>
            <Form.Check
              inline
              label="City"
              value="city"
              name="promotionArea"
              type="radio"
              onChange={setForm}
              id="city"
            />
            <Form.Check
              inline
              label="State"
              value="state"
              name="promotionArea"
              type="radio"
              onChange={setForm}
              id="state"
            />
             <Form.Check
              inline
              label="Canada Country"
              value="country"
              name="promotionArea"
              type="radio"
              onChange={setForm}
              id="country"
            />
          </Form.Group>
          {form.promotionArea == "city" && (
          <Form.Group className="col">
        <Form.Label>Select City</Form.Label>
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          isMulti
          name="cityList"
          value={selectedCity}
          options={cityOption}
          onChange={handleCityChange}
      />
        </Form.Group>
          )}
          {form.promotionArea == "state" && (
        <Form.Group className="col">
        <Form.Label>Select State</Form.Label>
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          isMulti
          name="stateList"
          value={selectedState}
          options={stateOption}
          onChange={handleStateChange}
      />
        </Form.Group>
         )}
            {/*<h5>Daily budget</h5>
            <p>Actual amount spent daily may vary.</p>*/}
            <h5>Campaign Plan List</h5>
            {cityList.length>0 || stateList.length>0  || form.promotionArea == "country"?  (
            campaignPlan.map((item, index) => (
            <div className="amount-wrapper">
              <div className="amount">
                <div className="left-part">
                {form.promotionArea == "city"?  (
                  <div className="amounts">${item.price*cityList.length}</div>
                  ):""}
                  {form.promotionArea == "state"?  (
                  <div className="amounts">${item.price*stateCityCount}</div>
                  ):""}
                  {form.promotionArea == "country"?  (
                  <div className="amounts">${item.price*cities.length}</div>
                  ):""}
                  <div className="devider"></div>
                  <div className="reach">
                   {/* <div>Estimated reach 354-1,023</div>*/}
                    <div>{item.days}-day campaign</div>
                  </div>
                </div>
                <div className="right-part">
                  <InputGroup>
                  <Form.Group>
                  <Form.Check
                    inline
                    label=""
                    name="campaignPlan"
                    type="radio"
                    value={item.id}
                    onChange={handlePlanChange}
                    id={"inline-radio-"+index}
                    
                  />
                   </Form.Group>
                  </InputGroup>
                </div>
              </div>
              
            </div>
            
            ))
            ):"Please select Promotion Area to select Campaign Plan with price details."}
            {/*<p className=" reach text-right mt-3 mb-0">Select custom budget and duration</p>*/}
            {cityList.length>0 || stateList.length  || form.promotionArea == "country"?  (
            <Button variant="contained" color="primary" onClick={(e) =>handleCustomPlan(e)} className="btn btn-primary">Create custom Plan</Button>
            
            ):""}
            <br/>
              {isCustomPlan?  (
                 <InputGroup className="mb-3">
                   <Form.Group className="col-4">
                   <Form.Label>From Date</Form.Label>
                   <Form.Control 
                   name="fromDate"
                   value={fromDate}
                   onChange={setForm}
                   type="date" 
                   required
                   />
                    <Form.Control.Feedback type="invalid" >Please enter Valid from</Form.Control.Feedback>
                 </Form.Group>
         
                 <Form.Group className="col-4">
                   <Form.Label>To date</Form.Label>
                   <Form.Control  name="toDate"
                   value={toDate}
                   onChange={setForm}
                   type="date"
                   required
                   />
                    <Form.Control.Feedback type="invalid" >Please enter valid till</Form.Control.Feedback>
                 </Form.Group>
                 <Button variant="contained" color="primary" onClick={(e) =>createCustomPlan(e)} className="btn btn-primary">Set Plan</Button>
                 </InputGroup>
                  ):""}
          </div>
        </div>

        <div className="col-md-6">
            <div >
              <h5>Payment Summary</h5>
              {selectedCampaignPlan!=undefined && selectedCampaignPlan!='' && selectedCampaignPlan!=null ?  (
                <div>
                  <div className="amount-wrapper bg-white">
              <div className="reach">Your ad will run for {selectedCampaignPlan.days} days</div>
              <div className="budget">
                <div>
                  <h6>Total Budget</h6>
                 {/* <div>$2.00 a day x {selectedCampaignPlan.days} days</div>*/}
                </div>
                {form.promotionArea == "city"?  (
                  <div className="total">${(selectedCampaignPlan.price*cityList.length)}</div>
                  ):""}
                  {form.promotionArea == "state"?  (
                  <div className="amounts">${(selectedCampaignPlan.price*stateCityCount)}</div>
                  ):""}
                  {form.promotionArea == "country"?  (
                  <div className="amounts">${(selectedCampaignPlan.price*cities.length)}</div>
                  ):""}
              </div>
              {discountValue!=undefined && discountValue!=null && discountValue!=0 ?  (
              <div>
              <div className="budget">
                <div>
                  <h6>Discount</h6>
                 {/* <div>$2.00 a day x {selectedCampaignPlan.days} days</div>*/}
                </div>
                
                  <div className="total">{discountType=="F"? "$"+discountValue: discountValue+"%"}</div>
                 
              </div>
              <div className="budget">
                <div>
                  <h6>Total Budget after Discount</h6>
                 {/* <div>$2.00 a day x {selectedCampaignPlan.days} days</div>*/}
                </div>
                {form.promotionArea == "city"?  (
                  <div className="total">${discountType=="F"?(selectedCampaignPlan.price*cityList.length)-discountValue:(selectedCampaignPlan.price*cityList.length)-((selectedCampaignPlan.price*cityList.length)-discountValue)/100}</div>
                  ):""}
                  {form.promotionArea == "state"?  (
                  <div className="amounts">${discountType=="F"?(selectedCampaignPlan.price*stateCityCount)-discountValue:(selectedCampaignPlan.price*stateCityCount)-((selectedCampaignPlan.price*stateCityCount)*discountValue)/100}</div>
                  ):""}
                  {form.promotionArea == "country"?  (
                  <div className="amounts">${discountType=="F"?(selectedCampaignPlan.price*cities.length-discountValue):(selectedCampaignPlan.price*cities.length)-((selectedCampaignPlan.price*cities.length)*discountValue)/100}</div>
                  ):""}
              </div>
              </div>
              ):""}
                    </div>


                    {form.promotionArea == "state" || form.promotionArea == "country" ?  (
                      <div>
                        <InputGroup className="mb-3">
              <Form.Group className="ml-4">
        <Form.Label >Enter promoccode (if you have)</Form.Label>
        <Form.Control
          type="text"
          readOnly={discountValue!=undefined && discountValue!=null && discountValue!=0?true:false}
          name="promocode"
          value={promocode}
          onChange={setForm}
        />
      </Form.Group>
      <Form.Group  className="ml-2 mt-4">
      <Form.Label ></Form.Label>
      {discountValue==undefined || discountValue==null || discountValue==0 ?  (
      <Button className=" ml-1" color="primary" onClick={handleApplyPromocode}>
      Apply Promocode
    </Button>
      
         ):<Button className=" ml-1" color="primary" onClick={handleRemovePromocode}>
         Remove Promocode
       </Button>}
       </Form.Group>
       </InputGroup>
        </div>
         ): ""}
         
{selectedCampaignPlan!=undefined && selectedCampaignPlan!='' && selectedCampaignPlan!=null ?  (
  <div class="float-center mb-4">
{/* <PaypalExpressBtn  env={env} client={client} currency={currency} total={total} onError={onError} onSuccess={onSuccess} onCancel={onCancel} />*/}
<PayPalButton  options={clientNew}  shippingPreference={"NO_SHIPPING"} vault={false} amount={total} onError={onError} onSuccess={onSuccess} onCancel={onCancel} />
</div>
):""}
              </div>

              
              ):"Please select Campaign Plan ."}
            </div>
          </div>
        </div>



     {/* <div className="row">
        <div className="col-md-6">
          <div className="amount-wrapper bg-white">
            <h5>Ad Library visibility
              
            </h5>
            <div className="reach">Your ad and information from your Marketplace profile may appear publicly in Ad Library search results. <a href="">Learn more</a></div>
          </div>
        </div>
  </div>*/}

       {/* <div className="row">
        <div className="col-md-6">
          <div className="amount-wrapper bg-white">
            <h5>Payment Method</h5>
            <div className="reach">You may receive more then one bill for this ad. Learn more <a href="">Learn more</a></div>
            <div className="card-deatils">
              <div className="card-side">
              <img src="https://static-00.iconduck.com/assets.00/visa-icon-512x322-wb39y8f5.png" alt="" className="mr-3" />
              <b>....4383</b>
              </div>
              <Button variant="contained" class="btn btn-secondary  ">Change</Button>
            </div>
          </div>
        </div>
      </div>*/}

      <div className="promote-now amount-wrapper bg-white d-flex justify-content-between flex-wrap align-items-center">
        <div>By clicking Promote Now, you agree to Offer Dhamaka <a href="">Terms & Condition</a> | <a href="">Help Center</a></div>

        {selectedCampaignPlan==undefined || selectedCampaignPlan=='' || selectedCampaignPlan==null ?  (
      <Button variant="contained" color="primary" onClick={(e) =>handleSubmit(e)} className="btn btn-primary">Promote Now</Button>
      ):""}


      </div>
      <ToastContainer />
    </Form>
  )
}

export default StartPromotion

// const style = {
//   form: {
//     backgroundColor: "white",
//     padding: "25px",
//     boxShadow: "0 0 15px silver",
//   },
// }
