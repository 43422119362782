import { createContext, useContext, useReducer } from "react"
import API from "../utils/API"

export const UserContext = createContext({})
export const useUser = () => useContext(UserContext)

const userActions = {
  MAKE_REQUEST: "MAKE_REQUEST",
  SET_USER_DATA: "SET_USER_DATA",
  SET_ROLE: "SET_ROLE",
  SET_PRIVILEGE: "SET_PRIVILEGE",
  SET_ROLES: "SET_ROLES",
  SET_USERS: "SET_USERS",
  ERROR: "ERROR",
}

const initialState = {
  isLoading: false,
  user: [],
  roles: [],
  users:[],
  privilege: [],
  error: "",
}

const reducer = (state, action) => {
  switch (action.type) {
    case userActions.MAKE_REQUEST:
      return { ...state, isLoading: true }
    case userActions.SET_USER_DATA:
      return { ...state, user: action.payload, isLoading: false }
    case userActions.SET_ROLE:
      return { ...state, role: action.payload, isLoading: false }
    case userActions.SET_PRIVILEGE:
      return { ...state, privilege: action.payload, isLoading: false }
      case userActions.SET_ROLES:
        return { ...state, roles: action.payload, isLoading: false }  
        case userActions.SET_USERS:
          return { ...state, users: action.payload, isLoading: false }  
    default:
      return state
  }
}

export const UserDataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const getPrivilege = async (user_id) => {
    try {
      dispatch({ type: userActions.MAKE_REQUEST })
      const response = await API.getPrivilege(user_id)
      dispatch({ type: userActions.SET_PRIVILEGE, payload: response.data })
    } catch (error) {
      console.log(error)
    }
  }

  const addRole = async (data) => {
    try {
      dispatch({ type: userActions.MAKE_REQUEST })
      const response = await API.addRole(data)
      console.log(response)
    } catch (error) {
      console.log(error)
    }
  }

  const getRoles = async (user_id) => {
    try {
      dispatch({ type: userActions.MAKE_REQUEST })
      const response = await API.getRoles(user_id)
      dispatch({ type: userActions.SET_ROLES, payload: response.data })
    } catch (error) {
      console.log(error)
    }
  }

  const getUsersByCompany = async (user_id) => {
    try {
      dispatch({ type: userActions.MAKE_REQUEST })
      const response = await API.getUsersByCompany(user_id)
      dispatch({ type: userActions.SET_USERS, payload: response.data })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <UserContext.Provider
      value={{
        ...state,
        getPrivilege,
        addRole,
        getRoles,
        getUsersByCompany
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
