import React, { useEffect, useState } from "react"
import { useStore, useAuth } from "../context"
import MUIDataTable from "mui-datatables"
import Button from "@material-ui/core/Button"
import AddRoundedIcon from "@material-ui/icons/AddRounded"
import { NavLink } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom";
import AddStore from "../screens/AddStore"
//import Modal from "react-modal";
import {Modal} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import API from "../utils/API";
import AddStorePolicy from "../screens/AddStorePolicy"

const useStyles = makeStyles(() => ({
  button: {
    "&:hover": {
      color: "#fff",
    },
  },
}))



function Stores({ data, selectedCompany }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [selectedStore, setSelectedStore] = useState("");
  const { loggedUserInfo } = useAuth();
  const { storeData,getStoreByCompany} =
    useStore();



  const rowData = storeData.map((item) => ({
    storeName: item.storeName,
    branch:item.code,
    managerName: item.managerName,
    mobileNo: item.managerMobile,
    action:item,
  }))


  function toggleModal(e,value) {
      
    setIsOpen(!isOpen);
    setSelectedStore(value)
    //isOpen=true;
    
  }
  function handleClose() {
    setIsOpen(false);
  }

  
  function toggleModal1(e,value) {
      
    setIsOpen1(!isOpen);
    setSelectedStore(value)
    //isOpen=true;
    
  }

  function storeApprove(e,value) {
      
    if(window.confirm('Are you sure to approve this store!'))
    {
    API.activeStoreCampaign(value).then((CatResult) => {  

      if(CatResult.data!=undefined)
      {
        if(data!=undefined&&data!=null&&data.length>0)
        getStoreByCompany(data[0].companyId);
      }

  })
  .catch((error) => {
    console.log(error);
    alert("Something went wrong! try again.");
 });
    }
   
      
   

    //isOpen=true;
    
  }


  function storeUnApprove(e,value) {
      
    if(window.confirm('Are you sure to un approve this store!'))
    {
    API.unactiveStoreCampaign(value).then((CatResult) => {  

      if(CatResult.data!=undefined)
      {
        if(data!=undefined&&data!=null&&data.length>0)
        getStoreByCompany(data[0].companyId);
      }

  })
  .catch((error) => {
    console.log(error);
    alert("Something went wrong! try again.");
 });
    }
   
      
   

    //isOpen=true;
    
  }

  function handleClose1() {
    setIsOpen1(false);
  }

  const columns = [
    {
      name: "storeName",
      label: "Store Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "branch",
      label: "Branch Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "managerName",
      label: "Manger Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "mobileNo",
      label: "Mobile Number",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          console.log("action value>>>>>>>>>>",value.id);
          return (
            <>
             <div>
            {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.privilegeCode.includes("ADD_UPDATE_COMPANY") || loggedUserInfo.isAgentUser=='Y' ||  loggedUserInfo.privilegeCode.includes("CAN_ADD_UPDATE") ) ? (
           <>
            <Button
            variant="contained"
            color="primary"
            //className={classes.button}
           // startIcon={<AddRoundedIcon />}
            //   size="small"
           // component={NavLink}
           // onClick={toggleModal}
           onClick={(e) =>toggleModal(e,value.id)}
            style={{justifyContent: 'right'}}
            size="small"
            to={"/home/addStore"}
          >
            Edit Store/Branch
          </Button> 
          <Button
            variant="contained"
            color="primary"
            className="ml-2"
            size="small"
            onClick={(e) =>toggleModal1(e,value.id)}
            style={{justifyContent: 'right'}}
            to={"/home/addStore"}
          >
            Add/Edit Store Policy
          </Button> 
          </>
        
        ) : null}

        {loggedUserInfo!=null && loggedUserInfo!=undefined && loggedUserInfo.isMaster=='Y' && value.isApprove!="Y"?
          <Button
          variant="contained"
          color="primary"
          className="ml-2"
          size="small"
          onClick={(e) =>storeApprove(e,value.id)}
          style={{justifyContent: 'right'}}
          to={"/home/addStore"}
          >
          Approve
          </Button> 
        :""}
        {loggedUserInfo!=null && loggedUserInfo!=undefined && loggedUserInfo.isMaster=='Y' && value.isApprove=="Y"?
          <Button
          variant="contained"
          color="primary"
          className="ml-2"
          size="small"
          onClick={(e) =>storeUnApprove(e,value.id)}
          style={{justifyContent: 'right'}}
          to={"/home/addStore"}
          >
         Un Approve
          </Button> 
        :""}
        </div>
        </> 
          );
        }
      },
    },
  ]
  
  const options = {
    rowsPerPage: 10,
    print: false,
    selectableRowsOnClick: false,
    selectToolbarPlacement: "none",
    selectableRows:"none",
  }

  return (
<>
    <MUIDataTable
      title={`${selectedCompany}'s Stores`}
      data={rowData}
      columns={columns}
      options={options}
    />
<Modal
          show={isOpen}
          onHide={handleClose}
          centered
        >
          <AddStore  storeId={selectedStore} setIsOpen={setIsOpen}  />;
        </Modal>
    <Modal
          show={isOpen1}
          onHide={handleClose1}
          centered
        >
          <AddStorePolicy  storeId={selectedStore} setIsOpen={setIsOpen1}  />;
        </Modal>
         </> 
  )
}

export default Stores
