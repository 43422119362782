import React,{ useEffect, useState } from "react"
import MUIDataTable from "mui-datatables"
import { makeStyles } from "@material-ui/core/styles"
import Tab from "@material-ui/core/Tab"
import TabContext from "@material-ui/lab/TabContext"
import TabList from "@material-ui/lab/TabList"
import Button from "@material-ui/core/Button"
import AddRoundedIcon from "@material-ui/icons/AddRounded"
import { TabPanel } from "@material-ui/lab"
import { NavLink } from "react-router-dom"
import API from "../utils/API";
import { useUser, useAuth,useStore } from "../context"
import Moment from 'react-moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import eventBus from "../utils/eventBus"
import {Modal,Form} from 'react-bootstrap';
import CampaignDeatils from "./CampaignDeatils"
import ViewCampaignAreaList from "./ViewCampaignAreaList"
import CampaignAnalytics from "./CampaignAnalytics"

const useStyles = makeStyles(() => ({
  tab: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    padding: "0 10px",
    boxShadow: "0 0 15px silver",
  },
  button: {
    "&:hover": {
      color: "#fff",
    },
  },
}))




var pageNumber=0;

function CampaignList() {
  const classes = useStyles()
  const [value, setValue] = React.useState("1")
  const [campaignTab, setCampaignTab] = React.useState("1")
  const [activeCampaign, setActiveCampaign] = useState([]);
  const [inactiveCampaign, setInactiveCampaign] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [isExpended, setIsExpended] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("campaign");
  const [selectedItem, setSelectedItem] = useState("");
  const { companyData, storeData, getCompanyByUser, getStoreByCompany } =useStore();
 // const [selectedProduct, setSelectedProduct] = useState([]);
  const { userInfo,loggedUserInfo } = useAuth();


  
  useEffect(() => {

    if(loggedUserInfo!=undefined)
    {

      API.getAllCompany(loggedUserInfo.email).then((companyResult) => {  
       
        if(companyResult.data!=undefined && companyResult.data.length>0)
        {
          setCompanyList(companyResult.data)
         
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            });

      if(localStorage.getItem('branchFilterCampaign')!=undefined && localStorage.getItem('branchFilterCampaign')!=null && localStorage.getItem('branchFilterCampaign')!='')
    {
      getStoreByCompany(localStorage.getItem('companyFilterCampaign'));
      
      API.getCompanyBranchCampaign(localStorage.getItem('branchFilterCampaign'),"active","flyer").then((result) => {  
            
        if(result.data!=undefined && result.data.length>0)
        {
          setActiveCampaign(result.data);
        }
        else
        {
          setActiveCampaign([]);
        }
    
      })
      .catch((error) => {
        console.log(error);
        alert(error);
    });

    API.getCompanyBranchCampaign(localStorage.getItem('branchFilterCampaign'),"inactive","flyer").then((result1) => {  
      
      if(result1.data!=undefined && result1.data.length>0)
      {
        setInactiveCampaign(result1.data);
      }
      else
      {
        setInactiveCampaign([]);
      }
  
    })
    .catch((error) => {
      console.log(error);
      alert(error);
  });

        }
        else{
      if(loggedUserInfo.companyId!=undefined && loggedUserInfo.companyId!=null && loggedUserInfo.companyId!='')      
  {   
            API.getCompanyCampaign(loggedUserInfo.companyId,"active","flyer").then((result) => {  
            
              if(result.data!=undefined && result.data.length>0)
              {
                setActiveCampaign(result.data);
              }
          
            })
            .catch((error) => {
              console.log(error);
              alert(error);
          });

          API.getCompanyCampaign(loggedUserInfo.companyId,"inactive","flyer").then((result1) => {  
            
            if(result1.data!=undefined && result1.data.length>0)
            {
              setInactiveCampaign(result1.data);
            }
        
          })
          .catch((error) => {
            console.log(error);
            alert(error);
        });

      

         
              }
            }
              }

            

         
    
  }, [loggedUserInfo]);



  const handleChange = (event, newValue) => {
    setValue(newValue)
    setIsExpended(false)
  }

  const handleCampaignTabChange = (event, newValue) => {
    setCampaignTab(newValue)
  }

 
  const columns = [
    {
      name: "id",
      options: {
        display: false,
      }
    },
    {
    name: "campaignName",
    label: "Campaign Name",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (item, tableMeta, updateValue) => {
        return (
          
          <div className="d-flex align-items-center">
         <a href="#"  onClick={(e) =>showChart(item.id)}>{item.campaignName}</a>
          
          </div>
               
           
           
        )
      }
      
    },
  },
  {
    name: "type",
    label: "Campaign Type",
    options: {
      filter: true,
      sort: true,
      display: false,
      
    },
  },
  
    {
      name: "startDate",
      label: "Start Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
          <Moment tz="CST" format="YYYY/MM/DD hh:mm:ss A">
          {value}
          </Moment>
          );
         }      
      },
      
    },
    {
      name: "endDate",
      label: "End Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
          <Moment tz="CST" format="YYYY/MM/DD hh:mm:ss A">
          {value}
          </Moment>
          );
         } 
      },
      
    },
    {
      name: "areaList",
      label: "Campaign Area",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              
            {value.campaignArea=="city"?
            <div className="d-flex align-items-center">
            {/*<p className="mb-0 mr-3">City</p>*/}
            <Button  variant="outlined"><a href="#"  onClick={(e) =>viewAreaList(e,value.cityList,"city")}>View City List</a>
            </Button>
            </div>
                  :
                  value.campaignArea=="state"?
                  <div>
            {/* <p>State</p>*/}
            <Button  variant="outlined"><a href="#"  onClick={(e) =>viewAreaList(e,value.stateList,"state")}>View State List</a>
            </Button></div>: localStorage.getItem("connectSite")=="CA"?"Canada-Wide":"US-Wide"}
              </div>
             
          )
      }
      },
      
    },
   
  /*  {
      name: "Action",
      label: "Action",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Button  variant="contained" component={NavLink}
              to={"/home/Products/promotion?id="+value} 
              color="primary">
                  Start Promotion
          </Button>
              &nbsp;
             <Button  variant="contained" component={NavLink}
              to={"/home/Products/addProduct?id="+value} 
              color="primary">
                  Update
        </Button>
              </div>
             
          )
      }
      },
      
    },*/
    
  ]

  const viewAreaList = async (e,data,type) => {
    setIsOpen(!isOpen);
    if(type=="city")
    {
    API.getAllCities(userInfo.email).then((result) => {  
         
      if(result.data!=undefined)
      {
        const myArrayFiltered = result.data.filter((el) => {
          return data.some((f) => {
            return f === el.id;
          });
        });
        setAreaList(myArrayFiltered);
        console.log("setIsOpen>>>>>>>>>>>>>>",isOpen);
        setIsOpen(!isOpen);
      }
           })
           .catch((error) => {
             //console.log(error);
           //  toast.error("error during create copy,try again.")
            // alert(error);
          });
        }
        if(type=="state")
        {
        API.getState(userInfo.email).then((result) => {  
             
          if(result.data!=undefined)
          {
            const myArrayFiltered = result.data.filter((el) => {
              return data.some((f) => {
                return f === el.id;
              });
            });
            setAreaList(myArrayFiltered);
            setIsOpen(!isOpen);
          }
               })
               .catch((error) => {
                 //console.log(error);
               //  toast.error("error during create copy,try again.")
                // alert(error);
              });
            }
        };

        function handleClose() {
          setIsOpen(false);
        }

  
  const inActivecolumns = [
    {
      name: "id",
      options: {
        display: false,
      }
    },     
    {
    name: "campaignName",
    label: "Campaign Name",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (item, tableMeta, updateValue) => {
        return (
          
          <div className="d-flex align-items-center">
         <a href="#"  onClick={(e) =>showChart(item.id)}>{item.campaignName}</a>
          
          </div>
               
           
           
        )
      }
      
    },
  },
  {
    name: "type",
    label: "Campaign Type",
    options: {
      filter: true,
      sort: true,
      display: false,
      
    },
  },
  
    {
      name: "startDate",
      label: "Start Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
          <Moment tz="CST" format="YYYY/MM/DD hh:mm:ss A">
          {value}
          </Moment>
          );
         }      
      },
      
    },
    {
      name: "endDate",
      label: "End Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
          <Moment tz="CST" format="YYYY/MM/DD hh:mm:ss A">
          {value}
          </Moment>
          );
         } 
      },
      
    },
    {
      name: "areaList",
      label: "Campaign Area",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              
            {value.campaignArea=="city"?
            <div className="d-flex align-items-center">
            <p className="mb-0 mr-3">City</p>
            <Button  variant="outlined"><a href="#"  onClick={(e) =>viewAreaList(e,value.cityList,"city")}>View City List</a>
            </Button>
            </div>
                  :
                  value.campaignArea=="state"?
                  <div>
            <p>State</p>
            <Button  variant="outlined"><a href="#"  onClick={(e) =>viewAreaList(e,value.stateList,"state")}>View State List</a>
            </Button></div>: localStorage.getItem("connectSite")=="CA"?"Canada-Wide":"US-Wide"}
              </div>
             
          )
      }
      },
      
    },
    {
      name: "Action",
      label: "Action",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
             <Button
            variant="contained"
            color="primary"
            className={classes.button}
           // startIcon={<AddRoundedIcon />}
            //   size="small"
            component={NavLink}
            to={"/home/campaign/promotionBatch?type=flyer&id="+value}
          >
           Renew  Campaign
          </Button>
              </div>
             
          )
      }
      },
      
    },
    
  ]


  
  const showChartItem = (id,itemId,type) => {
   
   setSelectedCampaign(id);
   setSelectedItem(itemId);
   setSelectedLevel("item");
    setIsExpended(true);
        
  };


  //var selectedCampaign="";
  var rowPerPage=10;
  const activeOptions = {
    download: false,
    print: false,
    rowsPerPage: 10,
    selectableRowsOnClick: false,
    selectToolbarPlacement: "none",
    selectableRows:"none",
    expandableRows: true,
    onChangePage: (currentPage) => {
      console.log("onChangePage>>>>>>>>>>>",currentPage);
      pageNumber=currentPage;
      for(var i=0;i<=activeCampaign.length-1;i++)
      {
        let row1 = document.getElementById(`MUIDataTableBodyRow-${i}`);
        console.log("row1 selection>>>>>>>>>>>>>",row1);
        if(row1!=null && row1!=undefined && row1!='')
        {
        row1.removeAttribute('style')
        }
      }
      
    },
    onRowSelectionChange: (curRowSelected,allRowsSelected) => {
      console.log("curRowSelected>>>>>>>>>>>>>>>>>>",curRowSelected[0])
      var selectedIndex=0;
      if(pageNumber>0)
      selectedIndex=(pageNumber*rowPerPage)+curRowSelected[0].index
      else
      {
        selectedIndex=curRowSelected[0].index  
      }

      console.log("selectedIndex row>>>>>>>>>>>",selectedIndex);
      const selectedRow = activeCampaign[selectedIndex];
      console.log("selected row>>>>>>>>>>>",selectedRow);
    for(var i=0;i<=activeCampaign.length-1;i++)
    {
      let row1 = document.getElementById(`MUIDataTableBodyRow-${i}`);
      console.log("row1 selection>>>>>>>>>>>>>",row1);
      if(row1!=null && row1!=undefined && row1!='')
      {
      row1.removeAttribute('style')
      }
    }
      
      let row = document.getElementById(`MUIDataTableBodyRow-${selectedIndex}`);
      console.log("row selection>>>>>>>>>>>>>",row);
      if(row!=null && row!=undefined && row!='')
      {
      row.setAttribute('style', 'background: #D3D3D3;');
      }

      showChart(selectedRow.id);
    },
    renderExpandableRow: (rowData, rowMeta) => {
     
      console.log("rowData>>>>>>>>>>>>>>>>>>",rowData[0])
      console.log("rowData2222222222>>>>>>>>>>>>>>>>>>",rowData[2])
      return (
        <tr>
          <td colSpan={7}>
          <CampaignDeatils
         campaignId={rowData[0]}
         type={rowData[2]}
         onItemSelect={showChartItem}

        />
          </td>
          
           </tr>
      );

    },
    page: 1
  }

 

  const InactiveOptions = {
    download: false,
    print: false,
    rowsPerPage: 10,
    selectableRowsOnClick: false,
    selectToolbarPlacement: "none",
    selectableRows:"none",
    expandableRows: true,
    onChangePage: (currentPage) => {
      console.log("onChangePage>>>>>>>>>>>",currentPage);
      pageNumber=currentPage;
      for(var i=0;i<=inactiveCampaign.length-1;i++)
      {
        let row1 = document.getElementById(`MUIDataTableBodyRow-${i}`);
        console.log("row1 selection>>>>>>>>>>>>>",row1);
        if(row1!=null && row1!=undefined && row1!='')
        {
        row1.removeAttribute('style')
        }
      }
      
    },
    onRowSelectionChange: (curRowSelected,allRowsSelected) => {
      console.log("curRowSelected>>>>>>>>>>>>>>>>>>",curRowSelected[0])
      var selectedIndex=0;
      if(pageNumber>0)
      selectedIndex=(pageNumber*rowPerPage)+curRowSelected[0].index
      else
      {
        selectedIndex=curRowSelected[0].index  
      }

      console.log("selectedIndex row>>>>>>>>>>>",selectedIndex);
      const selectedRow = inactiveCampaign[selectedIndex];
      console.log("selected row>>>>>>>>>>>",selectedRow);
    for(var i=0;i<=inactiveCampaign.length-1;i++)
    {
      let row1 = document.getElementById(`MUIDataTableBodyRow-${i}`);
      console.log("row1 selection>>>>>>>>>>>>>",row1);
      if(row1!=null && row1!=undefined && row1!='')
      {
      row1.removeAttribute('style')
      }
    }
      
      let row = document.getElementById(`MUIDataTableBodyRow-${selectedIndex}`);
      console.log("row selection>>>>>>>>>>>>>",row);
      if(row!=null && row!=undefined && row!='')
      {
      row.setAttribute('style', 'background: #D3D3D3;');
      }

      showChart(selectedRow.id);
    },
    renderExpandableRow: (rowData, rowMeta) => {
      console.log("rowData>>>>>>>>>>>>>>>>>>",rowData[0])
   
      return (
        <tr>
        <td colSpan={7}>
        <CampaignDeatils
       campaignId={rowData[0]}
       type={rowData[2]}
       onItemSelect={showChartItem}
      
      />
        </td>
        
         </tr>
      );

    },
    page: 1
  }


  
  const showChart = async (id) => {
    setSelectedCampaign(id);
    setSelectedLevel("campaign")
      setIsExpended(true);
        
  };



  const getCompanyBranches = async (e) => {
    console.log("event>>>>>>>>>",e.target.value);
    if(e.target.value!=undefined && e.target.value!='')
    {
      localStorage.setItem('companyFilterCampaign', e.target.value);
      getStoreByCompany(e.target.value);
        }
        
  };

  const handleBranchChanage = async (e) => {
    console.log("event>>>>>>>>>",e.target.value);
    if(e.target.value!=undefined && e.target.value!='')
    {
      localStorage.setItem('branchFilterCampaign', e.target.value);
      API.getCompanyBranchCampaign(e.target.value,"active","flyer").then((result) => {  
            
        if(result.data!=undefined && result.data.length>0)
        {
          setActiveCampaign(result.data);
        }
        else
        {
          setActiveCampaign([]);
        }
    
      })
      .catch((error) => {
        console.log(error);
        alert(error);
    });

    API.getCompanyBranchCampaign(e.target.value,"inactive","flyer").then((result1) => {  
      
      if(result1.data!=undefined && result1.data.length>0)
      {
        setInactiveCampaign(result1.data);
      }
      else
      {
        setInactiveCampaign([]);
      }
  
    })
    .catch((error) => {
      console.log(error);
      alert(error);
  });

        }
        
  };



  const activeCampaignData = activeCampaign.map((item) => ({
    id:item.id,
    campaignName: item,
    type:item.campaignType=="flyer"?"Flyer":"Sponsored",
    startDate:item.validFrom,
    endDate:item.validTill,
    campaignArea:item.campaignArea,
    areaList:item,
    Action:item.id,
  }));

  const inactiveCampaignData = inactiveCampaign.map((item) => ({
    id:item.id,
    campaignName: item,
    type:item.campaignType=="flyer"?"Flyer":"Sponsored",
    startDate:item.validFrom,
    endDate:item.validTill,
    campaignArea:item.campaignArea,
    areaList:item,
    Action:item.id,
  }));



  
  

  

  return (
    <div>
    <TabContext value={value}>
      <div className={classes.tab}>
        <TabList
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="simple tabs example"
          centered
        >
          <Tab
            className={value === "1" && "font-weight-bold"}
            label={"Active Flyer Campaign"}
            value="1"
          />
          <Tab
            className={value === "2" && "font-weight-bold"}
            label={"Inactive Flyer Campaign"}
            value="2"
          />
         
        </TabList>
        {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")) ? (
        <div className="d-flex">
        <Form.Control
          as="select"
          className="mr-3"
          name="company"
          value={localStorage.getItem('companyFilterCampaign')}
          onChange={getCompanyBranches}
          required
        >
          <option>Select Company</option>
          {companyList.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Form.Control>
        
        <Form.Control
          as="select"
          className="mr-sm-0"
          name="companyBranch"          
          value={localStorage.getItem('branchFilterCampaign')}
          onChange={handleBranchChanage}
          required
        >
          <option>Select Branch</option>
          {storeData.map((item, index) => (
            <option key={item.id} value={item.id}>
              {item.code}
            </option>
          ))}
        </Form.Control>
        </div>
         ) : null}
         {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("CAN_ADD_UPDATE")) ? (  
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<AddRoundedIcon />}
          component={NavLink}
          to={"/home/campaign/campaignType?type=flyer"}
        >
          Create Campaign
        </Button>
         ) : null}
      </div>
      {isExpended?
      <div className="mt-4 ml-4">
        <CampaignAnalytics
         campaignId={selectedCampaign}
         itemId={selectedItem}
         level={selectedLevel}
         type={"flyer"}
        />
        </div>:""}
      <TabPanel value="1">
        <MUIDataTable
        className="store-table"
          title={"Active Flyer Campaign"}
          data={activeCampaignData}
          columns={columns}
          options={activeOptions}
        />

       
      </TabPanel>
      <TabPanel value="2">
        <MUIDataTable
        className="store-table"
          title={"Inactive Flyer Campaign"}
          data={inactiveCampaignData}
          columns={inActivecolumns}
          options={InactiveOptions}
        />
      </TabPanel>
      
      <ToastContainer />
      <Modal
        show={isOpen}
        onHide={handleClose}
        centered
        size="l"
      >
        <ViewCampaignAreaList areaList={areaList}  setIsOpen={setIsOpen}  />;
      </Modal>
    </TabContext>
    </div>

    

   
  )
}
export default CampaignList
