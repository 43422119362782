import React, { useEffect, useState } from "react"
import { useStore, useAuth } from "../context"
import { Stores } from "../domain"
import MUIDataTable from "mui-datatables"
import Button from "@material-ui/core/Button"
import AddRoundedIcon from "@material-ui/icons/AddRounded"
import { NavLink } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom";
import AddStore from "../screens/AddStore"
//import Modal from "react-modal";
import {Modal} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import API from "../utils/API";
const useStyles = makeStyles(() => ({
  button: {
    "&:hover": {
      color: "#fff",
    },
  },
}))


var pageNumber=0;

function ManageCompany() {
  const classes = useStyles()
  const { companyData, storeData, getCompanyByUser, getStoreByCompany,stateData, getState } =
    useStore();
  const { userInfo } = useAuth();
  console.log(userInfo);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [selectedCompanyLogo, setSelectedCompanyLogo] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState(-1);
  const { loggedUserInfo } = useAuth();
  useEffect(() => {
    getCompanyByUser(userInfo.email);
    API.getCompanyByUser(userInfo.email).then((result) => {  
       
      if(result.data!=undefined && result.data.length>0)
      {
    getStoreByCompany(result.data[0].id);
    setSelectedCompany(result.data[0].name);
    setSelectedCompanyId(result.data[0].id);
    setSelectedCompanyLogo(result.data[0].logo);
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });
    
  }, []);


  const columns = [
    {
      name: "logo",
      label: " ",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
            <img height='100' width='100'  src={value}/>
          );
        }
      },
    },
    {
      name: "name",
      label: "Company Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "regName",
      label: "Register Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "regState",
      label: "Register State",
      options: {
        filter: false,
        sort: false,
        display:localStorage.getItem("connectSite")=="US"?true:false,
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
          loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.privilegeCode.includes("ADD_UPDATE_COMPANY") ) ? (
            
            <Button
            variant="contained"
            color="primary"
           // startIcon={<AddRoundedIcon />}
            component={NavLink}
            to={"/home/company/addCompany?id="+value}
          >
            Edit
          </Button>
          ) : <Button
          variant="contained"
          color="primary"
         // startIcon={<AddRoundedIcon />}
          component={NavLink}
          to={"/home/company/AddLogo?id="+value}
        >
          Edit Logo
        </Button>
          );
        }
      },
    },
    
  ];

  let options = {};
  var rowPerPage=10;
  if(loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.privilegeCode.includes("ADD_UPDATE_COMPANY")))
  {

    options = {
      rowsPerPage: 10,
      print: false,
      customToolbar: () => (
       
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddRoundedIcon />}
          component={NavLink}
          to={"/home/company/addCompany"}
        >
          Add Company
        </Button>
     
      ),
      //selectableRows: "single",
      //selectableRowsOnClick: true,
      selectableRowsOnClick: true,
       selectToolbarPlacement: "none",
      selectableRows:"single",
      onChangeRowsPerPage: (curRowSelected) => {
        console.log("curRowchange>>>>>>>>>>>",curRowSelected);
        
      },
      onChangePage: (currentPage) => {
        console.log("onChangePage>>>>>>>>>>>",currentPage);
        pageNumber=currentPage;
        for(var i=0;i<=companyData.length-1;i++)
        {
          let row1 = document.getElementById(`MUIDataTableBodyRow-${i}`);
          console.log("row1 selection>>>>>>>>>>>>>",row1);
          if(row1!=null && row1!=undefined && row1!='')
          {
          row1.removeAttribute('style')
          }
        }
        
      },
      onRowSelectionChange: (curRowSelected,allRowsSelected) => {
        console.log("curRowSelected row>>>>>>>>>>>",curRowSelected);
        console.log("allRowsSelected row>>>>>>>>>>>",allRowsSelected);
       
        var selectedIndex=0;
        // if(pageNumber>0)
        // selectedIndex=(pageNumber*rowPerPage)+curRowSelected[0].dataIndex
        // else
        // {
          selectedIndex=curRowSelected[0].dataIndex  
       // }

        console.log("selectedIndex row>>>>>>>>>>>",selectedIndex);
        const selectedRow = companyData[selectedIndex];
        console.log("selected row>>>>>>>>>>>",selectedRow);
      for(var i=0;i<=companyData.length-1;i++)
      {
        let row1 = document.getElementById(`MUIDataTableBodyRow-${i}`);
        console.log("row1 selection>>>>>>>>>>>>>",row1);
        if(row1!=null && row1!=undefined && row1!='')
        {
        row1.removeAttribute('style')
        }
      }
        
        let row = document.getElementById(`MUIDataTableBodyRow-${selectedIndex}`);
        console.log("row selection>>>>>>>>>>>>>",row);
        if(row!=null && row!=undefined && row!='')
        {
        row.setAttribute('style', 'background: #3f51b5;');
        }
       
        getStoreByCompany(selectedRow.id);
        setSelectedCompany(selectedRow.name);
        setSelectedCompanyId(selectedRow.id);
        setSelectedCompanyLogo(selectedRow.logo);
      },
      onSearchChange: (searchText) => {
        console.log("onSearchChange >>>>>>>>>>>>>>>",searchText);
        for(var i=0;i<=companyData.length-1;i++)
        {
          let row1 = document.getElementById(`MUIDataTableBodyRow-${i}`);
          console.log("row1 selection>>>>>>>>>>>>>",row1);
          if(row1!=null && row1!=undefined && row1!='')
          {
          row1.removeAttribute('style')
          }
        }
      },
      onSearchClose: () => {
        console.log("search close>>>>>>>>>>>>>>>");
        for(var i=0;i<=companyData.length-1;i++)
        {
          let row1 = document.getElementById(`MUIDataTableBodyRow-${i}`);
          console.log("row1 selection>>>>>>>>>>>>>",row1);
          if(row1!=null && row1!=undefined && row1!='')
          {
          row1.removeAttribute('style')
          }
        }
      },
    };
    
  }
  else
  {
   
    options = {
      rowsPerPage: 10,
      print: false,
     // selectableRows: "single",
      //selectableRowsOnClick: true,
     // selectableRowsOnClick: false,
     selectableRowsOnClick: true,
      selectToolbarPlacement: "none",
      selectableRows:"single",

      onChangeRowsPerPage: (curRowSelected) => {
        console.log("curRowchange>>>>>>>>>>>",curRowSelected);
        
      },
      onChangePage: (currentPage) => {
        console.log("onChangePage>>>>>>>>>>>",currentPage);
        pageNumber=currentPage;
        for(var i=0;i<=companyData.length-1;i++)
        {
          let row1 = document.getElementById(`MUIDataTableBodyRow-${i}`);
          console.log("row1 selection>>>>>>>>>>>>>",row1);
          if(row1!=null && row1!=undefined && row1!='')
          {
          row1.removeAttribute('style')
          }
        }
        
      },
      onRowSelectionChange: (curRowSelected,allRowsSelected) => {
        console.log("curRowSelected row>>>>>>>>>>>",curRowSelected);
       
        var selectedIndex=0;
        if(pageNumber>0)
        selectedIndex=(pageNumber*rowPerPage)+curRowSelected[0].index
        else
        {
          selectedIndex=curRowSelected[0].index  
        }

        console.log("selectedIndex row>>>>>>>>>>>",selectedIndex);
        const selectedRow = companyData[selectedIndex];
        console.log("selected row>>>>>>>>>>>",selectedRow);
      for(var i=0;i<=companyData.length-1;i++)
      {
        let row1 = document.getElementById(`MUIDataTableBodyRow-${i}`);
        console.log("row1 selection>>>>>>>>>>>>>",row1);
        if(row1!=null && row1!=undefined && row1!='')
        {
        row1.removeAttribute('style')
        }
      }
        
        let row = document.getElementById(`MUIDataTableBodyRow-${selectedIndex}`);
        console.log("row selection>>>>>>>>>>>>>",row);
        if(row!=null && row!=undefined && row!='')
        {
        row.setAttribute('style', 'background: #3f51b5');
        }
       
        getStoreByCompany(selectedRow.id);
        setSelectedCompany(selectedRow.name);
        setSelectedCompanyId(selectedRow.id);
        setSelectedCompanyLogo(selectedRow.logo);
      },
     
    };
}

function getRegStateValue(value)
{
  
  const filterStateValue = stateData.filter(function (item) {
    return value == item.id;
  });

  console.log("filterStateValue>>>>>>>>>>>>>>>>>>>>>",filterStateValue);
  if(filterStateValue!=undefined&&filterStateValue!=null && filterStateValue.length>0)
  return filterStateValue[0].name;
  else
  return "";

}

  const rowData = companyData.map((item) => ({
    logo: item.logo!=undefined && item.logo!="" && item.logo!=null?item.logo:"https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg",
    name: item.name,
    regName: item.regName,
    regState: getRegStateValue(item.stateId),
    action: item.id,
  
  }));
  function toggleModal() {
    if(selectedCompanyId=='' ||selectedCompanyId==undefined )
    {
      toast.error("Please select company");
    }
    else
    {
    setIsOpen(!isOpen);
    }
  }
  function handleClose() {
    setIsOpen(false);
  }

  return (
    <>
      <MUIDataTable
        className="mb-3 store-table"
        title="Company"
        data={rowData}
        columns={columns}
        options={options}
      />
       {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.privilegeCode.includes("CAN_ADD_UPDATE")) ? (
        <div className="py-3 text-right">
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<AddRoundedIcon />}
          //   size="small"
         // component={NavLink}
          onClick={toggleModal}
          style={{justifyContent: 'right'}}
          to={"/home/addStore"}
        >
          Add More Store/Branch
        </Button> 
        </div>
        ) : null}
        <Modal
        show={isOpen}
        onHide={handleClose}
        centered
      >
        <AddStore companyId={selectedCompanyId} setIsOpen={setIsOpen} businessName={selectedCompany} selectedCompanyLogo={selectedCompanyLogo} />;
      </Modal>
        <br/>
      {storeData?.length > 0 && (
        <Stores data={storeData} setIsOpen={setIsOpen} selectedCompany={selectedCompany}  />
      )}
       <ToastContainer />
    </>
   
  );
}

export default ManageCompany;
