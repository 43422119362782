import React from "react"

import category from '../../assets/cat.PNG'
import signuppng from '../../assets/sign-up.png'
import storeinfo from '../../assets/store-info.png'
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


import NavigationIcon from '@material-ui/icons/Navigation';
import Fab from '@material-ui/core/Fab';
import Sidebar from "./Sidebar"
import SearchBar from "./SearchBar"
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'




const useStylesTop = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const useStylesAcord = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {

        fontWeight: theme.typography.fontWeightRegular,
    },
}));

// const FormContainer = styled.form`
//   width: 400px;
//   background-color: #fff;
//   padding: 50px;
//   margin-top: 150px;
//   box-shadow: 0 0 20px silver;
//   border-radius: 10px;
// `
// const RegisterLink = styled(Link)`
//   position: absolute;
//   top: 0;
//   left: 0;
//   margin: 10px 25px;
//   background-color: #fff;
//   border-radius: 10px;
//   box-shadow: 0 0 20px silver;
//   padding: 25px;
//   :hover {
//     text-decoration: none;
//   }
// `


function ShopifyDepartmentHelp() {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const classesTop = useStylesTop();

    

    const history = useHistory();

    const handleSubmit = async (e) => {

        history.push(`/login`)
    }

    return (
        <>
<SearchBar/>

            <div className={classes.root}>
                <AppBar position="static" color="#fff" position="sticky">
                    <div className="container-fluid px-3 px-lg-5">
                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" indicatorColor="primary">
                            <Tab label="Functional">Functional</Tab>
                            <Tab label="Technical"></Tab>
                        </Tabs>
                    </div>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <div className="container-fluid px-0 px-lg-5">
                        <div className="row mt-3">
                            <div className="col-md-3 ">
                            <Sidebar/>

                            </div>

                            <div className="col-md-9">
                                <div className="help-content">
                                    <section id="Offers-Collections">
                                       

                                        <h3>Set Department for products</h3>
                                        <p>Department wise shopping makes it convenient for consumers to find the product easily.</p>
                                        <p>So you can set your products in relevant departments and get more consumer eyeball.</p>
                                        <p>To set up a department for the product you have synced, follow the steps given below.</p>
                                        <p>You can set it from Shopify Admin using <strong>Set/Update Department</strong> action tab available in Sync product.</p>
                                        <p>After selecting the product for which you want to set the department, A popup window will appear where you can route through categories to the final subcategory where you want your product to be listed.</p>
                                        <p>Once confirmed your product will start appearing in real time on ShopiAds into relevant department.</p>
                                        <p>Note : Product can be set in any one department at a given time, updating it will place the product in the last changed department, You can anytime view to which department it is set on.</p>

                                        <Zoom>
                                            <picture>
                            <source media="(max-width: 800px)" srcSet={require('../../assets/setDepartment.png').default} />
                            <img
                              alt="that wanaka tree"
                              src={require('../../assets/setDepartment.png').default}
                              width="500"
                            />
                          </picture>

                          </Zoom> 

                          <br/>
                          <p></p>
                          <p></p>


                          <Zoom>
                                            <picture>
                            <source media="(max-width: 800px)" srcSet={require('../../assets/setDepartment1.png').default} />
                            <img
                              alt="that wanaka tree"
                              src={require('../../assets/setDepartment1.png').default}
                              width="500"
                            />
                          </picture>

                          </Zoom> 
                                    </section>

                                </div>

                            </div>

                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    Technical
                </TabPanel>
            </div>

            <div className="backtoptop">
                <a href="#top">
                    <Fab variant="extended">
                        <NavigationIcon className={classesTop.extendedIcon} />
                        Top
                    </Fab>
                </a>
            </div>




        </>
    )
}

export default ShopifyDepartmentHelp