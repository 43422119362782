import React from "react"
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom"
import { AddOffer, ManageOffers,StartPromotion } from "../domain"
import MapProduct from "../domain/MapProduct"
import StartPromotionBatch from "../domain/StartPromotionBatch"

function Offers() {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/manage`} component={ManageOffers} />
      <Route path={`${path}/add`} component={AddOffer} />
      <Route path={`${path}/promotion`} component={StartPromotion} />
      <Route path={`${path}/mapProduct`} component={MapProduct} />
      <Redirect to={`${path}/manage`} />
    </Switch>
  )
}

export default Offers
