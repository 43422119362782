import React,{ useState,useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import API from "../utils/API";
import { ToastContainer, toast } from 'react-toastify';
import validator from 'validator'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useAuth } from "../context";

const FormContainer = styled.form`
  width: 500px;
  // background-color: #fff;
  padding: 50px;
  // margin-top: 75px;
  box-shadow: 0 0 20px silver;
  border-radius: 10px;
  position: relative;
`;

const market = [
  {"label":"Canada","value":"Canada"},
  {"label":"United States","value":"United States"},
  
  
];

function StoreInfoFormModel({ form, setForm, navigation }) {
  const { storeName, businessName, websiteUrl, logo, branchName,marketPlace } = form;
  const [validated,setValidate]=useState(false);
  const {loggedUserInfo } = useAuth();
  const [errorMessage, setErrorMessage] = useState('')
  const animatedComponents = makeAnimated();
  const [selectedMarketPlace, setSelectedMarketPlace] = React.useState([])

  useEffect(() => {
    console.log("marketPlace info model step>>>>>>>>>>",marketPlace);
    if(marketPlace!=undefined && marketPlace!=null && marketPlace.length>0)
                {
                    var existMarketPlace=[]
    
                    marketPlace.map((item) => {
                      if(item!='' && item!="" && item!=" ")
                      {
                        var tempObj ={"label":item,"value":item}
                        existMarketPlace.push(tempObj);
                      }
                    });
                    setSelectedMarketPlace(existMarketPlace);
                }

  },[loggedUserInfo]);

  //form.branchName=form.branchName ? form.branchName : "Primary";
  const onSubmit = (e) => {
   /* let invalidFields = 0;
    const inputs = document.querySelectorAll(".form-control");
    inputs.forEach((element) => {
      if (element.hasAttribute("required")) {
        if (element.value.length === 0) {
          element.classList.add("is-invalid");
          invalidFields++;
          return;
        }
      }
    });
    if (invalidFields > 0) return;*/
    setValidate(true);
    console.log("form.businessName>>>>>>>>>>",form.businessName);
  /*  if (form.businessName == '' || form.businessName == undefined) {
      e.preventDefault();
      e.stopPropagation();
  }*/
   if (form.storeName == '' || form.storeName == undefined) {
    e.preventDefault();
    e.stopPropagation();
  }
  else if (form.branchName == '' || form.branchName == undefined) {
    e.preventDefault();
    e.stopPropagation();
  }

  else if (selectedMarketPlace != undefined  && selectedMarketPlace.length==0) {
    //console.log("selectedMarketPlace.length>111111111111111>>>>>>>>>",selectedMarketPlace.length);
    toast.error("Please select marketPlace")
    e.preventDefault();
    e.stopPropagation();
   // e.stopNavigation();
  }

  else if (form.websiteUrl == '' || form.websiteUrl == undefined ) {
    e.preventDefault();
    e.stopPropagation();
  }

  else if (!validator.isURL(form.websiteUrl)) {
    setErrorMessage('Please enter valid URL')
    e.preventDefault();
    e.stopPropagation();
  }

  else if(form.websiteUrl != '' && branchName != '' && form.storeName != '' && validator.isURL(form.websiteUrl) && (selectedMarketPlace != undefined  && selectedMarketPlace.length>0))
  {
    console.log("form.storeId>>>>>>>>>>>>>>",form.storeId);
    if(form.storeId==null || form.storeId==undefined || form.storeId == "")
    {
    API.checkBranchExist(branchName,form.companyId).then((result) => {  
      console.log("result>>>>>>>>>>>",result);
      if(result.data)
      {
        toast.error("Branch code already exist for this company");
      }
      else
      {
 navigation.next();
      }
    })
    .catch((error) => {
      console.log(error);
      alert(error);
    });
  }
    else
    {
      navigation.next();
    }

  }
  };

  const onChangeFun = (e) => {
    e.target.classList.remove("is-invalid");
    setForm(e);
  };

  const handleMarketPlaceChange = (selectedOptions) => {
    console.log("selectedOptions>>>>>>>>>>>>>>>>",selectedOptions);
    //form.marketPlace=[];
    form.marketPlace=selectedOptions.map((item1) => (item1.value))
    //form.weeklyOff=selectedOptions.value;
    setSelectedMarketPlace(selectedOptions);
  };


 /* if(form.storeId==null || form.storeId==undefined)
  {
  onSubmit();
  }*/
  return (
    <FormContainer className="mx-auto">
      <h3 className="text-center mb-3">Store Info</h3>
      <Form noValidate validated={validated} action="#">
     {<Form.Group>
        <Form.Label>Company's business name</Form.Label>
        <Form.Control
          required
          readOnly
          name="businessName"
          type="text"
          value={businessName}
          onChange={setForm}
          required
        />
        <Form.Text className="text-muted">
          Enter the company/business name as registered in{" "}
          <strong>corporation</strong>
        </Form.Text>
        <Form.Control.Feedback type="invalid" >Please enter company name</Form.Control.Feedback>
      </Form.Group>}
      <Form.Group>
        <Form.Label>Your preferred store name</Form.Label>
        <Form.Control
          required
          name="storeName"
          type="text"
          value={storeName}
          onChange={onChangeFun}
          required
        />
        <Form.Control.Feedback type="invalid" >Please enter store name</Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>Branch Name</Form.Label>
        <Form.Control
          required
          name="branchName"
          type="text"
          value={branchName}
          onChange={onChangeFun}
          required
        />
        <Form.Text className="text-muted">
          Enter name of your branch
        </Form.Text>
        <Form.Control.Feedback type="invalid" >Please enter branch name</Form.Control.Feedback>
      </Form.Group> 

      <Form.Group>
        <Form.Label>Show in Marketplaces</Form.Label>
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          isMulti
          name="marketPlace"
          value={selectedMarketPlace}
          options={market}
          onChange={handleMarketPlaceChange}
          
      />
        
      </Form.Group>
      <Form.Group>
        <Form.Label>Website Url</Form.Label>
        <Form.Control
          required
          name="websiteUrl"
          type="text"
          disabled={true}
          value={websiteUrl}
          onChange={setForm}
          required
        />
        <Form.Control.Feedback type="invalid" >Please enter website url</Form.Control.Feedback>
        <span style={{
          fontWeight: 'bold',
          color: 'red',
        }}>{errorMessage}</span>
      </Form.Group>

      {/*<Form.Group style={{ display: "none" }}>
        <Form.Control required name="logo" type="file" onChange={setForm} />
      </Form.Group>*/}

      {/* <Form.Label>Company Logo</Form.Label>*/}
      {/* <Form.Row className="m-0">
        <Row>
          <Col sm={10}>
            <Form.Group>
              <Form.Control
                name="websiteUrl"
                type="text"
                value={logo}
                readonly
              />
            </Form.Group>
          </Col>
          <Col sm={2}>
            <Form.Group>
              <Button variant="outline-secondary">Browse</Button>
            </Form.Group>
          </Col>
        </Row>
      </Form.Row> */}

       {/*<Form.File
        id="custom-file"
        label={logo}
        custom
        name="logo"
        value={logo}
        onChange={setForm}
      />*/}
      <div className="w-100 d-flex justify-content-around">
       {/* <Button
          className="mt-3 w-50 mr-1"
          onClick={() => navigation.previous()}
        >
          Back
        </Button>*/}
        <Button className="mt-3 w-50 ml-1" onClick={onSubmit}>
          Next
        </Button>
      </div>
      </Form>
      <ToastContainer />
    </FormContainer>
  );
}

export default StoreInfoFormModel;
