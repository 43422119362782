import React,{ useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import MUIDataTable from "mui-datatables"
import Button from "@material-ui/core/Button"
import AddRoundedIcon from "@material-ui/icons/AddRounded"
import Tab from "@material-ui/core/Tab"
import TabContext from "@material-ui/lab/TabContext"
import TabList from "@material-ui/lab/TabList"
import { TabPanel } from "@material-ui/lab"
import { NavLink } from "react-router-dom"
import API from "../utils/API";
import { useUser, useStore, useAuth } from "../context"
import Moment from 'react-moment';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import 'moment-timezone';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import SelectProductCategory from "./SelectProductCategory"
import {Modal,Form} from 'react-bootstrap';
import eventBus from "../utils/eventBus"
import ViewOfferProduct from "./ViewOfferProduct"

const useStyles = makeStyles(() => ({
  tab: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    padding: "0 10px",
    boxShadow: "0 0 15px silver",
  },
  button: {
    "&:hover": {
      color: "#fff",
    },
  },
}))


const data = []


function ManageOffers() {
  const classes = useStyles()
  const [value, setValue] = React.useState("1")
  const [activeCompanyOffer, setActiveCompanyOffer] = useState([]);
  const [inactiveCompanyOffer, setInActiveCompanyOffer] = useState([]);
  const [pendingCompanyOffer, setPendingCompanyOffer] = useState([]);
  const [activeCompanyOfferCount, setActiveCompanyOfferCount] = useState(0);
  const [inactiveCompanyOfferCount, setInActiveCompanyOfferCount] = useState(0);
  const [pendingCompanyOfferCount, setPendingCompanyOfferCount] = useState(0);
  //const [selectedCompanyFilter, setSelectedCompanyFilter] = useState(0);
 // const [selectedOffer, setSelectedOffer] = useState([]);
 const [companyList, setCompanyList] = useState([]);
 const [offerProductData, setOfferProductData] = useState([]);
 const [isOpen, setIsOpen] = useState(false);
 const { companyData, storeData, getCompanyByUser, getStoreByCompany } =
    useStore();
  const { userInfo,loggedUserInfo } = useAuth();
  let selectedOffer=[];
  const history = useHistory();
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  

  useEffect(() => {

    if(loggedUserInfo!=undefined)
    {
      API.getAllCompany(loggedUserInfo.email).then((companyResult) => {  
       
        if(companyResult.data!=undefined && companyResult.data.length>0)
        {
          setCompanyList(companyResult.data)
         
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            });

            if(localStorage.getItem('branchFilter')!=undefined && localStorage.getItem('branchFilter')!=null && localStorage.getItem('branchFilter')!='')
            {
             // localStorage.setItem('branchFilter', e.target.value);
             getStoreByCompany(localStorage.getItem('companyFilter'));
              API.getCompanyBranchOffer(localStorage.getItem('branchFilter'),"active").then((result) => {  
               
                if(result.data!=undefined)
                {
                  setActiveCompanyOffer(result.data)
                  setActiveCompanyOfferCount(result.data.length)
                }
                     })
                     .catch((error) => {
                       console.log(error);
                       alert(error);
                    });
          
                    API.getCompanyBranchOffer(localStorage.getItem('branchFilter'),"pending").then((result) => {  
                 
                      if(result.data!=undefined)
                      {
                        setPendingCompanyOffer(result.data)
                        setPendingCompanyOfferCount(result.data.length)
                      }
                           })
                           .catch((error) => {
                             console.log(error);
                             alert(error);
                          });
          
                    API.getCompanyBranchOffer(localStorage.getItem('branchFilter'),"inactive").then((result) => {  
                 
                      if(result.data!=undefined)
                      {
                        setInActiveCompanyOffer(result.data)
                        setInActiveCompanyOfferCount(result.data.length)
                      }
                           })
                           .catch((error) => {
                             console.log(error);
                             alert(error);
                          });
                }
            else{
            if(loggedUserInfo.companyId!=undefined && loggedUserInfo.companyId!=null && loggedUserInfo.companyId!='')      
            {
    API.getCompanyOffer(loggedUserInfo.companyId,"active").then((result) => {  
       
      if(result.data!=undefined && result.data.length>0)
      {
        setActiveCompanyOffer(result.data)
        setActiveCompanyOfferCount(result.data.length)
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });

          API.getCompanyOffer(loggedUserInfo.companyId,"pending").then((result) => {  
       
            if(result.data!=undefined && result.data.length>0)
            {
              setPendingCompanyOffer(result.data)
              setPendingCompanyOfferCount(result.data.length)
            }
                 })
                 .catch((error) => {
                   console.log(error);
                   alert(error);
                });

          API.getCompanyOffer(loggedUserInfo.companyId,"inactive").then((result) => {  
       
            if(result.data!=undefined && result.data.length>0)
            {
              setInActiveCompanyOffer(result.data)
              setInActiveCompanyOfferCount(result.data.length)
            }
                 })
                 .catch((error) => {
                   console.log(error);
                   alert(error);
                });
              }
            }
              eventBus.on("copyCreated", (data) =>{
             
                if(localStorage.getItem('branchFilter')!=undefined && localStorage.getItem('branchFilter')!=null && localStorage.getItem('branchFilter')!='')
                {
                 // localStorage.setItem('branchFilter', e.target.value);
                 getStoreByCompany(localStorage.getItem('companyFilter'));
                  API.getCompanyBranchOffer(localStorage.getItem('branchFilter'),"active").then((result) => {  
                   
                    if(result.data!=undefined)
                    {
                      setActiveCompanyOffer(result.data)
                      setActiveCompanyOfferCount(result.data.length)
                    }
                         })
                         .catch((error) => {
                           console.log(error);
                           alert(error);
                        });
              
                        API.getCompanyBranchOffer(localStorage.getItem('branchFilter'),"pending").then((result) => {  
                     
                          if(result.data!=undefined)
                          {
                            setPendingCompanyOffer(result.data)
                            setPendingCompanyOfferCount(result.data.length)
                          }
                               })
                               .catch((error) => {
                                 console.log(error);
                                 alert(error);
                              });
              
                        API.getCompanyBranchOffer(localStorage.getItem('branchFilter'),"inactive").then((result) => {  
                     
                          if(result.data!=undefined)
                          {
                            setInActiveCompanyOffer(result.data)
                            setInActiveCompanyOfferCount(result.data.length)
                          }
                               })
                               .catch((error) => {
                                 console.log(error);
                                 alert(error);
                              });
                    }
                else{
            if(loggedUserInfo.companyId!=undefined && loggedUserInfo.companyId!=null && loggedUserInfo.companyId!='')      
            {
    API.getCompanyOffer(loggedUserInfo.companyId,"active").then((result) => {  
       
      if(result.data!=undefined && result.data.length>0)
      {
        setActiveCompanyOffer(result.data)
        setActiveCompanyOfferCount(result.data.length)
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });

          API.getCompanyOffer(loggedUserInfo.companyId,"pending").then((result) => {  
       
            if(result.data!=undefined && result.data.length>0)
            {
              setPendingCompanyOffer(result.data)
              setPendingCompanyOfferCount(result.data.length)
            }
                 })
                 .catch((error) => {
                   console.log(error);
                   alert(error);
                });

          API.getCompanyOffer(loggedUserInfo.companyId,"inactive").then((result) => {  
       
            if(result.data!=undefined && result.data.length>0)
            {
              setInActiveCompanyOffer(result.data)
              setInActiveCompanyOfferCount(result.data.length)
            }
                 })
                 .catch((error) => {
                   console.log(error);
                   alert(error);
                });
              }
            }
              }
              );

            }
    
  }, [loggedUserInfo]);



  const columns = [
    {
      name: "offerImage",
      label: " ",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
            <img height='100' width='100'  src={value}/>
          );
        }
      },
    },
    {
      name: "offerHeading",
      label: "Offer/Collection heading",
      options: {
        filter: true,
        sort: true,
        
      },
    },
    {
      name: "offerSubHeading",
      label: "Offer Sub heading",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "branch",
      label: "Branch",
      options: {
        filter: true,
        sort: false,
        display: loggedUserInfo!=null && loggedUserInfo!=undefined && loggedUserInfo.isMaster!='Y' && loggedUserInfo.isAgentUser!='Y' && !loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")?true:false
      },
    },
    {
      name: "campaignName",
      label: "Campaign",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "startDate",
      label: "Start Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
          <Moment  tz="CST" format="YYYY/MM/DD hh:mm:ss A">
          {value}
          </Moment>
          );
         }      
      },
      
    },
    {
      name: "endDate",
      label: "End Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
          <Moment tz="CST" format="YYYY/MM/DD hh:mm:ss A">
          {value}
          </Moment>
          );
         } 
      },
      
    },
    {
      name: "Action",
      label: "Action",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
          {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("CAN_ADD_UPDATE")) ? (
              <div>
              <Button  variant="contained" 
              component={NavLink}
              to={"/home/offers/add?id="+value}
              color="primary">
                  Edit
              </Button>
              <Button  variant="contained" className="ml-2"
             onClick={(e) =>createCopy(e,value)}
             color="primary">
                 Create Copy
         </Button>
         </div>
              ) : null}
             
             <Button  variant="contained" className="mt-2"
             onClick={(e) =>viewProduct(e,value)}
             color="primary">
                 Associated Products
          </Button>
          <Button  variant="contained" className="mt-2"
             component={NavLink}
             to={"/home/offers/mapProduct?id="+value}
             color="primary">
                 Map More Products
          </Button>
         </div>  
             
          )
      }
      },
      
    },
    
  ]

  const pendingColumns = [
    {
      name: "offerImage",
      label: " ",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
            <img height='100' width='100'  src={value}/>
          );
        }
      },
    },
    {
      name: "offerHeading",
      label: "Offer/Collection heading",
      options: {
        filter: true,
        sort: true,
        
      },
    },
    {
      name: "offerSubHeading",
      label: "Offer Sub heading",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "branch",
      label: "Branch",
      options: {
        filter: true,
        sort: false,
        display: loggedUserInfo!=null && loggedUserInfo!=undefined && loggedUserInfo.isMaster!='Y' && loggedUserInfo.isAgentUser!='Y' && !loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")?true:false
      },
    },
    {
      name: "createdBy",
      label: "Created By",
      options: {
        filter: true,
        sort: false,
      },
      
    },
    {
      name: "Action",
      label: "Action",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            
        <div>
          {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("CAN_ADD_UPDATE")) ? (
              <div>
              <Button  variant="contained" 
              component={NavLink}
              to={"/home/offers/add?id="+value}
              color="primary">
                  Edit
              </Button>
             
             <Button  variant="contained" className="ml-2"
             onClick={(e) =>createCopy(e,value)}
             color="primary">
                 Create Copy
         </Button>
        
         </div>
              ) : null}
             
             <Button  variant="contained" className="mt-2"
             onClick={(e) =>viewProduct(e,value)}
             color="primary">
                 Associated Products
          </Button>
         </div>  
              
             
          )
      }
      },
      
    },
    
  ]
  const inActiveColumns = [
    {
      name: "offerImage",
      label: " ",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
            <img height='100' width='100'  src={value}/>
          );
        }
      },
    },
    {
      name: "offerHeading",
      label: "Offer/Collection heading",
      options: {
        filter: true,
        sort: true,
        
      },
    },
    {
      name: "offerSubHeading",
      label: "Offer Sub heading",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "branch",
      label: "Branch",
      options: {
        filter: true,
        sort: false,
        display: loggedUserInfo!=null && loggedUserInfo!=undefined && loggedUserInfo.isMaster!='Y' && loggedUserInfo.isAgentUser!='Y' && !loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")?true:false
      },
    },
    {
      name: "campaignName",
      label: "Campaign",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "startDate",
      label: "Start Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
          <Moment  tz="CST" format="YYYY/MM/DD hh:mm:ss A">
          {value}
          </Moment>
          );
        }
      },
      
    },
    {
      name: "endDate",
      label: "End Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
          <Moment  tz="CST" format="YYYY/MM/DD hh:mm:ss A">
          {value}
          </Moment>
          );
         } 
      },
      
    },
    {
      name: "Action",
      label: "Action",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
         /* var isOpen=false;
          function toggleModal() {
  
            isOpen=true
          
          }
          function handleClose() {
            isOpen=false
          }*/
          return (
            <div>
          {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("CAN_ADD_UPDATE")) ? (
              <div>
              <Button  variant="contained" 
              component={NavLink}
              to={"/home/offers/add?id="+value}
              color="primary">
                  Edit
              </Button>
             
             <Button  variant="contained" className="ml-2"
             onClick={(e) =>createCopy(e,value)}
             color="primary">
                 Create Copy
         </Button>
        
         </div>
              ) : null}
             
             <Button  variant="contained" className="mt-2"
             onClick={(e) =>viewProduct(e,value)}
             color="primary">
                 Associated Products
          </Button>
         </div>  
          )
      }
      },
      
    },
    
  ]

  const createCopy = async (e,id) => {
    API.createOfferCopy(id).then((result) => {  
         
      if(result.data!=undefined)
      {
        toast.success("Offer copy created.")
        //window.location.reload();
        eventBus.dispatch("copyCreated", { message: "offer copy created created" });
      }
           })
           .catch((error) => {
             //console.log(error);
             toast.error("error during create copy,try again.")
             alert(error);
          });
        };

        const viewProduct = async (e,id) => {
          setIsOpen(!isOpen);
          API.getOfferProducts(id).then((result) => {  
               
            if(result.data!=undefined)
            {
              setOfferProductData(result.data);
              setIsOpen(!isOpen);
            }
                 })
                 .catch((error) => {
                   //console.log(error);
                 //  toast.error("error during create copy,try again.")
                  // alert(error);
                });
              };

              function handleClose() {
                setIsOpen(false);
              }

  const activeOptions = {
    download: false,
    print: false,
    rowsPerPage: 10,
    selectableRowsOnClick: false,
    selectToolbarPlacement: "none",
    selectableRows:"none",
    
  }


  const options = {
    download: false,
    print: false,
    rowsPerPage: 10,
    selectableRowsOnClick: false,
    selectToolbarPlacement: "none",
    selectableRows:"none",
   /* customToolbar: () => (
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddRoundedIcon />}
        component={NavLink}
        onClick={(e) =>handleSubmit(e)}
        to={"/home/company/addCompany"}
      >
       Start Promotion
      </Button>
    ),*/
    selectableRowsOnClick: false,
    onRowSelectionChange: (curRowSelected,allRowsSelected,rowsSelected) => {
      selectedOffer=[];
      //console.log("curRowSelected>>>>>>>>>>>",curRowSelected)
      //console.log("allRowsSelected>>>>>>>>>>>",allRowsSelected)
      //console.log("rowsSelected>>>>>>>>>>>",rowsSelected)
      for(var i=0;i<allRowsSelected.length;i++)
      {
      const offerData = pendingCompanyOffer[allRowsSelected[i].index];
      selectedOffer.push(offerData.id);
      }
     console.log("clicked>>>>>>>>>>>",selectedOffer.join())
    },
  }

const handleSubmit = async (e) => {

  if(selectedOffer!=undefined && selectedOffer!=null && selectedOffer!='' && selectedOffer.length>0)
  {
    history.push(`/home/offers/promotionBatch?id=`+selectedOffer.join())
  }
  else
  {
    toast.error("Please select pending/inactive offer")
  }

  };

 const getCompanyBranches = async (e) => {
    console.log("event>>>>>>>>>",e.target.value);
    
    if(e.target.value!=undefined && e.target.value!='')
    {
      localStorage.setItem('companyFilter', e.target.value);
      getStoreByCompany(e.target.value);
        }
        
  };

  const handleBranchChanage = async (e) => {
    console.log("event>>>>>>>>>",e.target.value);
    if(e.target.value!=undefined && e.target.value!='')
    {
      localStorage.setItem('branchFilter', e.target.value);
      API.getCompanyBranchOffer(e.target.value,"active").then((result) => {  
       
        if(result.data!=undefined)
        {
          setActiveCompanyOffer(result.data)
          setActiveCompanyOfferCount(result.data.length)
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            });
  
            API.getCompanyBranchOffer(e.target.value,"pending").then((result) => {  
         
              if(result.data!=undefined)
              {
                setPendingCompanyOffer(result.data)
                setPendingCompanyOfferCount(result.data.length)
              }
                   })
                   .catch((error) => {
                     console.log(error);
                     alert(error);
                  });
  
            API.getCompanyBranchOffer(e.target.value,"inactive").then((result) => {  
         
              if(result.data!=undefined)
              {
                setInActiveCompanyOffer(result.data)
                setInActiveCompanyOfferCount(result.data.length)
              }
                   })
                   .catch((error) => {
                     console.log(error);
                     alert(error);
                  });
        }
        
  };

  const activeOfferData = activeCompanyOffer.map((item) => ({
    offerHeading: item.heading,
    offerSubHeading:item.subHeading,
    type:item.type,
    branch:item.storeName,
    campaignName:item.campaignName,
    createdBy:item.createdBy,
    startDate:item.validFrom,
    endDate:item.validTill,
    offerImage:item.fullImage!=undefined && item.fullImage!="" && item.fullImage!=null?item.fullImage:"https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg",
    Action:item.id,
  }));

  const inActiveOfferData = inactiveCompanyOffer.map((item) => ({
    offerHeading: item.heading,
    offerSubHeading:item.subHeading,
    type:item.type,
    branch:item.storeName,
    campaignName:item.campaignName,
    createdBy:item.createdBy,
    startDate:item.validFrom,
    endDate:item.validTill,
    offerImage:item.fullImage!=undefined && item.fullImage!="" && item.fullImage!=null?item.fullImage:"https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg",
    Action:item.id,
  }));

  const pendingOfferData = pendingCompanyOffer.map((item) => ({
    offerHeading: item.heading,
    offerSubHeading:item.subHeading,
    type:item.type,
    branch:item.storeName,
    createdBy:item.createdBy,
    offerImage:item.fullImage!=undefined && item.fullImage!="" && item.fullImage!=null?item.fullImage:"https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg",
    Action:item.id,
  }));

  return (
    <TabContext value={value}>
      <div className={classes.tab}>
        <TabList
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="simple tabs example"
          centered
        >
          <Tab
            className={value === "1" && "font-weight-bold"}
            label={"Live ("+activeCompanyOfferCount+")"}
            value="1"
          />
          <Tab
            className={value === "2" && "font-weight-bold"}
            label={"Pending ("+pendingCompanyOfferCount+")"}
            value="2"
          />
          <Tab
            className={value === "3" && "font-weight-bold"}
            label={"Inactive ("+inactiveCompanyOfferCount+")"} 
            value="3"
          />
        </TabList>
        {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")) ? (
        <div className="d-flex">
        <Form.Control
          as="select"
          className=" mr-3"
          name="company"
          value={localStorage.getItem('companyFilter')}
          onChange={getCompanyBranches}
          required
        >
          <option>Select Company</option>
          {companyList.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Form.Control>
        
        <Form.Control
          as="select"
          className="mr-sm-0"
          name="companyBranch"          
          value={localStorage.getItem('branchFilter')}
          onChange={handleBranchChanage}
          required
        >
          <option>Select Branch</option>
          {storeData.map((item, index) => (
            <option key={item.id} value={item.id}>
              {item.code}
            </option>
          ))}
        </Form.Control>
        </div>
         ) : null}
          {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' ||  loggedUserInfo.privilegeCode.includes("CAN_ADD_UPDATE")) ? (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<AddRoundedIcon />}
          //   size="small"
          component={NavLink}
          to={"/home/offers/add"}
        >
          Add Offer/Collection
        </Button>
         ) : null}
       {/*} <Button
        variant="contained"
        color="primary"
        startIcon={<AddRoundedIcon />}
        //component={NavLink}
        onClick={(e) =>handleSubmit(e)}
      >
       Start Promotion
  </Button>*/}
      </div>
      <TabPanel value="1">
        <MUIDataTable
          title={"Live Offers/Collection"}
          data={activeOfferData}
          columns={columns}
          options={activeOptions}
          
        />
      </TabPanel>
      <TabPanel value="2">
        <MUIDataTable
          title={"Pending Offers/Collection"}
          data={pendingOfferData}
          columns={pendingColumns}
          options={options}
        />
      </TabPanel>
      <TabPanel value="3">
        <MUIDataTable
          title={"Inactive Offers/Collection"}
          data={inActiveOfferData}
          columns={inActiveColumns}
          options={options}
        />
      </TabPanel>

      <Modal
        show={isOpen}
        onHide={handleClose}
        centered
        size="xl"
      >
        <ViewOfferProduct offerProductData={offerProductData}  setIsOpen={setIsOpen}  />;
      </Modal>

      <ToastContainer />
     
    </TabContext>
  )
}
export default ManageOffers
