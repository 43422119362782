import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom"
import { Header, DrawerMenu } from "../domain"
import Offers from "./Offers"
import Products from "./Products"
import Company from "./Company"
import Branches from "./Branches"
import Users from "./Users"
import Categories from "./Categories"
import Transactions from "./Transactions"
import Invoice from "./Invoice"
import Campaign from "./Campaign"
import Bills from "./Bills"
import Section from "./Section"
import ManageCatalog from "../domain/ManageCatalog"
import CatalogProductDetails from "../domain/CatalogProductDetails"
import Catalog from "./Catalog"
import Profile from "./Profile"
import SponsoredCampaignList from "../domain/SponsoredCampaignList"
import PaymentMethod from "../domain/PaymentMethod"
import { useAuth } from "../context"
import PaymentMethodAdmin from "../domain/PaymentMethodAdmin"
import Report from "./Report"
import ProductVariant from "./ProductVariant"
import ConsolidateConversionReport from "./ConsolidateConversionReport"
import ShopifySponsored from "../domain/ShopifySponsored"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: "100vw",
    height: "100vh",
    // background: "#eee",
  },
}))

export default function HomePage() {
  const classes = useStyles()
  const { path } = useRouteMatch()
  const [open, setOpen] = useState(true)
  const handleDrawerOpen = () => setOpen(true)
  const handleDrawerClose = () => setOpen(false)
  const { signUp, userInfo, loggedUserInfo } = useAuth();

  return (
    <div className={classes.root}>
      <Header handleDrawer={handleDrawerOpen} drawerState={open} />
      <DrawerMenu handleDrawer={handleDrawerClose} drawerState={open} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <Route path={`${path}/company`} component={Company} />
          <Route path={`${path}/branches`} component={Branches} />
          <Route path={`${path}/offers`} component={Offers} />
          <Route path={`${path}/products`} component={Products} />
          <Route path={`${path}/productVariant`} component={ProductVariant} />
          <Route path={`${path}/categories`} component={Categories} />
          <Route path={`${path}/users`} component={Users} />
          <Route path={`${path}/transactions`} component={Transactions} /> 
          <Route path={`${path}/campaign`} component={Campaign} /> 
          <Route path={`${path}/invoice`} component={Invoice} /> 
          <Route path={`${path}/bills`} component={Bills} /> 
          <Route path={`${path}/section`} component={Section} /> 
          <Route path={`${path}/catalog`} component={Catalog} /> 
          <Route path={`${path}/report`} component={Report} /> 
          <Route path={`${path}/shopifySponsored`} component={ShopifySponsored} /> 
          <Route path={`${path}/consolidateConversionReport`} component={ConsolidateConversionReport} /> 
          {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || (loggedUserInfo.privilegeCode!=undefined && loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY"))) ? (
          <Route path={`${path}/paymentMethod`} component={PaymentMethodAdmin} /> 
          ) : <Route path={`${path}/paymentMethod`} component={PaymentMethod} /> 
          }
          <Route path={`${path}/profile`} component={Profile} /> 
          <Redirect to={`${path}/company`} />
          
        </Switch>
      </main>
    </div>
  )
}
