import React,{useState,useEffect} from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { provinces } from "../commonData/provinces";
import { cities } from "../commonData/cities";
import { useHistory } from "react-router-dom";
import { useStore, useAuth } from "../context"
import API from "../utils/API";
const FormContainer = styled.form`
  width: 500px;
  background-color: #fff;
  padding: 50px;
  margin-top: 25px;
  box-shadow: 0 0 20px silver;
  border-radius: 10px;
`;

let country = ["Canada"];

if(localStorage.getItem("connectSite")=="CA")
{
  country = ["Canada"];
}
else
{
  country = ["US"];
}

function AddressForm({ form, setForm, navigation,selectedLogoFile }) {
  const history = useHistory();
  const [validated,setValidate]=useState(false);
  const [cities,setCities]=useState([]);
  const [citiesBa,setCitiesBa]=useState([]);
  const { stateData, getState} =useStore();
  useEffect(() => {
    getState("gust");
     
  }, []);
  const {
    countryBA,
    streetBA,
    cityBA,
    provinceBA,
    postalCodeBA,
    isPhysicalStore,
    AppartmentName,
    secStreetBA,
    secCityBA,
    secProvinceBA,
    secPostalCodeBA,
    secAppartmentName,
    isPhysicalSameAsRegisteredAddress,
    countryPA,
    streetPA,
    cityPA,
    provincePA,
    postalCodePA,
  } = form;

  const handleStateChange = async (e) => {
    //console.log("event>>>>>>>>>",e.target.value);
    form.provinceBA=e.target.value;
    API.getCitiesByState("gust",e.target.value).then((result) => {  
       
      if(result.data!=undefined && result.data.length>0)
      {
        setCities(result.data);
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });

  };

  const handleStateChange1 = async (e) => {
    //console.log("event>>>>>>>>>",e.target.value);
    form.secProvinceBA=e.target.value;
    API.getCitiesByState("gust",e.target.value).then((result) => {  
       
      if(result.data!=undefined && result.data.length>0)
      {
        setCitiesBa(result.data);
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });

  };

  const handleSubmit = async (e) => {
    setValidate(true);
    if (form.countryBA == '' || form.countryBA == undefined) {
      e.preventDefault();
      e.stopPropagation();
  }
  else if (form.provinceBA == '' || form.provinceBA == undefined) {
    e.preventDefault();
    e.stopPropagation();
  }
  else if (form.cityBA == '' || form.cityBA == undefined) {
    e.preventDefault();
    e.stopPropagation();
  }

  else if (form.postalCodeBA == '' || form.postalCodeBA == undefined) {
    e.preventDefault();
    e.stopPropagation();
  }
  else if (form.AppartmentName == '' || form.AppartmentName == undefined) {
    e.preventDefault();
    e.stopPropagation();
  }
  else if (form.streetBA == '' || form.streetBA == undefined) {
    e.preventDefault();
    e.stopPropagation();
  }

  else if (form.isPhysicalStore == '' || form.isPhysicalStore == undefined) {
    e.preventDefault();
    e.stopPropagation();
  }

  else if (form.isPhysicalStore=='Y' && (form.isPhysicalSameAsRegisteredAddress == '' || form.isPhysicalSameAsRegisteredAddress == undefined)) {
    e.preventDefault();
    e.stopPropagation();
  }

  
  else if(form.streetBA != '' && form.AppartmentName != '' && form.postalCodeBA != '' && form.cityBA != '' && form.provinceBA != '' && form.countryBA != ''   )
  {
 navigation.next();
  }
  };
  return (
    <FormContainer className="mx-auto">
      <h3 className="text-center mb-3">Business Address</h3>
      <Form noValidate validated={validated} action="#">
      <Form.Group>
        <Form.Label>Country</Form.Label>
        <Form.Control
          as="select"
          className="mr-sm-2"
          id="inlineFormCustomSelect"
          name="countryBA"
          custom
          value={countryBA}
          onChange={setForm}
          required
        >
          <option>Select Country</option>
          {country.map((item, index) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid" >Please select country</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>{localStorage.getItem("connectSite")=="CA"?"Province":"Company Corporation/Registry State"}</Form.Label>
        <Form.Control
          as="select"
          className="mr-sm-2"
          id="inlineFormCustomSelect"
          name="provinceBA"
          custom
          value={provinceBA}
          onChange={handleStateChange}
          required
        >
          <option>Select State/Province</option>
          {stateData.map((item, index) => (
            <option key={index} value={item.id}>
              {item.name}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid" >{localStorage.getItem("connectSite")=="CA"?"Please select province":"Please select state/province"}</Form.Control.Feedback>
      </Form.Group>
      <Form.Row className="m-0">
        <Row>
          <Col>
            <Form.Group>
              <Form.Control
                as="select"
                className="mr-sm-2"
                id="inlineFormCustomSelect"
                name="cityBA"
                custom
                value={cityBA}
                onChange={setForm}
                required
              >
                <option>Select City</option>
                {cities.map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid" >Please select city</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Control
                name="postalCodeBA"
                placeholder="Postal Code"
                value={postalCodeBA}
                onChange={setForm}
                required
              />
               <Form.Control.Feedback type="invalid" >Please enter postal code</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Form.Row>
      <Form.Group>
        <Form.Label>Unit</Form.Label>
        <Form.Control
          name="AppartmentName"
          type="text"
          value={AppartmentName}
          onChange={setForm}
          required
        />
         <Form.Control.Feedback type="invalid" >Please enter unit</Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="exampleForm.ControlTextarea1">
        <Form.Label>Street Address</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="streetBA"
          value={streetBA}
          onChange={setForm}
          required
        />
         <Form.Control.Feedback type="invalid" >Please enter street address</Form.Control.Feedback>
      </Form.Group>

      {/* <Form.Group>
        <Form.Label>City</Form.Label>
        <Form.Control
          name="cityBA"
          type="text"
          value={cityBA}
          onChange={setForm}
        />
      </Form.Group> */}

      {/* <Form.Group>
        <Form.Label>Postal Code</Form.Label>
        <Form.Control
          name="postalCodeBA"
          type="text"
          value={postalCodeBA}
          onChange={setForm}
        />
      </Form.Group> */}
      <Form.Label>Is this a physical store as well ?</Form.Label>
      <Form.Group>
        <Form.Check
          inline
          label="Yes"
          name="isPhysicalStore"
          type="radio"
          value="Y"
          checked={isPhysicalStore=="Y"?true:false}
          onChange={setForm}
          id="inline-radio-1"
          
          
        />
        <Form.Check
          inline
          label="No"
          name="isPhysicalStore"
          type="radio"
          value="N"
          checked={isPhysicalStore=="N"?true:false}
          onChange={setForm}
          id="inline-radio-2"
          required
          
        />
       <Form.Control.Feedback type="invalid" >Please select physical store option</Form.Control.Feedback>
      </Form.Group>

      {isPhysicalStore == "Y" && (
        <div>
          <Form.Label>
            Physical address same as registered business address !
          </Form.Label>
          <Form.Group>
            <Form.Check
              inline
              label="Yes"
              value="Y"
              checked={isPhysicalSameAsRegisteredAddress=="Y"?true:false}
              name="isPhysicalSameAsRegisteredAddress"
              type="radio"
              onChange={setForm}
              id="same-address-1"
            />
            <Form.Check
              inline
              label="No"
              value="N"
              checked={isPhysicalSameAsRegisteredAddress=="N"?true:false}
              name="isPhysicalSameAsRegisteredAddress"
              type="radio"
              onChange={setForm}
              id="same-address-2"
              required
            />
          </Form.Group>
        </div>
      )}

      {isPhysicalStore === "Y" && isPhysicalSameAsRegisteredAddress === "N" && (
        <div>
          <Form.Group>
            <Form.Label>State/Province</Form.Label>
            <Form.Control
              as="select"
              className="mr-sm-2"
              id="inlineFormCustomSelect"
              name="secProvinceBA"
              custom
              value={secProvinceBA}
              onChange={handleStateChange1}
            >
              <option>Select Province</option>
              {stateData.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Row className="m-0">
            <Row>
              <Col>
                <Form.Group>
                  <Form.Control
                    as="select"
                    className="mr-sm-2"
                    id="inlineFormCustomSelect"
                    name="secCityBA"
                    custom
                    value={secCityBA}
                    onChange={setForm}
                  >
                    <option>Select City</option>
                    {citiesBa.map((item, index) => (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Control
                    name="secPostalCodeBA"
                    placeholder="Postal Code"
                    value={secPostalCodeBA}
                    onChange={setForm}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form.Row>
          <Form.Group>
            <Form.Label>Unit</Form.Label>
            <Form.Control
              name="secAppartmentName"
              type="text"
              value={secAppartmentName}
              onChange={setForm}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Street Address</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="secStreetBA"
              value={secStreetBA}
              onChange={setForm}
            />
          </Form.Group>

          {/* <Form.Group>
        <Form.Label>City</Form.Label>
        <Form.Control
          name="cityBA"
          type="text"
          value={cityBA}
          onChange={setForm}
        />
      </Form.Group> */}
        </div>
      )}

      <div className="w-100 d-flex justify-content-around">
        <Button
          className="mt-3 w-50 mr-1"
          onClick={() => navigation.previous()}
        >
          Back
        </Button>
        <Button className="mt-3 w-50 ml-1" onClick={handleSubmit}>
          Next
        </Button>
      </div>
      </Form>
    </FormContainer>
  );
}

export default AddressForm;
