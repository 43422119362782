
import { Form, InputGroup } from "react-bootstrap"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import React, { useEffect,useState } from "react";
import { useAuth,useUser,useStore } from "../context";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Navigation } from "@material-ui/icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import {createUser} from "../utils/API";
import API from "../utils/API";
import { useForm, useStep } from "react-hooks-helper";
import { useHistory } from "react-router-dom";
import { uuid } from '../utils/uuid'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import moment from 'moment';


const defaultData = {
  heading: "",
  subHeading: "",
  category:[],
  description: "",  
  validFrom:"",
  validTo:"",
  imageUrl:"",
  storeId:"",
  storeName:"",
  offerImage:"",
  isActive:"",
  isPrimary:"",
  product:[],
  companyId:"",
  vendorUrl:"",
  marketPlace:[],
  type:"Offer",
};

const market = [
  {"label":"Canada","value":"Canada"},
  {"label":"United States","value":"United States"},
  
  
];

function AddOffers(props) {
  const updateId=new URLSearchParams(props.location.search).get("id");
const [form, setForm] = useForm(defaultData);
const [validated,setValidate]=useState(false);
const { signUp, userInfo,loggedUserInfo } = useAuth();
const { categoryData, storeData,getMainCategories,getStoreByCompany } =useStore();
const { heading, subHeading, category,description,validFrom,validTo,imageUrl,isActive,storeId,storeName,isPrimary,product,companyId,vendorUrl,type,marketPlace } = form;
const [selectedMainCategory, setSelectedMainCategory] = React.useState([])
const [selectedOfferImage, setSelectedOfferImage] = React.useState([])
const [offerData, setOfferData] = React.useState([])
const [isPrimaryOffer, setIsPrimaryOffer] = React.useState(false)
const [productList, setProductList] = useState([]);
const [selectedProduct, setSelectedProduct] = React.useState([])
const [fileUrl,setFileUrl]=useState('');
const [companyList, setCompanyList] = useState([]);
const animatedComponents = makeAnimated();
const [updateBranch, setUpdateBranch] = useState(false);
const [selectedBranch, setSelectedBranch] = useState("");
const [selectedBranchName, setSelectedBranchName] = useState("");
const [selectedCompanyName, setSelectedCompanyName] = useState("");
const [selectedBranchLogo, setSelectedBranchLogo] = useState("");
const [isInactive, setIsInactive] = useState(false);
const [selectedMarketPlace, setSelectedMarketPlace] = React.useState([])

useEffect(() => {
  console.log("useEffect>>>>>>>>>>>>>>>>>>");
    getMainCategories();
    if(loggedUserInfo!=undefined)
    {
      API.getAllCompany(loggedUserInfo.email).then((companyResult) => {  
       
        if(companyResult.data!=undefined && companyResult.data.length>0)
        {
          setCompanyList(companyResult.data)
         
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            }); 
            if(loggedUserInfo.companyId!=undefined && loggedUserInfo.companyId!=null && loggedUserInfo.companyId!='')
    getStoreByCompany(loggedUserInfo.companyId);

    if(updateId!=undefined && updateId!=null)
    {
      API.getOfferById(loggedUserInfo.email,updateId).then((result) => {  
       
        console.log("offer data>>>>>>>>>>>>>>",result)
        if(result.data!=undefined)
        {
          form.heading=result.data.heading
          form.subHeading=result.data.subHeading
          form.description=result.data.description
         // form.validFrom=result.data.validFrom
          //form.validTo=result.data.validTill
          form.imageUrl=result.data.fullImage;
          form.product=result.data.products;
         form.companyId=result.data.companyId
         form.type=result.data.type;
         form.vendorUrl=result.data.vendorUrl;
         var date1 = moment(result.data.validTill)
         var nowDate= moment();
         console.log("date1.isBefore(nowDate) date>>>>>>>>>>>",date1.isBefore(nowDate))
                    console.log("nowDate date>>>>>>>>>>>",nowDate)
                    console.log("to date>>>>>>>>>>>",date1)
         if(date1.isBefore(nowDate))
         {
           setIsInactive(true);
         }
         else
         {
           setIsInactive(false);
         }

         getStoreByCompany(result.data.companyId);
         if(result.data.canUpdateBranch=="Y")
                setUpdateBranch(false);
                else
                setUpdateBranch(true);

         if(result.data.categories!=undefined && result.data.categories.length>0)
         {
         setSelectedMainCategory(result.data.categories[0]);
         form.category.push(result.data.categories[0]);
         }
         // form.isActive=result.data.active=="Y"?true:false
         form.isPrimary=result.data.isPrimary
          form.storeId=result.data.storeId
          form.storeName=result.data.storeName

          form.marketPlace=result.data.marketPlace;
          if(result.data.marketPlace!=undefined && result.data.marketPlace!=null && result.data.marketPlace.length>0)
            {
                var existMarketPlace=[]

                result.data.marketPlace.map((item) => {
                  if(item!='' && item!="" && item!=" ")
                  {
                    var tempObj ={"label":item,"value":item}
                    existMarketPlace.push(tempObj);
                  }
                });
                setSelectedMarketPlace(existMarketPlace);
            }

          setSelectedBranch(result.data.storeId);
          setSelectedBranchName(result.data.storeName)
          setSelectedBranchLogo(result.data.logo)
          setFileUrl(result.data.fullImage);
         if(result.data.isPrimary=='Y')
         setIsPrimaryOffer(true);
         else
         setIsPrimaryOffer(false);

          setOfferData(result.data)

          API.getCompanyId(result.data.companyId).then((companyResult) => {  
       
            if(companyResult.data!=undefined)
            {
              setSelectedCompanyName(companyResult.data.name);
             
            }
                 })
                 .catch((error) => {
                   console.log(error);
                   alert(error);
                });


          console.log("isInactive>>>>>>>>>>>>>>>>>>>",isInactive);
    API.getCompanyProduct(loggedUserInfo.companyId,updateId!=null && updateId!=undefined && updateId!='' && !date1.isBefore(nowDate)?"allincludeActive":"all").then((productResult) => {  
                     
      if(productResult.data!=undefined && productResult.data.length>0)
      {
        setProductList(productResult.data);
        var existProduct=[]
        productResult.data.map((item) => {
          if(result.data.products.includes(item.id))
          var tempObj ={"label":<div><img src={item.images!=undefined && item.images!="" && item.images!=null&& item.images.length>0?item.images[0]:"https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg"} height="60px" width="60px"/> &nbsp;{item.name} </div>,"value":item.id}
          existProduct.push(tempObj)
          
        });
        setSelectedProduct(existProduct);
      
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });

        }
      })
      .catch((error) => {
        console.log(error);
        alert(error);
     });

     
    }
    else
    {
      form.heading=""
          form.subHeading=""
          form.description=""
         // form.validFrom=result.data.validFrom
          //form.validTo=result.data.validTill
          form.imageUrl=""
         // form.isActive=result.data.active=="Y"?true:false
          form.storeId=""
          form.type="Offer"
          form.vendorUrl=""
          form.marketPlace=[];
          setSelectedMarketPlace([]);
          setSelectedBranch("");
          setSelectedBranchName("")
          setSelectedBranchLogo("")
          setSelectedProduct([]);
          form.companyId=""
    }

    if(updateId==null || updateId==undefined || updateId=='')
    {
    API.getCompanyProduct(loggedUserInfo.companyId,"all").then((productResult) => {  
                     
      if(productResult.data!=undefined && productResult.data.length>0)
      {
        setProductList(productResult.data);
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });
        }
    
  }
}, [updateId,loggedUserInfo]);


let productOption=[];
//let categoryOption=[];
console.log("form.productOption>>>>>>>>>>>>>>>>",form.maincategories)
productList.map((item) => {
  let tempObj ={"label":<div><img src={item.images!=undefined && item.images!="" && item.images!=null&& item.images.length>0?item.images[0]:"https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg"} height="60px" width="60px"/> &nbsp;{item.name} </div>,"value":item.id}
  productOption.push(tempObj)
 
});

const history = useHistory();


console.log("imageUrl data>>>>>>>>>>>>>>",form.imageUrl)
/*if(updateId!=undefined && updateId!=null && (form.imageUrl==undefined || form.imageUrl==''))
{
        form.heading=offerData.heading
        form.subHeading=offerData.subHeading
        form.description=offerData.description
       // form.validFrom=offerData.validFrom
        //form.validTo=offerData.validTill
        form.imageUrl=offerData.fullImage;
       // form.isActive=offerData.active=="Y"?true:false
        form.storeId=offerData.storeId
        //setSelectedMainCategory(offerData.)
       // setFileUrl(offerData.fullImage)

}*/


const getCompanyBranches = async (e) => {
  console.log("event>>>>>>>>>",e.target.value);
  if(e.target.value!=undefined && e.target.value!='')
  {
    form.companyId=e.target.value
    var index = e.nativeEvent.target.selectedIndex;
      var companyName="";
      if(e.nativeEvent.target[index].text!=undefined)
      {
        companyName=e.nativeEvent.target[index].text.replace(/\s+/g, '')
      }
     setSelectedCompanyName(companyName);
    getStoreByCompany(e.target.value);
      }
      
};


  const handleSubmit = async (e) => {
    try {
      setValidate(true);
      console.log("form.companyId>>>>>>>>>>>>>>>>>>",form.companyId);
  /*  if (form.otp == '' || form.otp == undefined) {
        e.preventDefault();
        e.stopPropagation();
    }*/
     if (form.heading == '' || form.heading == undefined) {
      e.preventDefault();
      e.stopPropagation();
     // e.stopNavigation();
     // return;
    }
    else if (form.subHeading == '' || form.subHeading == undefined) {
      e.preventDefault();
      e.stopPropagation();
     // e.stopNavigation();
     // return;
    }

    else if (form.description == '' || form.description == undefined) {
      e.preventDefault();
      e.stopPropagation();
     // e.stopNavigation();
     // return;
    }
    else if (form.vendorUrl == '' || form.vendorUrl == undefined) {
      e.preventDefault();
      e.stopPropagation();
     // e.stopNavigation();
     // return;
    }
    else if (selectedMarketPlace != undefined  && selectedMarketPlace.length==0) {
      toast.error("Please select marketPlace")
     // e.stopNavigation();
     // return;
    }
  //  else if (form.validFrom == '' || form.validFrom == undefined) {
    //  e.preventDefault();
     // e.stopPropagation();
     // e.stopNavigation();
     // return;
    //}
    //else if (form.validTo == '' || form.validTo == undefined) {
      //e.preventDefault();
      //e.stopPropagation();
     // e.stopNavigation();
     // return;
    //}
    else if (loggedUserInfo!=null && loggedUserInfo!=undefined && (selectedMarketPlace != undefined  && selectedMarketPlace.length>0) &&  (loggedUserInfo.isMaster=='Y' || 
    loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")) &&
    (form.companyId == '' || form.companyId == undefined))
    {
      toast.error("Please select company");
      e.preventDefault();
      e.stopPropagation();
    }
    else if (form.storeId == '' || form.storeId == undefined) {
      e.preventDefault();
      e.stopPropagation();
     // e.stopNavigation();
     // return;
    }
    else if(form.heading != '' && form.subHeading != '' && form.description != ''  && form.vendorUrl != '' || 
    (loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || 
    loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")) &&
    (form.companyId !='' ) ))
    {
      try {

        var offerId=""
        if(updateId!=undefined && updateId!=null)
        {
           offerId=updateId;
        }
        else
        {
           offerId=uuid();
        }
        if(selectedOfferImage!=undefined && selectedOfferImage!='')
      var imageExtension = selectedOfferImage.name.substring(selectedOfferImage.name.indexOf('.'),selectedOfferImage.name.length)

      var uploadFolder="";
     
      if(loggedUserInfo.companyName!=undefined)
      {
        // uploadImageName=loggedUserInfo.companyName.replace(/\s+/g, '')

        uploadFolder= selectedCompanyName!=undefined && selectedCompanyName!=null && selectedCompanyName!=""?selectedCompanyName.replace(/\s+/g, ''):loggedUserInfo.companyName.replace(/\s+/g, '');
        uploadFolder=uploadFolder+"/"+selectedBranchName;
      }



        let offerBody = {
          id:offerId,
          heading:form.heading,
          subHeading:form.subHeading,
          description:form.description,
         validFrom:updateId!=undefined && updateId!=null && updateId!=''?offerData.validFrom:"",
          validTill:updateId!=undefined && updateId!=null && updateId!=''?offerData.validTill:"",
          campaignName:updateId!=undefined && updateId!=null && updateId!=''?offerData.campaignName:"",
          campaignArea:updateId!=undefined && updateId!=null && updateId!=''?offerData.campaignArea:"",
          cityList:updateId!=undefined && updateId!=null && updateId!=''?offerData.cityList:[],
          stateList:updateId!=undefined && updateId!=null && updateId!=''?offerData.stateList:[],
          budget:updateId!=undefined && updateId!=null && updateId!=''?offerData.budget:"",
          fullImage:selectedOfferImage!=undefined && selectedOfferImage!=''?API.fullBucketURL+uploadFolder+"/"+offerId+imageExtension:form.imageUrl,
          active:updateId!=undefined && updateId!=null && updateId!=''?offerData.active:"N",
          totalRating:updateId!=undefined && updateId!=null && updateId!=''?offerData.totalRating:0,
          avgRating:updateId!=undefined && updateId!=null && updateId!=''?offerData.avgRating:0,
          categories:form.category,
          storeId:form.storeId,
          storeName:form.storeName,
          createdBy:userInfo.email,
          updatedBy:userInfo.email,
          companyId:form.companyId!=undefined && form.companyId!=null && form.companyId!=''?form.companyId:loggedUserInfo.companyId,
          isPrimary:form.isPrimary,
          products:form.product,
          canUpdateBranch:"N",
          vendorUrl:form.vendorUrl,
          type:form.type,
          marketPlace: marketPlace,
          logo:selectedBranchLogo,
          campaignId:updateId!=undefined && updateId!=null && updateId!=''?offerData.campaignId:"",
         };
         if(updateId!=undefined && updateId!=null)
        {
         API.updateOffer(userInfo.email,offerBody);
        }
        else
        {
          API.createOffer(userInfo.email,offerBody);
        }
         uploadOfferImage(selectedOfferImage,loggedUserInfo.companyName.replace(/\s+/g, ''),form.storeId,offerId)
         setTimeout(() => { 
            
          history.push(`/home/offers`)
        
        }, 3000);
      
       // await login(`${email}`, `${password}`)
        //history.replace("/home")
        //    history.replace("/home");
      } catch (err) {
        console.log(err);
      }
    }
    
    } catch (e) {
      console.log(e);
    }
   
  };


  const handleCollectionSubmit = async (e) => {
    try {
      setValidate(true);
      console.log("form.companyId>>>>>>>>>>>>>>>>>>",form.companyId);
  /*  if (form.otp == '' || form.otp == undefined) {
        e.preventDefault();
        e.stopPropagation();
    }*/
     if (form.heading == '' || form.heading == undefined) {
      e.preventDefault();
      e.stopPropagation();
     // e.stopNavigation();
     // return;
    }
    else if (selectedMarketPlace != undefined  && selectedMarketPlace.length==0) {
      toast.error("Please select marketPlace")
     // e.stopNavigation();
     // return;
    }
    /*else if (form.product == '' || form.product == undefined) {
      toast.error("Please map at least one product with collection");
      e.preventDefault();
      e.stopPropagation();
     // e.stopNavigation();
     // return;
    }*/
    else if (loggedUserInfo!=null && loggedUserInfo!=undefined && (selectedMarketPlace != undefined  && selectedMarketPlace.length>0) && (loggedUserInfo.isMaster=='Y' || 
    loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")) &&
    (form.companyId == '' || form.companyId == undefined))
    {
      toast.error("Please select company");
      e.preventDefault();
      e.stopPropagation();
    }
    else if (form.storeId == '' || form.storeId == undefined) {
      e.preventDefault();
      e.stopPropagation();
     // e.stopNavigation();
     // return;
    }
    else if(form.heading != ''   && form.product != '' || 
    (loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || 
    loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")) &&
    (form.companyId !='' ) ))
    {
      try {

        var offerId=""
        if(updateId!=undefined && updateId!=null)
        {
           offerId=updateId;
        }
        else
        {
           offerId=uuid();
        }
        if(selectedOfferImage!=undefined && selectedOfferImage!='')
      var imageExtension = selectedOfferImage.name.substring(selectedOfferImage.name.indexOf('.'),selectedOfferImage.name.length)

      var uploadFolder="";
     
      if(loggedUserInfo.companyName!=undefined)
      {
        // uploadImageName=loggedUserInfo.companyName.replace(/\s+/g, '')

        uploadFolder= selectedCompanyName!=undefined && selectedCompanyName!=null && selectedCompanyName!=""?selectedCompanyName.replace(/\s+/g, ''):loggedUserInfo.companyName.replace(/\s+/g, '');
        uploadFolder=uploadFolder+"/"+selectedBranchName;
      }



        let offerBody = {
          id:offerId,
          heading:form.heading,
          subHeading:form.subHeading,
          description:form.description,
         validFrom:updateId!=undefined && updateId!=null && updateId!=''?offerData.validFrom:"",
          validTill:updateId!=undefined && updateId!=null && updateId!=''?offerData.validTill:"",
          campaignName:updateId!=undefined && updateId!=null && updateId!=''?offerData.campaignName:"",
          campaignArea:updateId!=undefined && updateId!=null && updateId!=''?offerData.campaignArea:"",
          cityList:updateId!=undefined && updateId!=null && updateId!=''?offerData.cityList:[],
          stateList:updateId!=undefined && updateId!=null && updateId!=''?offerData.stateList:[],
          budget:updateId!=undefined && updateId!=null && updateId!=''?offerData.budget:"",
          fullImage:selectedOfferImage!=undefined && selectedOfferImage!=''?API.fullBucketURL+uploadFolder+"/"+offerId+imageExtension:form.imageUrl,
          active:updateId!=undefined && updateId!=null && updateId!=''?offerData.active:"N",
          totalRating:updateId!=undefined && updateId!=null && updateId!=''?offerData.totalRating:0,
          avgRating:updateId!=undefined && updateId!=null && updateId!=''?offerData.avgRating:0,
          categories:form.category,
          storeId:form.storeId,
          storeName:form.storeName,
          createdBy:userInfo.email,
          updatedBy:userInfo.email,
          companyId:form.companyId!=undefined && form.companyId!=null && form.companyId!=''?form.companyId:loggedUserInfo.companyId,
          isPrimary:form.isPrimary,
          products:form.product,
          canUpdateBranch:"N",
          sponsoredCampaignId:updateId!=undefined && updateId!=null && updateId!=''?offerData.sponsoredCampaignId:"",
        sponsoredCampaignName:updateId!=undefined && updateId!=null && updateId!=''?offerData.sponsoredCampaignName:"",
        sponsoredType:updateId!=undefined && updateId!=null && updateId!=''?offerData.sponsoredType:"",
        isSponsored:updateId!=undefined && updateId!=null && updateId!=''?offerData.isSponsored:"",
        keywords:updateId!=undefined && updateId!=null && updateId!=''?offerData.keywords:[],
        sponsoredSection:updateId!=undefined && updateId!=null && updateId!=''?offerData.sponsoredSection:[],
        sponsoredValidFrom:updateId!=undefined && updateId!=null && updateId!=''?offerData.sponsoredValidFrom:"",
        sponsoredValidTill:updateId!=undefined && updateId!=null && updateId!=''?offerData.sponsoredValidTill:"",
          vendorUrl:form.vendorUrl,
          type:form.type,
          logo:selectedBranchLogo,
          marketPlace: marketPlace,
          campaignId:updateId!=undefined && updateId!=null && updateId!=''?offerData.campaignId:"",
         };
         if(updateId!=undefined && updateId!=null)
        {
         API.updateOffer(userInfo.email,offerBody);
        }
        else
        {
          API.createOffer(userInfo.email,offerBody);
        }
         uploadOfferImage(selectedOfferImage,loggedUserInfo.companyName,form.storeId,offerId)
         setTimeout(() => { 
            
          history.push(`/home/offers`)
        
        }, 3000);
      
       // await login(`${email}`, `${password}`)
        //history.replace("/home")
        //    history.replace("/home");
      } catch (err) {
        console.log(err);
      }
    }
    
    } catch (e) {
      console.log(e);
    }
   
  };


  const handleMarketPlaceChange = (selectedOptions) => {
    console.log("selectedOptions>>>>>>>>>>>>>>>>",selectedOptions);
    form.marketPlace=[];
    form.marketPlace=selectedOptions.map((item1) => (item1.value))
    //form.weeklyOff=selectedOptions.value;
    setSelectedMarketPlace(selectedOptions);
  };
 
    const handleMainCategoryChange = (selectedOptions) => {
      form.category=[];  
      console.log("selectedOptions>>>>>>>>>>>>>>>>",selectedOptions) 
      setSelectedMainCategory(selectedOptions.target.value)
      //selectedOptions.map((item1) => {
       form.category.push(selectedOptions.target.value);
      //});
      //form.weeklyOff=selectedOptions.value;
     // console.log("selectedOptions>>>>>>>>>>>>>>>>",form.mainCategory)
      
    };

    const uploadImage =  (e) => {
      form.companyLogo=e.target.files[0].name;
      //form.fileObject=Array.from(e.target.files)
      setSelectedOfferImage(e.target.files[0]);
      setFileUrl(URL.createObjectURL(e.target.files[0]));
     // setFileUrl(e.target.files[0].name);
      
      console.log("selectedOfferImage>>>>>>>>>>>>",selectedOfferImage);
      
        //setForm(form.companyLogo);
    }

    const getPrimaryOffer =  (e) => {
      var checked = e.target.checked;
      if(form.storeId==undefined || form.storeId=='' || form.storeId==null)
      {
        toast.error("Please select Branch first.");
        e.target.checked = false;
      }
      else
      {
      //form.isPrimary=  
      if(e.target.checked)
        {
      //  api call here
      setIsPrimaryOffer(true);
      API.getPrimaryOffer(loggedUserInfo.email,loggedUserInfo.companyId,form.storeId).then((result) => {
        //console.log("result>>>>>>>>>>>>>>>>>>>>>>>>>>",result);  
        if(result.data!=undefined && result.data.length>0) 
        {      
        if(result.data!=undefined && updateId != result.data[0].id  && result.data.length>0)
        {
          if(window.confirm(' Making this offer primary will remove existing primary!Do you want to proceed?'))
          { 
            form.isPrimary = 'Y';
            //form.oldPrimaryOfferId= result.data.id;
          }
        else
          {e.target.checked = false;
            form.isPrimary = 'N';
          }
        }
        else
        {
          form.isPrimary = 'Y';
        }
      }
      else
      {
        form.isPrimary = 'Y';
      }
      })
      .catch((error) => {
        console.log(error);
        alert(error);
     });        
       
        }
        else{
          setIsPrimaryOffer(false);
          e.target.checked = false;
            form.isPrimary = 'N';
        }
      }
    }

    const uploadOfferImage = async (selectedOfferImage,companyName,storeId,offerId) => {
      const formData = new FormData()
      formData.append('file', selectedOfferImage)
      console.log("selectedOfferImage>>>>>>>>>>>",selectedOfferImage);
      if (selectedOfferImage !== null && selectedOfferImage!='') {
        //store.dispatch('setOverlay', { showing: true })
        var imageName = selectedOfferImage.name.substring(0, selectedOfferImage.name.indexOf('.'))
        API.uploadOfferImage(formData,selectedCompanyName!=undefined && selectedCompanyName!=null && selectedCompanyName!=""?selectedCompanyName:companyName,selectedBranchName,offerId)
          .then(response => {
            if (response.isAxiosError) {
              console.log("image response>>>>>>>>>",response);
             // store.dispatch('setOverlay', { showing: false })
              //store.dispatch('setSnackbar', { color: 'error', text: 'Something went wrong please try again' })
            } else {
              //store.dispatch('setOverlay', { showing: false })
              //store.dispatch('setSnackbar', { text: 'Company Logo Uploaded successfully' })
            }
          }).catch(response => {
           // store.dispatch('setOverlay', { showing: false })
            // error callback
          })
      }
      // API.setUploadLogo(formData)
    }

    const handleProductChange = (selectedOptions) => {
      form.product=[];   
      setSelectedProduct(selectedOptions)
      selectedOptions.map((item1) => {
        form.product.push(item1.value)
      });
      //form.weeklyOff=selectedOptions.value;
      console.log("selectedOptions>>>>>>>>>>>>>>>>",form.product)
      
    };

    const handleBranchChanage = async (e) => {
      console.log("event>>>>>>>>>",e.target.value);
      if(e.target.value!=undefined && e.target.value!='')
      {
        setSelectedBranch(e.target.value);
        form.storeId=e.target.value;
        var index = e.nativeEvent.target.selectedIndex;
        form.storeName=e.nativeEvent.target[index].text;
        setSelectedBranchName(e.nativeEvent.target[index].text);
        API.getCompanyBranchProduct(e.target.value,"all").then((productResult) => {  
                     
          if(productResult.data!=undefined && productResult.data.length>0)
          {
            setProductList(productResult.data);
          }
          else
          {
            setProductList([]);
          }
               })
               .catch((error) => {
                 console.log(error);
                 alert(error);
              });

              storeData.map((item, index) => {
                item.id==e.target.value?setSelectedBranchLogo(item.companyLogo):setSelectedBranchLogo("")
                
              }
              )

              API.getStoreById(e.target.value).then((result) => {  
                console.log("store market place1111>>>>>>>>>",result)
                if(result.data!=undefined)
                {
                    console.log("store market place>>>>>>>>>",result.data)
              if(result.data.marketPlace!=undefined && result.data.marketPlace!=null && result.data.marketPlace.length>0)
              {
                  var existMarketPlace=[]
            
                  result.data.marketPlace.map((item) => {
                    if(item!='' && item!="" && item!=" ")
                    {
                      form.marketPlace.push(item);
                      var tempObj ={"label":item,"value":item}
                      existMarketPlace.push(tempObj);
                    }
                  });
                  setSelectedMarketPlace(existMarketPlace);
              }
                }
              })
              .catch((error) => {
                console.log(error);
                alert(error);
             });

      }
    };

  return (
    
    <Form noValidate validated={validated} action="#" style={style.form}>
       <InputGroup>
      <Form.Group className="col-1">
          <Button onClick={(e) =>history.goBack()} variant="contained" color="primary" type="button">
            Back
          </Button>
        </Form.Group>
      </InputGroup>
      <center className="mb-5">
        <Form.Label as="legend">Offer/Collection Form Details</Form.Label>
      </center>
      <InputGroup className="mb-3">
      {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")) ? (
            <Form.Group className="col-6">
          <Form.Label>Select Company</Form.Label>

        <Form.Control
          as="select"
          className=" mr-3"
          disabled={updateBranch}
          name="company"
          value={companyId}
          onChange={getCompanyBranches}
          required
        >
          <option>Select Company</option>
          {companyList.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Form.Control>
        </Form.Group>
         ) : null}
         <Form.Group className="col-6">
          <Form.Label>Select Branch</Form.Label>
          <Form.Control as="select" name="storeId" value={selectedBranch} 
          //onChange={setForm} 
          onChange={handleBranchChanage}
          required 
          disabled={updateBranch} className="mr-sm-2" custom>
          <option value="">Select Branch</option>
              {storeData.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.code}
                </option>
              ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid" >Please select branch</Form.Control.Feedback>
        </Form.Group>
      </InputGroup>

      <InputGroup className="mb-3">
      <Form.Label></Form.Label>
      <Form.Group className="col-6">
        <Form.Check
          inline
          label="Offer"
          name="type"
          type="radio"
          value="Offer"
          checked={type=="Offer"?true:false}
          onChange={setForm}
          id="inline-radio-1"
          
          
        />
        <Form.Check
          inline
          label="Collection"
          name="type"
          type="radio"
          value="Collection"
          checked={type=="Collection"?true:false}
          onChange={setForm}
          id="inline-radio-2"
          required
          
        />
       <Form.Control.Feedback type="invalid" >Please select type</Form.Control.Feedback>
      </Form.Group>
        </InputGroup>
        {type == "Collection" && (
          <InputGroup className="mb-3">
      
      <Form.Group className="col-6">
        <Form.Label>Collection Heading</Form.Label>
        <Form.Control 
        name="heading"
        value={heading}
        onChange={setForm}
        type="text"
        required
         />
          <Form.Control.Feedback type="invalid" >Please enter collection name</Form.Control.Feedback>
      </Form.Group>
</InputGroup>

        )}
        <Form.Group className="col-6">
        <Form.Label>Show in Marketplaces</Form.Label>
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          isMulti
          name="marketPlace"
          value={selectedMarketPlace}
          options={market}
          onChange={handleMarketPlaceChange}
          required
      />
       </Form.Group>
        {type == "Offer" && (
        <div>
      <InputGroup className="mb-3">
      
        <Form.Group className="col-6">
          <Form.Label>Offer Heading</Form.Label>
          <Form.Control 
          name="heading"
          value={heading}
          onChange={setForm}
          type="text"
          required
           />
            <Form.Control.Feedback type="invalid" >Please enter offer heading</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="col-6">
          <Form.Label>Offer Sub-Heading</Form.Label>
          <Form.Control name="subHeading"
          value={subHeading}
          onChange={setForm}
          type="text"
          required
          />
          <Form.Control.Feedback type="invalid" >Please enter offer sub heading</Form.Control.Feedback>
        </Form.Group>

        
        <Form.Group className="col-6">
          <Form.Label>Select Categories</Form.Label>
          <Form.Control as="select"  name="category" value={selectedMainCategory} onChange={handleMainCategoryChange} 
           
          className="mr-sm-2" custom>
          <option value="">Select Category</option>
              {categoryData.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
          </Form.Control>
        </Form.Group>
        <Form.Group className="col-6">
          <Form.Label>Offer Description</Form.Label>
          <Form.Control
          name="description"
          value={description}
          onChange={setForm}
          as="textarea" rows={3}
          required
           />
           <Form.Control.Feedback type="invalid" >Please enter description</Form.Control.Feedback>
        </Form.Group>
      </InputGroup>

      <InputGroup className="mb-3">
      {/*  <Form.Group className="col-4">
          <Form.Label>Valid From</Form.Label>
          <Form.Control 
          name="validFrom"
          value={validFrom}
          onChange={setForm}
          type="date" 
          required
          />
           <Form.Control.Feedback type="invalid" >Please enter Valid from</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="col-4">
          <Form.Label>Valid Till</Form.Label>
          <Form.Control  name="validTo"
          value={validTo}
          onChange={setForm}
          type="date"
          required
          />
           <Form.Control.Feedback type="invalid" >Please enter valid till</Form.Control.Feedback>
              </Form.Group>*/}
              <Form.Group className="col-4">
          <Form.Label>Offer Image</Form.Label> <br></br>
          <img width="150" height="150" src={fileUrl}/>
          <Form.Control className="mt-3" type="file"  onChange={uploadImage} />
          <strong className="mt-2">For Best view please upload image with size 1000 x 550</strong>
        </Form.Group>

        <Form.Group className="col-6">
          <Form.Label>Vendor URL</Form.Label>
          <Form.Control name="vendorUrl"
          value={vendorUrl}
          onChange={setForm}
          type="text"
          required
          />
          <Form.Control.Feedback type="invalid" >Please enter vendor url</Form.Control.Feedback>
        </Form.Group>
        </InputGroup>
        </div>
      )}


        <Form.Group className="col-12 mb-3 mt-2">
        <Form.Label>Mapped Products to this {type}</Form.Label>
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          isMulti
          isDisabled={updateId!=null && updateId!=undefined && updateId!='' && !isInactive?true:false}
          name="product"
          value={selectedProduct}
          options={productOption}
          onChange={handleProductChange}
      />
       
      </Form.Group>

        
        {/*<p>{fileUrl}</p>*/}
       

      
     

     {/* <InputGroup className="mb-3">
        <Form.Group className="col-4">
        <Form.Check
          type="checkbox"
          className="text-muted"
          name="isActive"
          defaultChecked={form.isActive}
          value={isActive}
          onChange={setForm}
          label="Activate Offer"
          
        />
        </Form.Group>
            </InputGroup>*/}
 {type == "Offer" && (
   <div>
<InputGroup className="mb-3">
        <Form.Group className="col-4">
        <Form.Check
          type="checkbox"
          className="text-muted"
          name="isPrimary"
          //defaultChecked={form.isPrimary=="Y"?true:false}
          checked={isPrimaryOffer}
          onChange={getPrimaryOffer}
          label="Show this offer on first screen of the store"
          
        />
        </Form.Group>

        


            </InputGroup>
          

      <InputGroup className="mb-3">
         {/*<Form.Group className="col-1">
       <Button onClick={(e) =>history.goBack()} variant="contained" color="primary" type="button">
            Back
          </Button>
          </Form.Group>*/}
        <Form.Group className="col-2">
          <Button onClick={(e) =>handleSubmit(e)} variant="contained" color="primary" type="button">
            Add
          </Button>
        </Form.Group>
        </InputGroup>
        </div>
        )}

{type == "Collection" && (
  <InputGroup className="mb-3">
  {/*<Form.Group className="col-1">
<Button onClick={(e) =>history.goBack()} variant="contained" color="primary" type="button">
     Back
   </Button>
   </Form.Group>*/}
 <Form.Group className="col-2">
   <Button onClick={(e) =>handleCollectionSubmit(e)} variant="contained" color="primary" type="button">
     Add Collection
   </Button>
 </Form.Group>
 </InputGroup>
)}
       
     
      <ToastContainer />
    </Form>
  )
}

export default AddOffers

const style = {
  form: {
    backgroundColor: "white",
    padding: "25px",
    boxShadow: "0 0 15px silver",
  },
}
