import React,{ useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import API from "../utils/API";
import { ToastContainer, toast } from 'react-toastify';
import validator from 'validator'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const FormContainer = styled.form`
  width: 500px;
  background-color: #fff;
  padding: 50px;
  margin-top: 75px;
  box-shadow: 0 0 20px silver;
  border-radius: 10px;
  position: relative;
`;

const market = [
  {"label":"Canada","value":"Canada"},
  {"label":"United States","value":"United States"},
  
  
];

function StoreInfoForm({ form, setForm, navigation,selectedLogoFile, setSelectedLogoFile }) {
  const { storeName, businessName, websiteUrl, companyLogo, branchName,corporationId,marketPlace } = form;
  const [errors, setErrors] = useState({});
 // const {setLogoFile}=setSelectedLogoFile;
  const [validated,setValidate]=useState(false);
  const [businessName1,setBusinessName1]=useState('');
  const [fileUrl,setFileUrl]=useState();
  form.companyLogo="";
  form.branchName=form.branchName ? form.branchName : "Primary";
  const [errorMessage, setErrorMessage] = useState('')
  const animatedComponents = makeAnimated();
  const [selectedMarketPlace, setSelectedMarketPlace] = React.useState(localStorage.getItem("connectSite")=="CA"?[{"label":"Canada","value":"Canada"}]:[{"label":"United States","value":"United States"}])
  localStorage.getItem("connectSite")=="CA"?form.marketPlace=["Canada"]:form.marketPlace=["United States"]
    ;
  function isJson(item) {
    item = typeof item !== "string"
        ? JSON.stringify(item)
        : item;

    try {
        item = JSON.parse(item);
    } catch (e) {
        return false;
    }

    if (typeof item === "object" && item !== null) {
        return true;
    }

    return false;
}
  const searchCompany = async (e) => {

    if (form.corporationId == '' || form.corporationId == undefined) {
      toast.error("Enter corporation Id s registered in corporation")
      //e.stopNavigation();
  }
  else
  {
    const headers= {
      'user-key': 'ea2b73443680a5f601f1801af41657dc'
       }
    fetch('https://corporations-ised-isde.api.canada.ca/api/v1/corporations/'+form.corporationId+'.json?lang=eng', { headers }).then(response => response.json())
    .then(data => 
    {
      if(data!=null && data!=undefined && data.length>0)
      {
        console.log("response>>>>>>>>>>>>>>>",data[0])
        console.log("is json>>>>>>>>>>>>>>>",isJson(data[0]))
        if(isJson(data[0]))
        {
          console.log("response>111111111111111>>>>>>>>>>>>>>",data[0])
          toast.success("Company set sucessfully")
          form.businessName=data[0].corporationNames[0].CorporationName.name;
          
          setBusinessName1(data[0].corporationNames[0].CorporationName.name);
          console.log("businessName>111111111111111>>>>>>>>>>>>>>",data[0].corporationNames[0].CorporationName.name)
        }
        else
        {
          form.businessName='';
          setBusinessName1('');
          toast.error(data[0]+".Please mail us with company details for store on boarding");
        }
     
      }
    }).catch(error => {
      console.error('There was an error!', error);
  });
  }

  }
  const onSubmit = async (e) => {
   /* let invalidFields = 0;
    const inputs = document.querySelectorAll(".form-control");
    inputs.forEach((element) => {
      if (element.hasAttribute("required")) {
        if (element.value.length === 0) {
          element.classList.add("is-invalid");
          invalidFields++;
          return;
        }
      }
    });
    if (invalidFields > 0) return;*/
    try {

      setValidate(true);
      setErrors({});
   // console.log("selectedMarketPlace.length>>>>>>>>>>",selectedMarketPlace.length);
    if (form.corporationId.trim() == '' || form.corporationId == undefined) {
      e.preventDefault();
      e.stopPropagation();
      setErrors({"corporationId":"Please enter Corporation id"});
      //e.stopNavigation();
  }

   /* else if (form.businessName == '' || form.businessName == undefined) {
      e.preventDefault();
      e.stopPropagation();
      toast.error("Set Company's business name by search company")
      //e.stopNavigation();
  }*/
  else if (form.storeName.trim() == '' || form.storeName == undefined) {
    e.preventDefault();
    e.stopPropagation();
    setErrors({"storeName":"Please enter Store name"});
   // e.stopNavigation();
  }
  else if (branchName.trim() == '' || branchName == undefined) {
    e.preventDefault();
    e.stopPropagation();
    setErrors({"branchName":"Please enter Branch name"});
   // e.stopNavigation();
  }

  else if (selectedMarketPlace != undefined  && selectedMarketPlace.length==0) {
    //console.log("selectedMarketPlace.length>111111111111111>>>>>>>>>",selectedMarketPlace.length);
    toast.error("Please select marketPlace")
    e.preventDefault();
    e.stopPropagation();
    setErrors({"storeName":"Please enter Store name"});
   // e.stopNavigation();
  }

  else if (selectedMarketPlace != undefined  && selectedMarketPlace.length==0) {
    //console.log("selectedMarketPlace.length>111111111111111>>>>>>>>>",selectedMarketPlace.length);
    toast.error("Please select marketPlace")
    e.preventDefault();
    e.stopPropagation();
   // e.stopNavigation();
  }

  else if (form.websiteUrl == '' || form.websiteUrl == undefined) {
    e.preventDefault();
    e.stopPropagation();
   // e.stopNavigation();
  }
  else if (!validator.isURL(form.websiteUrl)) {
    setErrorMessage('Please enter valid URL')
    e.preventDefault();
    e.stopPropagation();
  }
  else if(form.websiteUrl != '' && branchName != '' && form.storeName != ''  && form.corporationId!='' && validator.isURL(form.websiteUrl) && (selectedMarketPlace != undefined  && selectedMarketPlace.length>0))
    {
     
      API.checkCorpIdExist(form.corporationId).then((result) => {  

        if (!result.data) {
          const headers= {
            'user-key': 'ea2b73443680a5f601f1801af41657dc'
             }
          //fetch('https://corporations-ised-isde.api.canada.ca/api/v1/corporations/'+form.corporationId+'.json?lang=eng', { headers }).then(response => response.json())
          fetch('https://searchapi.mrasservice.ca/Search/api/v1/search?fq=keyword:%7B'+form.corporationId+'%7D+Status_State:Active&lang=en&queryaction=fieldquery&sortfield=score&sortorder=desc', { headers }).then(response => response.json())
          .then(data => 
          {
            console.log("response corp search>>>>>>>>>>>>>>>",data)
            if(data!=null && data!=undefined)
            {
             // console.log("response company name>>>>>>>>>>>>>>>",data.docs[0].Company_Name)
            //  console.log("is json>>>>>>>>>>>>>>>",isJson(data[0]))
              if(data.count>0)
              {
               // console.log("response>111111111111111>>>>>>>>>>>>>>",data[0])
               // toast.success("Company set sucessfully")
                form.businessName=data.docs[0].Company_Name;
                
                setBusinessName1(data.docs[0].Company_Name);
                API.checkCompanyExist(form.businessName).then((result) => {  
                  console.log("result>>>>>>>>>>>",result);
                  if(result.data)
                  {
                    toast.error("Company name already exist");
                  }
                  else
                  {
                    navigation.next();
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                      alert(error);
                  });
                  
    
               
               // console.log("businessName>111111111111111>>>>>>>>>>>>>>",data[0].corporationNames[0].CorporationName.name)
              }
              else
              {
                form.businessName='';
                setBusinessName1('');
                API.sendCorporationMail(form.email,form.mobile,form.name,form.corporationId);
                toast.error("Could not found corporation "+form.corporationId+".Please mail us with company details for store on boarding");
              }

              //////////////////////////
           
            }
          }).catch(error => {
            console.error('There was an error!', error);
        });  

        }
        else
        {
      toast.error("Corporation Id " + form.corporationId +" already use.Please mail us with company details for store on boarding"); }

      })
      .catch((error) => {
        console.log(error);
        alert(error);
    });

    }
} catch (e) {
  console.log(e);
}
  //setValidate(true);
   
  };


  const onSubmitUS = async (e) => {
    /* let invalidFields = 0;
     const inputs = document.querySelectorAll(".form-control");
     inputs.forEach((element) => {
       if (element.hasAttribute("required")) {
         if (element.value.length === 0) {
           element.classList.add("is-invalid");
           invalidFields++;
           return;
         }
       }
     });
     if (invalidFields > 0) return;*/
     try {
       setValidate(true);
       setErrors({});
     console.log("form.branchName>>>>>>>>>>",branchName);
     if (form.corporationId.trim() == '' || form.corporationId == undefined) {
      e.preventDefault();
      e.stopPropagation();
      setErrors({"corporationId":"Please enter Corporation id"});
      //e.stopNavigation();
  }
 
     else if (form.businessName.trim() == '' || form.businessName == undefined) {
       e.preventDefault();
       e.stopPropagation();
       setErrors({"businessName":"Please enter Company name"});
       //e.stopNavigation();
   }
   else if (form.storeName.trim() == '' || form.storeName == undefined) {
     e.preventDefault();
     e.stopPropagation();
     setErrors({"storeName":"Please enter Store name"});
    // e.stopNavigation();
   }
   else if (branchName.trim() == '' || branchName == undefined) {
     e.preventDefault();
     e.stopPropagation();
     setErrors({"branchName":"Please enter Branch name"});
    // e.stopNavigation();
   }
 
   else if (selectedMarketPlace != undefined  && selectedMarketPlace.length==0) {
     //console.log("selectedMarketPlace.length>111111111111111>>>>>>>>>",selectedMarketPlace.length);
     toast.error("Please select marketPlace")
     e.preventDefault();
     e.stopPropagation();
    // e.stopNavigation();
   }
 
   else if (form.websiteUrl == '' || form.websiteUrl == undefined) {
     e.preventDefault();
     e.stopPropagation();
    // e.stopNavigation();
   }
   else if (!validator.isURL(form.websiteUrl)) {
     setErrorMessage('Please enter valid URL')
     e.preventDefault();
     e.stopPropagation();
   }
   else if(form.websiteUrl != '' && branchName != '' && form.storeName != ''  && form.corporationId!='' && form.businessName!='' && validator.isURL(form.websiteUrl) && (selectedMarketPlace != undefined  && selectedMarketPlace.length>0) )
     {
      
       API.checkCorpIdExist(form.corporationId).then((result) => {  
 
         if (!result.data) {
                 API.checkCompanyExist(form.businessName).then((result) => {  
                   console.log("result>>>>>>>>>>>",result);
                   if(result.data)
                   {
                     toast.error("Company name already exist");
                   }
                   else
                   {
                     navigation.next();
                       }
                     })
                     .catch((error) => {
                       console.log(error);
                       alert(error);
                   });
                   
     
 
          
 
         }
         else
         {
       toast.error("Corporation Id " + form.corporationId +" already use.Please mail us with company details for store on boarding"); }
 
       })
       .catch((error) => {
         console.log(error);
         alert(error);
     });
 
     }
 } catch (e) {
   console.log(e);
 }
   //setValidate(true);
    
   };

  const onChangeFun = (e) => {
    e.target.classList.remove("is-invalid");
    setForm(e);
  };

    const uploadLogo =  (e) => {
    form.companyLogo=e.target.files[0].name;
    //form.fileObject=Array.from(e.target.files)
    setSelectedLogoFile(e.target.files[0]);
    //setFileUrl(URL.createObjectURL(e.target.files[0]));
    setFileUrl(e.target.files[0].name);
    console.log("setSelectedLogoFile>>>>>>>>>>>>",selectedLogoFile);
    
      //setForm(form.companyLogo);
  }

  const handleMarketPlaceChange = (selectedOptions) => {
    console.log("selectedOptions>>>>>>>>>>>>>>>>",selectedOptions);
    form.marketPlace=[];
    form.marketPlace=selectedOptions.map((item1) => (item1.value))
    //form.weeklyOff=selectedOptions.value;
    setSelectedMarketPlace(selectedOptions);
  };

  return (
    
    <FormContainer className="mx-auto">
      <h3 className="text-center mb-3">Store Info</h3>
      <Form noValidate validated={validated} action="#">
      <Form.Group controlId="validationbusinessName">
        <Form.Label>Company's Corporation/Registry Id</Form.Label>
        <Form.Control
          name="corporationId"
          type="text"
          value={corporationId}
          onChange={setForm}
          required
        />
         
        <Form.Text className="text-muted">
          Enter the Corporation/Registry Id as registered in{" "}
          <strong>corporation</strong>
        </Form.Text>

        <div className="text-danger">{errors.corporationId}</div>
       {/* <Button className="mt-2 ml-4" onClick={searchCompany}>
          Search Company
  </Button>*/}
      </Form.Group >
      {//localStorage.getItem("connectSite")=="US"?
      <Form.Group controlId="validationbusinessName">
        <Form.Label>Company Name</Form.Label>
        <Form.Control
          name="businessName"
          type="text"
          value={businessName}
          onChange={setForm}
          required
        />
       
       <div className="text-danger">{errors.businessName}</div>
       
      </Form.Group >
//:""
}
     {/* <Form.Group controlId="validationbusinessName">
        <Form.Label>Company's business name</Form.Label>
        <Form.Control
          name="businessName"
          type="text"
          readOnly={true}
          value={businessName1}
          onChange={setForm}
          required
        />
       <Form.Text className="text-muted">
          Enter the company/business name as registered in{" "}
          <strong>corporation</strong>
  </Form.Text>
   <Form.Text className="text-muted">
          Search above to set company/business name 
  </Form.Text>
        <Form.Control.Feedback type="invalid" >Please enter company name</Form.Control.Feedback>
      </Form.Group >*/}
      <Form.Group controlId="validationstoreName" >
        <Form.Label>Your preferred store name</Form.Label>
        <Form.Control
          name="storeName"
          type="text"
          value={storeName}
          onChange={onChangeFun}
          required
        />
        <div className="text-danger">{errors.storeName}</div>
      </Form.Group>

      <Form.Group>
        <Form.Label>Branch Name</Form.Label>
        <Form.Control
          name="branchName"
          type="text"
          value={branchName}
          onChange={onChangeFun}
          required
        />
        <Form.Text className="text-muted">
          Enter name of your branch if any
        </Form.Text>
        <div className="text-danger">{errors.branchName}</div>
      </Form.Group> 

      <Form.Group>
        <Form.Label>Show in Marketplaces</Form.Label>
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          isMulti
          name="marketPlace"
          value={selectedMarketPlace}
          options={market}
          onChange={handleMarketPlaceChange}
          required
      />
        
      </Form.Group>

      <Form.Group>
        <Form.Label>Website Url</Form.Label>
        <Form.Control
          name="websiteUrl"
          type="text"
          value={websiteUrl}
          onChange={setForm}
          required
        />
        <Form.Control.Feedback type="invalid" >Please enter website url</Form.Control.Feedback>
        <span style={{
          fontWeight: 'bold',
          color: 'red',
        }}>{errorMessage}</span>
      </Form.Group>


     

      {/*<Form.Group style={{ display: "none" }}>
        <Form.Control required name="companyLogo"   type="file" onChange={uploadLogo} />
       
      </Form.Group>*/}

      <Form.Label>Company Logo</Form.Label>
      {/* <Form.Row className="m-0">
        <Row>
          <Col sm={10}>
            <Form.Group>
              <Form.Control
                name="websiteUrl"
                type="text"
                value={logo}
                readonly
              />
            </Form.Group>
          </Col>
          <Col sm={2}>
            <Form.Group>
              <Button variant="outline-secondary">Browse</Button>
            </Form.Group>
          </Col>
        </Row>
      </Form.Row> */}

      <Form.File
        id="custom-file"
        label={companyLogo}
        accept="image/*"
        custom
        name="companyLogo"
        //onChange={setForm}
        onChange={uploadLogo}
      />
      <p>{fileUrl}</p>
      <strong className="mt-2">For Best view please upload logo with size 500 x 250</strong>
     
       {/*<img src={fileUrl}/>*/}
       <input type="hidden" value={form.fileObject}/>
      <div className="w-100 d-flex justify-content-around">
        <Button
          className="mt-3 w-50 mr-1"
          onClick={() => navigation.previous()}
        >
          Back
        </Button>
        <Button className="mt-3 w-50 ml-1" onClick={onSubmitUS}>
          Next
        </Button>
      </div>
      </Form>
      <ToastContainer />
      </FormContainer>
     
   
  );
}

export default StoreInfoForm;
