import React, { useEffect,useState } from "react";
import { Form, Button, InputGroup, Alert } from "react-bootstrap";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { useAuth,useUser } from "../context";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Navigation } from "@material-ui/icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import {createUser} from "../utils/API";
import API from "../utils/API";
import { auth } from "../utils/FirebaseConfig"
import { useForm, useStep } from "react-hooks-helper";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { uuid } from '../utils/uuid'

const defaultData = {
  categoryName: "",
categoryImage: "",
subCategory:[],
  isRoot: "",  
  mainCategory:[],
};

const FormContainer = styled.form`
  width: 400px;
  background-color: #fff;
  padding: 50px;
  margin-top: 150px;
  box-shadow: 0 0 20px silver;
  border-radius: 10px;
`;
const RegisterLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px 25px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px silver;
  padding: 25px;
  :hover {
    text-decoration: none;
  }
`;

const AddCategory = (props) => {
  console.log("query param>>>>>>>>>>>>>",new URLSearchParams(props.location.search).get("id"))
  const catId=new URLSearchParams(props.location.search).get("id");
  const mainCatId=new URLSearchParams(props.location.search).get("selectedParentId");
  const bucketURL = 'https://shopiadsimages.blob.core.windows.net/storeimages/';
  const [form, setForm] = useForm(defaultData);
  const [mobile, setMobile] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [btnDisable, setBtnDisable] = useState("");
  const [validated,setValidate]=useState(false);
  const { resetPassword } = useAuth();
  const { roles, getRoles } = useUser();
  //const { userInfo } = useAuth();
  const { categoryName,categoryImage,isRoot,mainCategory,subCategory } = form;
  /*if(catId==undefined || catId==null || catId=="")
  {
    form.categoryName="";
          form.isRoot="";
          form.mainCategory=[];
  }*/
  if(mainCatId=="main")
  form.isRoot=true;
  
  const { signUp, userInfo,loggedUserInfo } = useAuth();
  const [selectedImageFile, setSelectedImageFile] = useState();
  const [fileUrl,setFileUrl]=useState("");
  const [selectedMainCategory, setSelectedMainCategory] = React.useState([])
  const animatedComponents = makeAnimated();
  const [categoryList, setcategoryList] = useState([]);
 // const [categoryList, setcategoryList] = useState([]);
 console.log("categoryName>>>>>>>>>>>>>>>>",form.categoryName)
  useEffect(() => {
    API.getCategory(userInfo.email).then((result) => {  
       
      if(result.data!=undefined && result.data.length>0)
      {
        setcategoryList(result.data)
      }

      
      if(catId!=undefined && catId!=null)
      {
      API.getCategoriesById(userInfo.email,catId).then((result1) => {  
   
        if(result1.data!=undefined)
        {
          form.categoryName=result1.data.name;
          form.isRoot=result1.data.root=="Y"?true:false;
          form.mainCategory=result1.data.maincategories;
          form.subCategory=result1.data.subcategories;
          var existMainCat=[]
          result.data.map((item) => {
            if(result1.data.maincategories.includes(item.id))
            var tempObj ={"label":item.name,"value":item.id}
            existMainCat.push(tempObj)
            
          });
         // console.log("existMainCat>7777777777>>>>>>>>>>>>>>>",existMainCat)
          setSelectedMainCategory(existMainCat)
          //  setSelectedMainCategory(result1.data.maincategories)
          setFileUrl(result1.data.image);

        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            });    
          }
          else
          {
            form.categoryName="";
          form.isRoot=false;
          form.mainCategory=[];
          var existMainCat=[]
          }
          if(mainCatId!=undefined && mainCatId!=null)
          {
            if(mainCatId=="main"){
              form.isRoot=true
            }
            else{
            form.mainCategory=[]
            form.mainCategory.push(mainCatId);
            var existMainCat=[]
          result.data.map((item) => {
            if(mainCatId.includes(item.id))
            var tempObj ={"label":item.name,"value":item.id}
            existMainCat.push(tempObj)
           });
        }
         // console.log("existMainCat>7777777777>>>>>>>>>>>>>>>",existMainCat)
          setSelectedMainCategory(existMainCat)
             }

           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });

         
    
  }, []);

  let categoryOption=[];
  //let categoryOption=[];
  console.log("form.maincategories>>>>>>>>>>>>>>>>",form.maincategories)
  categoryList.map((item) => {
    let tempObj ={"label":item.name,"value":item.id}
    categoryOption.push(tempObj)
   
  });
  const handleSubmit = async (e) => {
    try {
      setValidate(true);
      console.log("form.categoryName>>>>>",form.categoryName)
      console.log("form.isRoot>>>>>",form.isRoot)
      console.log("form.mainCategory>>>>>",form.mainCategory)
     if (form.categoryName == '' || form.categoryName == undefined) {
      console.log("form.mainCategory>11111111>>>>",form.mainCategory)
      e.preventDefault();
      e.stopPropagation();
     
     // e.stopNavigation();
     // return;
    }

    else if(!form.isRoot && form.mainCategory.length<=0)
    {
      alert("Please select main category for this category");
      e.preventDefault();
      e.stopPropagation();
    }
    else if(form.categoryName != '' && (form.isRoot || form.mainCategory.length>0))
    {
      console.log("form.mainCategory>22222222222>>>>",form.mainCategory)
      try {
        uploadCategory(selectedImageFile)
        let categoryBody = {
          'id': catId!=undefined && catId!=null?catId:uuid(),
          'name': form.categoryName,
          'subcategories': catId!=undefined && catId!=null?form.subCategory:[],
          'maincategories': form.mainCategory,
          'image': selectedImageFile!=undefined?bucketURL+"imagesProd/categories/"+selectedImageFile.name:fileUrl,
          'root': form.isRoot?"Y":"N",
          'createdOn': new Date()
         };
         if(catId!=undefined && catId!=null)
          {
            console.log("form.mainCategory33333333333>>>>>",form.mainCategory)
            API.updateCategory(userInfo.email,categoryBody);
            
          }
          else
          {
            console.log("form.mainCategory>4444444444>>>>",form.mainCategory)
            API.createCategory(userInfo.email,categoryBody);
          }
         setTimeout(() => { 

            
          history.push(`/home/categories`)
        
        }, 3000);
      
      } catch (err) {
        console.log(err);
      }
    }
    //setValidate(false);
    
    
    } catch (e) {
      console.log(e);
    }
   
  };

  const uploadCategory = async (logoFile) => {
    const formData = new FormData()
    formData.append('file', logoFile)
    console.log("logoFile>>>>>>>>>>>",logoFile);
    if (logoFile !== null && logoFile!=undefined) {
      //store.dispatch('setOverlay', { showing: true })
      var imageName = logoFile.name.substring(0, logoFile.name.indexOf('.'))
      API.uploadCategoryImage(formData, "categories",imageName)
        .then(response => {
          if (response.isAxiosError) {
           // store.dispatch('setOverlay', { showing: false })
            //store.dispatch('setSnackbar', { color: 'error', text: 'Something went wrong please try again' })
          } else {
            //store.dispatch('setOverlay', { showing: false })
            //store.dispatch('setSnackbar', { text: 'Company Logo Uploaded successfully' })
          }
        }).catch(response => {
         // store.dispatch('setOverlay', { showing: false })
          // error callback
        })
    }
    // API.setUploadLogo(formData)
  }

  const history = useHistory();
  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
        this.validateSignUp(e);
    }
}

const uploadLogo =  (e) => {
  form.categoryImage=e.target.files[0].name;
  //form.fileObject=Array.from(e.target.files)
  setSelectedImageFile(e.target.files[0]);
  //setFileUrl(URL.createObjectURL(e.target.files[0]));
  setFileUrl(e.target.files[0].name);
  console.log("setSelectedImageFile>>>>>>>>>>>>",setSelectedImageFile);
  
    //setForm(form.companyLogo);
}

  
const handleMainCategoryChange = (selectedOptions) => {
  form.mainCategory=[];   
  setSelectedMainCategory(selectedOptions)
  selectedOptions.map((item1) => {
    form.mainCategory.push(item1.value)
  });
  //form.weeklyOff=selectedOptions.value;
  console.log("selectedOptions>>>>>>>>>>>>>>>>",form.mainCategory)
  
};

  return (
    <>
          <Form noValidate validated={validated} action="#" style={style.form}>
      <center className="mb-5">
        <Form.Label as="legend">Add Categories</Form.Label>
      </center>
      
      <InputGroup className="mb-2">
          <Form.Group controlId="validationmobileNo" className="col-3">
            <Form.Label>Category Name</Form.Label>
            <Form.Control
              name="categoryName"
              autoComplete={false}
              value={categoryName}
              type="text"
              onChange={setForm}
              required
            />
             <Form.Control.Feedback type="invalid" >Please enter category name</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="validationmobileNo" className="col-3">
            <Form.Label>Category Image</Form.Label>
          <Form.File
        id="custom-file"
        label={categoryImage}
        //accept="image/*"
        custom
        name="categoryImage"
        //onChange={setForm}
        onChange={uploadLogo}
      />
       <p>{fileUrl}</p>
       </Form.Group>
          </InputGroup>

          <InputGroup className="mb-2">

         {/* <Form.Group controlId="formBasicCheckbox" className="col-3">
        <Form.Check
          type="checkbox"
          className="text-muted"
          name="isRoot"
          defaultChecked={form.isRoot}
          value={isRoot}
          onChange={setForm}
          label="is Root Category"
          
        />
  </Form.Group>*/}
      {/*!form.isRoot && (
      <Form.Group className="col-3">
        <Form.Label>Select Main Category</Form.Label>
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          isMulti
          name="weeklyOff"
          value={selectedMainCategory}
          options={categoryOption}
          onChange={handleMainCategoryChange}
      />
 </Form.Group>
      )*/}

          </InputGroup>
          <InputGroup className="mb-3">
          <Form.Group className="col-2">
          <Button
          onClick={(e) =>handleSubmit(e)}
            variant="primary"
            type="button"
            className="w-100"
          >
            Submit
          </Button>
          </Form.Group>
      </InputGroup>
          </Form>
       
      <ToastContainer />
    </>
  );
};

export default AddCategory;

const style = {
  backButton: {
    fontSize: "25px",
    marginBottom: "10px",
    color: "blue",
    cursor: "pointer",
  },
};
