import React from "react"
import { useHistory } from "react-router-dom";


// const FormContainer = styled.form`
//   width: 400px;
//   background-color: #fff;
//   padding: 50px;
//   margin-top: 150px;
//   box-shadow: 0 0 20px silver;
//   border-radius: 10px;
// `
// const RegisterLink = styled(Link)`
//   position: absolute;
//   top: 0;
//   left: 0;
//   margin: 10px 25px;
//   background-color: #fff;
//   border-radius: 10px;
//   box-shadow: 0 0 20px silver;
//   padding: 25px;
//   :hover {
//     text-decoration: none;
//   }
// `

function SearchBar() {
    const handleSubmit = async (e) => {

        history.push(`/login`)
      }
      const history = useHistory();

    return (
        <>
            
            <div className="help-bar" id="top">
                <h6>ShopiAds Help</h6>
                <button onClick={(e) => handleSubmit(e)} className="btn btn-default bg-white">Back to ShopiAds</button>
            </div>
            

            <div className="search-banner">
                <div className="container-fluid px-3 px-lg-5">
                  {/* <div className="row">
                        <div className="col-10 col-md-4 mx-auto">
                            <h5 className="text-white text-center">Search ShopiAds Help Desk</h5>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-white"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg></span>
                                </div>
                                <input type="text" class="form-control border-left-0" placeholder="Ask a question or search by keyword" />
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <button className="border-0"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                        </svg></button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

    */}

                </div>
            </div>
        </>
    )
}

export default SearchBar