import React, { useState,useEffect } from "react";
import { useAuth } from "../context";
import { Form, Button, Alert, InputGroup } from "react-bootstrap";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { auth } from "../utils/FirebaseConfig";
import API from "../utils/API";
import {
  AppBar,
  Card,
  CardContent,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import ShopiAdsLogo from "../assets/original_with_white-cutout.png";
import SignIn from "../assets/signIn.jpeg";
import shopifyLogo from "../assets/Shopify-App-Store-Badge.png";
import { makeStyles } from "@material-ui/core/styles";
import {
  PlayCircleFilledWhite,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import { width } from "@material-ui/system";
import ReactFlagsSelect from "react-flags-select";

const FormContainer = styled.form`
  width: 400px;
  background-color: #fff;
  padding: 50px;
  margin-top: 150px;
  box-shadow: 0 0 20px silver;
  border-radius: 10px;
`;
const RegisterLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px 25px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px silver;
  padding: 25px;
  :hover {
    text-decoration: none;
  }
`;
const useStyles = makeStyles(() => ({
  root: {
    width: "85%",
    marginTop: "10%",
    backgroundColor: "#FFFFFF",
    boxShadow: "box-shadow: 0px 3px 6px #00000029 !important",
    borderRadius: "25px !important",
    "& img": {
      width: "20%",
      display: "flex",
      marginLeft: "auto",
      marginRight: "auto",
    },
    "& h4": {
      fontSize: "20px",
      fontWeight: "700",
      textAlign: "center",
      "& span": {
        color: "#1C76D3",
      },
    },
  },
  appBar: {
    backgroundColor: "#1C76D3 !important",
    height: "10%",
    // padding:" 1%"
  },
  bodyContainer: {
    marginTop: "6%",
  },
  leftContainer: {
    marginTop: "8%",
    "& h4": {
      marginBottom: "5%",
    },
    "& img": {
      width: "80%",
      marginLeft: "8%"
    },
  },
  rightContainerForm: {
    height: "100%",
    marginTop: "-6% !important",
    boxShadow: "none !important",
    "& a": {
      paddingBottom: "15px",
      fontWeight: "500",
      textDecoration: "underline !important"
    },
  },
  loginHeader: {
    // backgroundColor: "#1C76D3",
    height: "100%",
    display: "flex",
    alignItems: "center",
    color: "white",
    paddingLeft: "30px",
    // position: "fixed",
    width: "100%",
    marginLeft: "2%",
    "& img": {
      height: "60px",
      width: "70px",
    },
    "& h4": {
      paddingTop: "20px",
    paddingLeft: "10px",
    }
  },
  loginFields: {
    marginBottom: "20px !important",
    width: "100%",
    "& div": {
      borderRadius: "10PX !important",
    },
  },
  signInButton: {
    width: "100%",
    backgroundColor: "#1C76D3 !important",
    color: "#FFFFFF",
    fontWeight: "500",
  },
  signUpText: {
    textDecoration: "underline !important",
    padding: "5px"
  }
}));

function Login() {
  const classes = useStyles();

  const { login, isEmailVerifed } = useAuth();
  const [user, setUser] = useState({
    adminEmail: "",
    adminPassword: "",
  });
  const [values, setValues] = React.useState({
    adminEmail: "",
    adminPassword: "",
    showPassword: false,
  });
  const { userInfo } = useAuth();
  const [inputType, setInputType] = useState("password");
  const [btnDisable, setBtnDisable] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [selected, setSelected] = useState("CA");
  const showSelectedLabel = Boolean("Show Selected Label", true);

  const showSecondarySelectedLabel = Boolean(
    "Show Secondary Selected Label",
    true
  );
  const showOptionLabel = Boolean("Show Option Label", true);
  const showSecondaryOptionLabel = Boolean("Show Secondary Option Label", true);

  const customLabels = Object("Custom Labels", {
    CA: { primary: "CA", secondary: "1" },
    US: { primary: "US", secondary: "+1" },
  });

  const queryParams = new URLSearchParams(window.location.search)
  const fromWhere = queryParams.get("from")

  const onSelect = (code) => {
    if(selected!=code)
    {
    if(code=="US")
    {
    window.location.replace("https://shopiads.co.uk/ADMIN-UI");
    //window.location.replace("http://localhost:3001")
    }

    if(code=="CA")
    {
   window.location.replace("https://shopiads.de/ADMIN-UI");
   // window.location.replace("http://localhost:3000");
    }

    setSelected(code);
  }
  };


  useEffect(() => {
    if(window.location.host!=undefined&&window.location.host.includes("de"))
        {
          localStorage.setItem("connectSite","CA")
        setSelected("CA")
        }
        else
        {
        setSelected("US")
        localStorage.setItem("connectSite","US")
        }

      }, [window.location.host]);

  const handleChange = (prop) => (event) => {
    setErrMsg("");
    setBtnDisable(false);
    setValues({ ...values, [prop]: event.target.value });
    // const { name, value } = e.target;
    // setUser((prevState) => ({
    //   ...prevState,
    //   [name]: value,
    // }));
  };

  const toggleInputType = (type) => setInputType(type);

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBtnDisable(true);
    auth
      .signInWithEmailAndPassword(values.adminEmail, values.adminPassword)
      .then((authUser) => {
        API.getUserById(values.adminEmail).then((result) => { 
         if(result.data!=undefined && result.data!=null && result.data!="" && result.data.authType=="A")
         {    
        if ((authUser.user.emailVerified) || (result.data!=undefined && result.data!=null && result.data!="" && result.data.isShopify=="Y")) {
          console.log("email  verified"); //This will return true or false
          login(values.adminEmail, values.adminPassword).then((authUser) => {
            console.log("login  sucess>>>>>>>>>>>>>>>>>>>>");
            setTimeout(() => {
              history.push(`/home`);
              if(fromWhere!=undefined&&fromWhere!=null&&fromWhere=="onBoard")
              window.location.reload();
            }, 3000);
          });
        } else {
          console.log("email not verified");
          setErrMsg("Email is not verifed");
        }
      }
      else
      {
        console.log("User is not Admin please Signup to Onboard");
        setErrMsg("User is not Admin please Signup to Onboard");
      }
      })
      .catch((error) => {
        console.log(error);
        alert(error);
     });
      })
      .catch(function (error) {
        setErrMsg(error.code.split("/")[1]);
      });
    /* try {
      setBtnDisable(true)
      await login(user.adminEmail, user.adminPassword)
     const isVerifed= await isEmailVerifed()
      console.log("userCredentia222222222222211>>>>>>>>>>>>>>>>",isVerifed);
      if(isVerifed)
      {
      history.replace("/home")
      }
      else
      {
        setErrMsg("Email is not verifed")
      }
     
      setUser({
        adminEmail: "",
        adminPassword: "",
      })
    } catch (err) {
      setErrMsg(err.code.split("/")[1])
    }*/

    setBtnDisable(false);
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Grid container>
        <Grid item md={12}>
          <AppBar position="fixed" className={classes.appBar}>
            <div className={classes.loginHeader}>
              <img src={ShopiAdsLogo} alt="LOGO" />
              <h4>ShopiAds</h4>
            </div>
          </AppBar>
        </Grid>
        
        <Grid container className={classes.bodyContainer}>

       

          <Grid item md={8}>
            <div className={classes.leftContainer} style={{marginTop: "6px"}}>
              <h4 className="d-flex justify-content-center">
                Advertise With Us
              </h4>
              <img src={SignIn} alt="LOGO" />
            </div>
          </Grid>
          <Grid item md={4} className="mb-4">
            <Card className={classes.root}>
              <CardContent>
                {/* <img src={ShopiAdsLogo} alt="LOGO" /> */}
                {/*<img src={ShopiAdsLogo} alt="LOGO" />*/}
                <h4>
                  SIGN IN TO <span> ShopiAds</span>
                </h4>

                <FormContainer
                  className={classes.rightContainerForm}
                  onSubmit={handleSubmit}
                >
                   <ReactFlagsSelect
                    selected={selected}
                    className="mb-4"
                    onSelect={onSelect}
                    showSelectedLabel={showSelectedLabel}
                    showSecondarySelectedLabel={showSecondarySelectedLabel}
                    showOptionLabel={showOptionLabel}
                    showSecondaryOptionLabel={showSecondaryOptionLabel}
                    customLabels={customLabels}
                    countries={["CA", "US",]}
                    //countries={["CA"]}
                  />
                  {errMsg && <Alert variant="danger">{errMsg}</Alert>}
                  <FormControl
                    className={classes.loginFields}
                    variant="outlined"
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Email Address
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-email"
                      type="text"
                      value={values.adminEmail}
                      onChange={handleChange("adminEmail")}
                      labelWidth={100}
                    />
                  </FormControl>
                  <FormControl
                    className={classes.loginFields}
                    variant="outlined"
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={values.showPassword ? "text" : "password"}
                      value={values.adminPassword}
                      onChange={handleChange("adminPassword")}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                  </FormControl>
                  <NavLink to={`/forgotPassword`} className="small float-right">
                    Forgot Password ?
                  </NavLink>
                  <Button
                    variant="primary"
                    disabled={btnDisable}
                    type="submit"
                    className={classes.signInButton}
                  >
                    SIGN IN
                  </Button>
                </FormContainer>
                <p  className="d-flex justify-content-center">Don't Have An Account? <NavLink to={`/register`} ><b className={classes.signUpText}> SIGN UP</b></NavLink></p>
                <p  className="d-flex justify-content-center">Need Any Help <a href="https://help.shopiads.com"  ><b className={classes.signUpText}> CLICK HERE</b></a></p>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <div className="shopifyBatch">
             
        <a href="https://apps.shopify.com/shopiads" target="_new" className="text-white pr-0"> <img className="shopifyBatch" src={shopifyLogo} alt="LOGO" /></a>
            </div>
      
    </>
  );
}

export default Login;