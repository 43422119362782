import React, { useEffect,useState } from "react";
import { Form, Button, InputGroup, Alert } from "react-bootstrap";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { useAuth,useStore,useUser } from "../context";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Navigation } from "@material-ui/icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import {createUser} from "../utils/API";
import API from "../utils/API";
import { auth } from "../utils/FirebaseConfig"
import { useForm, useStep } from "react-hooks-helper";
import {uuid} from "../utils/uuid";
import moment from 'moment';


const defaultData = {
  name: "",
  tab: "",
  category: "",
  categoryName: "",
  isOfferSection:"",
  isProductSection:"",
  fromDate:"",
  toDate:"",
  configStartDate:"",
  
};

const FormContainer = styled.form`
  width: 400px;
  background-color: #fff;
  padding: 50px;
  margin-top: 150px;
  box-shadow: 0 0 20px silver;
  border-radius: 10px;
`;
const RegisterLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px 25px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px silver;
  padding: 25px;
  :hover {
    text-decoration: none;
  }
`;

const AddSection = (props) => {
  let sectionId=new URLSearchParams(props.location.search).get("id");
  
  const [form, setForm] = useForm(defaultData);
  
  const [validated,setValidate]=useState(false);
  const { resetPassword } = useAuth();
  const { roles, getRoles } = useUser();
  const [isAgent, setIsAgent] = React.useState(false)
  //const { userInfo } = useAuth();const [userData,setUse
  const { name, tab, category,categoryName,isOfferSection,isProductSection, fromDate,toDate,configStartDate} = form;
  const { signUp, userInfo,loggedUserInfo } = useAuth();
  const [sectionData, setSectionData] = useState();
  const [errors, setErrors] = useState({});
  const { categoryData,getMainCategories } =useStore();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [offerSection, setOfferSection] = useState(false);
  const [productSection, setProductSection] = useState(false);

  console.log("loggedUserInfo>>>>>>>>>>>",loggedUserInfo);
  console.log("userInfo>>>>>>>>>>>",userInfo);
  useEffect(() => {
    getMainCategories();
    

    if(sectionId!=undefined && sectionId!=null)
    {
      API.getSectionById(sectionId).then((result) => {  
       
        console.log("user data>>>>>>>>>>>>>>",result)
        if(result.data!=undefined)
        {
          
          form.name=result.data.name
          form.tab=result.data.tabName
          form.category=result.data.category
          form.categoryName=result.data.categoryName
          form.isOfferSection=result.data.isOfferSection
          if(result.data.startDate!=undefined&&result.data.startDate!=null)
          var from= moment(result.data.startDate).format("yyyy-MM-DD");
          //console.log("from>>>>>>>>>>>>>>>>",from);
          form.fromDate=from
          if(result.data.endDate!=undefined&&result.data.endDate!=null)
          var to= moment(result.data.endDate).format("yyyy-MM-DD");
          form.toDate=to
          if(result.data.configStartDate!=undefined&&result.data.configStartDate!=null)
          var configDate= moment(result.data.configStartDate).format("yyyy-MM-DD");
          form.configStartDate=configDate
          if(result.data.isOfferSection=="Y")
          setOfferSection(true);
          form.isProductSection=result.data.isProductSection

          if(result.data.isProductSection=="Y")
          setProductSection(true);
          
          setSectionData(result.data)
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error);
     });

    }
    else
    {
          form.name=""
          form.tab=""
          form.roleId=""
          form.category=""
          form.categoryName=""
          form.isOfferSection=""
          form.isProductSection=""
          form.fromDate=""
          form.toDate=""
          form.configStartDate=""
          setOfferSection(false);
          setProductSection(false);
          
    }

  }, [sectionId]);

 

 

  const handleSubmit = async (e) => {
    try {
      setValidate(true);
     
      var date1 = moment(form.fromDate)
    var date2 = moment(form.toDate)
   var nowDate= moment().format("yyyy-MM-DD");
   var configDate = moment(form.configStartDate)

      if(!offerSection&&!productSection)
      {
        e.preventDefault();
        e.stopPropagation();
        toast.error("Please select atleast one option for Section For")
       
      }
   else if (form.name == '' || form.name == undefined) {
        e.preventDefault();
        e.stopPropagation();
    }
    else if (form.tab == '' || form.tab == undefined) {
      e.preventDefault();
      e.stopPropagation();
     
    }
    else if (form.category == '' || form.category == undefined) {
      e.preventDefault();
      e.stopPropagation();
     
    }
    else if(form.fromDate==undefined || form.fromDate==null || form.fromDate=='')
    {
      toast.error("Please enter from date")
    }
    /*else if(date1.isBefore(nowDate))
    {
      toast.error("Selected from date is invalid")
    }*/

    else if(form.toDate==undefined || form.toDate==null || form.toDate=='')
    {
      toast.error("Please enter to date")
    }

    else if(date2<=date1)
    {
      toast.error("from date can not greater than or equal to date")
    }

    else if(form.configStartDate==undefined || form.configStartDate==null || form.configStartDate=='')
    {
      toast.error("Please enter Section Configuration Start Date")
    }

    else if(configDate.isBefore(nowDate))
    {
      toast.error("Please enter valid Section Configuration Start Date ")
    }

    else if(form.name != '' && form.tab != '' && form.category != '' && (offerSection||productSection))
    {
      try {

        var from = new Date(form.fromDate);
        var to = new Date(form.toDate);
        var configDate = new Date(form.configStartDate);

        let sectionBody = {
          id:sectionId!=undefined && sectionId!=null?sectionId:uuid(),
          name:form.name,
          active:sectionId!=undefined && sectionId!=null?sectionData.active :"Y",
          orderNo:sectionId!=undefined && sectionId!=null?sectionData.orderNo :0,
          tabName:form.tab,
          category:form.category,
          categoryName:form.categoryName,
          isOfferSection:form.isOfferSection,
          isProductSection:form.isProductSection,
          startDate:from,
          endDate:to,
          configStartDate:configDate,
          createdBy:userInfo.email,
          updatedBy:userInfo.email,
         };
         if(sectionId!=undefined && sectionId!=null)
         {
          API.createSection(userInfo.email,sectionBody);
         }
         else
         {
         API.createSection(userInfo.email,sectionBody);
         }
         setTimeout(() => { 
            
          history.push(`/home/section`)
        
        }, 3000);
      
       // await login(`${email}`, `${password}`)
        //history.replace("/home")
        //    history.replace("/home");
      } catch (err) {
        console.log(err);
      }
    }
    
    } catch (e) {
      console.log(e);
    }
   
  };

  const history = useHistory();
  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
        this.validateSignUp(e);
    }
}


const handleCategoryChanage = async (e) => {
  console.log("event>>>>>>>>>",e.target.value);
  if(e.target.value!=undefined && e.target.value!='')
  {
    setSelectedCategory(e.target.value);
    form.category=e.target.value;
    var index = e.nativeEvent.target.selectedIndex;
    form.categoryName=e.nativeEvent.target[index].text;
  }
};

const checkedOfferSection =  (e) => {
  if(e.target.checked)
        {
      //  api call here
      setOfferSection(true);
      form.isOfferSection="Y"
        }
        else
        {
          form.isOfferSection=""
          setOfferSection(false);
        }

};

const checkedProductSection =  (e) => {
  if(e.target.checked)
        {
      //  api call here
      setProductSection(true);
      form.isProductSection="Y"
        }
        else
        {
          form.isProductSection=""
          setProductSection(false);
        }

};

  return (
    <>
          <Form noValidate validated={validated} action="#" style={style.form}>
          <InputGroup>
      <Form.Group className="col-1">
          <Button onClick={(e) =>history.goBack()} variant="primary" color="primary" type="button">
            Back
          </Button>
        </Form.Group>
      </InputGroup>
      <center className="mb-5">
        <Form.Label as="legend">Section Form Details</Form.Label>
      </center>
      
      <InputGroup className="mb-2">

      <Form.Label className="ml-4">Section For:</Form.Label>
          <Form.Group className="col-3">
            <Form.Check
              inline
              label="Offer/Collection"
              //value="Section"
              name="offerSection"
            checked={offerSection}
              type="checkbox"
              onChange={checkedOfferSection}
              id="sponsoredTypeSection"
            />
            <Form.Check
              inline
              label="Product"
             // value="Keywords"
              name="productSection"
             checked={productSection}
              type="checkbox"
              onChange={checkedProductSection}
              id="sponsoredTypeKeyword"
            />
            </Form.Group>

            </InputGroup>
         
      <InputGroup className="mb-2">
         
          <Form.Group controlId="validationfirstName" className="col-3">
            <Form.Label>Section Name</Form.Label>
            <Form.Control
              name="name"
              type="text"
              value={name}
              onChange={setForm}
              required
            />
             <Form.Control.Feedback type="invalid" >Please enter section name</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-3">
            <Form.Label>Select Page</Form.Label>
            <Form.Control
              as="select"
              className="mr-sm-2"
              id="inlineFormCustomSelect"
              name="tab"
              custom
              value={tab}
              onChange={setForm}
              required
            >
              <option></option>
              <option value="Offer">Offer</option>
             {/* <option value="Store">Store</option>*/}

             
              
            </Form.Control>
            <Form.Control.Feedback type="invalid" >Please select page</Form.Control.Feedback>
          </Form.Group>

          </InputGroup>
          <InputGroup className="mb-2">
          <Form.Group className="col-3">
            <Form.Label>Select Category</Form.Label>
            <Form.Control
              as="select"
              className="mr-sm-2"
              id="inlineFormCustomSelect1"
              name="category"
              custom
              value={category}
              onChange={handleCategoryChanage}
              required
            >
              <option></option>
              <option value="Trending">Trending</option>
              {categoryData.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
              
            </Form.Control>
            <Form.Control.Feedback type="invalid" >Please select category</Form.Control.Feedback>
          </Form.Group>
         
          </InputGroup>

          <InputGroup className="mb-2">
                   <Form.Group className="col-3">
                   <Form.Label>Section Start Date </Form.Label>
                   <Form.Control 
                   name="fromDate"
                   value={fromDate}
                   onChange={setForm}
                   type="date" 
                   required
                   />
                    <Form.Control.Feedback type="invalid" >Please enter Valid from</Form.Control.Feedback>
                 </Form.Group>
         
                 <Form.Group className="col-3">
                   <Form.Label>Section End Date</Form.Label>
                   <Form.Control  name="toDate"
                   value={toDate}
                   onChange={setForm}
                   type="date"
                   required
                   />
                    <Form.Control.Feedback type="invalid" >Please enter valid till</Form.Control.Feedback>
                 </Form.Group>
                 
                 </InputGroup>

                 <InputGroup className="mb-2">
                  
         
                 <Form.Group className="col-3">
                   <Form.Label>Section Configuration Start Date </Form.Label>
                   <Form.Control  name="configStartDate"
                   value={configStartDate}
                   onChange={setForm}
                   type="date"
                   required
                   />
                    <Form.Control.Feedback type="invalid" >Please enter valid till</Form.Control.Feedback>
                 </Form.Group>
                 
                 </InputGroup>
          

          <InputGroup className="mb-3">
          <Form.Group className="col-2">
          <Button
          onClick={(e) =>handleSubmit(e)}
            variant="primary"
            type="button"
            className="w-100"
          >
            Submit
          </Button>
          </Form.Group>
      </InputGroup>
          </Form>
       
      <ToastContainer />
    </>
  );
};

export default AddSection;

const style = {
  backButton: {
    fontSize: "25px",
    marginBottom: "10px",
    color: "blue",
    cursor: "pointer",
  },
};
