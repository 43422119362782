import React from "react"
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom"
import { AddUser, AddRole, ManageUsers } from "../domain"
import AddCompany from "../domain/AddCompany"
import AddCompanyLogo from "../domain/AddCompanyLogo"
import ManageCompany from "../domain/ManageCompany"

function Company() {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/manage`} component={ManageCompany} />
      <Route path={`${path}/AddCompany`} component={AddCompany} />
      <Route path={`${path}/AddLogo`} component={AddCompanyLogo} />
      <Redirect to={`${path}/manage`} />
    </Switch>
  )
}

export default Company
