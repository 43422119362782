import React,{ useState,useEffect,useRef,Fragment } from "react"
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';



// const FormContainer = styled.form`
//   width: 400px;
//   background-color: #fff;
//   padding: 50px;
//   margin-top: 150px;
//   box-shadow: 0 0 20px silver;
//   border-radius: 10px;
// `
// const RegisterLink = styled(Link)`
//   position: absolute;
//   top: 0;
//   left: 0;
//   margin: 10px 25px;
//   background-color: #fff;
//   border-radius: 10px;
//   box-shadow: 0 0 20px silver;
//   padding: 25px;
//   :hover {
//     text-decoration: none;
//   }
// `

const useStylesAcord = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {

        fontWeight: theme.typography.fontWeightRegular,
    },
}));


function Sidebar() {

    const classesAcord = useStylesAcord();
    const [expanded, setExpanded] = React.useState('Started');

    useEffect(() => {

      
        if(window.location.pathname.indexOf("offer-collection")!=-1 || window.location.pathname.indexOf("create-new-offer")!=-1
        || window.location.pathname.indexOf("edit-offer")!=-1 || window.location.pathname.indexOf("offer-copy")!=-1
        || window.location.pathname.indexOf("offer-associated-product")!=-1 || window.location.pathname.indexOf("collection")!=-1
        || window.location.pathname.indexOf("help-products")!=-1 || window.location.pathname.indexOf("create-new-products")!=-1
        || window.location.pathname.indexOf("edit-products")!=-1 || window.location.pathname.indexOf("product-copy")!=-1)
        {
           
            setExpanded("add_creation");
        }
        else if(window.location.pathname.indexOf("campaign")!=-1 || window.location.pathname.indexOf("invoice")!=-1
        )
        {
            
            setExpanded("setup");
        }

        else if(window.location.pathname.indexOf("update-user")!=-1 || window.location.pathname.indexOf("roles")!=-1
        || window.location.pathname.indexOf("add-new-roll")!=-1 || window.location.pathname.indexOf("how-to-convert-your-account-into-an-agency")!=-1
        || window.location.pathname.indexOf("Manager Role")!=-1)
        {
            
            setExpanded("user");
        }
        else if(window.location.pathname.indexOf("synchelp")!=-1 || window.location.pathname.indexOf("shopifyHelp")!=-1 
        || window.location.pathname.indexOf("syncProducthelp")!=-1 || window.location.pathname.indexOf("syncCollectionhelp")!=-1
        || window.location.pathname.indexOf("syncCategoryhelp")!=-1 || window.location.pathname.indexOf("syncDepartmenthelp")!=-1
        || window.location.pathname.indexOf("syncOccasionHelp")!=-1 || window.location.pathname.indexOf("syncErrorhelp")!=-1
        || window.location.pathname.indexOf("syncOfferhelp")!=-1    || window.location.pathname.indexOf("synchelpOfferMode")!=-1
        
        )
        {
            setExpanded("shopify");
        }
        else
        {
            setExpanded("Started");
        }
       

    },[window.location.pathname]);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
      };

    return (
        <>
            <div className="sidebar">
                <h4>Functional</h4>
                <div className={classesAcord.root}>
                    <Accordion expanded={expanded === 'Started'} onChange={handleChange('Started')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classesAcord.heading}>Getting Started</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ul items={['Getting-Started-with-ShopiAds', 'How-to-Start-with-ShopiAds', 'Signing-Up', 'Dashboard', 'Login',
                                'Forgot-Password', 'Company', 'Adding-More-Stores-Branches',
                                'Edit-Store-Branch', 'Add-Edit-Store-Policy', 'Offers-Collections', 'Create-New-Offer',
                                'Edit-Offer', 'Create-Copy', 'Offer-Associated-Products', 'Collections', 'Products', 'Create-New-Product', 'Edit-Product',
                                'Create-Copys', 'Campaign', 'Invoice', 'Update-User', 'Roles', 'Add-New-Role', 'How-to-convert-your-account-into-an-agency?', 'Manager-Role']} currentClassName="is-current">
                                <li><a href="/ADMIN-UI/help/gettingStarted">Getting Started with ShopiAds</a></li>
                                <li><a href="/ADMIN-UI/help/startWithShopAds">How to Start with ShopiAds</a></li>
                                <li><a href="/ADMIN-UI/help/signup">Signing Up</a></li>
                                <li><a href="/ADMIN-UI/help/dashboard">Dashboard</a></li>
                                <li><a href="/ADMIN-UI/help/login-help">Login</a></li>
                                <li><a href="/ADMIN-UI/help/forgot-password-help">Forgot Password</a></li>
                                <li><a href="/ADMIN-UI/help/company">Company</a></li>
                                <li><a href="/ADMIN-UI/help/help-store">Stores</a></li>
                                <li><a href="/ADMIN-UI/help/add-store-branch">Adding More Stores/Branches</a></li>
                                <li><a href="/ADMIN-UI/help/edit-store-branch">Edit Store/Branch</a></li>
                                <li><a href="/ADMIN-UI/help/add-edit-store-policy">Add Edit/Store Policy</a></li>

                            </ul>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'shopify'} onChange={handleChange('shopify')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4a-content"
                            id="panel4a-header"
                        >
                            <Typography className={classesAcord.heading}>Shopify Integration</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <ul items={['Getting-Started','sync-product-help','sync-collection-help','sync-category-help',
                                'sync-department-help','sync-occasion-help','sync-error-help'
                            ]}>
                                    <li><a href="/ADMIN-UI/help/synchelp" >What is Store and Offer mode</a></li>
                                    <li><a href="/ADMIN-UI/help/synchelpOfferMode" >What is an Offer in ShopiAds</a></li>
                                    <li><a href="/ADMIN-UI/help/syncProducthelp" >Sync Product</a></li>
                                    <li><a href="/ADMIN-UI/help/syncCollectionhelp" >Sync Collection</a></li>
                                    <li><a href="/ADMIN-UI/help/syncOfferhelp" >Sync Offer</a></li>
                                    <li><a href="/ADMIN-UI/help/syncCategoryhelp" >Set Category in Offer mode</a></li>
                                    <li><a href="/ADMIN-UI/help/syncDepartmenthelp" >Set Department in Offer mode</a></li>
                                    <li><a href="/ADMIN-UI/help/syncOccasionHelp" >Set Occasion in Offer mode</a></li>
                                    <li><a href="/ADMIN-UI/help/syncErrorhelp" >Error</a></li>

                                </ul>
                            </Typography>
                        </AccordionDetails>

                        
                    </Accordion>

                    <Accordion expanded={expanded === 'add_creation'} onChange={handleChange('add_creation')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classesAcord.heading}>Ad Creation</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <ul>
                                    <li><a href="/ADMIN-UI/help/offer-collection">Offers/Collections</a></li>
                                    <li><a href="/ADMIN-UI/help/create-new-offer">Create New Offer</a></li>
                                    <li><a href="/ADMIN-UI/help/edit-offer">Edit Offer</a></li>

                                    <li><a href="/ADMIN-UI/help/offer-copy">Create Copy</a></li>
                                    <li><a href="/ADMIN-UI/help/offer-associated-product">Offer Associated Products</a></li>
                                    <li><a href="/ADMIN-UI/help/collection">Collections</a></li>
                                    <li><a href="/ADMIN-UI/help/help-products">Products</a></li>
                                    <li><a href="/ADMIN-UI/help/create-new-products">Create New Product</a></li>
                                    <li><a href="/ADMIN-UI/help/edit-products">Edit Product</a></li>
                                    <li><a href="/ADMIN-UI/help/product-copy">Create Copy</a></li>

                                </ul>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'setup'} onChange={handleChange('setup')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3-header"
                        >
                            <Typography className={classesAcord.heading}>Campaign Setup</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <ul>
                                    <li><a href="/ADMIN-UI/help/campaign">Campaign</a></li>
                                    <li><a href="/ADMIN-UI/help/invoice">Invoice</a></li>

                                </ul>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'user'} onChange={handleChange('user')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4a-content"
                            id="panel4a-header"
                        >
                            <Typography className={classesAcord.heading}>User Managemnt</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <ul>
                                    <li><a href="/ADMIN-UI/help/update-user">Update User</a></li>
                                    <li><a href="/ADMIN-UI/help/roles">Roles</a></li>
                                    <li><a href="/ADMIN-UI/help/add-new-roll">Add New Role</a></li>
                                    <li><a href="/ADMIN-UI/help/how-to-convert-your-account-into-an-agency">How to convert your account into an agency?</a></li>
                                    <li><a href="/ADMIN-UI/help/manager-role">Manager Role</a></li>

                                </ul>
                            </Typography>
                        </AccordionDetails>

                        
                    </Accordion>

                    <Accordion expanded={expanded === 'user'} onChange={handleChange('user')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4a-content"
                            id="panel4a-header"
                        >
                            <Typography className={classesAcord.heading}>Contact</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <ul>
                                    <li><a href="https://www.shopiads.ca/contact" target="_new">Contact US</a></li>
                                   

                                </ul>
                            </Typography>
                        </AccordionDetails>

                        
                    </Accordion>

                </div>
            </div>





        </>
    )
}

export default Sidebar