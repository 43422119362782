import React, { useEffect, useState } from "react"
import { useStore, useAuth } from "../context"
import { Stores } from "."
import MUIDataTable from "mui-datatables"
import Button from "@material-ui/core/Button"
import AddRoundedIcon from "@material-ui/icons/AddRounded"
import { NavLink } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom";
//import Modal from "react-modal";
import {Modal} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import API from "../utils/API";
import Moment from 'react-moment';


const useStyles = makeStyles(() => ({
  tab: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    padding: "0 10px",
    boxShadow: "0 0 15px silver",
  },
  button: {
    "&:hover": {
      color: "#fff",
    },
  },
}))

function CampaignType(props) {

  const history = useHistory();
  const classes = useStyles()
  const [isStoreNotApprove, setIsStoreNotApprove] = useState(false);
 const { signUp, userInfo, loggedUserInfo } = useAuth();
  const campagainType=new URLSearchParams(props.location.search).get("type");
 /* const rowData = transactionList.map((item) => ({
    paymentId: item.id,
    date: item.createdOn,
    amount:item.amount,
    paymentType:item.paymentType,
    status:item.status
  }));*/
  useEffect(() => {
   if (loggedUserInfo.isMaster!='Y' &&
    loggedUserInfo.isAgentUser!='Y' )
    { 
      console.log("loggedUserInfo>>>>>>>>>>>>>>>>>>>",loggedUserInfo)
      //getStoreByCompany(loggedUserInfo.storeId);
     
  if(loggedUserInfo.storeId!=undefined && loggedUserInfo.storeId!=null)
  {
  API.getStoreById(loggedUserInfo.storeId).then((result) => {  
     
    if(result.data!=undefined && result.data!=null && result.data.isApprove!="Y")
    {
      setIsStoreNotApprove(true);
    }
  })
  .catch((error) => {
    console.log(error);
   // alert(error);
 });
}
    }
},[campagainType]);
  const rowData=[];

  const handleFlyer = async (e) => {

    history.push(`/home/campaign/promotionBatch?type=flyer`)
  };

  const handleSponsored = async (e) => {

    history.push(`/home/campaign/sponsoredPromotion?type=sponsored`)
  };

  return (
   
      <div className="mb-4 mt-4 pb-4">
    <div className="h4 mb-3">Choose your campaign type</div>
    <div className="row">
    {campagainType=="flyer"?
      <div className="col-md-12">
              <div className="card h-100">
          <div className="card-header text-center bg-white">
          <h4 className="font-weight-bold mb-0 py-2">Create flyer</h4>
          {isStoreNotApprove?<h6 className="font-weight-bold mb-0 py-2 text-danger">You can create campaigns but it will be live and starts appearing on ShopiAds within 24-Hrs once store is Approved
          </h6>:""}
          </div>
          <div className="card-body p-0">
          <div className="card-image">
              <img src={require('../assets/flyer_image.JPG').default} height="400" width="75%" className="ml-4"></img>
          </div>
           <div className="p-4 text-center">
           <p className="card-text">Add product or offer/collection to your store catalog.</p>
           <Button
          onClick={(e) =>handleFlyer(e)}
            variant="contained"
            color="primary"
            type="button"
            className="mb-3 w-50"
          >
            Continue
          </Button>
            <div className="d-flex justify-content-center">
             {/*<div className="question-mark">
            ?
            </div>

           <div className="card-text"><a href="#">Explore Sponsored Products</a></div>*/}
            </div>
           </div>
          </div>
        </div>
              </div>
:
              <div className="col-md-12">
              <div className="card h-100">
          <div className="card-header text-center bg-white">
          <h4 classnames=" font-weight-bold mb-0 py-2">Sponsored display</h4>
          {isStoreNotApprove?<h6 className="font-weight-bold mb-0 py-2 text-danger">You can create sponsored campaigns once store is Approved within 24-Hrs
          </h6>:""}
          </div>
          <div className="card-body p-0">
          <div className="card-image">
              
              <img src={require('../assets/Sponsored_image.JPG').default} height="400" width="75%" className="ml-4"></img>
          </div>
           <div className="p-4 text-center">
           <p className="card-text">Promote products to shoppers actively searching with related keyword or within particular section on ShopiAds.</p>
            
           <Button
          onClick={(e) =>handleSponsored(e)}
            variant="contained"
            type="button"
            color="primary"
            className="mb-3 w-50"
          >
            Continue
          </Button>
            <div className="d-flex justify-content-center">
            {/*<div className="question-mark">
            ?
            </div>

            <div className="card-text"><a href="#">Explore Sponsored Display</a></div>*/}
            </div>
           </div>
          </div>
        </div>
      </div>
}

    </div>
    <div className="text-center mt-4 ">
      <p className="card-text">want help from ShopiAds Advertising specialist to create your first campaign? <a href="https://help.shopiads.com" target="_new">Help</a></p>
    </div>
      </div>

      
  )
}

export default CampaignType
