import React, { useEffect,useState } from "react";
import { Form, Button, InputGroup, Alert } from "react-bootstrap";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { useAuth,useUser,useStore } from "../context";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Navigation } from "@material-ui/icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import {createUser} from "../utils/API";
import API from "../utils/API";
import { auth } from "../utils/FirebaseConfig"
import { useForm, useStep } from "react-hooks-helper";
import { uuid } from '../utils/uuid'

const defaultData = {
  businessName: "",
  companyLogo: "",
  countryBA: "",
  streetBA: "",  
  cityBA:"",
  provinceBA:"",
  postalCodeBA:"",
  AppartmentName:"",

};

let country = ["Canada"];

if(localStorage.getItem("connectSite")=="CA")
{
  country = ["Canada"];
}
else
{
  country = ["US"];
}

const FormContainer = styled.form`
  width: 400px;
  background-color: #fff;
  padding: 50px;
  margin-top: 150px;
  box-shadow: 0 0 20px silver;
  border-radius: 10px;
`;
const RegisterLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px 25px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px silver;
  padding: 25px;
  :hover {
    text-decoration: none;
  }
`;

const AddCompany = (props) => {
  const companyId=new URLSearchParams(props.location.search).get("id");
  console.log("updated companyId id>>>>>>>>>>>>>",companyId);
  const [cities,setCities]=useState([]);
  const { stateData, getState} =useStore();
  const [form, setForm] = useForm(defaultData);
  const [fileUrl,setFileUrl]=useState();
  const [mobile, setMobile] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [btnDisable, setBtnDisable] = useState("");
  const [selectedLogoFile, setSelectedLogoFile] = useState("");
  const [validated,setValidate]=useState(false);
  const [companyData,setCompanyData]=useState(false);
  const { resetPassword } = useAuth();
  const { roles, getRoles } = useUser();
  //const { userInfo } = useAuth();
  const { businessName, companyLogo, countryBA,streetBA,cityBA,provinceBA,postalCodeBA,AppartmentName } = form;
  const { signUp, userInfo,loggedUserInfo } = useAuth();
  console.log("loggedUserInfo>>>>>>>>>>>",loggedUserInfo);
  console.log("userInfo>>>>>>>>>>>",userInfo);
  useEffect(() => {
    getState("gust");
    getRoles(userInfo.email);
    if(companyId!=undefined && companyId!=null)
    {
      API.getCompanyId(companyId).then((result) => {  
       
        console.log("user data>>>>>>>>>>>>>>",result)
        if(result.data!=undefined)
        {
          setCompanyData(result.data)
          setFileUrl(result.data.logo);
          if(result.data.stateId!=undefined&&result.data.stateId!=null&&result.data.stateId!="")
          {
          API.getCitiesByState("gust",result.data.stateId).then((result) => {  
       
            if(result.data!=undefined && result.data.length>0)
            {
              setCities(result.data);
            }
                 })
                 .catch((error) => {
                   console.log(error);
                   alert(error);
                });
              }
          form.businessName=result.data.name
          //form.countryBA=result.data.countryId
          form.countryBA=country
          form.streetBA=result.data.address2
          form.cityBA=result.data.cityId
          form.provinceBA=result.data.stateId
          form.postalCodeBA=result.data.pincode
          form.AppartmentName=result.data.address1
          form.companyLogo=result.data.logo
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error);
     });

    }
    else
    {
          form.businessName=""
          form.countryBA=""
          form.streetBA=""
          form.cityBA=""
          form.provinceBA=""
          form.postalCodeBA=""
          form.AppartmentName=""
          form.companyLogo="" 
    }

  }, [companyId]);

 /* if(companyId!=undefined && companyId!=null)
  {
          form.businessName=companyData.name
          form.countryBA=companyData.countryId
          form.streetBA=companyData.address2
          form.cityBA=companyData.cityId
          form.provinceBA=companyData.stateId
          form.postalCodeBA=companyData.pincode
          form.AppartmentName=companyData.address1
          form.companyLogo=companyData.logo

        
          //setFileUrl(companyData.logo);

  }*/

  const handleStateChange = async (e) => {
    //console.log("event>>>>>>>>>",e.target.value);
    form.provinceBA=e.target.value;
    API.getCitiesByState("gust",e.target.value).then((result) => {  
       
      if(result.data!=undefined && result.data.length>0)
      {
        setCities(result.data);
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });

  };



  const handleSubmit = async (e) => {
    try {
      setValidate(true);
      console.log("otp>>>>>>>>>>>>>>>>>>",form.otp);
  /*  if (form.otp == '' || form.otp == undefined) {
        e.preventDefault();
        e.stopPropagation();
    }*/
     if (form.businessName == '' || form.businessName == undefined) {
      e.preventDefault();
      e.stopPropagation();
     // e.stopNavigation();
     // return;
    }
    else if (form.countryBA == '' || form.countryBA == undefined) {
      e.preventDefault();
      e.stopPropagation();
     // e.stopNavigation();
     // return;
    }

    else if (form.streetBA == '' || form.streetBA == undefined) {
      e.preventDefault();
      e.stopPropagation();
     // e.stopNavigation();
     // return;
    }
    else if (form.cityBA == '' || form.cityBA == undefined) {
      e.preventDefault();
      e.stopPropagation();
     // e.stopNavigation();
     // return;
    }
    else if (form.provinceBA == '' || form.provinceBA == undefined) {
      e.preventDefault();
      e.stopPropagation();
     // e.stopNavigation();
     // return;
    }
    else if (form.postalCodeBA == '' || form.postalCodeBA == undefined) {
      e.preventDefault();
      e.stopPropagation();
     // e.stopNavigation();
     // return;
    }

    else if (form.AppartmentName == '' || form.AppartmentName == undefined) {
      e.preventDefault();
      e.stopPropagation();
     // e.stopNavigation();
     // return;
    }
    else if(form.businessName != '' && form.countryBA != '' && form.streetBA != '' && form.cityBA != '' && form.provinceBA != '' && form.postalCodeBA != '' && form.AppartmentName != ''  )
    {
      try {
        API.checkCompanyExist(form.businessName).then((result) => {  
          console.log("result>>>>>>>>>>>",result);
          if(result.data && (companyId==undefined || companyId==null || companyId=='') )
          {
            toast.error("Company name already exist");
          }
          else
          {
            if(selectedLogoFile!=undefined && selectedLogoFile!="")
            var imageExtension = selectedLogoFile.name.substring(selectedLogoFile.name.indexOf('.'),selectedLogoFile.name.length)
      
            var uploadImageName="";
            var uploadFolder="";
            if(businessName!=undefined)
            {
               uploadImageName=businessName.replace(/\s+/g, '')
              uploadFolder=businessName.replace(/\s+/g, '')
            }
              uploadImageName=uploadImageName+"_logo"
    
            var id=""
            if(companyId!=undefined && companyId!=null)
            {
              id=companyId;
            }
            else
            {
              id=uuid();
            }
    
            let companyBody = {
              id:id,
              name:form.businessName,
              regName:companyId!=undefined && companyId!=null?companyData.regName:form.businessName,
              corporationId:companyId!=undefined && companyId!=null?companyData.corporationId:"",
              website:companyId!=undefined && companyId!=null?companyData.website:"",
              managerName:companyId!=undefined && companyId!=null?companyData.managerName:"",
              managerMobile:companyId!=undefined && companyId!=null?companyData.managerMobile:"",
              managerEmail:companyId!=undefined && companyId!=null?companyData.managerEmail:"",
              isShopify:companyId!=undefined && companyId!=null?companyData.isShopify:"",
              logo:selectedLogoFile!=undefined && selectedLogoFile!=""?API.fullBucketURL+uploadFolder+"/"+uploadImageName+imageExtension:form.companyLogo,
              active:"Y",
              address1:form.AppartmentName,
              address2:form.streetBA,
              pincode:form.postalCodeBA,
              cityId:form.cityBA,
              stateId:form.provinceBA,
              countryId:localStorage.getItem("connectSite")=="CA"?"canada":"US",
              createdBy:companyId!=undefined && companyId!=null?companyData.createdBy:userInfo.email,
              updatedBy:userInfo.email,
             };
             if(companyId!=undefined && companyId!=null)
             {
              API.updateCompany(userInfo.email,companyBody);
             }
             else
             {
             API.createCompany(userInfo.email,companyBody);
             }
    
             if(selectedLogoFile!=undefined && selectedLogoFile!="")
           {
            uploadCompanyLogo(selectedLogoFile,uploadFolder);
           }
    
             setTimeout(() => { 
                
              history.push(`/home/company`)
            
            }, 3000);
          }
    })
    .catch((error) => {
      console.log(error);
      alert(error);
   });


      
       // await login(`${email}`, `${password}`)
        //history.replace("/home")
        //    history.replace("/home");
      } catch (err) {
        console.log(err);
      }
    }
    
    } catch (e) {
      console.log(e);
    }
   
  };

  const uploadCompanyLogo = async (logoFile,companyName) => {
    const formData = new FormData()
    formData.append('file', logoFile)
    console.log("logoFile>>>>>>>>>>>",logoFile);
    if (logoFile !== null) {
      //store.dispatch('setOverlay', { showing: true })
      var imageName = logoFile.name.substring(0, logoFile.name.indexOf('.'))
      API.uploadCompanyLogo(formData, companyName,"storeId")
        .then(response => {
          if (response.isAxiosError) {
           // store.dispatch('setOverlay', { showing: false })
            //store.dispatch('setSnackbar', { color: 'error', text: 'Something went wrong please try again' })
          } else {
            //store.dispatch('setOverlay', { showing: false })
            //store.dispatch('setSnackbar', { text: 'Company Logo Uploaded successfully' })
          }
        }).catch(response => {
         // store.dispatch('setOverlay', { showing: false })
          // error callback
        })
    }
    // API.setUploadLogo(formData)
  }

  const history = useHistory();
  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
        this.validateSignUp(e);
    }
}

const handleRoleChange = async (e) => {
  //console.log("event>>>>>>>>>",e.target.value);
  form.roleId=e.target.value;

};

const uploadLogo =  (e) => {
  form.companyLogo=e.target.files[0].name;
  //form.fileObject=Array.from(e.target.files)
  setSelectedLogoFile(e.target.files[0]);
  //setFileUrl(URL.createObjectURL(e.target.files[0]));
  setFileUrl(e.target.files[0].name);
  console.log("setSelectedLogoFile>>>>>>>>>>>>",selectedLogoFile);
  
    //setForm(form.companyLogo);
}

  return (
    <>
          <Form noValidate validated={validated} action="#" style={style.form}>
          <InputGroup>
      <Form.Group className="col-1">
          <Button onClick={(e) =>history.goBack()} variant="primary"   type="button">
            Back
          </Button>
        </Form.Group>
      </InputGroup>
      <center className="mb-5">
        <Form.Label as="legend">Company Form Details</Form.Label>
      </center>
      
      <InputGroup className="mb-2">
      <Form.Group controlId="validationbusinessName" className="col-3">
        <Form.Label>Company's business name</Form.Label>
        <Form.Control
          name="businessName"
          type="text"
          value={businessName}
          onChange={setForm}
          required
        />
        <Form.Text className="text-muted">
          Enter the company/business name as registered in{" "}
          <strong>corporation</strong>
        </Form.Text>
        <Form.Control.Feedback type="invalid" >Please enter company name</Form.Control.Feedback>
      </Form.Group >
      <Form.Group controlId="validationbusinessName" className="col-3">
      <Form.Label>Company Logo</Form.Label>
          <Form.File
        id="custom-file"
        label={companyLogo}
        accept="image/*"
        custom
        name="companyLogo"
        //onChange={setForm}
        onChange={uploadLogo}
      />
      <p>{fileUrl}</p>
      <strong className="mt-2">For Best view please upload logo with size 500 x 250</strong>
      </Form.Group >
          </InputGroup>
          <InputGroup className="mb-2">
          <Form.Group className="col-3">
        <Form.Label>Country</Form.Label>
        <Form.Control
          as="select"
          className="mr-sm-2"
          id="inlineFormCustomSelect"
          name="countryBA"
          custom
          value={countryBA}
          onChange={setForm}
          required
        >
          <option></option>
          {country.map((item, index) => (
            <option  key={index} value={item}>
              {item}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid" >Please select country</Form.Control.Feedback>
      </Form.Group>
  
      <Form.Group className="col-3">
        <Form.Label>State/Province</Form.Label>
        <Form.Control
          as="select"
          className="mr-sm-2"
          id="inlineFormCustomSelect"
          name="provinceBA"
          custom
          value={provinceBA}
          onChange={handleStateChange}
          required
        >
          <option></option>
          {stateData.map((item, index) => (
            <option key={index} value={item.id}>
              {item.name}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid" >Please select province</Form.Control.Feedback>
      </Form.Group>
         
          </InputGroup>
          <InputGroup className="mb-2">
          <Form.Group className="col-3">
              <Form.Control
                as="select"
                className="mr-sm-2"
                id="inlineFormCustomSelect"
                name="cityBA"
                custom
                value={cityBA}
                onChange={setForm}
                required
              >
                <option></option>
                {cities.map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid" >Please select city</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col-3">
              <Form.Control
                name="postalCodeBA"
                placeholder="Postal Code"
                value={postalCodeBA}
                onChange={setForm}
                required
              />
               <Form.Control.Feedback type="invalid" >Please enter postal code</Form.Control.Feedback>
            </Form.Group>
          </InputGroup>
          <InputGroup className="mb-2">

          <Form.Group className="col-3">
        <Form.Label>Unit</Form.Label>
        <Form.Control
          name="AppartmentName"
          type="text"
          value={AppartmentName}
          onChange={setForm}
          required
        />
         <Form.Control.Feedback type="invalid" >Please enter unit</Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="exampleForm.ControlTextarea1" className="col-3">
        <Form.Label>Street Address</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="streetBA"
          value={streetBA}
          onChange={setForm}
          required
        />
         <Form.Control.Feedback type="invalid" >Please enter street address</Form.Control.Feedback>
      </Form.Group>
            </InputGroup>
          <InputGroup className="mb-3">
          <Form.Group className="col-2">
          <Button
          onClick={(e) =>handleSubmit(e)}
            variant="primary"
            type="button"
            className="w-100"
          >
            Submit
          </Button>
          </Form.Group>
      </InputGroup>
          </Form>
       
      <ToastContainer />
    </>
  );
};

export default AddCompany;

const style = {
  backButton: {
    fontSize: "25px",
    marginBottom: "10px",
    color: "blue",
    cursor: "pointer",
  },
};
