import React, { useEffect, useState } from "react"
import { useStore, useAuth } from "../context"
import { Stores } from "."
import MUIDataTable from "mui-datatables"
import Button from "@material-ui/core/Button"
import AddRoundedIcon from "@material-ui/icons/AddRounded"
import { NavLink } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom";
//import Modal from "react-modal";
import {Modal} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import API from "../utils/API";
import Moment from 'react-moment';
import {Form,InputGroup} from 'react-bootstrap';

const useStyles = makeStyles(() => ({
  tab: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    padding: "0 10px",
    boxShadow: "0 0 15px silver",
  },
  button: {
    "&:hover": {
      color: "#fff",
    },
  },
}))




function BillOrdersView(props) {

  const billId=new URLSearchParams(props.location.search).get("billId");
  const [orderList, setOrderList] = useState([]);
  const { userInfo,loggedUserInfo } = useAuth();
  const classes = useStyles()
  const [companyList, setCompanyList] = useState([]);
  const history = useHistory();
  const { companyData, storeData, getCompanyByUser, getStoreByCompany } =
    useStore();
  const options = {
    rowsPerPage: 10,
    print: false,
    selectableRowsOnClick: false,
    selectToolbarPlacement: "none",
    selectableRows:"none",
   /* customToolbar: () => (
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddRoundedIcon />}
       // onClick={toggleModal}
        component={NavLink}
        to={"/home/categories/AddCategory"}
      >
        Add Category
      </Button>
    ),*/
    
  }

  useEffect(() => {
    if(billId!=undefined)
   {
    
    API.getShopifyOrderByBill(billId).then((result) => {  
             
      if(result.data!=undefined && result.data.length>0)
      {
        setOrderList(result.data)
      }
      else
      {
        setOrderList([])
      }
           })
           .catch((error) => {
             console.log(error);
             alert(error);
          });

    }
  }, [billId]);




  const columns = [
    {
      name: "orderNumber",
      label: "Order Number",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "date",
      label: "Order Date",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
          <Moment format="YYYY/MM/DD">
          {value}
          </Moment>
          );
         } 
      },
    },
    {
      name: "price",
      label: "Price",
      options: {
        filter: false,
        sort: true,
      },
    },  
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "source",
      label: "Source",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "customerEmail",
      label: "Customer Email/Phone",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "captureReason",
      label: "Capture Reason",
      options: {
        filter: false,
        sort: true,
      },
    },
  
 
    
   /* {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
            <Button
            variant="contained"
            color="primary"
           // startIcon={<AddRoundedIcon />}
            component={NavLink}
            to={"/home/categories/AddCategory?id="+value}
          >
            Update
          </Button>
          );
        }
      },
    },*/
    
  ];

  const rowData = orderList.map((item) => ({
    orderNumber: item.orderNumber,
    date: item.createdOn,
    price:item.totalPrice,
    status:item.status,
    source:item.source,
    captureReason:item.captureReason,
    customerEmail:item.customerEmail!=undefined&&item.customerEmail!=null &&item.customerEmail!="null" &&item.customerEmail!=""? item.customerEmail:item.customerPhoneNumber,
    //paymentType:item.paymentType,
    //status:item.status
  }));





  return (

    <div>
    <div className={classes.tab}>
   
      <div className="d-flex  py-2">
     
      <InputGroup>
      <Form.Group className="col-1">
          <Button onClick={(e) =>history.goBack()} variant="contained" color="primary" type="button">
            Back
          </Button>
        </Form.Group>
      </InputGroup>

      </div>
     
</div>
   
    <MUIDataTable
        className="mb-3"
        title="Order Deatils"
        data={rowData}
        columns={columns}
        options={options}
      />
      </div>
  )
}

export default BillOrdersView
