import React, { useState,useEffect } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import firebase from "firebase/app";
import { useStore, useAuth } from "../context";
import { useHistory } from "react-router-dom";
import { EmailOutlined } from "@material-ui/icons";
import { uuid } from '../utils/uuid'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import API from "../utils/API";


const FormContainer = styled.form`
  width: 500px;
  // background-color: #fff;
  padding: 50px;
  // margin-top: 25px;
  box-shadow: 0 0 20px silver;
  border-radius: 10px;
`;

const week = [
  {"label":"Sunday","value":"Sunday"},
  {"label":"Monday","value":"Monday"},
  {"label":"Tuesday","value":"Tuesday"},
  {"label":"Wednesday","value":"Wednesday"},
  {"label":"Thursday","value":"Thursday"},
  {"label":"Friday","value":"Friday"},
  {"label":"Saturday","value":"Saturday"},
];


function  PersonalInfoFormModel({ form, setForm, navigation,setIsOpen }) {
  const [otp, setotp] = React.useState();
  const [otpResult, setOtpResult] = React.useState(null);
  const [mobileNum, setMobileNum] = React.useState("");
  const [isOtpClicked, setOtpClicked] = React.useState(false);
  const { createStore } = useStore();
  const { signUp, userInfo } = useAuth();
  const { login } = useAuth()
  const {loggedUserInfo } = useAuth();
  //console.log("setIsOpen>>>>>>>in final step>>>>",setIsOpen);
  
  const [validated,setValidate]=useState(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T10:00:00'));
  const [selectedToDate, setToSelectedDate] = React.useState(new Date('2014-08-18T21:00:00'));
  const [storeData, setStoreData] = useState();
  
  var userId;
  const animatedComponents = makeAnimated();
  const [selectedWeekOff, setSelectedWeekOff] = React.useState([])
  const [errors, setErrors] = useState({});

  const history = useHistory();

  const {
    mobileNo,

    firstName,
    password,
    email,
    storeId,
    branchName,
    storeName,
    businessName,
    websiteUrl,
    logo,
    companyId,

    mainCategory,

    countryBA,
    streetBA,
    cityBA,
    provinceBA,
    postalCodeBA,
    AppartmentName,
    isPhysicalStore,
    isPhysicalSameAsRegisteredAddress,
    secStreetBA,
    secCityBA,
    secProvinceBA,
    secPostalCodeBA,
    secAppartmentName,
    companyLogo,
    countryPA,
    streetPA,
    cityPA,
    provincePA,
    postalCodePA,

    ownerName,
    managerEmail,
    fromHrs,
    toHrs,
    timeNote,
    contactNumber,
    operatingHoursFrom,
    operatingHoursTo,
    weeklyOff,
    marketPlace,
    agreement,
  } = form;

  console.log("store id on final step>>>>>>>>>>",storeId);

  useEffect(() => {
    console.log("weeklyOff step>>>>>>>>>>",weeklyOff);
    console.log("fromHrs step>>>>>>>>>>",fromHrs);
  if(weeklyOff!=undefined && weeklyOff!=null && weeklyOff!='' && !Array.isArray(weeklyOff))
  {
      var existWeekOff=[]
      var temp=weeklyOff.split(",");
      temp.map((item) => {
        if(item!='' && item!="" && item!=" ")
        {
          var tempObj ={"label":item,"value":item}
          existWeekOff.push(tempObj);
        }
      });
      setSelectedWeekOff(existWeekOff);
  }
  if(fromHrs!=undefined && fromHrs!=null && fromHrs!='')
  {
    var h="";
    var m="";
    var s="";
    var fHrs="";
    console.log("fromHrs111111111>>>>>>>>>>>>>>>>>",fromHrs);
    var temp=fromHrs.split(":")
    console.log("temp>>>>>>>>>>>>>>>>>",temp);
    h=temp[0].length<2?"0"+temp[0]:temp[0]
    m=temp[1].length<2?"0"+temp[1]:temp[1]
    s=temp[2].length<2?"0"+temp[2]:temp[2]
    fHrs=h+":"+m+":"+s;
    console.log("fHrs>>>>>>>>>>>>>>>>>"+fHrs);
    setSelectedDate(new Date('2014-08-18T'+fHrs))
    form.fromHrs=selectedDate.getHours()+":"+selectedDate.getMinutes()+":"+selectedDate.getSeconds();
  }
  else
  {
    form.fromHrs=selectedDate.getHours()+":"+selectedDate.getMinutes()+":"+selectedDate.getSeconds();
  }

  if(toHrs!=undefined && toHrs!=null && toHrs!='')
  {
    var h="";
    var m="";
    var s="";
    var fHrs="";
    console.log("toHrs111111111>>>>>>>>>>>>>>>>>",toHrs);
    var temp=toHrs.split(":")
    console.log("temp>>>>>>>>>>>>>>>>>",temp);
    h=temp[0].length<2?"0"+temp[0]:temp[0]
    m=temp[1].length<2?"0"+temp[1]:temp[1]
    s=temp[2].length<2?"0"+temp[2]:temp[2]
    fHrs=h+":"+m+":"+s;
    console.log("fHrs>>>>>>>>>>>>>>>>>"+fHrs);
    setToSelectedDate(new Date('2014-08-18T'+fHrs))
    form.toHrs=selectedToDate.getHours()+":"+selectedToDate.getMinutes()+":"+selectedToDate.getSeconds();
  }
  else
  {
    form.toHrs=selectedToDate.getHours()+":"+selectedToDate.getMinutes()+":"+selectedToDate.getSeconds();
  }

  if(storeId!=undefined && storeId!=null)
  {
  API.getStoreById(storeId).then((result) => {  
     
    if(result.data!=undefined)
    {
      setStoreData(result.data);
    }
  })
  .catch((error) => {
    console.log(error);
    alert(error);
 });
}
  
  //form.toHrs=selectedToDate.getHours()+":"+selectedToDate.getMinutes()+":"+selectedToDate.getSeconds();
},[loggedUserInfo]);

  const Register = async (e) => {
    setValidate(true);
    setErrors({});
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    console.log("form.agreement>>>>>>>>>>>>",form.agreement)
    console.log("form.contactNumber>>>>>>>>>>>>",form.contactNumber)
    console.log("form.managerEmail>>>>>>>>>>>>",form.managerEmail)
    console.log("form.ownerName>>>>>>>>>>>>",form.ownerName)
    if (form.ownerName == '' || form.ownerName == undefined) {
      e.preventDefault();
      e.stopPropagation();
      console.log("form.ownerName11111111111>>>>>>>>>>>>",form.ownerName)  
  }
  else if (form.managerEmail == '' || form.managerEmail == undefined) {
    setErrors({"email":"Please enter email address."});
    e.preventDefault();
    e.stopPropagation();
    console.log("form.ownerName1222222222>>",form.ownerName)  
  }
  else if (!pattern.test(form.managerEmail)) {
      
    setErrors({"email":"Please enter valid email address."});
    e.preventDefault();
    e.stopPropagation();
 
// e.stopNavigation();
// return;
}
  else if (form.contactNumber == '' || form.contactNumber == undefined) {
    setErrors({"mobile":"Please enter contact number."});
    e.preventDefault();
    e.stopPropagation();
    console.log("form.ownerName13333333333>>>>>>>>>>",form.ownerName)  
  }

  else if (form.contactNumber != '' && form.contactNumber != undefined && form.contactNumber.length<10) {
    setErrors({"mobile":"Please enter valid mobile number."});
    e.preventDefault();
    e.stopPropagation();
}

  else if (form.agreement == '' || form.agreement == undefined) {
    e.preventDefault();
    e.stopPropagation();
    console.log("form.ownerName144444444444111>>>>>>>>>>>>",form.ownerName)  
  }
  else if(form.agreement != '' && form.contactNumber != '' &&  form.contactNumber.length==10 && form.managerEmail != '' &&  pattern.test(form.managerEmail) && form.ownerName != '' )
    {
      console.log("form.ownerName5555555551111>>>>>>>>>>>>",form.ownerName)  
      console.log("mainCategory1111111>>>>>>>>>>>",mainCategory)  
    let body = {
      id:  storeId!=null && storeId!='' && storeId!=undefined?storeId:uuid(),
      code: branchName,
      gstin: "",
      storeName: storeName,
      companyId:`${companyId}`,
      companyBusinessName: businessName,
      websiteUrl: websiteUrl,
      companyLogo: `${companyLogo}`,
      categories: mainCategory,
      countryId: localStorage.getItem("connectSite")=="CA"?"canada":"US",
      address1: `${AppartmentName}`,
      address2: `${streetBA}`,
      stateId: `${provinceBA}`,
      cityId: `${cityBA}`,
      pincode: `${postalCodeBA}`,
      isphysical: `${isPhysicalStore}`,
      isSameAddress: `${isPhysicalSameAsRegisteredAddress}`,
      storeCountryId: localStorage.getItem("connectSite")=="CA"?"canada":"US",
      storeAddress1: `${secAppartmentName} `,
      storeAddress2: `${secStreetBA}`,
      storeStateId: `${secProvinceBA}`,
      storeCityId: `${secCityBA}`,
      storePincode: secPostalCodeBA,
      managerName: ownerName,
      managerMobile: contactNumber,
      managerEmail: managerEmail,
      fromHrs: [fromHrs] ,
      toHrs: [toHrs],
      marketPlace: marketPlace,
      timeNote:timeNote,
      weeklyOff: `${weeklyOff}`,
      featuredFrom: new Date().toISOString(),
      featuredTill: new Date().toISOString(),
      active: "Y",
      isPremium: "Y",
      areas: [],
      createdOn: storeData?.createdOn,
      updatedOn: new Date().toISOString(),
      createdBy: storeData?.createdBy,
      updatedBy: userInfo.email,
      logo: "",
      area: "",
      storeDescription:storeData?.storeDescription,
      returnPolicy:storeData?.returnPolicy,
      deliveryPolicy:storeData?.deliveryPolicy,
      warrantyPolicy:storeData?.warrantyPolicy,
      deliveryType:storeData?.deliveryType,
      secondDeliveryType:storeData?.secondDeliveryType,
      deliveryNotes:storeData?.deliveryNotes,
      primaryOfferImage:storeData?.primaryOfferImage,
      domainURL:storeData?.domainURL,
      totalLikes:storeData?.totalLikes,
      isShopify:storeData?.isShopify,
      shopifyRecurringChargeId:storeData?.shopifyRecurringChargeId,
      timeNote:storeData?.timeNote,
      isApprove:storeData?.isApprove,
      storeSellCountry:storeData?.storeSellCountry,
      logoSmall:storeData?.logoSmall,
      logoMedium:storeData?.logoMedium,


    };

    try {
     
    userId = userInfo.email;
    if(storeId!=null && storeId!='' && storeId!=undefined)
    {
      API.updateStore(userInfo.email, body);
    }
    else
    {
   API.createStoreOnly(userInfo.email, body);
    }
   setIsOpen(false);
   //history.replace("/home");
   history.push(`/home`)
     // await login(`${email}`, `${password}`)
      //history.replace("/home")
      //    history.replace("/home");
    } catch (err) {
      console.log(err);
    }
  }
  };

  // const onRegister = () => {
  //   if (otp) {
  //     otpResult
  //       .confirm(otp)
  //       .then((result) => {
  //         console.log(result.user, "user");
  //         alert("number verified Successfully");
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         alert(error);
  //       });
  //   } else {
  //     alert("Please enter Otp");
  //   }
  // };
  // const onGetOtp = (mobileNo) => {
  //   if (mobileNo) {
  //     setOtpClicked(true);
  //     let reCaptcha = new firebase.auth.RecaptchaVerifier("recaptcha");
  //     let number = "+1" + mobileNo;
  //     firebase
  //       .auth()
  //       .signInWithPhoneNumber(number, reCaptcha)
  //       .then((res) => {
  //         setOtpResult(res);
  //       });
  //   } else {
  //     alert("Mobile number is mandatory");
  //   }
  // };

  const handleDateChange = (date) => {
    //setSelectedDate(date);
    console.log("date>>>>>>>",date.getHours()+":"+date.getMinutes()+":"+date.getSeconds());
    form.fromHrs=date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
    setSelectedDate(date);
  };
  const handleDateChange1 = (date) => {
   // setSelectedDate(date);

    form.toHrs=date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
    setToSelectedDate(date);
  };
  const handleWeeakOffChange = (selectedOptions) => {
    console.log("selectedOptions>>>>>>>>>>>>>>>>",selectedOptions);
    form.weeklyOff=selectedOptions.map((item1) => (item1.value+", "))
    //form.weeklyOff=selectedOptions.value;
    setSelectedWeekOff(selectedOptions);
  };

  return (
    <FormContainer className="mx-auto">
      <h3 className="text-center mb-3">Personal Info</h3>
      <Form noValidate validated={validated} action="#">
      <Form.Group>
        <Form.Label>Owner / Manager name</Form.Label>
        <Form.Control
          name="ownerName"
          type="text"
          value={ownerName}
          onChange={setForm}
          required
        />
        <Form.Control.Feedback type="invalid" >Please enter owner/Manager</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Email Address</Form.Label>
        <Form.Control
          name="managerEmail"
          type="email"
          value={managerEmail}
          onChange={setForm}
          required
        />
        <div className="text-danger">{errors.email}</div>
      </Form.Group>
      <Form.Group>
        <Form.Label>Contact Number</Form.Label>
        <Form.Control
          name="contactNumber"
          type="number"
          value={contactNumber}
          onInput = {(e) =>{
            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
          }}
          
          onChange={setForm}
          required
        />
        <div className="text-danger">{errors.mobile}</div>  
      </Form.Group>

      <Form.Label>Store operating hours</Form.Label>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Form.Row>
        <Row>
          <Col>
            <Form.Group>
            
        <KeyboardTimePicker
          margin="normal"
          id="fromHrsr"
          name="fromHrs"
          label="From Hrs"    
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
            <KeyboardTimePicker
          margin="normal"
          id="toHrs"
          name="toHrs"
          value={selectedToDate}
          label="To Hrs"
          onChange={handleDateChange1}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        />
            </Form.Group>
          </Col>
        </Row>
      </Form.Row>
      </MuiPickersUtilsProvider>

      <Form.Group controlId="exampleForm.ControlTextarea1">
        <Form.Label>Specify time other than regular time </Form.Label>
        <Form.Control
          as="textarea"
          rows={2}
          name="timeNote"
          value={timeNote}
          onChange={setForm}
        />
         <Form.Control.Feedback type="invalid" >Please enter other than regular time</Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>Weekly Off</Form.Label>
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          isMulti
          name="weeklyOff"
          value={selectedWeekOff}
          options={week}
          onChange={handleWeeakOffChange}
      />
       
      </Form.Group>
      <Form.Group controlId="formBasicCheckbox">
        <Form.Check
          type="checkbox"
          className="text-muted"
          name="agreement"
          value={agreement}
          onChange={setForm}
          label="I agree to comply with terms and conditions of ShopiAds business solution agreement"
          required
        />
        <Form.Control.Feedback type="invalid" >I agree terms and conditions </Form.Control.Feedback>
      </Form.Group>

      <div className="w-100 d-flex justify-content-around">
        <Button
          className="mt-3 w-50 mr-1"
          onClick={() => navigation.previous()}
        >
          Back
        </Button>
        <Button onClick={Register} className="mt-3 w-50 ml-1">
          {"Submit"}
        </Button>
      </div>
      </Form>
    </FormContainer>
  );
}

export default PersonalInfoFormModel;
