import React,{ useEffect, useState } from "react"
import MUIDataTable from "mui-datatables"
import { makeStyles } from "@material-ui/core/styles"
import Tab from "@material-ui/core/Tab"
import TabContext from "@material-ui/lab/TabContext"
import TabList from "@material-ui/lab/TabList"
import Button from "@material-ui/core/Button"
import AddRoundedIcon from "@material-ui/icons/AddRounded"
import { TabPanel } from "@material-ui/lab"
import { NavLink } from "react-router-dom"
import API from "../utils/API";
import { useUser, useAuth,useStore } from "../context"
import Moment from 'react-moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import eventBus from "../utils/eventBus"
import {Modal,Form} from 'react-bootstrap';
import CampaignDeatils from "./CampaignDeatils"
import ViewCampaignAreaList from "./ViewCampaignAreaList"

const useStyles = makeStyles(() => ({
  tab: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    padding: "0 10px",
    boxShadow: "0 0 15px silver",
  },
  button: {
    "&:hover": {
      color: "#fff",
    },
  },
}))





function SponsoredCampaignList() {
  const classes = useStyles()
  const [value, setValue] = React.useState("1")
  const [campaignTab, setCampaignTab] = React.useState("1")
  const [activeSponsoredCampaign, setSponsoredActiveCampaign] = useState([]);
  const [inactiveSponsoredCampaign, setSponsoredInactiveCampaign] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const { companyData, storeData, getCompanyByUser, getStoreByCompany } =useStore();
 // const [selectedProduct, setSelectedProduct] = useState([]);
  const { userInfo,loggedUserInfo } = useAuth();


  
  useEffect(() => {

    if(loggedUserInfo!=undefined)
    {

      API.getAllCompany(loggedUserInfo.email).then((companyResult) => {  
       
        if(companyResult.data!=undefined && companyResult.data.length>0)
        {
          setCompanyList(companyResult.data)
         
        }
             })
             .catch((error) => {
               console.log(error);
               alert(error);
            });

      if(localStorage.getItem('branchFilterCampaign')!=undefined && localStorage.getItem('branchFilterCampaign')!=null && localStorage.getItem('branchFilterCampaign')!='')
    {
      getStoreByCompany(localStorage.getItem('companyFilterCampaign'));
      
    

  API.getCompanyBranchCampaign(localStorage.getItem('branchFilterCampaign'),"active","sponsored").then((result) => {  
            
    if(result.data!=undefined && result.data.length>0)
    {
      setSponsoredActiveCampaign(result.data);
    }
    else
    {
      setSponsoredActiveCampaign([]);
    }

  })
  .catch((error) => {
    console.log(error);
    alert(error);
});

API.getCompanyBranchCampaign(localStorage.getItem('branchFilterCampaign'),"inactive","sponsored").then((result1) => {  
  
  if(result1.data!=undefined && result1.data.length>0)
  {
    setSponsoredInactiveCampaign(result1.data);
  }
  else
  {
    setSponsoredInactiveCampaign([]);
  }

})
.catch((error) => {
  console.log(error);
  alert(error);
});
        }
        else{
      if(loggedUserInfo.companyId!=undefined && loggedUserInfo.companyId!=null && loggedUserInfo.companyId!='')      
  {   
            

        API.getCompanyCampaign(loggedUserInfo.companyId,"active","sponsored").then((result) => {  
            
          if(result.data!=undefined && result.data.length>0)
          {
            setSponsoredActiveCampaign(result.data);
          }
      
        })
        .catch((error) => {
          console.log(error);
          alert(error);
      });

      API.getCompanyCampaign(loggedUserInfo.companyId,"inactive","sponsored").then((result1) => {  
        
        if(result1.data!=undefined && result1.data.length>0)
        {
          setSponsoredInactiveCampaign(result1.data);
        }
    
      })
      .catch((error) => {
        console.log(error);
        alert(error);
    });

         
              }
            }
              }

            

         
    
  }, [loggedUserInfo]);



  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleCampaignTabChange = (event, newValue) => {
    setCampaignTab(newValue)
  }

 
  

  const viewAreaList = async (e,data,type) => {
    setIsOpen(!isOpen);
    if(type=="city")
    {
    API.getAllCities(userInfo.email).then((result) => {  
         
      if(result.data!=undefined)
      {
        const myArrayFiltered = result.data.filter((el) => {
          return data.some((f) => {
            return f === el.id;
          });
        });
        setAreaList(myArrayFiltered);
        console.log("setIsOpen>>>>>>>>>>>>>>",isOpen);
        setIsOpen(!isOpen);
      }
           })
           .catch((error) => {
             //console.log(error);
           //  toast.error("error during create copy,try again.")
            // alert(error);
          });
        }
        if(type=="state")
        {
        API.getState(userInfo.email).then((result) => {  
             
          if(result.data!=undefined)
          {
            const myArrayFiltered = result.data.filter((el) => {
              return data.some((f) => {
                return f === el.id;
              });
            });
            setAreaList(myArrayFiltered);
            setIsOpen(!isOpen);
          }
               })
               .catch((error) => {
                 //console.log(error);
               //  toast.error("error during create copy,try again.")
                // alert(error);
              });
            }
        };

        function handleClose() {
          setIsOpen(false);
        }

  
 

  const sponsoredColumns = [
    {
      name: "id",
      options: {
        display: false,
      }
    },
    {
    name: "campaignName",
    label: "Campaign Name",
    options: {
      filter: true,
      sort: true,
      
    },
  },
  {
    name: "type",
    label: "Campaign Type",
    options: {
      filter: true,
      sort: true,
      display: false,
      
    },
  },
  {
    name: "product",
    label: "Offer/Product",
    options: {
      filter: true,
      sort: true,
      
    },
  },
    {
      name: "startDate",
      label: "Start Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
          <Moment tz="CST" format="YYYY/MM/DD hh:mm:ss A">
          {value}
          </Moment>
          );
         }      
      },
      
    },
    {
      name: "endDate",
      label: "End Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
          <Moment tz="CST" format="YYYY/MM/DD hh:mm:ss A">
          {value}
          </Moment>
          );
         } 
      },
      
    },
    {
      name: "budget",
      label: "Budget",
      options: {
        filter: true,
        sort: true,
        
      },
    },
    {
      name: "Action",
      label: "Action",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
             {/*} <Button  variant="contained" component={NavLink}
              to={"/home/Products/promotion?id="+value} 
              color="primary">
                  Start Promotion
          </Button>
              &nbsp;
             <Button  variant="contained" component={NavLink}
              to={"/home/Products/addProduct?id="+value} 
              color="primary">
                  Update
        </Button>*/}
              </div>
             
          )
      }
      },
      
    },
    
  ]
  
  const sponsoredInActivecolumns = [
    {
      name: "id",
      options: {
        display: false,
      }
    },     
    {
    name: "campaignName",
    label: "Campaign Name",
    options: {
      filter: true,
      sort: true,
      
    },
  },
  {
    name: "type",
    label: "Campaign Type",
    options: {
      filter: true,
      sort: true,
      display: false,
      
    },
  },
  {
    name: "product",
    label: "Offer/Product",
    options: {
      filter: true,
      sort: true,
      
    },
  },
  
    {
      name: "startDate",
      label: "Start Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
          <Moment tz="CST" format="YYYY/MM/DD hh:mm:ss A">
          {value}
          </Moment>
          );
         }      
      },
      
    },
    {
      name: "endDate",
      label: "End Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //alert(value);
          return (
          <Moment tz="CST" format="YYYY/MM/DD hh:mm:ss A">
          {value}
          </Moment>
          );
         } 
      },
      
    },
    {
      name: "budget",
      label: "Budget",
      options: {
        filter: true,
        sort: true,
        
      },
    },
    {
      name: "Action",
      label: "Action",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
             <Button
            variant="contained"
            color="primary"
            className={classes.button}
           // startIcon={<AddRoundedIcon />}
            //   size="small"
            component={NavLink}
            to={"/home/campaign/sponsoredPromotion?type=sponsored&id="+value}
          >
           Renew  Campaign
          </Button>
              </div>
             
          )
      }
      },
      
    },
    
  ]

  var selectedCampaign="";
  const activeOptions = {
    download: false,
    print: false,
    rowsPerPage: 10,
    selectableRowsOnClick: false,
    selectToolbarPlacement: "none",
    selectableRows:"none",
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      console.log("rowData>>>>>>>>>>>>>>>>>>",rowData[0])
      console.log("rowData2222222222>>>>>>>>>>>>>>>>>>",rowData[2])
      return (
        <tr>
          <td colSpan={7}>
          <CampaignDeatils
         campaignId={rowData[0]}
         type={rowData[2]}
        />
          </td>
          
           </tr>
      );

    },
    page: 1
  }

  const InactiveOptions = {
    download: false,
    print: false,
    rowsPerPage: 10,
    selectableRowsOnClick: false,
    selectToolbarPlacement: "none",
    selectableRows:"none",
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      console.log("rowData>>>>>>>>>>>>>>>>>>",rowData[0])
      return (
        <tr>
        <td colSpan={7}>
        <CampaignDeatils
       campaignId={rowData[0]}
       type={rowData[2]}
      />
        </td>
        
         </tr>
      );

    },
    page: 1
  }


  


  const getCompanyBranches = async (e) => {
    console.log("event>>>>>>>>>",e.target.value);
    if(e.target.value!=undefined && e.target.value!='')
    {
      localStorage.setItem('companyFilterCampaign', e.target.value);
      getStoreByCompany(e.target.value);
        }
        
  };

  const handleBranchChanage = async (e) => {
    console.log("event>>>>>>>>>",e.target.value);
    if(e.target.value!=undefined && e.target.value!='')
    {
      localStorage.setItem('branchFilterCampaign', e.target.value);
  API.getCompanyBranchCampaign(e.target.value,"active","sponsored").then((result) => {  
            
    if(result.data!=undefined && result.data.length>0)
    {
      setSponsoredActiveCampaign(result.data);
    }
    else
    {
      setSponsoredActiveCampaign([]);
    }

  })
  .catch((error) => {
    console.log(error);
    alert(error);
});

API.getCompanyBranchCampaign(e.target.value,"inactive","sponsored").then((result1) => {  
  
  if(result1.data!=undefined && result1.data.length>0)
  {
    setSponsoredInactiveCampaign(result1.data);
  }
  else
  {
    setSponsoredInactiveCampaign([]);
  }

})
.catch((error) => {
  console.log(error);
  alert(error);
});
        }
        
  };



  


  const activeSponsoredCampaignData = activeSponsoredCampaign.map((item) => ({
    id:item.id,
    campaignName: item.campaignName,
    type:item.campaignType=="flyer"?"Flyer":"Sponsored",
    product:"",
    startDate:item.sponsoredValidFrom,
    endDate:item.sponsoredValidTill,
    budget:item.budget,
    Action:item.id,
  }));

  const inactiveSponsoredCampaignData = inactiveSponsoredCampaign.map((item) => ({
    id:item.id,
    campaignName: item.campaignName,
    type:item.campaignType=="flyer"?"Flyer":"Sponsored",
    product:"",
    startDate:item.sponsoredValidFrom,
    endDate:item.sponsoredValidTill,
    budget:item.budget,
    Action:item.id,
  }));

  
  

  

  return (
    
    <TabContext value={value}>
      <div className={classes.tab}>
        <TabList
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="simple tabs example"
          centered
        >
          <Tab
            className={value === "1" && "font-weight-bold"}
            label={"Active Campaign"}
            value="1"
          />
          <Tab
            className={value === "2" && "font-weight-bold"}
            label={"Inactive Campaign"}
            value="2"
          />
         
        </TabList>
        {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("VIEW_ALL_COMPANY")) ? (
        <div className="d-flex">
        <Form.Control
          as="select"
          className="mr-3"
          name="company"
          value={localStorage.getItem('companyFilterCampaign')}
          onChange={getCompanyBranches}
          required
        >
          <option>Select Company</option>
          {companyList.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Form.Control>
        
        <Form.Control
          as="select"
          className="mr-sm-0"
          name="companyBranch"          
          value={localStorage.getItem('branchFilterCampaign')}
          onChange={handleBranchChanage}
          required
        >
          <option>Select Branch</option>
          {storeData.map((item, index) => (
            <option key={item.id} value={item.id}>
              {item.code}
            </option>
          ))}
        </Form.Control>
        </div>
         ) : null}
         {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.isAgentUser=='Y' || loggedUserInfo.privilegeCode.includes("CAN_ADD_UPDATE")) ? (  
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<AddRoundedIcon />}
          component={NavLink}
          to={"/home/campaign/campaignType?type=sponsored"}
        >
          Create Campaign
        </Button>
         ) : null}
      </div>
      <TabPanel value="1">
        <MUIDataTable
          title={"Active Sponsored Campaign"}
          data={activeSponsoredCampaignData}
          columns={sponsoredColumns}
          options={activeOptions}
        />
      </TabPanel>
      <TabPanel value="2">
        <MUIDataTable
          title={"Inactive Sponsored Campaign"}
          data={inactiveSponsoredCampaignData}
          columns={sponsoredInActivecolumns}
          options={InactiveOptions}
        />
      </TabPanel>
      
      

      <ToastContainer />
      <Modal
        show={isOpen}
        onHide={handleClose}
        centered
        size="l"
      >
        <ViewCampaignAreaList areaList={areaList}  setIsOpen={setIsOpen}  />;
      </Modal>
    </TabContext>
    
    

   
  )
}
export default SponsoredCampaignList
