import React, { useEffect, useState } from "react"
import { useStore, useAuth } from "../context"
import { Stores } from "."
import MUIDataTable from "mui-datatables"
import Button from "@material-ui/core/Button"
import AddRoundedIcon from "@material-ui/icons/AddRounded"
import { NavLink } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom";
//import Modal from "react-modal";
import {Modal} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import API from "../utils/API";
import Moment from 'react-moment';
import { findAllByDisplayValue } from "@testing-library/react"
import moment from 'moment';

const columns = [
  {
    name: "offer",
    label: "Offer/Collection/Product",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "type",
    label: "Type",
    options: {
      filter: false,
      sort: true,
    },
  }, 
  {
    name: "StartDate",
    label: "Start Date",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        //alert(value);
        return (
        <Moment tz="CST" format="YYYY/MM/DD hh:mm:ss A">
        {value}
        </Moment>
        );
       } 
    },
  },
  {
    name: "EndDate",
    label: "End Date",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        //alert(value);
        return (
        <Moment tz="CST" format="YYYY/MM/DD hh:mm:ss A">
        {value}
        </Moment>
        );
       } 
    },
  },
  {
    name: "budget",
    label: "Budget",
    options: {
      filter: false,
      sort: true,
    },
  },  
  
  /*{
    name: "cpc",
    label: "CPC",
    options: {
      filter: false,
      sort: false,
    },
  },  */
  {
    name: "impressions",
    label: "Impressions",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "clicks",
    label: "Clicks",
    options: {
      filter: false,
      sort: true,
    },
  },
  
 /* {
    name: "action",
    label: "Action",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        //alert(value);
        return (
          <Button
          variant="contained"
          color="primary"
         // startIcon={<AddRoundedIcon />}
          component={NavLink}
          to={"/home/categories/AddCategory?id="+value}
        >
          Update
        </Button>
        );
      }
    },
  },*/
  
];

const sponsoredColumns = [
  {
    name: "section",
    label: "Section/Keyword",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "type",
    label: "Type",
    options: {
      filter: false,
      sort: true,
    },
  }, 
  {
    name: "bid",
    label: "Bid",
    options: {
      filter: false,
      sort: true,
    },
  }, 
  {
    name: "suggestedBid",
    label: "Suggested Bid",
    options: {
      filter: false,
      sort: true,
    },
  },  
  
 /* {
    name: "action",
    label: "Action",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        //alert(value);
        return (
          <Button
          variant="contained"
          color="primary"
         // startIcon={<AddRoundedIcon />}
          component={NavLink}
          to={"/home/categories/AddCategory?id="+value}
        >
          Update
        </Button>
        );
      }
    },
  },*/
  
];

const useStyles = makeStyles(() => ({
  tab: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    padding: "0 10px",
    boxShadow: "0 0 15px silver",
  },
  button: {
    "&:hover": {
      color: "#fff",
    },
  },
}))

const rowData=[];

function ManageCampaign(props) {

  const campaignId=new URLSearchParams(props.location.search).get("id");
  const type=new URLSearchParams(props.location.search).get("type");
  const classes = useStyles()

  const [offerCampaign, setOfferCampaign] = useState([]);
  const [productCampaign, setProductCampaign] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [isInactive, setIsInactive] = useState(false);
  const { userInfo,loggedUserInfo } = useAuth();
  const options = {
    rowsPerPage: 10,
    print: false,
    selectableRowsOnClick: false,
    selectToolbarPlacement: "none",
    selectableRows:"none",
   /* customToolbar: () => (
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddRoundedIcon />}
       // onClick={toggleModal}
        component={NavLink}
        to={"/home/categories/AddCategory"}
      >
        Add Category
      </Button>
    ),*/
    
  }

  useEffect(() => {
    if(campaignId!=undefined)
   {

    setOfferCampaign([])
    setProductCampaign([])
          API.getCampaignById(campaignId).then((campaignResult) => {  
       
            if(campaignResult.data!=undefined )
            {

              setCampaignData(campaignResult.data)
              var date1 = moment(campaignResult.data.validTill)
                    var nowDate= moment();
                    console.log("date1.isBefore(nowDate) date>>>>>>>>>>>",date1.isBefore(nowDate))
                    console.log("nowDate date>>>>>>>>>>>",nowDate)
                    console.log("to date>>>>>>>>>>>",date1)
                    if(date1.isBefore(nowDate))
                    {
                      setIsInactive(true);
                    }
                    else
                    {
                      setIsInactive(false);
                    }

            }
                 })
                 .catch((error) => {
                   console.log(error);
                   alert(error);
                });

                API.getCampaignOffer(campaignId,type,undefined,undefined).then((result) => {  
       
                  if(result.data!=undefined && result.data.length>0)
                  {
                    setOfferCampaign(result.data)
                    
                  }
                       })
                       .catch((error) => {
                         console.log(error);
                         alert(error);
                      });

                      API.getCampaignProduct(campaignId,type,undefined,undefined).then((result) => {  
       
                        if(result.data!=undefined && result.data.length>0)
                        {
                          setProductCampaign(result.data)
                        }
                             })
                             .catch((error) => {
                               console.log(error);
                               alert(error);
                            });
    }
  }, [campaignId]);
console.log("productCampaign>>>>>>>>>>>>>>",productCampaign);
console.log("offerCampaign>>>>>>>>>>>>>>",offerCampaign);
console.log("offer id>>>>>>>>>>",'offerId' in offerCampaign);
  const allCampaignItem = [...offerCampaign, ...productCampaign];
  const rowData = allCampaignItem.map((item) => ({
    
    offer: 'offerId' in item?item.name:item.heading,
    StartDate: item.validFrom,
    EndDate:item.validTill,
    budget:item.budget,
    type:'offerId' in item?"Product":item.type,
   // cpc:item.cpc,
    impressions:item.impressions,
    clicks:item.clicks
  }));
  
  /*let sponsoredRowData=[];
  if(type=="sponsored" && campaignData!=undefined && campaignData!=null)
  {
    const allSponsoredCampaignItem = [...campaignData.sponsoredSection, ...campaignData.keywords];
     sponsoredRowData = allSponsoredCampaignItem.map((item) => ({
    
      section: 'section' in item?item.section:item.keyword,
      bid: item.bid,
      suggestedBid:item.suggestedBid,
      budget:item.budget,
      type:'section' in item?"Section":"Keyword",
     // cpc:item.cpc,
      impressions:item.impressions,
      clicks:item.clicks
    }));
  }*/

  return (
    <div>
      <div className="mb-4 mt-4">
        {loggedUserInfo!=null && loggedUserInfo!=undefined && (loggedUserInfo.isMaster=='Y' || loggedUserInfo.privilegeCode.includes("CAN_ADD_UPDATE")) ? (  
    <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<AddRoundedIcon />}
          //   size="small"
          component={NavLink}
          to={"/home/campaign/campaignType"}
        >
         Create Campaign
        </Button>
        ) : null}
        &nbsp;&nbsp;
        {isInactive  ?  (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
         // startIcon={<AddRoundedIcon />}
          //   size="small"
          component={NavLink}
          to={"/home/campaign/promotionBatch?type="+campaignData.campaignType+"&id="+campaignData.id}
        >
         Renew This Campaign
        </Button>
        ):""}

        </div>
        {type=="sponsored"?
    <MUIDataTable
    className="mb-3"
    title={campaignData.campaignName}
    data={rowData}
    columns={columns}
    options={options}
      />
      :<MUIDataTable
      className="mb-3"
      title={campaignData.campaignName}
      data={rowData}
      columns={columns}
      options={options}
    />}
      </div>
  )
}

export default ManageCampaign
