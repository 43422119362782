import React from "react"
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom"
import CatalogCollectionDetails from "../domain/CatalogCollectionDetails"
import CatalogProductDetails from "../domain/CatalogProductDetails"
import ManageCatalog from "../domain/ManageCatalog"
import ManageInvoice from "../domain/ManageInvoice"
import ViewInvoice from "../domain/ViewInvoice"

function Catalog() {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/manage`} component={ManageCatalog} />
      <Route path={`${path}/catalogDetail`} component={CatalogProductDetails} />
      <Route path={`${path}/catalogCollectionDetail`} component={CatalogCollectionDetails} />
      <Redirect to={`${path}/manage`} />
    </Switch>
  )
}

export default Catalog
